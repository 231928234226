import { connect } from 'react-redux';
import { checkRoles } from 'modules/auth';
import AnnualPlans from '../components/AnnualPlans';

const mapDispatchToProps = {
  checkRoles,
};

const mapStateToProps = (state) => ({
  agencyId: state.auth.profile.extend.agency.id,
});

export default connect(mapStateToProps, mapDispatchToProps)(AnnualPlans);
