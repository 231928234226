import Client from 'api/release/HTTPClient';
import { ITvCppWithBaseCoefficients } from 'types';

const tvAPI = {
  getPrices (year: number): Promise<ITvCppWithBaseCoefficients[]> {
    return Client.get(`/api/tvPrices/?year=${year}`);
  },

  getPricesAndCache (year): Promise<ITvCppWithBaseCoefficients[]> {
    return Client.get(`/api/tvPrices/?year=${year}`);
  },
};

export default tvAPI;
