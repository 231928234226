import alert from 'react-s-alert';

type AlertType = 'warning' | 'error' | 'success' | 'info';
const showAlert = (type: AlertType) => (message: string, config?: any) => {
  if (process.env.NODE_ENV === 'development') {
    const consoleMessageType =
      type === 'warning' ? 'warn' :
        type === 'error' ? 'error' :
          'log';

    console[consoleMessageType](message);
  }

  alert[type](message, config);
};

const CustomAlert = {
  info: showAlert('info'),
  log: showAlert('info'),
  success: showAlert('success'),
  warn: showAlert('warning'),
  warning: showAlert('warning'),
  danger: showAlert('error'),
  error: showAlert('error'),
};

export default CustomAlert;
