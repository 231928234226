import React, { Component, Fragment } from 'react';
import cx from 'classnames';
import moment from 'moment';
import Tooltip from 'rc-tooltip';
import LocalizedMessage from 'components/LocalizedMessage';
import {
  formattedNumber,
  formattedNumberWithZero,
  percentOrHyphen
} from 'helpers/utils';
import classes from '../AnnualPlan.module.scss';
import { OutMediaplanRepresentation, TargetAudience } from 'types';
import Select from 'components/Select';

interface IProps {
  mediaplans: OutMediaplanRepresentation[];
  targetAudience: TargetAudience | undefined;
  onChangeTargetAudience: (ta: any) => void;
}

interface IState {
  mediaplans: OutMediaplanRepresentation[];
  annualBudget: number;
  targetAudiences: any;
  isNewSchema: boolean;
}
class AdCampaignsList extends Component<IProps, IState> {
  state: IState = {
    mediaplans: [],
    annualBudget: 0,
    targetAudiences: '',
    isNewSchema: false
  };

  reachIndexesArray = Array.from({ length: 6 });

  componentDidMount () {
    this.setState(AdCampaignsList.parseMediaplans(this.props.mediaplans));
  }

  static getDerivedStateFromProps (props) {
    return AdCampaignsList.parseMediaplans(props.mediaplans);
  }

  static parseMediaplans (mediaplans) {
    let annualBudget = 0;
    let isNewSchema;
    const targetAudiences: string[] = [];

    mediaplans.forEach(mediaplan => {
      annualBudget += mediaplan.budget || 0;

      if (mediaplan.targetAudience) {
        targetAudiences.push(mediaplan.targetAudience.name);
      }

      const { calculations } = mediaplan;
      isNewSchema = !(
        calculations &&
        calculations.restricted &&
        calculations.restricted.reachAll === null
      );
    });

    return {
      isNewSchema,
      annualBudget,
      targetAudiences: [...new Set(targetAudiences)].join(', ')
    };
  }

  periodFormatter = (from, to) => `${moment(from).format('DD/MM/YY')} - ${moment(to).format('DD/MM/YY')}`;

  render () {
    const { mediaplans } = this.props;
    const { isNewSchema, annualBudget, targetAudiences } = this.state;

    const isShowConversions =
      mediaplans[0].isConverseOptimization ||
      mediaplans[0].isConverseOptimization === null;

    return (
      <div className='row'>
        <div className='col-lg-12'>
          <div className='ibox'>
            <div className='ibox-title'>
              <h5>
                <LocalizedMessage id='campaigns' />
              </h5>
            </div>
            <div className='ibox-content'>
              <div className={classes['display-flex']}>
                <div>
                  <table
                    className={cx(
                      'table',
                      classes.table,
                      classes['centered-cells'],
                      classes['annualPlan-campaigns-show-fixed-table']
                    )}
                  >
                    <thead>
                      <tr>
                        <th
                          className='text-nowrap'
                          style={{ minWidth: '145px' }}
                        >
                          <div>
                            <br />
                            <div>
                              <div>
                                <LocalizedMessage id='title' />
                              </div>
                            </div>
                          </div>
                        </th>
                      </tr>
                      <tr>
                        <th>
                          <br />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {mediaplans.map((row, index) => (
                        <tr
                          key={`${row.name || '-'}_${index}`}
                          style={{ height: 49 * 3 }} // rowspan=3 не работает
                        >
                          <td
                            className={cx(
                              'text-nowrap',
                              classes['campaigns-table-cell-center']
                            )}
                          >
                            {row.name || '-'}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <div className={classes['right-flex-table']}>
                  <table
                    className={cx(
                      'table',
                      classes.table,
                      classes['centered-cells'],
                      classes['campaigns-show-table']
                    )}
                  >
                    <thead>
                      <tr>
                        <th
                          rowSpan={2}
                          className='text-nowrap'
                          style={{ minWidth: '136px' }}
                        >
                          <LocalizedMessage id='brand' />
                        </th>
                        <th
                          rowSpan={2}
                          className='text-nowrap'
                          style={{ minWidth: '149px', width: '149px' }}
                        >
                          <LocalizedMessage id='period' />
                        </th>
                        <th
                          rowSpan={2}
                          className='text-nowrap'
                          style={{ minWidth: '149px', width: '149px' }}
                        >
                          <LocalizedMessage id='retroperiod' />
                          {' '}
                          <Tooltip
                            placement='top'
                            overlay={
                              <LocalizedMessage id='retroperiod.description' />
                            }
                          >
                            <i className='glyphicon glyphicon-info-sign' />
                          </Tooltip>
                        </th>
                        <th rowSpan={2} style={{ minWidth: '150px' }}>
                          <LocalizedMessage id='targetAudience' />
                        </th>
                        {!isNewSchema ? (
                          <th
                            colSpan={2}
                            className={cx(
                              'text-nowrap',
                              classes['light-blue-background']
                            )}
                            style={{ borderBottomColor: 'transparent' }}
                          >
                            <LocalizedMessage id='annualPlan.adCampaigns.heads.calculationResults' />
                          </th>
                        ) : (
                          <>
                            <th
                              rowSpan={2}
                              className={cx(
                                'text-nowrap',
                                classes['light-blue-background']
                              )}
                            >
                              <LocalizedMessage id='media' />
                            </th>
                            <th
                              rowSpan={2}
                              className={cx(
                                'text-nowrap',
                                classes['light-blue-background']
                              )}
                            >
                              <LocalizedMessage id='retroperiod' />
                            </th>
                            <th
                              rowSpan={2}
                              className={cx(
                                'text-nowrap',
                                classes['light-blue-background']
                              )}
                            >
                              <LocalizedMessage id='annualPlan.adCampaigns.heads.universe' />
                            </th>
                            <th
                              colSpan={6}
                              className={cx(
                                'text-nowrap',
                                classes['light-blue-background'],
                                classes['cell-with-border']
                              )}
                            >
                              <LocalizedMessage id='annualPlan.adCampaigns.heads.reachPercent' />
                            </th>
                            <th
                              colSpan={isShowConversions ? 3 : 2}
                              className={cx(
                                'text-nowrap',
                                classes['light-yellow-background'],
                                classes['cell-with-border']
                              )}
                            >
                              <LocalizedMessage id='annualPlan.adCampaigns.heads.plan' />
                            </th>
                          </>
                        )}
                        <th
                          colSpan={3}
                          className={cx(
                            'text-nowrap',
                            classes['light-green-background']
                          )}
                          style={{ borderBottomColor: 'transparent' }}
                        >
                          <LocalizedMessage id='budget' />
                        </th>
                      </tr>
                      <tr>
                        {isNewSchema ? (
                          <>
                            <th
                              className={cx(
                                'text-nowrap',
                                classes['light-blue-background'],
                                classes['cell-with-border']
                              )}
                            >
                              1+
                            </th>
                            <th
                              className={cx(
                                'text-nowrap',
                                classes['light-blue-background'],
                                classes['cell-with-border']
                              )}
                            >
                              2+
                            </th>
                            <th
                              className={cx(
                                'text-nowrap',
                                classes['light-blue-background'],
                                classes['cell-with-border']
                              )}
                            >
                              3+
                            </th>
                            <th
                              className={cx(
                                'text-nowrap',
                                classes['light-blue-background'],
                                classes['cell-with-border']
                              )}
                            >
                              4+
                            </th>
                            <th
                              className={cx(
                                'text-nowrap',
                                classes['light-blue-background'],
                                classes['cell-with-border']
                              )}
                            >
                              5+
                            </th>
                            <th
                              className={cx(
                                'text-nowrap',
                                classes['light-blue-background'],
                                classes['cell-with-border']
                              )}
                            >
                              6+
                            </th>
                            <th
                              className={cx(
                                'text-nowrap',
                                classes['light-yellow-background'],
                                classes['cell-with-border']
                              )}
                            >
                              <LocalizedMessage id='annualPlan.adCampaigns.heads.reachFrequency' />
                            </th>
                            <th
                              className={cx(
                                'text-nowrap',
                                classes['light-yellow-background'],
                                classes['cell-with-border']
                              )}
                            >
                              <LocalizedMessage id='annualPlan.adCampaigns.heads.reachShare' />
                            </th>
                            {isShowConversions && (
                              <th
                                className={cx(
                                  'text-nowrap',
                                  classes['light-yellow-background'],
                                  classes['cell-with-border']
                                )}
                              >
                                <LocalizedMessage id='annualPlan.adCampaigns.heads.conversions' />
                              </th>
                            )}
                          </>
                        ) : (
                          <>
                            <th
                              className={cx(
                                'text-nowrap',
                                classes['light-blue-background']
                              )}
                              style={{ minWidth: '145px', paddingRight: 0 }}
                            >
                              <LocalizedMessage id='annualPlan.adCampaigns.heads.reach' />
                            </th>
                            <th
                              className={cx(
                                'text-nowrap',
                                classes['light-blue-background']
                              )}
                              style={{ minWidth: '145px', paddingRight: 0 }}
                            >
                              <LocalizedMessage id='annualPlan.adCampaigns.heads.reachPercent' />
                            </th>
                          </>
                        )}
                        <th
                          className={cx(
                            'text-nowrap',
                            classes['light-green-background']
                          )}
                          style={{ minWidth: '145px', paddingRight: 0 }}
                        >
                          <LocalizedMessage id='campaign' />
                        </th>
                        <th
                          className={cx(
                            'text-nowrap',
                            classes['light-green-background']
                          )}
                          style={{
                            paddingLeft: 0,
                            paddingRight: 0,
                            minWidth: '100px'
                          }}
                        >
                          <LocalizedMessage id='internet' />
                        </th>
                        <th
                          className={cx(
                            'text-nowrap',
                            classes['light-green-background']
                          )}
                          style={{ minWidth: '108px', paddingLeft: 0 }}
                        >
                          <LocalizedMessage id='tv' />
                        </th>
                      </tr>
                    </thead>

                    <tbody>{mediaplans.map(this.renderMediaplanRow)}</tbody>
                  </table>
                </div>
              </div>

              <div className='hr-line-dashed' />

              {mediaplans && mediaplans.length ? (
                <div className='form-horizontal'>
                  <div className='row'>
                    <div className='row m-b-sm'>
                      <div className='col-xs-1' />
                      <div className='col-xs-3 text-right'>
                        <label>
                          <LocalizedMessage id='totalBudgetRur' />
                        </label>
                      </div>
                      <div className='col-xs-8'>
                        {formattedNumberWithZero(annualBudget)}
                      </div>
                    </div>
                    <div className='row m-b-sm'>
                      <div className='col-xs-1' />
                      <div className='col-xs-3 text-right'>
                        <label>
                          <LocalizedMessage id='targetAudiences' />
                        </label>
                      </div>
                      <div className='col-xs-8'>{targetAudiences}</div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderMediaplanRow = (
    mediaplan: OutMediaplanRepresentation,
    mediaplanIndex
  ) => {
    const { isNewSchema } = this.state;
    const { targetAudience } = this.props;

    const taOptions = [
      ...Object.entries(mediaplan.additionalTAStats || {}).map(([key, t]) => ({
        value: { id: Number(key), name: t.name },
        label: t.name
      })),
      {
        label: mediaplan.targetAudience.name,
        value: undefined
      }
    ];

    const { calculations: dCalc } = mediaplan;

    const ta: TargetAudience | undefined = this.props.targetAudience;

    const { TV = {}, OLV = {}, ALL = {} }: any =
      ta && mediaplan.additionalTAStats
        ? mediaplan.additionalTAStats[ta.id].campaignCommonData
        : mediaplan.campaignCommonData;

    const calculations =
      ta && mediaplan.additionalTAStats
        ? mediaplan.additionalTAStats[ta.id].calculations.calculations
        : dCalc;

    const result = ALL;

    const resultReachPercent = calculations?.reachPercent;

    const frequency = dCalc?.frequency
      ? dCalc.frequency.replace('null+', '')
      : '';

    // null check is for old mediaplans where this check wasn't implemented
    const isShowConversions =
      mediaplan.isConverseOptimization ||
      mediaplan.isConverseOptimization === null;

    return (
      <Fragment key={mediaplanIndex}>
        <tr key={`${mediaplanIndex}-TV`} style={{ height: '49px' }}>
          <td className='text-nowrap' rowSpan={3}>
            {mediaplan.brand && mediaplan.brand.name
              ? mediaplan.brand.name
              : '-'}
          </td>
          <td className='text-nowrap' rowSpan={3}>
            {this.periodFormatter(mediaplan.from, mediaplan.to)}
          </td>
          <td className='text-nowrap' rowSpan={3}>
            {this.periodFormatter(mediaplan.retroFrom, mediaplan.retroTo)}
          </td>
          <td className='text-nowrap' rowSpan={3}>
            <Select
              options={taOptions}
              onChange={(e: any) => {
                this.props.onChangeTargetAudience(e.value);
              }}
              value={
                targetAudience
                  ? { label: targetAudience!.name, value: targetAudience }
                  : {
                    label: mediaplan.targetAudience.name,
                    value: mediaplan.targetAudience
                  }
              }
            />
          </td>
          {!isNewSchema ? (
            <>
              <td
                className={classes['light-blue-background']}
                style={{ paddingRight: 0 }}
              >
                {calculations &&
                calculations.restricted &&
                calculations.restricted.reach !== null
                  ? formattedNumberWithZero(calculations.restricted.reach)
                  : ''}
              </td>
              <td
                className={classes['light-blue-background']}
                style={{ paddingLeft: 0 }}
              >
                {calculations &&
                calculations.restricted &&
                calculations.restricted.reachPlus !== null &&
                calculations.restricted.reachPlus !== undefined
                  ? formattedNumberWithZero(calculations.restricted.reachPlus)
                  : ''}
              </td>
            </>
          ) : (
            <>
              <td className={classes['light-blue-background']}>
                <span className='text-nowrap'>TV</span>
              </td>
              <td className={classes['light-blue-background']}>
                <span className='text-nowrap'>
                  {this.periodFormatter(TV.retroFrom, TV.retroTo)}
                </span>
              </td>
              <td className={classes['light-blue-background']}>
                <span className='text-nowrap'>
                  {formattedNumber(TV.universe, 3)}
                </span>
              </td>
              {this.reachIndexesArray.map((_, index) => (
                <td
                  key={index}
                  className={cx(
                    classes['light-blue-background'],
                    classes['cell-with-border']
                  )}
                >
                  <span className='text-nowrap'>
                    {TV.reachAll && TV.reachAll[index] !== undefined
                      ? formattedNumberWithZero(TV.reachAll[index].reachPercent)
                      : 0}
                  </span>
                </td>
              ))}
              <td
                className={cx(
                  classes['light-yellow-background'],
                  classes['cell-with-border']
                )}
              >
                <span className='text-nowrap'>{frequency || ''}</span>
              </td>
              <td
                className={cx(
                  classes['light-yellow-background'],
                  classes['cell-with-border']
                )}
              >
                <span className='text-nowrap'>
                  {calculations &&
                  calculations.restricted &&
                  calculations.restricted.reachPercent !== null
                    ? formattedNumberWithZero(
                      calculations.restricted.reachPercent
                    )
                    : ''}
                </span>
              </td>
              {isShowConversions && (
                <td
                  className={cx(
                    classes['light-yellow-background'],
                    classes['cell-with-border']
                  )}
                >
                  <span className='text-nowrap'>
                    {typeof calculations?.conversion === 'number'
                      ? formattedNumberWithZero(calculations.conversion)
                      : ''}
                  </span>
                </td>
              )}
            </>
          )}
          <td
            rowSpan={3}
            className={classes['light-green-background']}
            style={{ paddingRight: 0 }}
          >
            {formattedNumberWithZero(mediaplan.budget || 0)}
            {' '}
            <span>
              <LocalizedMessage id='rur' />
            </span>
          </td>
          <td
            rowSpan={3}
            className={classes['light-green-background']}
            style={{ paddingLeft: 0, paddingRight: 0 }}
          >
            <span>
              <LocalizedMessage id='min' />
            </span>
            {' '}
            {percentOrHyphen(mediaplan.internetBudgetPercent || 0, 1)}
          </td>
          <td
            rowSpan={3}
            className={classes['light-green-background']}
            style={{ paddingLeft: 0 }}
          >
            <span>
              <LocalizedMessage id='min' />
            </span>
            {' '}
            {percentOrHyphen(mediaplan.tvBudgetPercent || 0, 1)}
          </td>
        </tr>
        <tr key={`${mediaplanIndex}-OLV`} style={{ height: '49px' }}>
          {isNewSchema && (
            <>
              <td className={classes['light-blue-background']}>
                <span className='text-nowrap'>OLV</span>
              </td>
              <td className={classes['light-blue-background']}>
                <span className='text-nowrap'>
                  {OLV.retroFrom &&
                    OLV.retroTo &&
                    this.periodFormatter(OLV.retroFrom, OLV.retroTo)}
                </span>
              </td>
              <td className={classes['light-blue-background']}>
                <span className='text-nowrap'>
                  {formattedNumber(OLV.universe, 3)}
                </span>
              </td>
              {this.reachIndexesArray.map((_, index) => (
                <td
                  key={index}
                  className={cx(
                    classes['light-blue-background'],
                    classes['cell-with-border']
                  )}
                >
                  <span className='text-nowrap'>
                    {OLV.reachAll &&
                      OLV.reachAll[index] !== undefined &&
                      formattedNumberWithZero(OLV.reachAll[index].reachPercent)}
                  </span>
                </td>
              ))}
              <td
                className={cx(
                  classes['light-yellow-background'],
                  classes['cell-with-border']
                )}
              >
                <span className='text-nowrap'>{frequency || ''}</span>
              </td>
              <td
                className={cx(
                  classes['light-yellow-background'],
                  classes['cell-with-border']
                )}
              >
                <span className='text-nowrap'>
                  {calculations &&
                    calculations.addPlatforms &&
                    calculations.addPlatforms.reachPercent !== null &&
                    formattedNumberWithZero(
                      calculations.addPlatforms.reachPercent
                    )}
                </span>
              </td>
              {isShowConversions && (
                <td
                  className={cx(
                    classes['light-yellow-background'],
                    classes['cell-with-border']
                  )}
                />
              )}
            </>
          )}
        </tr>
        <tr key={`${mediaplanIndex}-ALL`} style={{ height: '49px' }}>
          {isNewSchema && (
            <>
              <td className={classes['light-blue-background']}>
                <span className='text-nowrap'>TV+OLV</span>
              </td>
              <td className={classes['light-blue-background']} />
              <td className={classes['light-blue-background']}>
                <span className='text-nowrap'>
                  {formattedNumber(result.universe, 3)}
                </span>
              </td>
              {this.reachIndexesArray.map((_, index) => (
                <td
                  key={index}
                  className={cx(
                    classes['light-blue-background'],
                    classes['cell-with-border']
                  )}
                >
                  <span className='text-nowrap'>
                    {result.reachAll && result.reachAll[index] !== undefined
                      ? formattedNumberWithZero(
                        result.reachAll[index].reachPercent
                      )
                      : 0}
                  </span>
                </td>
              ))}
              <td
                className={cx(
                  classes['light-yellow-background'],
                  classes['cell-with-border']
                )}
              >
                <span className='text-nowrap'>{frequency || ''}</span>
              </td>
              <td
                className={cx(
                  classes['light-yellow-background'],
                  classes['cell-with-border']
                )}
              >
                <span className='text-nowrap'>
                  {resultReachPercent
                    ? formattedNumberWithZero(resultReachPercent)
                    : ''}
                </span>
              </td>
              {isShowConversions && (
                <td
                  className={cx(
                    classes['light-yellow-background'],
                    classes['cell-with-border']
                  )}
                >
                  <span className='text-nowrap'>
                    {typeof calculations?.conversion === 'number'
                      ? formattedNumberWithZero(calculations.conversion)
                      : ''}
                  </span>
                </td>
              )}
            </>
          )}
        </tr>
      </Fragment>
    );
  };
}

export default AdCampaignsList;
