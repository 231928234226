import React, { Component } from 'react';
import cx from 'classnames';
import { formattedNumberWithZero } from 'helpers/utils';

import LocalizedMessage, { localizeMessage } from 'components/LocalizedMessage';
import classes from '../AnnualPlan.module.scss';
import { OlvCalculated } from 'routes/AnnualPlan/components/AnnualPlan';
import { OlvFrontendRepresentation } from 'types';


interface IProps {
  olvCalculated: OlvCalculated
}

class OLVDealCalculation extends Component<IProps> {
  reachIndexesArray = Array.from({ length: 6 });

  render () {
    const { platforms, total } = this.props.olvCalculated;
    const updatedTotal = { ...total, name: `${localizeMessage({ id: 'total' })} OLV` };


    const custom = Object.keys(platforms[0].customColumns || {});

    return (
      <div className='row'>
        <div className='col-lg-12'>
          <div className='ibox'>
            <div className='ibox-title'>
              <h5>
                <LocalizedMessage id='annualPlan.OLVdealCalculation.title' />
              </h5>
            </div>
            <div className='ibox-content'>
              <table className={cx(
                'table',
                classes.table,
                'table-bordered',
                classes['centered-cells'],
              )}
              >
                <thead>
                  <tr>
                    <th className={cx(classes['month-budget-column'])} />
                    <th className={cx(classes['month-budget-column'])}>
                      <LocalizedMessage id='budget' />
                    </th>
                    <th className={cx(classes['month-budget-column'])}>
                      <LocalizedMessage id='annualPlan.OLVdealCalculation.shows' />
                    </th>
                    <th className={cx(classes['month-budget-column'])}>
                      <LocalizedMessage id='trp' />
                    </th>
                    {this.reachIndexesArray.map((_, index) => (
                      <th key={index} className={cx(classes['month-budget-column'])}>
                        <LocalizedMessage id='reach' />
                        {` ${index + 1}+`}
                      </th>
                    ))}
                    {custom.map(it => <th>{it}</th>)}
                  </tr>
                </thead>
                <tbody>
                  {platforms.map(this.renderRow)}
                  {this.renderRow(updatedTotal, 'total')}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderRow = (row: OlvFrontendRepresentation & { name: string}, key) => {
    const customColumns = Object.keys(row.customColumns || {});


    return (
      <tr key={key}>
        <td>
          {row.name}
        </td>
        <td>
          {formattedNumberWithZero(row.budget)}
        </td>
        <td>
          {formattedNumberWithZero(row.impression)}
        </td>
        <td>
          {formattedNumberWithZero(row.trp)}
        </td>
        {this.reachIndexesArray.map((_, index) => (
          <td key={index}>
            {`${row.reachAll && row.reachAll[index]
              ? formattedNumberWithZero(row.reachAll[index].reachPercent)
              : 0
            }%`}
          </td>
        ))}
        {customColumns.map(key => <td>{row.customColumns && row.customColumns[key] || '-'}</td>)}
      </tr>
    );
  };
}

export default OLVDealCalculation;
