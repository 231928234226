import { fetchResponse } from './helpers/fakeFetch';
import Client from '../release/HTTPClient';

import annualBudgetsList from './data/annualBudgetsList.json';
import annualBudgetsFilterItems from './data/annualBudgetsFilterItems.json';
import annualBudgetDetails from './data/annualBudgetDetails.json';

const annualBudgetsAPI = {
  list () {
    console.log('api.annualBudgets.list()');

    return fetchResponse(1000, annualBudgetsList);
  },

  getForAdvertiserPeriod () {
    console.log('api.annualBudgets.getForAdvertiserPeriod()');

    return Client.get('/api/annualBudgets/getForClientPeriod');
  },

  get (id) {
    console.log('api.annualBudgets.get(', id, ')');

    return fetchResponse(1000, annualBudgetDetails);
  },

  getFilterItems (request) {
    console.log('api.annualBudgets.getFilterItems(', request, ')');

    return fetchResponse(1000, annualBudgetsFilterItems);
  },

  save (id, data) {
    console.log('api.annualBudgets.getFilterItems(', id, ',', data, ')');

    if (!id) {
      return Client.post('/api/annualBudgets/new', data);
    }

    return Client.put(`/api/annualBudgets/${id}`, data);
  },
};

export default annualBudgetsAPI;
