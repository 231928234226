import { IAuthTokens } from "types";

type TSetCookie = (name: string, value: string, options?: {expires?: Date | number, path?: string}) => void
export const setCookie: TSetCookie = (name, value, options = {path: '/'}) => {
  let updatedCookie = `${name}=${value}`;

  if (options.expires) {
    const expires = options.expires instanceof Date ? options.expires.toUTCString() : options.expires;
    updatedCookie = `${updatedCookie}; ${typeof options.expires === 'number' ? 'max-age' : 'expires'}=${expires}`
  }

  updatedCookie = `${updatedCookie}; path=${options.path || '/'}; sameSite=Strict;`

  document.cookie = updatedCookie;
}

export const getCookie = (name: string): string | null => {
  const values = document.cookie.split(';').filter((i) => i.split('=')[0].trim() === name);

  if (!values.length) return null;
  const [value] = values;

  return value.split('=')[1];
}

export const deleteCookie = (name: string) => {
  setCookie(name, '', {expires: -1})
}

const getExpires = (expireIn: number, type: 'access' | 'refresh') => {
  const coefficient = type === 'access' ? 0.01 : 0.001;
  return Math.round(expireIn - (expireIn * coefficient));
}

const getExpireUTCString = (expireIn: number) => {
  return new Date(new Date().getTime() + (expireIn * 1000)).toUTCString()
}

export const addTokensToCookies = (authResponse: IAuthTokens) => {
  const {
    access_token: accessToken,
    refresh_token: refreshToken,
    expires_in: accessTokenExpireIn,
    refresh_expires_in: refreshTokenExpireIn
  } = authResponse;

  const expires = {
    access: getExpires(accessTokenExpireIn, 'access'),
    refresh: getExpires(refreshTokenExpireIn, 'refresh')
  }



  setCookie('accessToken', accessToken, { expires: expires.access });
  setCookie('refreshToken', refreshToken, { expires: expires.refresh });

  setCookie('accessTokenExpireIn', getExpireUTCString(expires.access));
  setCookie('refreshTokenExpireIn', getExpireUTCString(expires.refresh));
}
