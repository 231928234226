import React, { Component } from 'react';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import LocalizedMessage, { localizeMessage } from 'components/LocalizedMessage';

import Select from 'components/Select';
import Breadcrumbs from 'components/Breadcrumbs';

import API from 'api';
import Alert from 'helpers/alert';
import { Link, RouteComponentProps } from 'react-router-dom';
import { IGoal, IKPI, IOption } from 'types';


interface IState {
  data: IGoal | null;
  name?: string
  periodOptions: IOption[];
  kpi: IKPI[]
}
class Goal extends Component<RouteComponentProps<{ id: string}>, IState> {
  static propTypes = {};

  years = {
    startYear: moment().add(1, 'year').year(),
    endYear: moment().add(-5, 'year').year(),
  };

  unitsOptions = [
    { value: 'NUMBER', label: <LocalizedMessage id='goal.kpiTypes.number' /> },
    { value: 'PERCENT', label: <LocalizedMessage id='goal.kpiTypes.percent' /> },
    { value: 'BOOLEAN', label: <LocalizedMessage id='goal.kpiTypes.boolean' /> },
  ];

  state: IState = {
    data: null,

    periodOptions: this.getPeriodOptions(),

    name: '',
    kpi: [],
  };

  mounted = false;

  componentDidMount () {
    this.mounted = true;

    this.loadData();
  }

  componentWillUnmount () {
    this.mounted = false;
  }

  getPeriodOptions () {
    const years: IOption[] = [];

    for (let year = this.years.startYear; year >= this.years.endYear; year--) {
      years.push({
        value: year,
        label: year.toString(),
      });
    }

    return years;
  }

  async loadData () {
    const {
      match: {
        params,
      },
    } = this.props;

    if (!params.id) {
      return;
    }

    try {
      const goal = await API.goals.get(params.id);

      if (!this.mounted) {
        return;
      }

      this.setState({
        data: goal,
        name: goal.name,
        kpi: goal.kpi
          ? goal.kpi.map(kpi => ({
            name: kpi.name,
            unit: kpi.unit.name,
          }))
          : [],
      });
    } catch (error) {
      console.error(error);

      Alert.error(
        localizeMessage({ id: 'errors.errorLoadingData' }),
      );
    }
  }

  getBreadcrumbs () {
    return [
      {
        title: <LocalizedMessage id='home' />,
        link: '/app',
      },
      {
        title: <LocalizedMessage id='goals' />,
        link: '/app/goals',
      },
      {
        title: <LocalizedMessage id='site.title.goal' />,
      },
    ];
  }

  handleChangeKpi = (index, type, value) => {
    const { kpi } = this.state;

    kpi[index] = {
      ...kpi[index],
      [type]: value,
    };

    this.setState({
      kpi,
    });
  };

  getSelectUnitConfig (index) {
    const { kpi } = this.state;

    const value = this.unitsOptions.find(unit => unit.value === kpi[index].unit) || null;

    return {
      isSearchable: false,
      isMulti: false,
      value,
      onChange: unit => this.handleChangeKpi(index, 'unit', unit.value),
      options: this.unitsOptions,
      placeholder: <LocalizedMessage id='goal.unit.placeholder' />,
      inputId: `kpi_unit_${index}`,
    };
  }

  handleNameChange = (e) => {
    this.setState({
      name: e.target.value,
    });
  };

  validate = () => {
    const { name, kpi } = this.state;

    return !(
      !name?.length ||
      !kpi.length ||
      kpi.some(item => !item.name?.length || !item.unit || !item.unit.length)
    );
  };

  onSubmit = async (e) => {
    e.preventDefault();

    const {
      match: {
        params,
      },
    } = this.props;
    const {
      name,
      kpi,
    } = this.state;

    if (!this.validate()) {
      return;
    }

    try {
      await API.goals.save(params.id, {
        name,
        kpi,
      });

      Alert.success(
        localizeMessage({ id: 'goal.successfullySaved' }),
      );
    } catch (error) {
      console.error(error);

      Alert.warn(
        localizeMessage({ id: 'goal.errorSaving' }),
      );
    }
  };

  addItem = () => {
    const { kpi } = this.state;

    this.setState({
      kpi: [
        ...kpi,
        {
          name: '',
          unit: 'NUMBER',
        },
      ],
    });
  };

  deleteItem (index) {
    const { kpi } = this.state;

    kpi.splice(index, 1);

    this.setState({
      kpi,
    });
  }

  render () {
    const {
      data,

      name,
      kpi,
    } = this.state;

    return (
      <>
        <LocalizedMessage
          id='site.title.goal'
        >
          {localizedMessage => (
            <>
              <Helmet
                title={localizedMessage}
              />
              <Breadcrumbs
                title={localizedMessage}
                data={this.getBreadcrumbs()}
              />
            </>
          )}
        </LocalizedMessage>

        <div className='wrapper wrapper-content'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='ibox float-e-margins'>
                <div className='ibox-title'>
                  <h5>
                    <LocalizedMessage
                      id={`goal.${!data ? 'adding' : 'editing'}`}
                    />
                  </h5>
                </div>
                <div className='ibox-content'>
                  <form
                    onSubmit={this.onSubmit}
                  >
                    <div className='form-horizontal'>
                      <div className='form-group'>
                        <label className='col-lg-2 control-label' htmlFor='name'>
                          <LocalizedMessage id='name' />
                        </label>
                        <div className='col-lg-10'>
                          <LocalizedMessage id='goal.name.placeholder'>
                            {localizedPlaceholder => (
                              <input
                                id='name'
                                type='text'
                                className='form-control'
                                name='name'
                                value={name}
                                placeholder={localizedPlaceholder}
                                onChange={this.handleNameChange}
                              />
                            )}
                          </LocalizedMessage>
                        </div>
                      </div>

                      <div className='hr-line-dashed' />

                      {
                        kpi.map((kpi, index) => (
                          <div
                            key={`kpi_${index}`}
                            className='form-group'
                          >
                            <label className='col-md-2 control-label'>
                              <span><LocalizedMessage id='kpi' /></span>
                              {' '}
(
                              {index + 1}
)
                            </label>
                            <div className='col-md-10'>
                              <div className='row m-b'>
                                <div className='col-md-4'>
                                  <input
                                    type='text'
                                    className='form-control'
                                    style={{ height: '38px' }}
                                    name={`kpi_name_${index}`}
                                    value={kpi.name}
                                    onChange={(e) => this.handleChangeKpi(index, 'name', e.target.value)}
                                    required
                                  />
                                </div>
                                <div className='col-md-2'>
                                  <Select {...this.getSelectUnitConfig(index)} />
                                </div>
                                <div className='col-md-2'>
                                  <i
                                    className='fa fa-trash fa-lg form-control-static _cursor--pointer'
                                    onClick={() => this.deleteItem(index)}
                                  />
                                </div>
                                <br />
                                <br />
                              </div>
                            </div>
                          </div>
                        ))
                      }

                      <div className='form-group'>
                        <div className='col-md-2' />
                        <div className='col-md-10'>
                          <button
                            type='button'
                            className='btn btn-primary btn-xs'
                            onClick={this.addItem}
                          >
                            <LocalizedMessage id='goal.addKpiBtn' />
                          </button>
                        </div>
                      </div>

                      <div className='hr-line-dashed' />

                      <div className='form-group'>
                        <div className='col-lg-2' />
                        <div className='col-lg-10'>
                          <Link
                            className='btn btn-white'
                            to='/app/goals'
                          >
                            <span><LocalizedMessage id='cancel' /></span>
                          </Link>
                          &nbsp;&nbsp;
                          <button
                            type='submit'
                            className='btn btn-primary'
                            disabled={!this.validate()}
                            onClick={this.onSubmit}
                          >
                            <>
                              <i className='fa fa-chevron-down fa-lg' />
                              <span><LocalizedMessage id='save' /></span>
                            </>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Goal;
