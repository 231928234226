import React from 'react';
import cx from 'classnames';
import { Locale } from 'types';

interface IProps {
  lang: Locale;
  selectLocale: (lang: Locale) => void;
}

const locales: Locale[] = ['ru', 'en'];

const LangSwitcher = ({ lang, selectLocale }: IProps) => (
  <>
    {locales.map(cLocale => (
      <span key={cLocale}>
        {' '}
        <button
          className={cx(
            'btn btn-circle',
            lang === cLocale ? 'btn-primary' : 'btn-default',
          )}
          onClick={() => selectLocale(cLocale)}
          data-test={`locale-${cLocale}`}
        >
          {cLocale.toUpperCase()}
        </button>
        {' '}
      </span>
    ))}
  </>
);

export default LangSwitcher;
