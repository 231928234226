import classes from 'routes/AnnualPlanEdit/components/AnnualPlanEdit.module.scss';
import React, { useCallback } from 'react';
import cx from 'classnames';
import Select from 'components/Select';
import LocalizedMessage, { localizeMessage } from 'components/LocalizedMessage';
import { NumericInput } from 'components/NumericInput';
import { formatNullableNumber } from 'routes/AnnualPlanEdit/helpers/formatNullableNumber';

const allFrequencies = Array.from({ length: 20 }, (_, i) => i + 1).map(n => ({
  value: n,
  name: `${n}+`
}));

export interface ITvOLVValues {
  min: number | null;
  max: number | null;
  step: number | null;
  freqs: number[] | null;
  enable: boolean | null;
}

interface IProps {
  values?: ITvOLVValues;
  onChange: (values: ITvOLVValues) => void;
}
export const defaultTvOlvValues: ITvOLVValues = {
  min: 0,
  max: 100,
  step: 1,
  freqs: [],
  enable: false
};

export default (props: IProps) => {
  const { onChange, values } = props;

  // const passedValuesValid = (values && !hasNullValues(values));
  // const passedValuesValid = (values && !hasNullValues(values));
  const definiteValues = values || defaultTvOlvValues;

  const isFrequenciesDisabled = !definiteValues.enable;

  const isDisabledAll = isFrequenciesDisabled || definiteValues.freqs == null || definiteValues.freqs.length === 0;

  const updateValues = useCallback(
    (val: Partial<ITvOLVValues>) => {
      onChange({
        ...definiteValues,
        ...val
      });
    },
    [values, onChange]
  );

  return (
    <table
      className={cx(
        'table',
        classes.table,
        classes['centered-cells'],
        classes['table-selectable'],
        classes['campaigns-table']
      )}
    >
      <thead>
        <tr>
          <th>
            <div>
              <br />
              <div>
                <div>
                  <span>
                    <LocalizedMessage id='annualPlanEdit.campaignChartMessages.enabled' />
                  </span>
                </div>
              </div>
            </div>
          </th>
          <th style={{ width: 200, maxWidth: 200 }}>
            <div>
              <br />
              <div>
                <div>
                  <span>
                    <LocalizedMessage id='annualPlanEdit.campaignChartMessages.frequencies' />
                  </span>
                </div>
              </div>
            </div>
          </th>
          <th>
            <div>
              <br />
              <div>
                <div>
                  <span>
                    <LocalizedMessage id='annualPlanEdit.campaignChartMessages.step' />
                  </span>
                </div>
              </div>
            </div>
          </th>
          <th>
            <div>
              <br />
              <div>
                <div>
                  <span>
                    <LocalizedMessage id='annualPlanEdit.campaignChartMessages.min' />
                  </span>
                </div>
              </div>
            </div>
          </th>
          <th>
            <div>
              <br />
              <div>
                <div>
                  <span>
                    <LocalizedMessage id='annualPlanEdit.campaignChartMessages.max' />
                  </span>
                </div>
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <input
              type='checkbox'
              checked={definiteValues.enable || false}
              onChange={e => updateValues({
                enable: e.target.checked
              })}
            />
          </td>
          <td>
            <Select
              isMulti
              isDisabled={isFrequenciesDisabled}
              value={(definiteValues.freqs || []).map(t => ({
                value: t,
                label: t.toString()
              }))}
              onChange={vt => {
                if (Array.isArray(vt)) {
                  updateValues({
                    freqs: vt.map(t => t.value)
                  });
                }
              }}
              options={allFrequencies.map(frequency => ({
                label: frequency.name,
                value: frequency.value
              }))}
            />
          </td>
          <td>
            <NumericInput
              id='step'
              disabled={isDisabledAll}
              name='step'
              placeholder={localizeMessage({ id : 'annualPlanEdit.campaignChartMessages.step' })}
              className={'form-control'}
              min={1}
              max={100}
              value={formatNullableNumber(definiteValues.step)}
              onChange={e => updateValues({
                step: e.target.value === '' ? null : Number(e.target.value)
              })}
              required
            />
          </td>
          <td>
            <NumericInput
              id='min'
              name='min'
              disabled={isDisabledAll}
              placeholder={localizeMessage({ id : 'annualPlanEdit.campaignChartMessages.min' })}
              className={'form-control'}
              min={0}
              max={100}
              value={formatNullableNumber(definiteValues.min)}
              onChange={e => updateValues({
                min: e.target.value === '' ? null : Math.max(0, Number(e.target.value))
              })}
              required
            />
          </td>
          <td>
            <NumericInput
              id='max'
              name='max'
              disabled={isDisabledAll}
              placeholder={localizeMessage({ id : 'annualPlanEdit.campaignChartMessages.max' })}
              className={'form-control'}
              min={1}
              max={100}
              value={formatNullableNumber(definiteValues.max)}
              onChange={e => updateValues({
                max:  e.target.value === '' ? null : Math.min(100, Number(e.target.value))
              })}
              required
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
};
