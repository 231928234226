import React, { PureComponent } from 'react';
import { HotTable, HotTableProps } from '@handsontable/react';
import Handsontable from 'handsontable';
import moment from 'moment';
import set from 'lodash/set';
import property from 'lodash/property';

import { MAX_INT_VALUE } from 'helpers/constants';
import { formatNumberToNumber } from 'helpers/utils';
import LocalizedMessage, { localizeMessage } from 'components/LocalizedMessage';
import { IChannel, IFullChannelConfig, IdToChannelConfigMap, isFullChannelConfig } from 'routes/AnnualPlanEdit/types';
import { defaultSeasonalDiscounts } from 'routes/AnnualPlanEdit/helpers/tv';

const numberColumnIDs = Array.from({ length: 12 }).map((e, index) => `seasonalDiscounts.${index}`);

interface IProps {
  params: IdToChannelConfigMap;
  channelsToDisplay: IChannel[];
  isLoadingPrices: boolean;
  onUpdateValidationStatus: (isValid: boolean, type: string) => void;
}

type ChannelConfigForDisplay = {
  channel: IChannel;
  seasonalDiscounts: IFullChannelConfig['seasonalDiscounts'];
  defaultSeasonalDiscounts: IFullChannelConfig['defaultSeasonalDiscounts'];
  isExcludedChannel: IFullChannelConfig['restrictions']['isExcludedChannel'];
};

class Discounts extends PureComponent<IProps> {
  monthNames = moment.monthsShort();
  cellsErrors: Array<Record<string, Array<string>>> = [];

  UNSAFE_componentWillReceiveProps (nextProps: IProps) {
    if (
      this.props.channelsToDisplay !== nextProps.channelsToDisplay ||
      this.props.isLoadingPrices && !nextProps.isLoadingPrices
    ) {
      this.toDisplay = this.getRefreshedToDisplay(nextProps);
    }
  }

  getRefreshedToDisplay = (props = this.props): ChannelConfigForDisplay[] => {
    const { params, channelsToDisplay } = props;

    return channelsToDisplay.map(channel => {
      const channelConfig = params[channel.id];
      if (isFullChannelConfig(channelConfig)) {
        return {
          channel,
          seasonalDiscounts: channelConfig.seasonalDiscounts,
          defaultSeasonalDiscounts: channelConfig.defaultSeasonalDiscounts,
          isExcludedChannel: channelConfig.restrictions.isExcludedChannel,
        };
      }

      return {
        channel,
        seasonalDiscounts: defaultSeasonalDiscounts,
        defaultSeasonalDiscounts,
        isExcludedChannel: channelConfig.restrictions.isExcludedChannel,
      };
    });
  };

  toDisplay: ChannelConfigForDisplay[] = this.getRefreshedToDisplay();

  getClassByMonthId = (monthIndex: number): string => {
    switch (monthIndex) {
      case 0:
      case 1:
      case 2:
        return 'c-calendar-winter';
      case 3:
      case 4:
      case 5:
        return 'c-calendar-spring';
      case 6:
      case 7:
      case 8:
        return 'c-calendar-summer';
      case 9:
      case 10:
      case 11:
        return 'c-calendar-autumn';
      default:
        return '';
    }
  };

  generateTableOptions = (): HotTableProps => ({
    data: this.toDisplay,
    colHeaders: true,
    nestedHeaders: [
      [
        localizeMessage({ id: 'channel' }),
        ...this.monthNames,
      ],
    ],
    columns: [
      {
        data: 'channel.nameWithType',
        readOnly: true,
      },
      ...this.monthNames.map((month, index) => ({
        data: `seasonalDiscounts.${index}`,
        type: 'numeric',
        renderer: this.cellRenderer,
      })),
    ],
    cells: (row, col, prop: string) => {
      const cellProperties: Handsontable.CellMeta = {
        errorIDs: this.cellsErrors[row]
          ? this.cellsErrors[row][prop] || []
          : [],
      };

      const foundMatch = prop.match(/seasonalDiscounts.([0-9]+)/);
      if (foundMatch && !this.props.isLoadingPrices) {
        const monthIndex = Number(foundMatch[1]);

        if (this.toDisplay[row] && this.toDisplay[row].isExcludedChannel) {
          cellProperties.readOnly = true;
          cellProperties.className = 'htDisabled';
        } else if (this.toDisplay[row] &&
            (
              this.toDisplay[row].seasonalDiscounts[monthIndex] !==
              this.toDisplay[row].defaultSeasonalDiscounts[monthIndex]
            )
        ) {
          cellProperties.className = cellProperties.errorIDs.length ? 'invalid' : 'c-calendar-different';
        } else {
          cellProperties.className = this.getClassByMonthId(monthIndex);
        }
      }

      return cellProperties;
    },
  });

  cellRenderer = (...args) => {
    const td = args[1];
    const row = args[2];
    const prop = args[4];
    const cellProperties = args[6];

    Handsontable.renderers.TextRenderer.apply(this, args);

    if (this.props.isLoadingPrices) {
      td.innerHTML = `${localizeMessage({ id: 'loading' })}...`;

      return;
    }
    const value = property<ChannelConfigForDisplay, number>(prop)(this.toDisplay[row]);
    td.innerHTML = formatNumberToNumber(value);

    if (
      td &&
      cellProperties.className &&
      cellProperties.className.indexOf('c-calendar-different') > -1
    ) {
      const monthIndex = parseInt(prop.replace('seasonalDiscounts.', ''), 10);
      td.title = `Changed the base value. The base value is ${
        this.toDisplay[row].defaultSeasonalDiscounts[monthIndex]}`;
    } else if (
      td &&
      cellProperties.className &&
      cellProperties.className.indexOf('invalid') > -1
    ) {
      const msg = cellProperties.errorIDs.map(id => localizeMessage({ id })).join('\n');
      td.setAttribute('title', msg);
    }
  };

  beforeRender = () => {
    let isValidTable = true;
    this.cellsErrors = this.toDisplay.map(
      row => numberColumnIDs.reduce(
        (acc, columnID) => {
          const isExcludedChannel = row.isExcludedChannel;
          const errorIDs = isExcludedChannel ? [] : this.validateCell(row, columnID);

          if (errorIDs.length) {
            isValidTable = false;
          }

          return { ...acc, [columnID]: errorIDs };
        }, {},
      ),
    );
    this.props.onUpdateValidationStatus(isValidTable, 'discounts');
  };

  validateCell = (row, columnID) => {
    const errorIDs: string[] = [];
    if (numberColumnIDs.includes(columnID)) {
      const value = property<any, number>(columnID)(row);

      if (Number.isNaN(Number(value))) {
        errorIDs.push('annualPlanEdit.errors.notNumber');
      } else if (value <= 0) {
        errorIDs.push('annualPlanEdit.errors.notExceed0');
      } else if (value > MAX_INT_VALUE) {
        errorIDs.push('annualPlanEdit.errors.exceedMaxInteger');
      }
    }

    return errorIDs;
  };

  afterChange = (changes: Handsontable.CellChange[] | null) => {
    if (!changes) {
      return;
    }

    changes.forEach(([row, columnID, oldVal, newValue]) => {
      if (typeof newValue === 'number') {
        set(this.toDisplay[row], columnID, formatNumberToNumber(newValue));
      }
    });
  };

  render () {
    return (
      <>
        <p className='_text-align--center m-t m-b'>
          <LocalizedMessage id='annualPlanEdit.tvCampaignParams.discounts' />
        </p>

        <HotTable
          {...this.generateTableOptions()}
          tableClassName='table'
          stretchH='all'
          width='100%'
          height='auto'
          afterChange={this.afterChange}
          beforeRender={this.beforeRender}
          autoWrapRow
          licenseKey='non-commercial-and-evaluation'
        />
      </>
    );
  }
}

export default Discounts;
