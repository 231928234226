import { fetchResponse } from './helpers/fakeFetch';
import Client from '../release/HTTPClient';

const campaignPriceTemplatesAPI = {
  list (advertiserId) {
    console.log('api.campaignPriceTemplates.list(', advertiserId, ')');

    return fetchResponse(1000, {
      items: [],
      total: -1,
    });
  },

  get (advertiserId, id) {
    console.log('api.campaignPriceTemplates.list(', advertiserId, ', ', id, ')');

    return Client.get(`/api/clients/${advertiserId}/campaignPriceTemplates/${id}`);
  },

  delete (advertiserId, id) {
    console.log('api.campaignPriceTemplates.list(', advertiserId, ', ', id, ')');

    return Client.delete(`/api/clients/${advertiserId}/campaignPriceTemplates/${id}`);
  },

  save (advertiserId, data) {
    console.log('api.campaignPriceTemplates.list(', advertiserId, ', ', data, ')');

    return Client.put(`/api/clients/${advertiserId}/campaignPriceTemplates/new`, data);
  },
};

export default campaignPriceTemplatesAPI;
