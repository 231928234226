import { Line } from 'react-chartjs-2';
import React, { useState } from 'react';
import classes from '../../../AnnualPlan.module.scss';
import cx from 'classnames';
import { localizeMessage } from 'components/LocalizedMessage';
import LocalizedMessage from 'components/LocalizedMessage/LocalizedMessage';
import {
  reachChartColors,
  usePlotData,
  useDownloadChartCallback, useExportTvOlvExcelCallback
} from './charts-shared';
import Range from 'components/Range';

interface IProps {
  planId: string;
  taId: number;
}
interface IPlotDisplaySetup {
  min: number;
  max: number;
}

export default ({ planId, taId }: IProps) => {
  const data = usePlotData(planId, 'TVOLV');

  const exportExcel = useExportTvOlvExcelCallback(planId);
  const [settings, setSettings] = useState<IPlotDisplaySetup>({
    min: 0,
    max: 100
  });


  const tvOlvPlotsData = data && data[taId].plots.tvOlvPlot;
  const [chartId, downloadChart] = useDownloadChartCallback(planId, `${planId}-tv-olv-chart`);

  const getChartData = () => {
    if (!tvOlvPlotsData) {
      return {
        options: {},
        data: {}
      };
    }
    const leftChartLabel = 'Ratio';
    const chartLabels = Object.keys(tvOlvPlotsData).filter(t => t !== leftChartLabel);
    chartLabels.sort();

    const ratioData = tvOlvPlotsData[leftChartLabel];

    // desc sort of ratio data
    ratioData.sort((a, b) => b - a);

    const reversed = [...ratioData].reverse();

    const highestRatio = ratioData[0];
    const lowestRatio = ratioData[ratioData.length - 1];

    const chartOptions = {
      legend: {
        labels: {
          filter: (item: { text: string }) => !item.text.includes('Split')
        },
        display: true
      },
      tooltips: {
        callbacks: {
          label: item => `${chartLabels[item.datasetIndex]}: ${Math.round(item.value * 100) /
              100}%`,
          title: (item) => `${localizeMessage({
            id: 'annualPlan.tvOlvChart.tvShare'
          })} ${(item[0].label * 100).toFixed(2)}%`
        }
      },
      scales: {
        xAxes: [
          {
            display: true,
            id: 'C',
            position: 'bottom',
            ticks: {
              callback: (value, index) => `${Math.round(reversed[index] * 100)}%`,
            },
            scaleLabel: {
              display: true,
              labelString: localizeMessage({
                id: 'annualPlan.tvOlvChart.olvShare'
              })
            }
          }
        ],
        yAxes: [
          {
            display: true,
            id: 'A',
            position: 'right',
            ticks: {
              callback: value => `${value}%`,
              min: settings.min,
              max: settings.max
            },
            scaleLabel: {
              display: true,
              labelString: `${localizeMessage({
                id: 'annualPlan.tvOlvChart.reach'
              })} %`
            }
          },
          {
            display: true,
            id: 'B',
            position: 'left',
            ticks: {
              callback: value => `${Math.round(value * 100)}%`,
              min: lowestRatio,
              max: highestRatio
            },
            scaleLabel: {
              display: true,
              labelString: localizeMessage({
                id: 'annualPlan.tvOlvChart.tvShare'
              })
            }
          }
        ]
      }
    };

    const chartData = {
      labels: tvOlvPlotsData[leftChartLabel],
      datasets: [
        ...chartLabels.map((chartLabel, index) => ({
          label: chartLabel,
          data: tvOlvPlotsData[chartLabel],
          yAxisID: 'A',
          borderColor:
            reachChartColors[index + (1 % reachChartColors.length) - 1],
          backgroundColor: 'transparent'
        })),

        // diagonal split line
        {
          label: 'Split1',
          data: ratioData,
          type: 'line',
          yAxisID: 'B',
          borderWidth: 0,
          backgroundColor: 'rgba(255, 255, 0, 0.05)',
          hoverBackgroundColor: 'transparent',
          pointRadius: 0
        },
        // 100% top-to-bottom
        {
          label: 'Split2',
          data: new Array(ratioData.length).fill(highestRatio),

          type: 'line',
          yAxisID: 'B',
          borderWidth: 0,
          backgroundColor: 'rgba(255, 0, 0, 0.05)',
          hoverBackgroundColor: 'transparent',
          pointRadius: 0
        }
      ]
    };

    return {
      options: chartOptions,
      data: chartData
    };
  };

  const { options: tvOlvOptions, data: tvOlvData } = getChartData();

  return (
    <div className={cx('row', classes['ad-campaigns-calc-res-charts'])}>
      <div className='col-lg-6'>
        <Line
          id={chartId}
          data={tvOlvData}
          options={tvOlvOptions}
          height={200}
        />
      </div>
      <div className='col-lg-6 form-group'>
        <div className='form-group'>
          <label htmlFor='min'>
            <LocalizedMessage id={'annualPlan.tvOlvChart.reach'} />
            {' '}
            {settings.min}
            %
            {' - '}
            {' '}
            {settings.max}
            %
          </label>
          <Range
            min={0}
            max={100}
            minValue={settings.min}
            maxValue={settings.max}
            onChange={e => setSettings({ ...settings, min: e.min, max: e.max })}
          />
        </div>

        <div className='form-group'>
          <button onClick={downloadChart} className='btn btn-white'>
            <LocalizedMessage id='annualPlan.chartsPanel.downloadChart' />
          </button>
        </div>
        <div className='form-group'>
          <button onClick={exportExcel} className='btn btn-white'>
            <LocalizedMessage id='annualPlan.chartsPanel.exportChart' />
          </button>
        </div>
      </div>
    </div>
  );
};
