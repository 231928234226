import React, { PureComponent } from 'react';
import { HotTable, HotTableProps } from '@handsontable/react';
import moment from 'moment';
import set from 'lodash/set';
import property from 'lodash/property';
import Handsontable from 'handsontable';

import { MAX_INT_VALUE } from 'helpers/constants';
import { formatNumberToNumber } from 'helpers/utils';
import { getPricesWithDiscountsAndExtraChargesByMonthsForSite } from '../../../../helpers/tv';
import LocalizedMessage, { localizeMessage } from 'components/LocalizedMessage';
import {
  IChannel,
  IdToChannelConfigMap,
  CppData,
  IFullChannelConfig,
  isFullChannelConfig
} from 'routes/AnnualPlanEdit/types';

interface IProps {
  params: IdToChannelConfigMap;
  cpp: CppData | null;
  channelsToDisplay: IChannel[];
  isLoadingPrices: boolean;
  onUpdateValidationStatus: (isValid: boolean, type: string) => void;
}

type FullChannelConfigForDisplay = {
  channel: IChannel;
  cpp: IFullChannelConfig['cpp'];
  seasonalDiscounts: IFullChannelConfig['seasonalDiscounts'];
  defaultSeasonalDiscounts: IFullChannelConfig['defaultSeasonalDiscounts'];
  prices: Array<number | null>;
  priceWithDefaultDiscount: Array<number | null>;
  months: number[];
};

type ToDisplay = { channel: IChannel } | FullChannelConfigForDisplay;
const isFullChannelConfigForDisplay = (
  toDisplay: ToDisplay
): toDisplay is FullChannelConfigForDisplay => (toDisplay as FullChannelConfigForDisplay).cpp !== undefined;

const propsForValidate = ['cpp.advertiserBasePrice', 'cpp.additionalDiscount'];

class Cpp extends PureComponent<IProps> {
  _hot: any = null;
  monthNames = moment.monthsShort();
  cellsErrors: Record<string, string>[] = [];

  UNSAFE_componentWillReceiveProps (nextProps: IProps) {
    if (
      this.props.channelsToDisplay !== nextProps.channelsToDisplay ||
      this.props.cpp !== nextProps.cpp ||
      (this.props.isLoadingPrices && !nextProps.isLoadingPrices)
    ) {
      this.toDisplay = this.getRefreshedToDisplay(nextProps);
    }
  }

  setHotRef = ref => {
    this._hot = ref;
  };

  getCpp = (
    cpp: FullChannelConfigForDisplay['cpp'],
    prices: FullChannelConfigForDisplay['prices'],
    month: number
  ) => {
    const discountCoefficient =
      cpp && typeof cpp.additionalDiscount === 'number'
        ? 1 - cpp.additionalDiscount / 100
        : 1;

    return Math.round((prices[month] || 0) * discountCoefficient * 100) / 100;
  };

  getRefreshedToDisplay = (props = this.props) => {
    const { params, cpp, channelsToDisplay } = props;

    return channelsToDisplay.map(channel => {
      const channelConfig = params[channel.id];
      if (isFullChannelConfig(channelConfig) && cpp) {
        const prices = getPricesWithDiscountsAndExtraChargesByMonthsForSite(
          channel,
          cpp,
          channelConfig
        );
        const row: FullChannelConfigForDisplay = {
          channel,
          cpp: channelConfig.cpp,
          seasonalDiscounts: channelConfig.seasonalDiscounts,
          defaultSeasonalDiscounts: channelConfig.defaultSeasonalDiscounts,
          prices,
          priceWithDefaultDiscount: getPricesWithDiscountsAndExtraChargesByMonthsForSite(
            channel,
            cpp,
            channelConfig,
            true
          ),
          months: this.monthNames.map((month, index) => this.getCpp(channelConfig.cpp, prices, index)
          )
        };

        return row;
      }

      return { channel };
    });
  };

  toDisplay: ToDisplay[] = this.getRefreshedToDisplay(this.props);

  getClassByMonthId = (monthIndex: number): string => {
    switch (monthIndex) {
      case 0:
      case 1:
      case 2:
        return 'c-calendar-winter';
      case 3:
      case 4:
      case 5:
        return 'c-calendar-spring';
      case 6:
      case 7:
      case 8:
        return 'c-calendar-summer';
      case 9:
      case 10:
      case 11:
        return 'c-calendar-autumn';
      default:
        return '';
    }
  };

  generateTableOptions = (): HotTableProps => ({
    data: this.toDisplay,
    colHeaders: true,
    nestedHeaders: [
      [
        localizeMessage({ id: 'channel' }),
        localizeMessage({ id: 'inventoryType' }),
        localizeMessage({ id: 'annualPlanEdit.tvCampaignParams.cpp.price' }),
        localizeMessage({ id: 'annualPlanEdit.tvCampaignParams.cpp.discount' }),
        ...this.monthNames
      ]
    ],
    columns: [
      {
        data: 'channel.nameWithType',
        readOnly: true
      },
      {
        data: 'channel.inventoryType',
        readOnly: true,
      },
      {
        data: 'cpp.advertiserBasePrice',
        type: 'numeric',
        numericFormat: {
          pattern: {
            mantissa: 2,
            trimMantissa: true
          } as any
        }
      },
      {
        data: 'cpp.additionalDiscount',
        type: 'numeric',
        numericFormat: {
          pattern: {
            postfix: '%',
            mantissa: 2
          }
        }
      },
      ...this.monthNames.map((month, index) => ({
        data: `months.${index}`,
        type: 'numeric',
        renderer: this.cellRenderer,
        readOnly: true
      }))
    ],
    cells: (rowIndex, col, columnID: string): Handsontable.CellMeta => {
      const cellProperties: Handsontable.CellMeta = {
        className: '',
        errorIDs: this.cellsErrors[rowIndex]
          ? this.cellsErrors[rowIndex][columnID] || []
          : []
      };

      if (
        propsForValidate.includes(columnID) &&
        cellProperties.errorIDs.length
      ) {
        cellProperties.renderer = this.validableCellRenderer;
        cellProperties.className += ' invalid';
      }

      const foundMatch = columnID.match(/months.([0-9]+)/);
      if (foundMatch && !this.props.isLoadingPrices) {
        const monthIndex = Number(foundMatch[1]);
        const channelConfig = this.toDisplay[rowIndex];

        if (
          channelConfig &&
          isFullChannelConfigForDisplay(channelConfig) &&
          channelConfig.seasonalDiscounts[monthIndex] !==
            channelConfig.defaultSeasonalDiscounts[monthIndex]
        ) {
          cellProperties.className = 'c-calendar-different';
        } else {
          cellProperties.className = this.getClassByMonthId(monthIndex);
        }
      }

      return cellProperties;
    }
  });

  validableCellRenderer = (...args) => {
    // [instance, td, row, col, prop, value, cellProperties]
    const [, td, , , , , cellProperties] = args;
    Handsontable.renderers.NumericRenderer.apply(this, args);
    const msg = cellProperties.errorIDs
      .map(id => localizeMessage({ id }))
      .join('\n');
    td.setAttribute('title', msg);
  };

  cellRenderer = (...args) => {
    const td = args[1];
    const row: number = args[2];
    const prop = args[4];
    const cellProperties = args[6];

    Handsontable.renderers.TextRenderer.apply(this, args);
    const rowData = this.toDisplay[row];
    if (this.props.isLoadingPrices) {
      td.innerHTML = `${localizeMessage({ id: 'loading' })}...`;
    } else if (
      isFullChannelConfigForDisplay(rowData) &&
      td &&
      cellProperties.className &&
      cellProperties.className.indexOf('c-calendar-different') > -1
    ) {
      const monthIndex = parseInt(prop.replace('months.', ''), 10);

      td.title = `Changed the base value. The base value is ${rowData.priceWithDefaultDiscount[monthIndex]}`;
    }
  };

  validateCell = (row, columnID) => {
    const errorIDs: string[] = [];
    const stringValue = property<any, string>(columnID)(row);
    const value = parseFloat(stringValue);
    if (value) {
      if (Number.isNaN(value) || Number.isNaN(Number(stringValue))) {
        // parseFloat считает строку `123asdas` за число и вторая проверка на isNaN это фиксит
        errorIDs.push('annualPlanEdit.errors.notNumber');
      } else if (value < 0) {
        errorIDs.push('annualPlanEdit.errors.notPositive');
      } else if (value > MAX_INT_VALUE) {
        errorIDs.push('annualPlanEdit.errors.exceedMaxInteger');
      }
    }

    return errorIDs;
  };

  afterGetColHeader = (col, th) => {
    if (col <= 3) {
      th.style.whiteSpace = 'normal';
    }

    return th;
  };

  beforeChange = (changes: (Handsontable.CellChange | null)[] | null) => {
    if (!changes) {
      return;
    }

    changes.forEach((change, index) => {
      if (!change) return;
      const [, columnID, , newValue] = change;
      if (columnID !== 'cpp.advertiserBasePrice') return;
      const numericValue = parseFloat(newValue);
      if (newValue === null) return;

      if (
        Number.isNaN(numericValue) ||
        Number.isNaN(Number(newValue)) ||
        numericValue <= 0
      ) {
        changes[index] = null;
      }
    });
  };

  afterChange = (changes: Handsontable.CellChange[] | null) => {
    if (!changes) {
      return;
    }

    let shouldRefreshToDisplay = false;
    changes.forEach(([row, columnID, oldVal, newValue], index) => {
      if (
        columnID === 'cpp.advertiserBasePrice' ||
        columnID === 'cpp.additionalDiscount'
      ) {
        if (typeof newValue === 'number') {
          set(this.toDisplay[row], columnID, formatNumberToNumber(newValue));
          shouldRefreshToDisplay = true;
        } else if (
          columnID === 'cpp.advertiserBasePrice' &&
          typeof oldVal === 'number' && newValue === null
        ) {
          set(this.toDisplay[row], columnID, newValue);
          shouldRefreshToDisplay = true;
        }
        this._hot.hotInstance.validateCells();
      }
    });

    if (shouldRefreshToDisplay) {
      this.toDisplay = this.getRefreshedToDisplay();
      this.forceUpdate();
    }
  };

  beforeRender = () => {
    let isValidTable = true;
    this.cellsErrors = this.toDisplay.map(row => propsForValidate.reduce((acc, columnID) => {
      const isExcludePlatform = property('restrictions.isExcludePlatform')(
        row
      );
      const errorIDs = isExcludePlatform
        ? []
        : this.validateCell(row, columnID);
      if (errorIDs.length) {
        isValidTable = false;
      }

      return { ...acc, [columnID]: errorIDs };
    }, {})
    );
    this.props.onUpdateValidationStatus(isValidTable, 'cpp');
  };

  render () {
    return (
      <>
        <p className='_text-align--center m-t m-b'>
          <LocalizedMessage id='annualPlanEdit.tvCampaignParams.cpp' />
        </p>

        <HotTable
          ref={this.setHotRef}
          {...this.generateTableOptions()}
          tableClassName='table'
          stretchH='all'
          width='100%'
          height='auto'
          autoWrapRow
          afterGetColHeader={this.afterGetColHeader}
          afterChange={this.afterChange}
          beforeChange={this.beforeChange}
          beforeRender={this.beforeRender}
          licenseKey='non-commercial-and-evaluation'
        />
      </>
    );
  }
}

export default Cpp;
