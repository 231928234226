import Client from 'api/release/HTTPClient';
import { IRegion, IServerPage } from 'types';

const regionsAPI = {
  list (request): Promise<IServerPage<IRegion>> {
    return Client.post('/api/regions/', request);
  },

  create (request): Promise<IRegion> {
    return Client.post('/api/regions/new', request);
  },
};

export default regionsAPI;
