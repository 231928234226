import React from 'react';

import Loader from '../Loader';
import classes from './PageLoader.module.scss';

const PageLoader = ({ loading }: { loading: boolean}) => (
  <Loader
    active={loading}
    className={classes.container}
  />
);

export default PageLoader;
