import { fetchResponse } from './helpers/fakeFetch';
import Client from '../release/HTTPClient';

import brandsList from './data/brandsList.json';
import brandsListByAdvertiser from './data/brandsListByAdvertiser.json';
import brandsFilterItems from './data/brandsFilterItems.json';

const brandsAPI = {
  list (request) {
    console.log('api.brands.list(', request, ')');

    return fetchResponse(1000, brandsList);
  },

  listByAdvertiser (advertiserId, request) {
    console.log('api.brands.listByAdvertiser(', advertiserId, ', ', request, ')');

    return fetchResponse(1000, brandsListByAdvertiser);
  },

  get (id) {
    console.log('api.brands.get(', id, ')');

    return Client.get(`/api/brands/${id}`);
  },

  getFilterItems (request) {
    console.log('api.brands.getFilterItems(', request, ')');

    return fetchResponse(1000, brandsFilterItems);
  },
};

export default brandsAPI;
