import { fetchResponse } from './helpers/fakeFetch';

import usersList from './data/usersList.json';
import usersFilterItems from './data/usersFilterItems.json';

const usersAPI = {
  list (request) {
    console.log('api.users.list(', request, ')');

    return fetchResponse(1000, usersList);
  },

  getRoles (request) {
    console.log('api.users.getRoles(', request, ')');

    return fetchResponse(1000, []);
  },

  getFilterItems (request) {
    console.log('api.users.getFilterItems(', request, ')');

    return fetchResponse(1000, usersFilterItems);
  },

  get (id) {
    console.log('api.users.get(', id, ')');

    return fetchResponse(1000, {});
  },

  save (id, request) {
    console.log('api.users.save(', id, ', ', request, ')');

    if (!id) {
      return fetchResponse(1000, request);
    }

    return fetchResponse(1000, request);
  },

  enable (id) {
    console.log('api.users.enable(', id, ')');

    return fetchResponse(1000, {});
  },

  disable (id) {
    console.log('api.users.disable(', id, ')');

    return fetchResponse(1000, {});
  },
};

export default usersAPI;
