import React, { Component } from 'react';
import pullAt from 'lodash/pullAt';
import flatten from 'lodash/flatten';
import without from 'lodash/without';
import cx from 'classnames';

import Select from 'components/Select';
import LocalizedMessage, { localizeMessage } from 'components/LocalizedMessage';
import { NumericInput } from 'components/NumericInput';
import { formatNumberToNumber, getColorScheme } from 'helpers/utils';
import { IChannelGroup, IChannelForGroup, IdToChannelConfigMap, IChannel } from 'routes/AnnualPlanEdit/types';

import globalClasses from '../../../AnnualPlanEdit.module.scss';
import classes from './ChannelGroupEditor.module.scss';

interface IProps {
  params: IdToChannelConfigMap;
  groups: IChannelGroup[];
  sourceChannels: IChannel[];
  onUpdateValidationStatus: (isValid: boolean) => void;
  updateForResrtictions: () => void;
  restrictionBase: 'BUDGET_GRP' | 'TRP_GRP'
}

interface IErrors {
  min: string[]; max: string[]; isEmptyName: boolean; isDuplicateName: boolean;
}

const minError = 'annualPlanEdit.errors.channelShareMoreMin';
const maxError = 'annualPlanEdit.errors.channelShareLessMax';
const minMaxError = 'annualPlanEdit.errors.minExceedMax';

const getChannelUniqValue = ({ id, type }: IChannelForGroup) => `${id}${type}`;
const convertSourceChannelToChannel = ({ nameWithType, externalId, type }: IChannel): IChannelForGroup => (
  { name: nameWithType, id: externalId, type: type.name }
);
const getChannelLabel = (option: IChannelForGroup) => option.name;

class ChannelGroupEditor extends Component<IProps> {
  errorIDs: IErrors[] = [];

  UNSAFE_componentWillReceiveProps (nextProps: IProps) {
    if (nextProps.sourceChannels !== this.props.sourceChannels) {
      this.mapUniqValueToID = this.getMapUniqValueToID(nextProps);
      this.checkExcludedChannel();
      this.forceUpdate();
    } else if (nextProps.sourceChannels.length) {
      this.checkExcludedChannel();
      this.validate(nextProps.groups);
      this.forceUpdate();
    }
  }

  getMapUniqValueToID = (props: IProps) => props.sourceChannels.reduce<Record<string, number>>((acc, c) => {
    const uniqValue = getChannelUniqValue(convertSourceChannelToChannel(c));

    return { ...acc, [uniqValue]: c.id };
  }, {});

  mapUniqValueToID = this.getMapUniqValueToID(this.props);

  updateGroups = (newGroups: IChannelGroup[], isChangedChannels?: boolean) => {
    const { groups } = this.props;

    groups.splice(0, groups.length, ...newGroups); // мутирует массив
    this.validate(groups);

    if (isChangedChannels) {
      this.props.updateForResrtictions();
    } else {
      this.forceUpdate();
    }
  };

  validate = (groups: IChannelGroup[]) => {
    const { onUpdateValidationStatus } = this.props;

    this.errorIDs = groups.map(g => this.validateGroup(g));
    const hasErrors = this.errorIDs
      .some(({ min, max, isDuplicateName }) => min.length > 0 || max.length > 0 || isDuplicateName);
    onUpdateValidationStatus(!hasErrors);
  };

  validateGroup = ({ group: { name }, budget: { channelPercent }, channels }: IChannelGroup) => {
    const { params, groups } = this.props;
    const min = channelPercent.min || 0;
    const max = channelPercent.max || 100;
    const uniqValues = channels.map(getChannelUniqValue);
    const channelsData = uniqValues.map(uniqValue => params[this.mapUniqValueToID[uniqValue]]);

    let minSum = 0;
    let maxSum = 0;

    channelsData.forEach(data => {
      if (!data.restrictions.isExcludedChannel) {
        minSum += data.restrictions.channelPercentMin || 0;
        maxSum += data.restrictions.channelPercentMax === 0 ? 0 : data.restrictions.channelPercentMax || 100;
      }
    });

    const errors: IErrors = { min: [], max: [], isEmptyName: false, isDuplicateName: false };

    if (!name.length) {
      errors.isEmptyName = true;
    }
    if (maxSum < min) {
      errors.min.push(minError);
    }
    if (minSum > max) {
      errors.max.push(maxError);
    }

    if (min > max) {
      errors.min.push(minMaxError);
    }

    if (min > 100) {
      errors.min.push('annualPlanEdit.errors.exceed100percent');
    } else if (min < 0) {
      errors.min.push('annualPlanEdit.errors.notPositive');
    }

    if (max > 100) {
      errors.max.push('annualPlanEdit.errors.exceed100percent');
    } else if (max < 0) {
      errors.max.push('annualPlanEdit.errors.notPositive');
    }

    let countNames = 0;

    groups.forEach(g => {
      const groupName = g.group.name;
      if (name === groupName) {
        countNames += 1;
      }
    });

    if (countNames > 1) {
      errors.isDuplicateName = true;
    }

    return errors;
  };

  checkExcludedChannel = () => {
    const { groups, params } = this.props;

    groups.forEach((group) => {
      const indexesForDelete: number[] = [];

      group.channels.forEach((channel, index) => {
        const data = params[this.mapUniqValueToID[getChannelUniqValue(channel)]];
        if (!data || data.restrictions.isExcludedChannel) {
          indexesForDelete.push(index);
        }
      });

      pullAt(group.channels, indexesForDelete); // pullAt мутирует массив
    });
  };

  makeSelectChangeHandler = (index: number) => (option: IChannelForGroup[]) => {
    const { groups } = this.props;

    const updatedGroups = groups.map((g, i) => (i === index ? { ...g, channels: option } : g));
    this.updateGroups(updatedGroups, true);
  };

  handleButtonClick = () => {
    const { groups } = this.props;
    const newGroup: IChannelGroup = {
      group: { name: `${localizeMessage({ id: 'group' })} ${groups.length + 1}` },
      channels: [],
      budget: { channelPercent: { min: null, max: null } },
    };
    this.updateGroups([...groups, newGroup]);
  };

  makeGroupNameChangeHandler = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const { groups } = this.props;
    const { value } = e.target;
    const updatedGroups = groups.map((g, i) => (i === index ? { ...g, group: { name: value } } : g));
    this.updateGroups(updatedGroups);
  };

  makeMinMaxChangeHandler = (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const { groups } = this.props;
    const { value, name } = e.target;
    const val = value === '' ? null : formatNumberToNumber(Number(value));

    const updatedGroups = groups.map((g, i) => (i === index
      ? { ...g, budget: { channelPercent: { ...g.budget.channelPercent, [name]: val } } }
      : g
    ));
    this.updateGroups(updatedGroups);
  };

  makeRemoveGroupClickHandler = (index: number) => () => {
    const { groups } = this.props;

    const localizedGroup = localizeMessage({ id: 'group' });
    const updatedGroups = without(groups, groups[index]).map(group => {
      const matchResult = group.group.name.match(/^(\w+)\s\d+$/);

      if (matchResult && matchResult[1] === localizedGroup) {
        const name = `${localizedGroup} ${index + 1}`;

        return { ...group, group: { ...group.group, name } };
      }

      return group;
    });
    this.updateGroups(updatedGroups, true);
  };

  render () {
    const { groups, sourceChannels, params, restrictionBase } = this.props;

    const selectedOptionUniqValues = flatten(groups.map(g => g.channels.map(c => getChannelUniqValue(c))));
    const options: IChannelForGroup[] = sourceChannels
      .filter((channel) => {
        const uniqValue = getChannelUniqValue(convertSourceChannelToChannel(channel));
        const data = params[this.mapUniqValueToID[uniqValue]];

        return !selectedOptionUniqValues.includes(uniqValue) && data && !data.restrictions.isExcludedChannel;
      })
      .map(convertSourceChannelToChannel);

    return (
      <div className={classes.channelGroupEditor}>
        <div className={classes.addGroup}>
          <button className={classes.addGroupBtn} onClick={this.handleButtonClick}>+</button>
          <div className={classes.addGroupLabel}><LocalizedMessage id='annualPlanEdit.createChannelsGroup' /></div>
        </div>
        <table className={cx(classes.table)}>
          <thead>
            <tr>
              <th rowSpan={2}>
                <LocalizedMessage id='groupName' />
              </th>
              <th className={classes.channelsColumn} rowSpan={2}>
                <LocalizedMessage id='channels' />
              </th>
              <th className={classes.minMaxColumn} colSpan={2}>
                <LocalizedMessage id={`annualPlanEdit.${restrictionBase}`} />
                {' '}
%
              </th>
              <th rowSpan={2} />
            </tr>
            <tr>
              <th className={classes.minMaxColumn}>
                Min
              </th>
              <th className={classes.minMaxColumn}>
                Max
              </th>
            </tr>
          </thead>
          <tbody>
            {groups.map((group, index) => {
              const errors: IErrors | undefined = this.errorIDs[index];
              const minErrorMsg = errors && errors.min.map(e => localizeMessage({ id: e })).join('\n');
              const maxErrorMsg = errors && errors.max.map(e => localizeMessage({ id: e })).join('\n');

              return (
                <tr key={index}>
                  <td>
                    <LocalizedMessage id='annualPlanEdit.errors.emptyField'>
                      {localizedMessage => (
                        <input
                          maxLength={30}
                          type='text'
                          className={cx(
                            'form-control',
                            globalClasses['form-control'],
                            { [classes.error]: errors && errors.isEmptyName },
                          )}
                          title={errors && errors.isEmptyName ? localizedMessage : undefined}
                          name='groupName'
                          onChange={this.makeGroupNameChangeHandler(index)}
                          value={group.group.name}
                          style={{ backgroundColor: getColorScheme(index) }}
                        />
                      )}
                    </LocalizedMessage>
                    {
                      errors && errors.isDuplicateName ? (
                        <div>
                          <small className='text-danger'>
                            <LocalizedMessage id='annualPlanEdit.errors.duplicateFieldName' />
                          </small>
                        </div>
                      )
                        : null
                    }
                  </td>
                  <td className={classes.channelsColumn}>
                    <Select<IChannelForGroup>
                      getOptionValue={getChannelUniqValue}
                      getOptionLabel={getChannelLabel}
                      onChange={this.makeSelectChangeHandler(index)}
                      options={options}
                      value={group.channels}
                      isMulti
                    />
                  </td>
                  <td className={classes.minMaxColumn} title={minErrorMsg}>
                    <NumericInput
                      className={cx(
                        'form-control',
                        globalClasses['form-control'],
                        { [classes.error]: minErrorMsg && minErrorMsg.length > 0 },
                      )}
                      name='min'
                      onChange={this.makeMinMaxChangeHandler(index)}
                      value={group.budget.channelPercent.min || ''}
                      min={0}
                      max={100}
                    />
                  </td>
                  <td className={classes.minMaxColumn} title={maxErrorMsg}>
                    <NumericInput
                      className={cx(
                        'form-control',
                        globalClasses['form-control'],
                        { [classes.error]: maxErrorMsg && maxErrorMsg.length > 0 },
                      )}
                      name='max'
                      onChange={this.makeMinMaxChangeHandler(index)}
                      value={group.budget.channelPercent.max || ''}
                      min={0}
                      max={100}
                    />
                  </td>
                  <td>
                    <LocalizedMessage id='delete'>
                      {localizedMessage => (
                        <i
                          title={localizedMessage}
                          className='fa fa-trash inline-button-delete-active'
                          onClick={this.makeRemoveGroupClickHandler(index)}
                        />
                      )}
                    </LocalizedMessage>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default ChannelGroupEditor;
