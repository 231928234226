import Client from 'api/release/HTTPClient';

// no swagger
const personaOlapSettingsAPI = {
  list (request) {
    return Client.get('/api/personaOlapSettings', request);
  },
};

export default personaOlapSettingsAPI;
