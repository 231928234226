import { fetchResponse } from './helpers/fakeFetch';

import targetingsList from './data/targetingsList.json';

const targetingsAPI = {
  list (request) {
    console.log('api.targetings.list(', request, ')');

    return fetchResponse(1000, targetingsList);
  },
};

export default targetingsAPI;
