import { connect } from 'react-redux';
import { selectLocale } from 'modules/locale';
import LangSwitcher from './LangSwitcher';

const mapDispatchToProps = {
  selectLocale,
};

const mapStateToProps = (state) => ({
  lang: state.locale.lang,
});

export default connect(mapStateToProps, mapDispatchToProps)(LangSwitcher);
