import Config from 'config';
import Client from 'api/release/HTTPClient';
import { IAuthTokens } from 'types';

const AuthAPI = {
  getTokensByUserPass (
    mail: string,
    password: string,
    grant_type = 'password',
    client_id = 'algorithm'
  ): Promise<IAuthTokens> {
    return Client.post(`${Config.api.authHost}/api/keycloak/auth/realms/ADV/protocol/openid-connect/token`, {
      username: mail,
      password,
      client_id,
      grant_type
    }, false, { isJson: false }, false);
  },

  getTokensByRefreshToken (
    refreshToken: string,
    withoutLoader = false,
    client_id = 'algorithm',
    grant_type = 'refresh_token',
  ): Promise<IAuthTokens> {
    return Client.post(`${Config.api.authHost}/api/keycloak/auth/realms/ADV/protocol/openid-connect/token`,
      { refresh_token: refreshToken, grant_type, client_id },
      false, { isJson: false }, undefined, undefined, { withoutLoader });
  },

  logOut (refreshToken, realm = 'ADV', client_id = 'algorithm',) {
    return Client.post(`${Config.api.authHost}/api/keycloak/auth/realms/ADV/protocol/openid-connect/logout`,
      { refresh_token: refreshToken, realm, client_id },
      false,
      { withRealm: true, isJson: false }
    );
  },
};

export default AuthAPI;
