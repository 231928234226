import moment from 'moment';
import 'moment/locale/ru';
import numeral from 'numeral';
import 'numeral/locales';
import locales from '../locales';

// ------------------------------------
// Constants
// ------------------------------------
export const LOCALE_SELECTED = 'LOCALE_SELECTED';

let defaultLocale = 'en';

// ------------------------------------
// Actions
// ------------------------------------
export const selectLocale = (locale = defaultLocale) => {
  localStorage.lang = locale;

  moment.locale(locale);
  numeral.locale(locale);

  return {
    type: LOCALE_SELECTED,
    locale,
  };
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [LOCALE_SELECTED]: (state, action) => ({
    ...state,
    lang: action.locale,
    messages: locales[action.locale],
  }),
};

// ------------------------------------
// Reducer
// ------------------------------------
if (
  localStorage.lang &&
  Object.keys(locales).indexOf(localStorage.lang) > -1
) {
  defaultLocale = localStorage.lang;
} else {
  localStorage.lang = defaultLocale;
}

moment.locale(defaultLocale);
numeral.locale(defaultLocale);

const initialState = {
  lang: defaultLocale,
  messages: locales[defaultLocale],
};

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};
