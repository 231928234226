import Client from 'api/release/HTTPClient';
import { IDictionary } from 'types';

const syncAPI = {
  getDictionaries (): Promise<IDictionary[]> {
    return Client.get('/api/sync/dictionaries');
  },

  sync (data): Promise<any> {
    return Client.post('/api/sync/', data);
  }
};

export default syncAPI;
