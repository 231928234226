import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Link, RouteComponentProps } from 'react-router-dom';
import { saveAs } from 'file-saver';
import LocalizedMessage, { localizeMessage } from 'components/LocalizedMessage';

import Breadcrumbs from 'components/Breadcrumbs';

import API from 'api';
import Alert from 'helpers/alert';
import { IAdvertiser } from 'types';

interface ITemplate {
  advertiserId?: number;
  id: number;
  templateName?: string;
  data?: File;
  type: {
    name:
    | 'YEAR'
    | 'INDIVIDUAL'
    | 'CAMPAIGNS_IMP'
    | 'DEAL_RES_IMP'
    | 'CHANNEL_RES_IMP';
  };
}
interface IState {
  template1: ITemplate;
  template2: ITemplate;
  templateFile?: any;
  advertiser: IAdvertiser | null;
}
class Advertiser extends Component<
RouteComponentProps<{ id: string }> & { checkRoles: (roles: string) => boolean},
IState
> {
  state: IState = {
    advertiser: null,
    template1: { id: -1, type: { name: 'YEAR' } },
    template2: { id: -1, type: { name: 'INDIVIDUAL' } },
    templateFile: null
  };

  mounted = false;

  componentDidMount () {
    this.mounted = true;

    this.loadData();
  }

  componentWillUnmount () {
    this.mounted = false;
  }

  async loadData () {
    const {
      match: { params }
    } = this.props;

    if (!params || !params.id) {
      return;
    }

    try {
      const [
        advertiser,
        templates
      ] = await Promise.all([
        API.advertisers.get(params.id),
        API.advertisers.getTemplates(params.id)
      ]);

      if (!this.mounted) {
        return;
      }

      const { template1, template2 } = this.state;

      templates.forEach(template => {
        // YEAR type
        if (
          template.type.name === template1.type.name &&
          (!template1.id || template1.id < template.id)
        ) {
          template1.id = template.id;
          template1.advertiserId = template.advertiserId;
          template1.templateName = template.templateName;
        }
        // INDIVIDUAL type
        if (
          template.type.name === template2.type.name &&
          (!template2.id || template1.id < template.id)
        ) {
          template2.id = template.id;
          template2.advertiserId = template.advertiserId;
          template2.templateName = template.templateName;
        }
      });

      this.setState({
        advertiser,
        template1,
        template2
      });
    } catch (error) {
      console.error(error);

      Alert.error(localizeMessage({ id: 'errors.errorLoadingData' }));
    }
  }

  updateTemplateFromData (templateName: 'template1' | 'template2') {
    return new Promise(resolve => {
      // if no file we resolve out
      if (!this.state[templateName].data) {
        return resolve();
      }

      return resolve();
      // if there is file, we instead set template to string?
      // this.setState(
      //   {
      //     template1: (this.state[templateName].data as File).name
      //   },
      //   () => {
      //     resolve();
      //   }
      // );
    });
  }

  uploadFile (template: ITemplate) {
    if (!template.data) {
      return Promise.resolve();
    }

    const { advertiser } = this.state;

    if (!advertiser) return;

    const formData = new FormData();
    formData.append('advertiserKeycloakId', advertiser.id!!.toString());
    formData.append('type', template.type.name);
    formData.append('file', template.data);

    return API.advertisers.saveOrUpdateTemplate(formData);
  }

  saveAdvertiserTemplates = async () => {
    // update template info before saving:
    await this.updateTemplateFromData('template1');
    await this.uploadFile(this.state.template1);
    await this.updateTemplateFromData('template2');
    await this.uploadFile(this.state.template2);

    Alert.success(
      localizeMessage(
        { id: 'advertiser-detail.alerts.advertiserTemplatesSavedSuccessfully' },
        {
          name: this.state.advertiser?.name
        }
      )
    );

    this.props.history.replace('/app/dictionaries/advertisers');
  };

  getFileDownloadUrl = async e => {
    e.preventDefault();

    const { template2 } = this.state;

    try {
      const response = await API.mediaplanTemplates.getFile(template2.id);

      const blob = await response.blob();

      const contentDisposition = response.headers.get('content-disposition');
      const filenameData = contentDisposition
        ? contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)
        : null;
      const filename = filenameData
        ? filenameData[1].replace(/['"]+/g, '')
        : template2.templateName;

      saveAs(blob, filename);

      Alert.success(
        localizeMessage({
          id: 'advertiser-detail.alerts.template-successfully-export'
        })
      );
    } catch (error) {
      console.error(error);

      Alert.error(
        localizeMessage(
          { id: 'advertiser-detail.alerts.template-failed-export' },
          { id: template2.templateName }
        )
      );
    }
  };

  handleFileChange = e => {
    this.setState({
      template2: {
        ...this.state.template2,
        data: e.target.files[0]
      }
    });
  };

  getBreadcrumbs () {
    return [
      {
        title: <LocalizedMessage id='home' />,
        link: '/app'
      },
      {
        title: <LocalizedMessage id='advertisers' />,
        link: '/app/dictionaries/advertisers'
      },
      {
        title: <LocalizedMessage id='advertiser-detail' />
      }
    ];
  }

  render () {
    const {
      match: { params },
      checkRoles
    } = this.props;

    const {
      advertiser,
      template2
    } = this.state;

    return (
      <>
        <LocalizedMessage
          id={[
            'site.title.dictionaries.advertiser-detail',
            'advertiser-detail.buttons.editWhiteList'
          ]}
        >
          {(localizedMessage, localizedEditWhiteListMessage) => (
            <>
              <Helmet title={localizedMessage} />
              <Breadcrumbs
                title={localizedMessage}
                data={this.getBreadcrumbs()}
                className='col-lg-9'
              >
                <div className='col-lg-3'>
                  <div className='title-action'>
                    {checkRoles('ADMIN') && (
                      <Link
                        className='btn btn-warning'
                        to={`/app/dictionaries/advertisers/${params.id}/whiteList`}
                      >
                        <i className='fa fa-pencil fa-lg' />
                        <span>{localizedEditWhiteListMessage}</span>
                      </Link>
                    )}
                  </div>
                </div>
              </Breadcrumbs>
            </>
          )}
        </LocalizedMessage>

        {advertiser ? (
          <div className='row'>
            <div className='col-lg-12'>
              <div className='wrapper wrapper-content'>
                <div className='ibox'>
                  <div className='ibox-title'>
                    <h5>
                      <LocalizedMessage
                        id={`advertiser-detail.${
                          !advertiser ? 'adding' : 'editing'
                        }`}
                      />
                    </h5>
                  </div>

                  <div className='ibox-content'>
                    <div className='form-horizontal'>
                      <div className='form-group'>
                        <label className='col-lg-3 control-label'>
                          <LocalizedMessage id='advertiser-detail.form.name' />
                        </label>
                        <div className='col-lg-9'>
                          <p className='form-control-static'>
                            {advertiser.name}
                          </p>
                        </div>
                      </div>
                      <div className='hr-line-dashed' />

                      <div className='form-group'>
                        <label className='col-lg-3 control-label'>
                          <span>
                            <LocalizedMessage id='advertiser-detail.form.currentMediaplanTemplate' />
                          </span>
                          :
                        </label>
                        <div className='col-lg-9'>
                          {template2.id ? (
                            <p className='form-control-static'>
                              <a
                                href={`/api/mediaplans/templates/file/${template2.id}`}
                                onClick={this.getFileDownloadUrl}
                              >
                                {template2.templateName}
                              </a>
                              &nbsp;&nbsp;
                              {template2.id && !template2.advertiserId ? (
                                <span>
                                  (
                                  <LocalizedMessage id='advertiser-detail.defaultTemplate' />
                                  )
                                </span>
                              ) : null}
                            </p>
                          ) : null}
                          {!template2.id ? (
                            <p className='form-control-static'>
                              <LocalizedMessage id='advertiser-detail.mediaplanTemplateNotLoaded' />
                            </p>
                          ) : null}
                        </div>
                      </div>
                      <div className='form-group'>
                        <label className='col-lg-3 control-label'>
                          <span>
                            <LocalizedMessage id='advertiser-detail.loadNewMediaplanTemplate' />
                          </span>
                          :
                        </label>
                        <div className='col-lg-9'>
                          <input
                            type='file'
                            className='form-control'
                            name='templateFile'
                            onChange={this.handleFileChange}
                            accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                          />
                        </div>
                      </div>

                      <div className='hr-line-dashed' />

                      <div className='form-group'>
                        <div className='col-lg-3' />
                        <div className='col-lg-9'>
                          <Link
                            className='btn btn-white'
                            to='/app/dictionaries/advertisers'
                          >
                            <span>
                              <LocalizedMessage id='cancel' />
                            </span>
                          </Link>
                          &nbsp;&nbsp;
                          <button
                            type='submit'
                            className='btn btn-primary'
                            onClick={this.saveAdvertiserTemplates}
                            disabled={!template2.data}
                          >
                            <>
                              <i className='fa fa-chevron-down fa-lg' />
                              <span>
                                <LocalizedMessage id='save' />
                              </span>
                            </>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </>
    );
  }
}

export default Advertiser;
