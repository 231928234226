import React, { PureComponent } from 'react';
import { Helmet } from 'react-helmet';
import LocalizedMessage from 'components/LocalizedMessage';
import URLFiltersHoc, { Props } from 'components/URLFiltersHoc';
import Breadcrumbs from 'components/Breadcrumbs';
import FilterList from 'components/FilterList';
import TableList from 'components/TableList';
import Select from 'components/Select';

import { actualYear, yearOptions } from 'helpers/yearOptions';

import API from 'api';

interface IState {
  filterItems: any[],
  dataList: any[],
  dataListTotal: number;
  isLoading: boolean;
  selectedYear: number;
}

const defaultSort = 'id';

class Channels extends PureComponent<Props, IState> {
  static propTypes = {};

  state: IState = {
    filterItems: [],
    dataList: [],
    dataListTotal: 1,
    isLoading: false,
    selectedYear: actualYear,
  };

  mounted = false;
  refreshListTimer: any = 0;
  refreshListTimerDelay = 500;

  componentDidMount () {
    this.mounted = true;
    this.loadData();
    this.refreshList();
  }

  componentDidUpdate (prevProps: Props, prevState: IState) {
    if (prevProps.filters !== this.props.filters) {
      this.refreshList();
    } else if (prevState.selectedYear !== this.state.selectedYear) {
      this.refreshList();
    }
  }

  componentWillUnmount () {
    this.mounted = false;
    clearTimeout(this.refreshListTimer);
  }

  async loadData () {
    const filterItems = await API.channels.getFilterItems();

    if (!this.mounted) {
      return;
    }

    this.setState({
      filterItems,
    });
  }

  refreshList () {
    clearTimeout(this.refreshListTimer);
    const { selectedYear } = this.state;
    const { currentPage, currentSort, max, items } = this.props.filters;
    const dataListRequest = {
      page: currentPage,
      order: currentSort,
      max,
      filter: { items },
      years: [selectedYear],
    };

    this.refreshListTimer = setTimeout(async () => {
      this.setState({ isLoading: true });

      const dataList = await API.channels.list(dataListRequest);

      if (!this.mounted) {
        return;
      }

      this.setState({
        dataList: dataList.items,
        dataListTotal: dataList.total,
        isLoading: false,
      });
    }, this.refreshListTimerDelay);
  }

  getBreadcrumbs () {
    return [
      {
        title: <LocalizedMessage id='home' />,
        link: '/app',
      },
      {
        title: <LocalizedMessage id='channels' />,
        link: '/app/dictionaries/channels',
      },
    ];
  }

  handleChangeYearSelect = (year) => {
    const selectedYear = year.value;
    this.setState({ selectedYear });
  };

  render () {
    const {
      filterItems,
      dataListTotal,
      dataList,
      isLoading,
      selectedYear,
    } = this.state;
    const { onUpdateSort, onUpdatePage, filters, onChangeFilterItems } = this.props;
    const selectedOption = (
      yearOptions.find((option) => option.value === selectedYear) ||
      {
        value: actualYear,
        label: String(actualYear)
      }
    );

    return (
      <>
        <LocalizedMessage
          id='site.title.dictionaries.channels'
        >
          {localizedMessage => (
            <>
              <Helmet
                title={localizedMessage}
              />
              <Breadcrumbs
                title={localizedMessage}
                data={this.getBreadcrumbs()}
              />
            </>
          )}
        </LocalizedMessage>

        <div className='wrapper wrapper-content'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='ibox float-e-margins'>
                <div className='ibox-content'>
                  <div className='row m-b-sm form-horizontal'>
                    <div className='col-lg-6 pull-right'>
                      <LocalizedMessage id='year'>
                        {localizedMessage => (
                          <>
                            <label className='col-lg-5 control-label' htmlFor='year'>
                              {localizedMessage}
                            </label>
                            <div className='col-lg-7'>
                              <Select
                                value={selectedOption}
                                options={yearOptions}
                                onChange={this.handleChangeYearSelect}
                                placeholder={localizedMessage}
                                inputId='year'
                              />
                            </div>
                          </>
                        )}
                      </LocalizedMessage>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-sm-12 m-b-sm d-flex'>
                      <div className='flex-grow-1 m-r'>
                        <FilterList
                          placeholder={<LocalizedMessage id='channels-dictionary.channelsFilter.placeholder' />}
                          options={filterItems}
                          onChange={onChangeFilterItems}
                          value={filters.items}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='table-responsive'>
                    <LocalizedMessage id={['name', 'type']}>
                      {(localizedName, localizedType) => (
                        <TableList
                          currentPage={filters.currentPage}
                          currentSort={filters.currentSort}
                          totalItems={dataListTotal}
                          itemsCountPerPage={filters.max}
                          onUpdateSort={onUpdateSort}
                          onUpdatePage={onUpdatePage}
                          isLoading={isLoading}
                          head={[
                            {
                              label: localizedName,
                              sort: 'name',
                            },
                            {
                              label: localizedType,
                              sort: 'type',
                            },
                          ]}
                        >
                          {
                            dataList.map(channel => (
                              <tr key={`${channel.id}_${channel.name}`}>
                                <td>
                                  {channel.name}
                                </td>
                                <td>
                                  {channel.type.nameTranslation}
                                </td>
                              </tr>
                            ))
                          }
                        </TableList>
                      )}
                    </LocalizedMessage>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default URLFiltersHoc(Channels, defaultSort);
