import React, { useState } from 'react';
import cx from 'classnames';
import { DateRangePicker } from 'react-dates';
import { INullableMomentRange } from 'routes/AnnualPlanEdit/types';
import API from 'api';

const FORMAT = 'DD/MM/YY';
const SEND_FORMAT = 'YYYY-MM-DD';

export default ({ id }: { id: string }) => {
  const [range, setRange] = useState<INullableMomentRange>({
    startDate: null,
    endDate: null
  });

  const [focus, setFocus] = useState<'startDate' | 'endDate' | null>(null);

  return (
    <span>
      <button
        onClick={() => {
          if (range.startDate === null || range.endDate === null) return;
          API.annualPlans.palomarsRecalculate(id, range.startDate.format(SEND_FORMAT), range.endDate.format(SEND_FORMAT));
        }}
        disabled={range.startDate == null || range.endDate == null}
        className={cx('btn btn-sm btn-inline-flex btn-primary')}
      >
        <span>Перерасчитать palomars</span>
      </button>

      <DateRangePicker
        onFocusChange={setFocus}
        focusedInput={focus}
        startDate={range.startDate}
        startDateId='palomars-retro-start-date'
        endDate={range.endDate}
        endDateId='palomars-retro-end-date'
        onDatesChange={(e: INullableMomentRange) => {
          setRange(e);
        }}
        small
        withPortal
        hideKeyboardShortcutsPanel
        firstDayOfWeek={1}
        isOutsideRange={() => false}
        displayFormat={() => FORMAT}
        disabled={false}
      />
    </span>
  );
};
