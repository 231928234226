import React, { Component } from 'react';
import cx from 'classnames';
import Scrollbars from 'react-custom-scrollbars';
import classes from './Scrollbar.module.scss';

interface IProps {
  className?: string;
  viewClassName?: string;
  autoWidth?: true;
  autoHeightMax?: number;
  viewProps?: {};
  hideHorizontalScrollbar?: true;
  hideVerticalScrollbar?: true;
  onScroll?: () => void;
}

class Scrollbar extends Component<IProps> {
  _scrollbars: any | null = null;
  _content: HTMLDivElement | null = null;

  componentDidMount () {
    this.calculateHeight();
  }

  UNSAFE_componentWillReceiveProps () {
    this.calculateHeight();
  }

  setScrollbarsRef = (ref: Scrollbars) => {
    this._scrollbars = ref;
  };

  setContentRef = (ref: HTMLDivElement) => {
    this._content = ref;
  };

  calculateHeight () {
    setTimeout(() => {
      const { autoWidth, autoHeightMax } = this.props;
      if (this._scrollbars && this._scrollbars.container && this._content) {
        if (autoWidth) {
          this._scrollbars.container.style.width = `${this._content.offsetWidth}px`;
        }
        if (autoHeightMax) {
          this._scrollbars.container.style.height = `${Math.min(this._content.offsetHeight, autoHeightMax)}px`;
        }
      }
    });
  }

  getContainer () {
    if (!this._scrollbars) {
      return null;
    }

    return this._scrollbars.container;
  }

  getView () {
    if (!this._scrollbars) {
      return null;
    }

    return this._scrollbars.view;
  }

  getScrollLeft () {
    if (!this._scrollbars) {
      return 0;
    }

    return this._scrollbars.getScrollLeft();
  }

  getScrollTop () {
    if (!this._scrollbars) {
      return 0;
    }

    return this._scrollbars.getScrollTop();
  }

  scrollLeft (left) {
    if (!this._scrollbars) {
      return;
    }

    this._scrollbars.scrollLeft(left);
  }

  scrollTop (top) {
    if (!this._scrollbars) {
      return;
    }

    this._scrollbars.scrollTop(top);
  }

  renderView = props => {
    const { viewProps, viewClassName } = this.props;
    const { style, className, ...otherProps } = props;

    const viewStyle = {
      ...style,
    };

    return (
      <div
        {...otherProps}
        className={cx(
          className,
          viewClassName,
        )}
        style={viewStyle}
        {...viewProps}
      />
    );
  };

  render () {
    const {
      children,
      className,
      autoWidth,
      hideHorizontalScrollbar,
      hideVerticalScrollbar,
      onScroll,
    } = this.props;

    return (
      <Scrollbars
        ref={this.setScrollbarsRef}
        hideTracksWhenNotNeeded
        renderTrackHorizontal={hideHorizontalScrollbar ? () => <div className={classes.TrackHidden} /> : undefined}
        renderTrackVertical={hideVerticalScrollbar ? () => <div className={classes.TrackHidden} /> : undefined}
        renderView={this.renderView}
        onScroll={onScroll}
      >
        <div
          ref={this.setContentRef}
          className={cx(
            { [classes.ContentAutoWidth]: autoWidth },
            className,
          )}
        >
          {children}
        </div>
      </Scrollbars>
    );
  }
}

export default Scrollbar;
