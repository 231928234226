import { Range } from 'react-range';
import React from 'react';
import classes from './Range.module.scss';

interface Props {
  min: number;
  max: number;
  minValue: number;
  maxValue: number;
  step?: number;
  onChange: (values: { min: number, max: number}) => void
}
export default (props: Props) => (
  <Range
    step={props.step === undefined ? 1 : props.step}
    min={props.min}
    max={props.max}
    values={[props.minValue, props.maxValue]}
    onChange={values => props.onChange({ min: values[0], max: values[1] })}
    renderTrack={({ props, children }) => (
      <div
        {...props}
        className={classes.range}
        style={{
          ...props.style
        }}
      >
        {children}
      </div>
    )}
    renderThumb={({ props }) => (
      <div
        {...props}
        className={classes.rangeThumb}
        style={{
          ...props.style
        }}
      />
    )}
  />
);
