import React, { useCallback, useEffect, useState } from 'react';
import API from 'api';
import LocalizedMessage, { localizeMessage } from 'components/LocalizedMessage/LocalizedMessage';
import { saveAs } from 'file-saver';
import Config from 'config';

export const reachChartColors = [
  'blue',
  'green',
  'DarkKhaki',
  'purple',
  'Crimson',
  'DarkSlateGray',
  'GoldenRod'
];

const loadData = async (id: string, chart: IChartType) => API.annualPlans.plots(id, chart);

export type IChartType = 'CBU' | 'TVOLV';

export const usePlotData = <T extends Record<string, any>>(
  planId: string,
  chart: IChartType
) => {
  const [data, setData] = useState<T | undefined>(undefined);
  useEffect(() => {
    loadData(planId, chart).then(setData).catch(() => undefined);
  }, [planId, chart]);

  return data;
};

interface IRangeInputProps {
  value: number;
  onChange: (newVal: number) => void;
  min?: number;
  max?: number;
}

interface IGenericRangeInputProps extends IRangeInputProps {
  labelLocalId: string;
  placeholderLocalId: string;
}

const GenericReachInput = ({
  value,
  onChange,
  labelLocalId,
  min,
  max,
  placeholderLocalId
}: IGenericRangeInputProps) => (
  <div className='form-group'>
    <label htmlFor='min'>
      <LocalizedMessage id={labelLocalId} />
      {' '}
      {value}
    </label>
    <input
      id='min'
      type='range'
      name='min'
      placeholder={localizeMessage({
        id: placeholderLocalId
      })}
      className={'form-control'}
      min={min}
      max={max}
      value={value}
      onChange={e => {
        const value = Number(e.target.value);
        onChange(value);
      }}
      required
    />
  </div>
);

export const MinReachInput = ({
  value,
  onChange,
  min,
  max
}: IRangeInputProps) => (
  <GenericReachInput
    value={value}
    onChange={onChange}
    min={min}
    max={max}
    labelLocalId={'annualPlan.tvOlvChart.reachMin'}
    placeholderLocalId={'annualPlanEdit.campaignChartMessages.min'}
  />
);

export const MaxReachInput = ({
  value,
  onChange,
  min,
  max
}: IRangeInputProps) => (
  <GenericReachInput
    value={value}
    onChange={onChange}
    min={min}
    max={max}
    labelLocalId={'annualPlan.tvOlvChart.reachMax'}
    placeholderLocalId={'annualPlanEdit.campaignChartMessages.max'}
  />
);

export const useDownloadChartCallback = (
  planId: string,
  customKey: string = `${planId}-chart`
): [string, () => void] => {
  const canvasId: string = customKey;
  const callback = useCallback(() => {
    const canvas = document.getElementById(
      customKey
    ) as HTMLCanvasElement | null;
    if (!canvas) return;
    canvas.toBlob(blob => {
      saveAs(blob, `${customKey}.png`);
    });
  }, [planId, customKey]);

  return [canvasId, callback];
};

export const useExportCbuExcelCallback = (planId: string, taId: number | string, base: string): () => void => {
  const realBase = base === 'trp' ? 'reach' : base;
  const url = `${Config.api.host}/api/annualPlans/plots/excel/cbu/${planId}/${taId}/${realBase.toUpperCase()}`;

  return useCallback(() => {
    window.open(url, '_blank');
  }, [planId, taId, base]);
};

export const useExportTvOlvExcelCallback = (planId: string): () => void => {
  const url = `${Config.api.host}/api/annualPlans/plots/excel/tvolv/${planId}`;

  return useCallback(() => {
    window.open(url, '_blank');
  }, [planId]);
};
