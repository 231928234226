import React, { PureComponent } from 'react';
import { HotTable, HotTableProps } from '@handsontable/react';
import Handsontable from 'handsontable';
import set from 'lodash/set';
import property from 'lodash/property';

import { MAX_INT_VALUE } from 'helpers/constants';
import { formatNumberToNumber } from 'helpers/utils';
import LocalizedMessage, { localizeMessage } from 'components/LocalizedMessage';
import { IChannel, IdToChannelConfigMap, IFullChannelConfig } from 'routes/AnnualPlanEdit/types';

interface IProps {
  params: IdToChannelConfigMap;
  channelsToDisplay: IChannel[];
  isLoadingPrices: boolean;
  onUpdateValidationStatus: (isValid: boolean, type: string) => void;
}

type ChannelConfigForDisplay = {
  channel: IChannel;
  extraCharge: IFullChannelConfig['extraCharge'];
  defaultExtraCharge: IFullChannelConfig['defaultExtraCharge'];
};

const numberColumnIDs = [
  'extraCharge.floatOffprime',
  'extraCharge.floatPrime',
  'extraCharge.fixOffprime',
  'extraCharge.fixPrime',
];

class ExtraCharge extends PureComponent<IProps> {
  cellsErrors: Record<string, string>[] = [];

  UNSAFE_componentWillReceiveProps (nextProps: IProps) {
    if (
      this.props.channelsToDisplay !== nextProps.channelsToDisplay ||
      this.props.params !== nextProps.params ||
      this.props.isLoadingPrices && !nextProps.isLoadingPrices
    ) {
      this.toDisplay = this.getRefreshedToDisplay(nextProps);
    }
  }

  getRefreshedToDisplay = (props = this.props) => {
    const { params, channelsToDisplay } = props;

    return channelsToDisplay.map(channel => ({
      channel,
      extraCharge: (params[channel.id] as any).extraCharge,
      defaultExtraCharge: (params[channel.id] as any).defaultExtraCharge,
    }));
  };

  toDisplay: ChannelConfigForDisplay[] = this.getRefreshedToDisplay(this.props);

  generateTableOptions = (): HotTableProps => ({
    data: this.toDisplay,
    colHeaders: true,
    nestedHeaders: [
      [
        localizeMessage({ id: 'channel' }),
        'Fix offprime',
        'Fix prime',
        'Superfix offprime',
        'Superfix prime',
      ],
    ],
    columns: [
      {
        data: 'channel.nameWithType',
        readOnly: true,
      },
      {
        data: 'extraCharge.floatOffprime',
        type: 'numeric',
        renderer: this.cellRenderer,
      },
      {
        data: 'extraCharge.floatPrime',
        type: 'numeric',
        renderer: this.cellRenderer,
      },
      {
        data: 'extraCharge.fixOffprime',
        type: 'numeric',
        renderer: this.cellRenderer,
      },
      {
        data: 'extraCharge.fixPrime',
        type: 'numeric',
        renderer: this.cellRenderer,
      },
    ],
    cells: (row, col, columnID: string) => {
      const cellProperties: Handsontable.CellMeta = {
        errorIDs: this.cellsErrors[row]
          ? this.cellsErrors[row][columnID] || []
          : []
      };

      if (numberColumnIDs.includes(columnID)) {
        const propName = columnID.replace('extraCharge.', '');

        if (cellProperties.errorIDs.length) {
          cellProperties.className = 'invalid';
        } else if (
          this.toDisplay[row] &&
            this.toDisplay[row].extraCharge[propName] !== this.toDisplay[row].defaultExtraCharge?.[propName]
        ) {
          cellProperties.className = 'c-calendar-different';
        } else {
          cellProperties.className =
              propName === 'floatOffprime' || propName === 'floatPrime'
                ? 'success'
                : 'warning';
        }
      }

      return cellProperties;
    },
  });

  cellRenderer = (...args) => {
    const td = args[1];
    const row = args[2];
    const prop = args[4];
    const cellProperties = args[6];

    Handsontable.renderers.TextRenderer.apply(this, args);
    const value = property<ChannelConfigForDisplay, number>(prop)(this.toDisplay[row]);
    td.innerHTML = formatNumberToNumber(value);

    if (cellProperties.className?.includes('invalid')) {
      const msg = cellProperties.errorIDs
        .map(id => localizeMessage({ id }))
        .join('\n');
      td?.setAttribute('title', msg);
    } else if (cellProperties.className?.includes('c-calendar-different')) {
      const monthIndex = prop.replace('extraCharge.', '');

      td?.setAttribute('title', `Changed the base value. The base value is ${
        this.toDisplay[row].defaultExtraCharge[monthIndex]}`);
    }
  };

  validateCell = (row, columnID) => {
    const errorIDs: string[] = [];
    const stringValue = property<unknown, string>(columnID)(row);
    const value = parseFloat(stringValue);
    if (value > MAX_INT_VALUE) {
      errorIDs.push('annualPlanEdit.errors.exceedMaxInteger');
    }

    return errorIDs;
  };

  afterChange = (changes: Handsontable.CellChange[] | null) => {
    if (!changes) {
      return;
    }

    changes.forEach(([row, columnID, oldVal, newValue]) => {
      if (typeof newValue === 'number') {
        set(this.toDisplay[row], columnID, formatNumberToNumber(newValue));
      }
    });
  };

  beforeRender = () => {
    let isValidTable = true;
    this.cellsErrors = this.toDisplay.map(row => numberColumnIDs.reduce((acc, columnID) => {
      const errorIDs = this.validateCell(row, columnID);
      if (errorIDs.length) {
        isValidTable = false;
      }

      return { ...acc, [columnID]: errorIDs };
    }, {})
    );
    this.props.onUpdateValidationStatus(isValidTable, 'extraCharge');
  };

  render () {
    return (
      <>
        <p className='_text-align--center m-t m-b'>
          <LocalizedMessage id='annualPlanEdit.tvCampaignParams.extraCharge' />
        </p>

        <HotTable
          {...this.generateTableOptions()}
          tableClassName='table'
          stretchH='all'
          width='100%'
          height='auto'
          autoWrapRow
          afterChange={this.afterChange}
          beforeRender={this.beforeRender}
          licenseKey='non-commercial-and-evaluation'
        />
      </>
    );
  }
}

export default ExtraCharge;
