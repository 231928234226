import { fetchResponse } from './helpers/fakeFetch';
import Client from '../release/HTTPClient';

import commitmentsList from './data/commitmentsList.json';
import commitmentsFilterItems from './data/commitmentsFilterItems.json';

const commitmentsAPI = {
  getForAdvertiserPeriod (request) {
    console.log('api.commitments.getForAdvertiserPeriod(', request, ')');

    return Client.post('/api/commitments/getForClientPeriod', request);
  },

  list (request) {
    console.log('api.commitments.list(', request, ')');

    return fetchResponse(1000, commitmentsList);
  },

  get (id) {
    console.log('api.commitments.get(', id, ')');

    return Client.get(`/api/commitments/${id}`);
  },

  save (id, data) {
    console.log('api.commitments.save(', id, ', ', data, ')');

    if (!id) {
      return Client.post('/api/commitments/new', data);
    }

    return Client.put(`/api/commitments/${id}`, data);
  },

  getFilterItems (request) {
    console.log('api.commitments.getFilterItems(', request, ')');

    return fetchResponse(1000, commitmentsFilterItems);
  },
};

export default commitmentsAPI;
