import { IPlatformConfig } from '../types';

export const discountDefaultValue = 1;
export function createNewPlatform (name = 'new platform'): IPlatformConfig {
  return {
    name,
    restrictions: {
      advertiserBasePrice: undefined,
      seasonalDiscounts: Array.from<number>({ length: 12 }).fill(discountDefaultValue),
      budgetPercent: { min: 0, max: 100 },
      targetHit: {},
      isExcludePlatform: false,
    },
  };
}

export const convertPlatform = (platform): IPlatformConfig => {
  const { name, quality, min, max, targetHit, averageFrequency,
    cookieRealUserConversion, maxReachPercent, cpm } = platform;

  return {
    name,
    restrictions: {
      quality,
      targetHit: { targetHitValue: targetHit },
      bigCitiesShare: platform['shareOfCities_100Plus'],
      averageFrequency,
      realUserConversion: cookieRealUserConversion,
      maxReachPercent,
      advertiserBasePrice: cpm || undefined,
      seasonalDiscounts: Array.from<number>({ length: 12 }).fill(discountDefaultValue),
      budgetPercent: { min: min || 0, max: max || 100 },
      isExcludePlatform: true,
    },
  };
};
