import { fetchResponse } from './helpers/fakeFetch';

import personaTargetAudienceList from './data/personaTargetAudienceList.json';

const personaTargetAudienceAPI = {
  list (request, channelType) {
    console.log('api.personaTargetAudience/', channelType, '.list(', request, ')');

    return fetchResponse(1000, personaTargetAudienceList);
  },
};

export default personaTargetAudienceAPI;
