import { fetchResponse } from './helpers/fakeFetch';

import regionsList from './data/regionsList.json';

const regionsAPI = {
  list (request) {
    console.log('api.regions.list(', request, ')');

    return fetchResponse(1000, regionsList);
  },

  create (request) {
    console.log('api.regions.create(request)');

    return fetchResponse(1000, {});
  },
};

export default regionsAPI;
