import { fetchResponse } from './helpers/fakeFetch';
import Client from '../release/HTTPClient';

import communicationChannelsList from './data/communicationChannelsList.json';

const communicationChannelsAPI = {
  list (request) {
    console.log('api.communicationChannels.list(', request, ')');

    return fetchResponse(1000, communicationChannelsList);
  },

  get (id) {
    console.log('api.communicationChannels.get(', id, ')');

    return Client.get(`/api/communicationChannels/${id}`);
  },
};

export default communicationChannelsAPI;
