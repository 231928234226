import FilterItems, { IProcessedFilterItem } from 'helpers/filterItems';
import Client from 'api/release/HTTPClient';
import { IServerPage, ISite } from 'types';

const sitesAPI = {
  list (request): Promise<IServerPage<ISite>> {
    return Client.post('/api/sites/', request);
  },

  // todo
  programmatic () {
    return Client.get('/api/sites/programmatic/');
  },

  get (id: number): Promise<ISite> {
    return Client.get(`/api/sites/${id}`);
  },

  async getFilterItems (request): Promise<IProcessedFilterItem[]> {
    const result = await Client.get('/api/sites/filter-items', request);

    return FilterItems.getDataAndPostProcess(result);
  },
};

export default sitesAPI;
