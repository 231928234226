import Client from '../release/HTTPClient';

const syncAPI = {
  getDictionaries () {
    return Client.get('/api/sync/dictionaries');
  },

  sync (data) {
    return Client.post('/api/sync/', data);
  },
};

export default syncAPI;
