import React from 'react';
import cx from 'classnames';
import LocalizedMessage from 'components/LocalizedMessage';
import { capitalizeFirstLetter } from 'helpers/utils';
import classes from './Loader.module.scss';

interface IProps {
  position?: 'absolute' | 'inline';
  description?: string | React.ReactNode;
  className?: string;
  active?: boolean;
  withText?: boolean;
  withDescription?: boolean;
  withBackground?: boolean;
  isSmall?: boolean;
}

const Loader = (props: IProps) => {
  const {
    position = 'absolute', active = true, withText = true, withDescription = false,
    withBackground = true, description, isSmall = false, className = null,
  } = props;

  return (
    <div
      className={cx(
        classes.Loader,
        classes[`Loader${capitalizeFirstLetter(position)}`],
        {
          [classes.LoaderActive]: active,
          [classes.LoaderSmall]: isSmall,
          [classes.LoaderWithBackground]: position !== 'inline' && withBackground && !isSmall,
        },
        className,
      )}
    >
      <div className={classes.LoaderContent}>
        <ul className={classes.LoaderAnimation}>
          {
              new Array(12).fill(null).map((value, index) => (
                <li
                  key={index}
                  className={classes.LoaderAnimationItem}
                />
              ))
            }
        </ul>
        {withText && !isSmall && (
          <p className={classes.LoaderText}>
            <LocalizedMessage
              id='loading'
            />
          </p>
        )}
        {withDescription && !isSmall && (
          <p className={classes.LoaderDescription}>
            {description || (
              <LocalizedMessage
                id='loadingDescription'
              />
            )}
          </p>
        )}
      </div>
    </div>
  );
};

Loader.defaultProps = {
  position: 'absolute',
  active: true,
  withText: true,
  withDescription: false,
  withBackground: true,
  isSmall: false,
  className: null,
};

export default Loader;
