import Client from 'api/release/HTTPClient';
import { ICommunicationChannel, IGenericListRequest, IServerPage } from 'types';

const communicationChannelsAPI = {
  list (request: IGenericListRequest): Promise<IServerPage<ICommunicationChannel>> {
    return Client.post('/api/communicationChannels/', request);
  },

  get (id: number): Promise<ICommunicationChannel> {
    return Client.get(`/api/communicationChannels/${id}`);
  }
};

export default communicationChannelsAPI;
