import classes from '../../AnnualPlanEdit.module.scss';
import React, { useCallback, useEffect, useState } from 'react';
import cx from 'classnames';
import { Tab, Tabs } from 'react-bootstrap';
import Cbu, { defaultCbuValues, ICbuValues } from './Tabs/Cbu';
import TvOLV, { defaultTvOlvValues, ITvOLVValues } from 'routes/AnnualPlanEdit/components/Panels/Charts/Tabs/TvOLV';
import { ICampaignPlots } from 'routes/AnnualPlanEdit/types';
import property from 'lodash/property';
import { insertDefaultPlotValues } from 'routes/AnnualPlanEdit/helpers/chart-validation';

type ChartsTab = 'cbu' | 'tvolv';

interface IProps {
  plots?: ICampaignPlots;
  campaignID: string | number;
  onUpdateTvOlv: (id: string | number, values: ITvOLVValues) => void;
  onUpdateCbu: (id: string | number, values: ICbuValues) => void;
}
export default (props: IProps) => {
  const [selectedTab, setSelectedTab] = useState<ChartsTab>('cbu');

  useEffect(() => {
    let plotsCopy = {
      ...(props.plots || {
        cbuPlot: defaultCbuValues,
        tvOlvPlot: defaultTvOlvValues
      })
    };
    plotsCopy = insertDefaultPlotValues(plotsCopy);
    props.onUpdateCbu(props.campaignID, plotsCopy.cbuPlot || defaultCbuValues);
    props.onUpdateTvOlv(
      props.campaignID,
      plotsCopy.tvOlvPlot || defaultTvOlvValues
    );
  }, []);

  const passCbuCallback = useCallback(
    (vals: ICbuValues) => {
      props.onUpdateCbu(props.campaignID, vals);
    },
    [props.campaignID]
  );

  const passTvOlvCallback = useCallback(
    (vals: ITvOLVValues) => {
      props.onUpdateTvOlv(props.campaignID, vals);
    },
    [props.campaignID]
  );

  return (
    <div>
      <div className='row m-b-sm'>
        <div className='col-lg-6'>
          <div className={cx('row', classes['goal-switcher'])}>
            <div className='col-lg-4' />
          </div>
        </div>
      </div>

      <div className='tabs-container'>
        <Tabs
          id='tv-campaign-params'
          defaultActiveKey={selectedTab}
          mountOnEnter
          unmountOnExit
          onSelect={(key: string) => setSelectedTab(key as ChartsTab)}
        >
          <Tab eventKey='cbu' title='CBU'>
            <Cbu
              values={
                property('cbuPlot')(props.plots) as ICbuValues | undefined
              }
              onChange={passCbuCallback}
            />
          </Tab>
          <Tab eventKey='tvolv' title='TVOLV'>
            <TvOLV
              values={
                property('tvOlvPlot')(props.plots) as ITvOLVValues | undefined
              }
              onChange={passTvOlvCallback}
            />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};
