import React, { PureComponent } from 'react';
import cx from 'classnames';
import Pagination from 'react-js-pagination';
import LocalizedMessage from 'components/LocalizedMessage';

import classes from './TableList.module.scss';

interface IProps {
  currentSort: string;
  totalItems: number;
  itemsCountPerPage: number;
  head: HeadItem[];
  isLoading: boolean;
  currentPage: number;
  // children: React.ReactChildren;
  onUpdateSort?: (newSortName: string) => void;
  onUpdatePage?: (newPage: number) => void;
}

type EmptyOption = { empty: boolean; props?: any};
type StandartOption = { label: string, sort?: string, props?: any };
export type HeadItem = StandartOption | EmptyOption;

const isEmptyOption = (arg: any): arg is EmptyOption => arg.empty === true;

class TableList extends PureComponent<IProps> {
  static defaultProps = {
    head: [],
    itemsCountPerPage: 15,
  };

  onClickSort (newSortName: string) {
    const { onUpdateSort, currentSort } = this.props;

    const isReverse = currentSort[0] === '-';
    const oldSortName = currentSort.slice(isReverse ? 1 : 0);
    const resultSortName = oldSortName === newSortName ? `${!isReverse ? '-' : ''}${newSortName}` : newSortName;

    if (typeof onUpdateSort === 'function') {
      onUpdateSort(resultSortName);
    }
  }

  handlePageChange = (currentPage: number) => {
    const { onUpdatePage } = this.props;

    if (typeof onUpdatePage === 'function') {
      onUpdatePage(currentPage);
    }
  };

  generateHeadItems () {
    const { head, currentSort } = this.props;

    return head.map((headItem, headIndex) => {
      if (isEmptyOption(headItem)) {
        return (
          <th
            key={headIndex}
            {...headItem.props}
          />
        );
      }
      const hasSorting = headItem.sort && headItem.sort.length;

      return (
        <th
          key={headIndex}
          className={cx({
            [classes.TableListSort]: hasSorting,
          })}
          onClick={hasSorting ? () => this.onClickSort(headItem.sort as string) : null}
          {...headItem.props}
        >
          <span>{headItem.label}</span>
          <i
            className={cx('fa', {
              [classes.IconAscent]: currentSort === headItem.sort,
              [classes.IconDescent]: currentSort === `-${headItem.sort}`,
            })}
          />
        </th>
      );
    });
  }

  render () {
    const {
      children,
      totalItems,
      itemsCountPerPage,
      head,
      isLoading,
      currentPage,
    } = this.props;

    const childrenComponent = !isLoading ? children : (
      <tr>
        <td colSpan={head.length} className='text-center'>
          <LocalizedMessage id='loading' />
          {'...'}
        </td>
      </tr>
    );

    return (
      <table
        className='table table-hover c-smart-table'
      >
        <thead>
          <tr>
            {this.generateHeadItems()}
          </tr>
        </thead>

        <tbody>
          {childrenComponent}
        </tbody>

        <tfoot>
          <tr>
            <td className='text-center' colSpan={head.length}>
              {totalItems > itemsCountPerPage && (
                <LocalizedMessage id={['pagination.first', 'pagination.last']}>
                  {(localizedFirst, localizedLast) => (
                    <Pagination
                      prevPageText='←'
                      nextPageText='→'
                      firstPageText={localizedFirst}
                      lastPageText={localizedLast}
                      activePage={currentPage}
                      itemsCountPerPage={itemsCountPerPage}
                      totalItemsCount={totalItems}
                      onChange={this.handlePageChange}
                    />
                  )}
                </LocalizedMessage>
              )}
            </td>
          </tr>
        </tfoot>
      </table>
    );
  }
}

export default TableList;
