import Client from 'api/release/HTTPClient';
import { IAgency, IGenericListRequest, IServerPage } from 'types';

const agenciesAPI = {
  current (): Promise<IAgency> {
    return Client.get('/api/agencies/current');
  },

  list (request: IGenericListRequest): Promise<IServerPage<IAgency>> {
    return Client.post('/api/agencies/', request);
  },

  accessible (withoutLoader = false): Promise<Array<Required<IAgency>>> {
    return Client.get(
      '/api/agencies/accessible',
      {}, true, undefined, undefined, undefined, { withoutLoader }
    );
  },

  get (id: number): Promise<IAgency> {
    return Client.get(`/api/agencies/${id}`);
  },
};

export default agenciesAPI;
