import Client from 'api/release/HTTPClient';
import { ITvCppWithBaseCoefficients } from 'types';

const tvPricesAPI = {
  getPrices (year, withoutLoader): Promise<ITvCppWithBaseCoefficients[]> {
    return Client.get(`/api/tvPrices/?year=${year}`, {}, true, {}, true, null, { withoutLoader });
  },
};

export default tvPricesAPI;
