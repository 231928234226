import { fetchResponse } from './helpers/fakeFetch';

import agenciesList from './data/agenciesList.json';

const agenciesAPI = {
  current () {
    console.log('api.agencies.current()');

    return fetchResponse(1000, {
      id: 108,
      name: 'Universal Media',
    });
  },

  list (request) {
    console.log('api.agencies.list(', request, ')');

    return fetchResponse(1000, agenciesList);
  },

  get (id) {
    console.log(`api.agencies.get(${id})`);

    return fetchResponse(1000, {});
  },
};

export default agenciesAPI;
