import React from 'react';
import cx from 'classnames';
import { Helmet } from 'react-helmet';
import Alert from 'react-s-alert';
import 'react-dates/initialize';
import Scrollbar from 'components/Scrollbar';
import Header from 'components/Header';
import Sidebar from 'components/Sidebar';
import YandexMetrika from 'components/YandexMetrika';
import PageLoader from 'components/PageLoader';
import Routes from 'routes';
import classes from './App.module.scss';
import { isOnPointsGroup } from 'config/constants';

export const App = () => (
  <div className={classes.Container}>
    <Helmet
      titleTemplate={`%s - ${isOnPointsGroup ? 'Prizma' : 'Aizek'}.Algorithm`}
      defaultTitle={`%s - ${isOnPointsGroup ? 'Prizma' : 'Aizek'}.Algorithm`}
    />
    <Sidebar />
    <div className={cx('gray-bg', classes.Main)}>
      <Header />
      <div className={classes.Content}>
        <Scrollbar className={classes.ContentScrollbar} viewProps={{ id: 'main_content' }}>
          <Routes />
        </Scrollbar>
      </div>
    </div>
    <Alert
      stack={{ limit: 5 }}
      position='top-right'
      effect='slide'
      timeout={5000}
    />
    <YandexMetrika />
    <PageLoader />
  </div>
);

export default App;
