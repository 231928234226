import { combineReducers } from 'redux';
import locale from './locale';
import auth from './auth';
import menu from './menu';
import loader from './loader';

export default combineReducers({
  locale,
  auth,
  menu,
  loader,
});
