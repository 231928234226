import React, { PureComponent } from 'react';
import { Helmet } from 'react-helmet';
import LocalizedMessage, { localizeMessage } from 'components/LocalizedMessage';
import URLFiltersHoc, { Props } from 'components/URLFiltersHoc';
import Breadcrumbs from 'components/Breadcrumbs';
import FilterList from 'components/FilterList';
import TableList from 'components/TableList';

import API from 'api';
import { Link } from 'react-router-dom';

interface IState {
  filterItems: any[],
  dataList: any[],
  dataListTotal: number;
  isLoading: boolean;
}

interface IOwnProps {
  checkRoles: (role: string) => boolean;
  agencyId: number;
}

class Advertisers extends PureComponent<Props & IOwnProps, IState> {
  state: IState = {
    filterItems: [],
    dataList: [],
    dataListTotal: 1,
    isLoading: false,
  };

  mounted = false;
  refreshListTimer = 0;
  refreshListTimerDelay = 500;

  componentDidMount () {
    this.mounted = true;
    this.loadFilters();
    this.refreshList();
  }

  componentDidUpdate (prevProps) {
    if (prevProps.filters !== this.props.filters) {
      this.refreshList();
    }

    if (prevProps.agencyId !== this.props.agencyId) {
      this.loadFilters();
      this.refreshList();
    }
  }

  componentWillUnmount () {
    this.mounted = false;
    clearTimeout(this.refreshListTimer);
  }


  async loadFilters () {
    const filterItems = await API.advertisers.getFilterItems(this.props.agencyId);

    if (!this.mounted) {
      return;
    }

    this.setState({
      filterItems,
    });
  }

  refreshList () {
    clearTimeout(this.refreshListTimer);
    const { currentPage, currentSort, max, items } = this.props.filters;
    const dataListRequest = {
      page: currentPage,
      order: currentSort,
      max,
      filter: { items },
    };

    this.refreshListTimer = window.setTimeout(async () => {
      this.setState({ isLoading: true });

      dataListRequest.filter.items = dataListRequest.filter.items.filter((item) => !item.includes('AGENCY'));
      dataListRequest.filter.items.push(`AGENCY_${this.props.agencyId}`);
      dataListRequest.filter.items = [...new Set(dataListRequest.filter.items)];


      const dataList = await API.advertisers.list(dataListRequest);

      if (!this.mounted) {
        return;
      }

      this.setState({
        dataList: dataList.items,
        dataListTotal: dataList.total,
        isLoading: false,
      });
    }, this.refreshListTimerDelay);
  }

  getBreadcrumbs () {
    return [
      {
        title: <LocalizedMessage id='home' />,
        link: '/app',
      },
      {
        title: <LocalizedMessage id='advertisers' />,
        link: '/app/dictionaries/advertisers',
      },
    ];
  }

  getBodyItems () {
    const { checkRoles } = this.props;
    const { dataList } = this.state;

    return dataList.map((row, rowIndex) => (
      <tr key={`${rowIndex}_${row.name}`}>
        <td>
          {
            checkRoles('ADMIN')
              ? (
                <Link
                  to={`/app/dictionaries/advertisers/${row.id}`}
                >
                  {row.name}
                </Link>
              )
              : <span>{row.name}</span>
          }
        </td>
        {
          checkRoles('SUPER_ADMIN')
            ? (
              <td>
                <span>{row.extend.agency.name}</span>
              </td>
            )
            : null
        }
      </tr>
    ));
  }

  render () {
    const {
      checkRoles,
      onUpdateSort, onUpdatePage, filters, onChangeFilterItems,
    } = this.props;

    const {
      filterItems,
      dataListTotal,
      isLoading,
    } = this.state;

    const isSuperUser = checkRoles('SUPER_ADMIN');

    const tableHead = [
      {
        label: localizeMessage({ id: 'name' }),
        sort: 'name',
      },
    ];

    if (isSuperUser) {
      tableHead.push({
        label: localizeMessage({ id: 'agency' }),
        sort: 'agency_id',
      });
    }

    return (
      <>
        <LocalizedMessage
          id='site.title.dictionaries.advertisers'
        >
          {localizedMessage => (
            <>
              <Helmet
                title={localizedMessage}
              />
              <Breadcrumbs
                title={localizedMessage}
                data={this.getBreadcrumbs()}
              />
            </>
          )}
        </LocalizedMessage>

        <div className='wrapper wrapper-content'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='ibox float-e-margins'>
                <div className='ibox-content'>
                  <div className='row'>
                    <div className='col-sm-12 m-b-sm d-flex'>
                      <div className='flex-grow-1 m-r'>
                        <FilterList
                          placeholder={<LocalizedMessage id='advertisers-dictionary.filter.placeholder' />}
                          options={filterItems}
                          onChange={onChangeFilterItems}
                          value={filters.items}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='table-responsive'>
                    <TableList
                      currentPage={filters.currentPage}
                      currentSort={filters.currentSort}
                      totalItems={dataListTotal}
                      itemsCountPerPage={filters.max}
                      onUpdateSort={onUpdateSort}
                      onUpdatePage={onUpdatePage}
                      isLoading={isLoading}
                      head={tableHead}
                    >
                      {this.getBodyItems()}
                    </TableList>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default URLFiltersHoc(Advertisers);
