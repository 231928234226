import React, { Component } from 'react';
import set from 'lodash/set';
import cx from 'classnames';
import { HotTable, HotTableProps } from '@handsontable/react';
import Handsontable from 'handsontable';

import Alert from 'helpers/alert';
import {
  formatNumberToNumber,
  formattedNumber,
  getColorScheme
} from 'helpers/utils';
import LocalizedMessage, { localizeMessage } from 'components/LocalizedMessage';
import API from 'api';
import {
  IChannel,
  IChannelConfig,
  IdToChannelConfigMap,
  IChannelGroup,
  ChannelType
} from 'routes/AnnualPlanEdit/types';

const numberColumnIDs = [
  'floatPrimePercentMin',
  'floatPrimePercentMax',
  'fixPrimePercentMin',
  'fixPrimePercentMax',
  'fixPercentMin',
  'fixPercentMax',
  'channelPercentMin',
  'channelPercentMax',
  'nameWithType'
];

const forGroupsIDs = [
  'isSFix',
  'channelPercentMin',
  'channelPercentMax',
  'isExcludedChannel'
];

const numericFormat = {
  pattern: {
    postfix: '%',
    mantissa: 2,
    trimMantissa: true,
  },
} as any;

interface IProps {
  params: IdToChannelConfigMap;
  sellerRestrictions: Record<string, { min: number; max: number }>;
  channelsToDisplay: IChannel[];
  sourceChannels: IChannel[];
  channelGroups: IChannelGroup[];
  restrictionBase: 'BUDGET_GRP' | 'TRP_GRP';
  channelType: ChannelType | null;
  regionalId: number | null;
  targetAudienceId: number | null;
  isShowEmptyLines: boolean;
  retroFrom: string;
  retroTo: string;
  onUpdateValidationStatus: (isValid: boolean, type: string) => void;
  onUpdateForChannelGroupEditor: () => void;
}

interface IState {
  isLoadingNaturalSplit: boolean;
  isChangedCountChannels: boolean;
}

type ChannelConfigForValidate = {
  channel: IChannel;
  restrictions: IChannelConfig['restrictions'];
  extraCharge: IChannelConfig['extraCharge'];
};

type ChannelConfigForDisplay = {
  channel: IChannel;
  restrictions: IChannelConfig['restrictions'];
  extraCharge: IChannelConfig['extraCharge'];
  sellerRestrictionMin: number | null;
  sellerRestrictionMax: number | null;
  naturalSplit: number | null;
};

class Restrictions extends Component<IProps, IState> {
  state: IState = {
    isLoadingNaturalSplit: false,
    isChangedCountChannels: false
  };

  _hot: any = null;

  toDisplay: ChannelConfigForDisplay[] = [];
  toValidate: ChannelConfigForValidate[] = [];
  cellsErrors: Array<Record<string, any>> = [];
  naturalSplitMap: Record<number, number> = {};
  minColumnIsValid: boolean = true;
  maxColumnIsValid: boolean = true;
  changedChannels: boolean[] = [];

  componentDidMount () {
    this.refreshData();
    this.forceUpdate();
  }

  UNSAFE_componentWillReceiveProps (nextProps: IProps) {
    if (
      this.props.params !== nextProps.params ||
      this.props.channelsToDisplay !== nextProps.channelsToDisplay
    ) {
      this.refreshData(nextProps);
    }

    if (
      nextProps.retroFrom !== this.props.retroFrom ||
      nextProps.retroTo !== this.props.retroTo ||
      nextProps.channelType !== this.props.channelType ||
      nextProps.targetAudienceId !== this.props.targetAudienceId ||
      nextProps.regionalId !== this.props.regionalId
    ) {
      this.naturalSplitMap = {};
      this.setState({
        isLoadingNaturalSplit: false
      });
    }
  }

  setHotRef = ref => {
    this._hot = ref;
  };

  getChannelsToValidate = (props: IProps) => {
    const { sourceChannels: channels, isShowEmptyLines, params } = props;

    return isShowEmptyLines
      ? channels
      : channels.filter(
        channel => !params[channel.id].restrictions.isExcludedChannel
      );
  };

  loadNaturalSplit = async (props = this.props) => {
    if (
      !props.retroFrom ||
      !props.retroTo ||
      !props.targetAudienceId ||
      !props.channelType
    ) {
      return;
    }

    this.setState({
      isLoadingNaturalSplit: true,
      isChangedCountChannels: false
    });

    try {
      const naturalSplit = await API.channels.getNaturalSplit(
        props.retroFrom,
        props.retroTo,
        props.targetAudienceId,
        props.channelType,
        props.regionalId
      );

      this.changedChannels = [];
      const naturalSplitMap = {};

      const sumOfSplit = naturalSplit.reduce((sum, split) => {
        const currentChannel = this.toDisplay.find(
          channel => channel.channel.id === split.id
        );

        return currentChannel && !currentChannel.restrictions.isExcludedChannel
          ? sum + split.natural_split
          : sum;
      }, 0);

      naturalSplit.forEach(split => {
        const currentChannel = this.toDisplay.find(
          channel => channel.channel.id === split.id
        );

        naturalSplitMap[split.id] =
          currentChannel && !currentChannel.restrictions.isExcludedChannel
            ? formattedNumber((split.natural_split / sumOfSplit) * 100)
            : null;
      });

      this.naturalSplitMap = naturalSplitMap;
      this.refreshData();

      this.setState({
        isLoadingNaturalSplit: false
      });
    } catch (error) {
      console.error(error);

      Alert.error(localizeMessage({ id: 'errors.errorLoadingNaturalSplit' }));

      this.setState({
        isLoadingNaturalSplit: false
      });
    }
  };

  refreshData = (props: IProps = this.props) => {
    const { params, channelsToDisplay, sellerRestrictions } = props;

    this.toDisplay = channelsToDisplay.map(channel => {
      const sellerRestrictionMin =
        (sellerRestrictions[channel.id] &&
          sellerRestrictions[channel.id].min) ||
        null;
      const sellerRestrictionMax =
        (sellerRestrictions[channel.id] &&
          sellerRestrictions[channel.id].max) ||
        null;

      if (
        typeof params[channel.id].restrictions.floatPrimePercentMin ===
          'undefined' &&
        typeof params[channel.id].restrictions.floatPrimePercentMax ===
          'undefined'
      ) {
        if (sellerRestrictionMin) {
          params[
            channel.id
          ].restrictions.floatPrimePercentMin = sellerRestrictionMin;
        }
        if (sellerRestrictionMax) {
          params[
            channel.id
          ].restrictions.floatPrimePercentMax = sellerRestrictionMax;
        }
      }

      return {
        channel,
        restrictions: params[channel.id].restrictions,
        extraCharge: params[channel.id].extraCharge,
        sellerRestrictionMin,
        sellerRestrictionMax,
        naturalSplit: this.naturalSplitMap[channel.id] || null
      };
    });

    this.toValidate = this.getChannelsToValidate(props).map(channel => ({
      channel,
      restrictions: params[channel.id].restrictions,
      extraCharge: params[channel.id].extraCharge
    }));
  };

  generateTableOptions = (): HotTableProps => ({
    data: this.toDisplay,
    colHeaders: true,
    columnHeaderHeight: 10,
    nestedHeaders: [
      [
        '',
        '',
        localizeMessage({
          id: 'annualPlanEdit.tvCampaignParams.restrictions.average'
        }),
        localizeMessage({ id: 'naturalSplit' }),
        {
          label: `${localizeMessage({
            id: `annualPlanEdit.${this.props.restrictionBase}`
          })} %`,
          colspan: 2
        },
        {
          label: 'Superfix, % Inventory',
          colspan: 2
        },
        {
          label: 'Superfix Prime % Inventory',
          colspan: 2
        },
        {
          label: 'Fix Prime % Inventory',
          colspan: 2
        },
        localizeMessage({
          id: 'annualPlanEdit.tvCampaignParams.restrictions.exclude'
        })
      ],
      [
        localizeMessage({ id: 'channel' }),
        localizeMessage({
          id: 'inventoryType'
        }),
        '',
        '',
        'Min',
        'Max',
        'Min',
        'Max',
        'Min',
        'Max',
        'Min',
        'Max',
        ''
      ]
    ],
    columns: [
      {
        data: 'channel.nameWithType',
        readOnly: true,
        renderer: this.channelsRenderer
      },
      {
        data: 'channel.inventoryType',
        title: 'inventoryType',
        readOnly: true,
      },
      {
        data: 'extraCharge.isSFix',
        title: 'average',
        type: 'checkbox',
        renderer: this.sFixRenderer
      },
      {
        data: 'naturalSplit',
        title: 'naturalSplit',
        readOnly: true,
        renderer: this.naturalSplitRenderer
      },
      {
        data: 'restrictions.channelPercentMin',
        type: 'numeric',
        numericFormat,
      },
      {
        data: 'restrictions.channelPercentMax',
        type: 'numeric',
        numericFormat,
      },
      {
        data: 'restrictions.fixPercentMin',
        title: 'fixPercent',
        type: 'numeric',
        numericFormat,
      },
      {
        data: 'restrictions.fixPercentMax',
        title: 'fixPercent',
        type: 'numeric',
        numericFormat,
      },
      {
        data: 'restrictions.fixPrimePercentMin',
        title: 'fixPrimePercent',
        type: 'numeric',
        numericFormat,
      },
      {
        data: 'restrictions.fixPrimePercentMax',
        title: 'fixPrimePercent',
        type: 'numeric',
        numericFormat,
      },
      {
        data: 'restrictions.floatPrimePercentMin',
        title: 'floatPrimePercent',
        type: 'numeric',
        numericFormat,
      },
      {
        data: 'restrictions.floatPrimePercentMax',
        title: 'floatPrimePercent',
        type: 'numeric',
        numericFormat,
      },
      {
        data: 'restrictions.isExcludedChannel',
        title: 'excludeChannel',
        type: 'checkbox'
      }
    ],
    cells: (rowIndex, columnIndex, prop: string) => {
      const row = this.toDisplay[rowIndex];
      if (!row || !this.cellsErrors.length) {
        return {};
      }
      const exColumnID = prop.split('.');
      const columnID = exColumnID[exColumnID.length - 1];
      const type = columnID.slice(-3);
      const prefix = columnID.slice(0, -3);
      const cellProperties: Handsontable.CellMeta = {
        className: '',
        readOnly: false,
        errorIDs:
          (this.cellsErrors[rowIndex] &&
            this.cellsErrors[rowIndex][columnID] &&
            this.cellsErrors[rowIndex][columnID].errorIDs) ||
          [],
        warningIDs:
          (this.cellsErrors[rowIndex] &&
            this.cellsErrors[rowIndex][columnID] &&
            this.cellsErrors[rowIndex][columnID].warningIDs) ||
          []
      };

      const cellPropertiesClassName: string[] = [];
      const isReadOnly = this.isReadOnlyCell(prop, row);

      if (isReadOnly) {
        cellProperties.readOnly = true;
        cellPropertiesClassName.push('htDisabled');
      }

      if (['naturalSplit', 'channel.nameWithType', 'channel.inventoryType'].includes(prop)) {
        cellProperties.readOnly = true;
      }

      const isSFixLogic =
        (prop === 'restrictions.fixPercentMin' ||
          prop === 'restrictions.fixPercentMax') &&
        row.extraCharge.isSFix;

      if (['Min', 'Max'].includes(type)) {
        cellProperties.renderer = this.commentsRenderer;
        switch (prefix) {
          case 'channelPercent':
            cellPropertiesClassName.push('info');
            break;
          case 'fixPercent':
            cellPropertiesClassName.push('warning');
            break;
          case 'fixPrimePercent':
            cellPropertiesClassName.push('warning');
            break;
          case 'floatPrimePercent':
            cellPropertiesClassName.push('success');
            break;
          default:
            break;
        }
      }


      if (prop === 'extraCharge.isSFix' && !row.restrictions.isExcludedChannel && row.channel.hasFix) {
        cellPropertiesClassName.push('sfix-channel');
      }

      if (cellProperties.errorIDs.length) {
        cellPropertiesClassName.push('invalid');
      } else if (
        cellProperties.warningIDs.length &&
        !row.restrictions.isExcludedChannel &&
        !isSFixLogic
      ) {
        if (prop === 'channel.nameWithType') {
          cellPropertiesClassName.push('notification');
        } else {
          cellPropertiesClassName.push('incorrect');
        }
      }

      cellProperties.className = cx(...cellPropertiesClassName);

      return cellProperties;
    }
  });

  isReadOnlyCell = (prop: string, row: ChannelConfigForDisplay) => {
    // TODO: remove this condition later
    if (prop === 'extraCharge.isSFix' && this.isLocalChannel(row.channel)) {
      return true;
    }

    if (
      prop !== 'restrictions.isExcludedChannel' &&
      row.restrictions.isExcludedChannel
    ) {
      return true;
    }

    const isFixPercentProp = prop.startsWith('restrictions.fixPercent');
    const isFixPrimePercentProp = prop.startsWith('restrictions.fixPrimePercent');
    const isFloatPrimePercentProp = prop.startsWith('restrictions.floatPrimePercent');
    const isFixOrSFixProp = isFixPercentProp || isFixPrimePercentProp || isFloatPrimePercentProp;

    if (isFixOrSFixProp && row.channel.type.name === 'ORBITAL') {
      return true;
    }

    if (
      row.extraCharge.isSFix &&
      (isFixPercentProp || isFloatPrimePercentProp)
    ) {
      return true;
    }

    return false;
  };

  isLocalChannel = (channel: IChannel) => channel.type.name === 'LOCAL';

  sFixRenderer = (...args) => {
    const [, td, index] = args;


    Handsontable.renderers.CheckboxRenderer.apply(this, args);

    if (
      !this.toDisplay[index].channel.hasSFix ||
      this.toDisplay[index].channel.type.name === 'REGIONAL'
    ) {
      td.innerHTML = '—';

      return;
    }

    const dataCell = this.toDisplay[index];

    if (
      !dataCell.channel.hasFix ||
      dataCell.restrictions.isExcludedChannel ||
      this.isLocalChannel(dataCell.channel) // TODO: remove it later
    ) return;

    const hoverInfo = document.createElement('span');
    hoverInfo.classList.add('on-hover');


    setTimeout(() => {
      hoverInfo.textContent = dataCell.extraCharge.isSFix
        ? localizeMessage({
          id:
            'annualPlanEdit.tvCampaignParams.restrictions.sfix-enabled'
        })
        : localizeMessage({
          id:
            'annualPlanEdit.tvCampaignParams.restrictions.sfix-disabled'
        });
    }, 50);

    td.appendChild(hoverInfo);
  };

  channelsRenderer = (...args) => {
    const td: HTMLTableDataCellElement = args[1];
    const { channelGroups } = this.props;

    Handsontable.renderers.TextRenderer.apply(this, args);

    channelGroups.forEach((group, index) => {
      group.channels.forEach(channel => {
        if (channel.name === td.textContent) {
          td.style.backgroundColor = getColorScheme(index);
          td.style.border = '1px solid #000';
          td.style.color = '#000';
        }
      });
    });
  };

  naturalSplitRenderer = (...args) => {
    const td = args[1];
    const { isLoadingNaturalSplit } = this.state;

    Handsontable.renderers.TextRenderer.apply(this, args);

    if (isLoadingNaturalSplit) {
      td.innerHTML = td.classList.contains('htDisabled')
        ? ''
        : `${localizeMessage({ id: 'loading' }).toLowerCase()}...`;
    }
  };

  checkAllExcludedSFix = () => !this.toDisplay
    .filter(row => row.channel.hasSFix)
    .some(row => !row.extraCharge.isSFix);

  onExcludeAllSFixSwitch = () => {
    const hasAllExcluded = this.checkAllExcludedSFix();

    this.toDisplay.forEach(row => {
      if (
        row.channel.hasSFix &&
        !this.isLocalChannel(row.channel) // TODO: remove it later
      ) {
        row.extraCharge.isSFix = !hasAllExcluded;
        row.restrictions.fixPercentMin = hasAllExcluded ? undefined : 0;
        row.restrictions.fixPercentMax = hasAllExcluded ? undefined : 100;
        row.restrictions.fixPrimePercentMin = hasAllExcluded
          ? undefined
          : row.restrictions.floatPrimePercentMin;
        row.restrictions.fixPrimePercentMax = hasAllExcluded
          ? undefined
          : row.restrictions.floatPrimePercentMax;
      }
    });

    this.props.onUpdateForChannelGroupEditor();
  };

  checkAllExcludedChannels = () => !this.toDisplay.some(row => !row.restrictions.isExcludedChannel);

  onExcludeAllChannelsSwitch = () => {
    const hasAllExcludedChannels = this.checkAllExcludedChannels();

    this.toDisplay.forEach(row => {
      row.restrictions.isExcludedChannel = !hasAllExcludedChannels;
    });

    this.props.onUpdateForChannelGroupEditor();

    if (Object.keys(this.naturalSplitMap).length) {
      const isChangedCountChannels = hasAllExcludedChannels
        ? Object.values(this.naturalSplitMap).some(split => !split)
        : Object.values(this.naturalSplitMap).some(split => split);

      this.setState({ isChangedCountChannels });
    }
  };

  makeExcludeButton = (header: 'average' | 'excludeChannel') => {
    const isExcludeText =
      header === 'average' ? this.checkAllExcludedSFix() : this.checkAllExcludedChannels();
    const onExcludeAllSwitch =
      header === 'average'
        ? this.onExcludeAllSFixSwitch.bind(this)
        : this.onExcludeAllChannelsSwitch.bind(this);

    const _button = document.createElement('button');
    _button.classList.add('htButton');
    _button.setAttribute('data-test', 'select-all');
    _button.addEventListener('click', onExcludeAllSwitch);
    _button.innerText = !isExcludeText ? 'Select all' : 'Unselect all';

    return _button;
  };

  makeNaturalSplitButton = () => {
    const { isChangedCountChannels } = this.state;

    const _button = document.createElement('button');
    _button.innerText = localizeMessage({ id: 'refresh' });
    _button.classList.add(
      'btn',
      isChangedCountChannels ? 'btn-warning' : 'btn-primary'
    );
    _button.addEventListener(
      'click',
      this.loadNaturalSplit.bind(this, this.props)
    );

    if (this.state.isLoadingNaturalSplit) {
      _button.setAttribute('disabled', 'true');
    }

    return _button;
  };

  afterGetColHeader = (col: number, th) => {
    const hotInstance: Handsontable = this._hot?.hotInstance;
    if (!hotInstance) {
      return;
    }
    const header = hotInstance.getColHeader(col);
    const headerToTooltipIdMap = {
      fixPercent: 'fix-percent',
      fixPrimePercent: 'fix-percent',
      floatPrimePercent: 'float-percent',
    };
    const tooltipId = headerToTooltipIdMap[header];
    if (tooltipId) {
      const tooltip = localizeMessage({ id: `annualPlanEdit.tvCampaignParams.restrictions.tooltips.${tooltipId}` });
      th.setAttribute('title', tooltip);
    }

    if (header === 'average' || header === 'excludeChannel') {
      const _colHeader = th.querySelector('.colHeader');

      if (_colHeader && !_colHeader.innerHTML.length) {
        _colHeader.append(this.makeExcludeButton(header));
      }
    }

    if (header === 'naturalSplit') {
      const { isChangedCountChannels } = this.state;
      const _colHeader = th.querySelector('.colHeader');

      if (_colHeader && !_colHeader.innerHTML.length) {
        _colHeader.append(this.makeNaturalSplitButton());

        if (isChangedCountChannels) {
          const _div = document.createElement('div');
          _div.innerText = localizeMessage({
            id:
              'annualPlanEdit.tvCampaignParams.restrictions.changed-count-channels'
          });
          _div.classList.add('warning-message');

          _colHeader.append(_div);
        }
      }
    }
  };

  generateMultipleValidator = validators => (value, callbackFn) => {
    Promise.all(
      validators.map(
        validator => new Promise<void>((resolve, reject) => {
          validator(value, result => {
            if (result) {
              resolve();
            } else {
              reject(new Error('bad result'));
            }
          });
        })
      )
    )
      .then(() => {
        callbackFn(true);
      })
      .catch(() => {
        callbackFn(false);
      });
  };

  validateFormMinColumnSum = () => {
    let sum = this.toValidate.reduce((prevSum, row) => {
      const { channelPercentMin, isExcludedChannel } = row.restrictions;

      return isExcludedChannel ? prevSum : prevSum + (channelPercentMin || 0);
    }, 0);

    sum = Math.round(sum * 100) / 100;

    this.minColumnIsValid = sum <= 100;
  };

  validateFormMaxColumnSum = () => {
    let hasMaxFields = false;
    let hasEmptyMaxFields = false;

    let sum = this.toValidate.reduce((prevSum, row) => {
      const { channelPercentMax, isExcludedChannel } = row.restrictions;

      if (isExcludedChannel) {
        return prevSum;
      }

      if (channelPercentMax !== undefined) {
        hasMaxFields = true;

        return prevSum + channelPercentMax;
      }

      hasEmptyMaxFields = true;

      return prevSum;
    }, 0);

    sum = Math.round(sum * 100) / 100;

    this.maxColumnIsValid = !hasMaxFields || hasEmptyMaxFields || sum >= 100;
  };

  validateCell = (row: ChannelConfigForDisplay, columnID: string) => {
    const errorIDs: string[] = [];
    const warningIDs: string[] = [];
    const prop = columnID;

    const type = prop.slice(-3);
    const prefix = prop.slice(0, -3);
    const value = parseFloat(row.restrictions[prop]);

    if (
      ['Min', 'Max'].includes(type) &&
      typeof row.restrictions[prop] !== 'undefined'
    ) {
      if (Number.isNaN(Number(row.restrictions[prop]))) {
        errorIDs.push('annualPlanEdit.errors.notNumber');
      } else {
        if (type === 'Min') {
          const maxPropName = `${prefix}Max`;
          const maxValue = row.restrictions[maxPropName];

          if (!Number.isNaN(Number(maxValue)) && parseFloat(maxValue) < value) {
            errorIDs.push('annualPlanEdit.errors.minExceedMax');
          }
        } else {
          const minPropName = `${prefix}Min`;
          const minValue = row.restrictions[minPropName];

          if (!Number.isNaN(Number(minValue)) && parseFloat(minValue) > value) {
            errorIDs.push('annualPlanEdit.errors.minExceedMax');
          }
        }

        if (value > 100) {
          errorIDs.push('annualPlanEdit.errors.exceed100percent');
        } else if (value < 0) {
          errorIDs.push('annualPlanEdit.errors.notPositive');
        }
      }
    }

    if (
      type === 'Max' &&
      !this.maxColumnIsValid &&
      prefix === 'channelPercent'
    ) {
      errorIDs.push('annualPlanEdit.errors.columnSumNotExceed100');
    } else if (
      type === 'Min' &&
      !this.minColumnIsValid &&
      prefix === 'channelPercent'
    ) {
      errorIDs.push('annualPlanEdit.errors.columnSumExceed100');
    }

    const isSFix = row.extraCharge.isSFix;

    if (
      !isSFix &&
      (prop === 'floatPrimePercentMin' || prop === 'floatPrimePercentMax')
    ) {
      if (row.sellerRestrictionMin && value < row.sellerRestrictionMin) {
        warningIDs.push('annualPlanEdit.warnings.exceedMinSellerRestriction');
      }

      if (row.sellerRestrictionMax && value > row.sellerRestrictionMax) {
        warningIDs.push('annualPlanEdit.warnings.exceedMaxSellerRestriction');
      }
    }
    if (
      isSFix &&
      (prop === 'fixPrimePercentMin' || prop === 'fixPrimePercentMax')
    ) {
      if (row.sellerRestrictionMin && value < row.sellerRestrictionMin) {
        warningIDs.push('annualPlanEdit.warnings.exceedMinSellerRestriction');
      }

      if (row.sellerRestrictionMax && value > row.sellerRestrictionMax) {
        warningIDs.push('annualPlanEdit.warnings.exceedMaxSellerRestriction');
      }
    }

    if (prop === 'nameWithType') {
      if (
        row.channel.hasSFix &&
        !row.extraCharge.isSFix &&
        !this.isLocalChannel(row.channel) // TODO: remove it later
      ) {
        warningIDs.push('');
      }
    }

    return {
      errorIDs,
      warningIDs
    };
  };

  commentsRenderer = (...args) => {
    // [instance, td, row, col, prop, value, cellProperties]
    const [, td, , , , , cellProperties] = args;
    Handsontable.renderers.NumericRenderer.apply(this, args);
    const messages = [...cellProperties.errorIDs, ...cellProperties.warningIDs];
    const msg = messages.map(id => localizeMessage({ id })).join('\n\n');
    if (msg) {
      td.setAttribute('title', msg);
    }
  };

  beforeRender = () => {
    this.validateFormMinColumnSum();
    this.validateFormMaxColumnSum();

    let isValidTable = true;
    this.cellsErrors = this.toDisplay.map(row => numberColumnIDs.reduce((acc, columnID) => {
      const { errorIDs, warningIDs } = this.validateCell(row, columnID);
      if (errorIDs.length) {
        isValidTable = false;
      }

      return { ...acc, [columnID]: { errorIDs, warningIDs } };
    }, {})
    );
    this.props.onUpdateValidationStatus(isValidTable, 'restrictions');
  };

  afterChange = changes => {
    if (!changes) {
      return;
    }

    let shouldUpdate = false;
    changes.forEach(([row, prop, oldValue, newValue]) => {
      const columnID: string = prop.split('.')[1];
      if (numberColumnIDs.includes(columnID) && typeof newValue === 'number') {
        set(this.toDisplay[row], prop, formatNumberToNumber(newValue));
      }
      if (forGroupsIDs.includes(columnID)) {
        shouldUpdate = true;
      }
      if (
        prop === 'restrictions.isExcludedChannel' &&
        Object.keys(this.naturalSplitMap).length
      ) {
        const isChangedChannel = newValue
          ? Boolean(this.naturalSplitMap[String(row + 1)])
          : !this.naturalSplitMap[String(row + 1)];

        this.changedChannels[row] = isChangedChannel;

        this.setState({
          isChangedCountChannels: this.changedChannels.some(channel => channel)
        });
      }
      if (prop === 'extraCharge.isSFix') {
        set(
          this.toDisplay[row],
          'restrictions.fixPercentMin',
          newValue ? 0 : undefined
        );
        set(
          this.toDisplay[row],
          'restrictions.fixPercentMax',
          newValue ? 100 : undefined
        );
        set(
          this.toDisplay[row],
          'restrictions.fixPrimePercentMin',
          newValue
            ? this.toDisplay[row].restrictions.floatPrimePercentMin
            : undefined
        );
        set(
          this.toDisplay[row],
          'restrictions.fixPrimePercentMax',
          newValue
            ? this.toDisplay[row].restrictions.floatPrimePercentMax
            : undefined
        );
      }
    });

    if (shouldUpdate) {
      this.props.onUpdateForChannelGroupEditor();
    }
  };

  render () {
    const { restrictionBase } = this.props;

    return (
      <>
        <p className='_text-align--center m-t m-b'>
          <strong>
            <LocalizedMessage
              id={`annualPlanEdit.tvCampaignParams.restrictions.${
                restrictionBase === 'BUDGET_GRP' ? 'budget' : 'grp-trp'
              }`}
            />
          </strong>
          {' '}
          <LocalizedMessage id='annualPlanEdit.tvCampaignParams.restrictions.end' />
        </p>

        <HotTable
          ref={this.setHotRef}
          {...this.generateTableOptions()}
          tableClassName='table'
          stretchH='all'
          width='100%'
          height='auto'
          autoWrapRow
          afterChange={this.afterChange}
          beforeRender={this.beforeRender}
          afterGetColHeader={this.afterGetColHeader}
          licenseKey='non-commercial-and-evaluation'
        />
      </>
    );
  }
}

export default Restrictions;
