/* eslint-disable react/jsx-indent */
import React, { PureComponent } from 'react';
import cx from 'classnames';
import { createSelector } from 'reselect';
import { Checkbox } from 'react-ui-icheck';
import { DateRangePicker } from 'react-dates';

import LocalizedMessage, { localizeMessage } from 'components/LocalizedMessage';
import Select from 'components/Select';
import { NumericInput } from 'components/NumericInput';
import { formatNumberToNumber } from 'helpers/utils';
import FilmDurations from '../components/FilmDurations/FilmDurations';
import {
  CapacityRepresentation,
  CustomUniverse,
  GoalType,
  IFrequency,
  IGoal,
  ILengthShare
} from 'routes/AnnualPlanEdit/types';
import { IOption, IPerformanceCalculationType } from 'types';

import { HandleCustomUniverseChange } from '../../AnnualPlanEdit';
import { INullableMomentRange } from '../../../types';

import classes from '../../AnnualPlanEdit.module.scss';

const formatSec = (val: number) => `${val} ${localizeMessage({ id: 'sec' })}`;


const DEFAULT_CAPACITY: CapacityRepresentation = {
  minOffPrimeCapacity: 2,
  minPrimeCapacity: 2,
  affinityPercent: 80
};

const performanceCalculationTypeOptions: IOption<
IPerformanceCalculationType
>[] = [
  {
    label: 'Сохранить пропорции качества',
    value: 'QUALITY_PROPORTION'
  },
  {
    label: 'Приоритезировать фикс',
    value: 'FIX_PRIORITIZE'
  }
];

interface IProps {
  isInternetOnlyAllowed: boolean;
  isConverseOptimization?: boolean;
  onChangeIsConverseOptimization: (e: boolean) => void;

  performanceCalculationType?: IPerformanceCalculationType;
  onChangePerformanceCalculationType: (
    newValue: IPerformanceCalculationType
  ) => void;

  isTradeWhiteList: boolean;
  onChangeIsTradeWhiteList: (newValue: boolean) => void;

  lengthShares: ILengthShare[];
  goal: IGoal;
  allFrequencies: IFrequency[];
  allClosedFrequencies: IFrequency[];
  isValidClosedFrequencies: boolean;
  targetReachIsOpen: boolean;
  isCalculatePalomarsReach: boolean;
  palomarsRetroDates: INullableMomentRange;
  customUniverse: CustomUniverse;
  onCustomUniverseChange: HandleCustomUniverseChange;
  onUpdateLengthShares: (lengthShares: ILengthShare[]) => void;
  onUpdateGoal: (goal: IGoal, validateClosedFrequencies?: boolean) => void;
  onChangeCalculatePalomars: (
    e: React.ChangeEvent<HTMLInputElement>,
    value: boolean
  ) => void;
  onUpdatePalomarsRetroDates: (dates: INullableMomentRange) => void;
}

interface IState {
  focusedPeriodInput: 'startDate' | 'endDate' | null;
}

class CampaignGoals extends PureComponent<IProps, IState> {
  state: IState = {
    focusedPeriodInput: null
  };

  getFrequenciesSelectizeConfig ({
    isClosedFrequencies = false,
    range = ''
  } = {}) {
    const { allFrequencies, allClosedFrequencies, goal } = this.props;
    const frequencies = isClosedFrequencies
      ? allClosedFrequencies
      : allFrequencies;
    const handleChange = isClosedFrequencies
      ? this.handleChangeClosedFrequencies.bind(this, range)
      : this.handleChangeFrequencySelect;

    const optionValue =
      isClosedFrequencies && range === 'max'
        ? goal.reachFrequencyMax && goal.reachFrequencyMax.id
        : goal.reachFrequencyMin && goal.reachFrequencyMin.id;

    const options: IOption[] = frequencies.map(frequency => ({
      label: frequency.name,
      value: frequency.value
    }));

    return {
      isSearchable: false,
      isMulti: false,
      placeholder: '',
      onChange: handleChange,
      options,
      value: options.find(option => option.value === optionValue) || options[0],
      dataTestAttr: 'goal-reach-frequency'
    };
  }

  selectTimeSelectOptions = createSelector(
    (props: IProps) => props.lengthShares,
    (lengthShares: ILengthShare[]) => Array.from({ length: 20 }).map((_, index) => {
      const lengths = lengthShares.map(share => share.length);
      const val = (index + 1) * 5;
      const isDisabled = lengths && lengths.indexOf(val) !== -1;

      return { value: val, label: formatSec(val), isDisabled };
    })
  );

  handleChangeRadioValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { goal, onUpdateGoal } = this.props;
    const value = e.target.value;

    onUpdateGoal({
      ...goal,
      type: value as GoalType,
      goalReachShare: value === GoalType.Conversion ? 0 : null,
      ...(value === GoalType.Reach ? { reachFrequencyMax: null } : {})
    });
  };

  handleChangeReachOptions = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      allFrequencies,
      allClosedFrequencies,
      goal,
      onUpdateGoal
    } = this.props;
    const val = e.target.value;

    if (val === 'opened') {
      onUpdateGoal({
        ...goal,
        reachFrequencyMin: {
          id: allFrequencies[0].value,
          name: allFrequencies[0].name
        },
        reachFrequencyMax: null
      });
    } else {
      onUpdateGoal({
        ...goal,
        reachFrequencyMin: {
          id: allClosedFrequencies[0].value,
          name: allClosedFrequencies[0].name
        },
        reachFrequencyMax: {
          id: allClosedFrequencies[0].value,
          name: allClosedFrequencies[0].name
        }
      });
    }
  };

  handleUpdateDurations = (lengthShares: ILengthShare[]) => {
    this.props.onUpdateLengthShares(lengthShares);
  };

  handleGoalShareChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { goal, onUpdateGoal } = this.props;
    const value =
      e.target.value !== ''
        ? formatNumberToNumber(Number(e.target.value))
        : null;
    onUpdateGoal({
      ...goal,
      goalReachShare: value
    });
  };

  handleChangeFrequencySelect = (frequency: IOption) => {
    const { goal, onUpdateGoal } = this.props;
    onUpdateGoal({
      ...goal,
      reachFrequencyMin: { id: frequency.value, name: frequency.label },
      reachFrequencyMax: null
    });
  };

  handleChangeClosedFrequencies = (range: string, frequency: IOption) => {
    const { goal, onUpdateGoal } = this.props;
    const option = range === 'min' ? 'reachFrequencyMin' : 'reachFrequencyMax';

    onUpdateGoal({
      ...goal,
      [option]: { id: frequency.value, name: frequency.label }
    });
  };

  handleFocusChange = (value: 'startDate' | 'endDate') => {
    this.setState({
      focusedPeriodInput: value
    });
  };

  render () {
    const {
      goal,
      lengthShares,
      isValidClosedFrequencies,
      targetReachIsOpen,
      onUpdateGoal,
      isCalculatePalomarsReach,
      palomarsRetroDates,
      onChangeCalculatePalomars,
      onUpdatePalomarsRetroDates,

      customUniverse,
      onCustomUniverseChange,

      isTradeWhiteList,
      onChangeIsTradeWhiteList,

      performanceCalculationType,
      onChangePerformanceCalculationType,

      isInternetOnlyAllowed,
      isConverseOptimization,
      onChangeIsConverseOptimization
    } = this.props;
    const timeSelectOptions = this.selectTimeSelectOptions(this.props);


    const goalCapacity = goal.capacity || DEFAULT_CAPACITY;


    // Bad pattern, but works here
    if (goal.capacity === undefined) {
      onUpdateGoal({
        ...goal,
        capacity: goalCapacity
      });
    }

    return (
      <div>
        <div className='row m-b-sm'>
          <div className='col-lg-8'>
            <div className={cx('row', classes['goal-switcher'])}>
              <div className='col-lg-3'>
                <input
                  type='radio'
                  id='optionsRadios1'
                  name='optionsRadios'
                  checked={goal.type === GoalType.Budget}
                  onChange={this.handleChangeRadioValue}
                  value={GoalType.Budget}
                />
                <label htmlFor='optionsRadios1'>
                  <span>
                    <LocalizedMessage id='annualPlanEdit.fields.goalSwitcher.fixedBudget' />
                  </span>
                </label>
              </div>
              <div className='col-lg-3'>
                <input
                  type='radio'
                  id='optionsRadios2'
                  name='optionsRadios'
                  value={GoalType.Reach}
                  onChange={this.handleChangeRadioValue}
                  checked={goal.type === GoalType.Reach}
                />
                <label htmlFor='optionsRadios2'>
                  <span>
                    <LocalizedMessage id='annualPlanEdit.fields.goalSwitcher.fixedReach' />
                  </span>
                </label>
              </div>

              {!isInternetOnlyAllowed && (
                <>
                  <div className='col-lg-3'>
                    <input
                      type='radio'
                      id='optionsRadios3'
                      name='optionsRadios'
                      value={GoalType.Conversion}
                      onChange={this.handleChangeRadioValue}
                      checked={goal.type === GoalType.Conversion}
                    />
                    <label htmlFor='optionsRadios3'>
                      <LocalizedMessage id='annualPlanEdit.fields.goalSwitcher.conversions' />
                    </label>
                  </div>

                  <div className='col-lg-3'>
                    <input
                      type='checkbox'
                      id='converse'
                      name='converse'
                      defaultChecked={false}
                      checked={isConverseOptimization}
                      onChange={e => onChangeIsConverseOptimization(e.target.checked)}
                    />
                    <label htmlFor='optionsRadios3'>Выводить конверсии</label>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

      {!isInternetOnlyAllowed && (
        <div className='form-horizontal'>
          <div className='form-group'>
            <label
              className={cx(
                'col-lg-4',
                'control-label',
                classes['duration-label']
              )}
            >
              <LocalizedMessage id='annualPlanEdit.fields.duration.label' />
            </label>

            <div className={cx('col-lg-8', classes['average-film-duration'])}>
              <FilmDurations
                lengthShares={lengthShares}
                onUpdateLengthShares={this.handleUpdateDurations}
                timeSelectOptions={timeSelectOptions}
              />
            </div>
          </div>
        </div>
      )}

        <div className='form-horizontal m-b-lg'>
          <div className='form-group'>
            <label
              className={cx(
                'col-lg-4',
                'control-label',
                classes['target-reach-label']
              )}
            >
              {goal.type === GoalType.Conversion ? (
                <LocalizedMessage id='annualPlanEdit.fields.minReach.label' />
              ) : (
                <LocalizedMessage id='annualPlanEdit.fields.selectFrequency.label' />
              )}
            </label>

            <div className={cx('col-lg-8', classes['display-flex'])}>
              <LocalizedMessage id='annualPlanEdit.fields.reachShare.placeholder'>
                {localizedReachShare => (
                  <NumericInput
                    className={classes['target-reach-share']}
                    name='percent'
                    min={0}
                    max={100}
                    step={0.01}
                    value={
                        typeof goal.goalReachShare === 'number'
                          ? goal.goalReachShare
                          : ''
                    }
                    onChange={this.handleGoalShareChange}
                    placeholder={localizedReachShare}
                    disabled={
                      ![GoalType.Reach, GoalType.Conversion].includes(goal.type)
                    }
                    data-test='goal-reach-share'
                  />
                )}
              </LocalizedMessage>

              {[GoalType.Budget, GoalType.Conversion].includes(goal.type) ? (
                <div className={'col-lg-10'}>
                  <div className='col-lg-5'>
                    <div>
                      <input
                        type='radio'
                        id='reachOptions1'
                        name='reachOptions'
                        checked={targetReachIsOpen}
                        onChange={this.handleChangeReachOptions}
                        value='opened'
                      />
                      <label className={classes.label} htmlFor='reachOptions1'>
                        <LocalizedMessage id='annualPlanEdit.fields.goalSwitcher.openFrequency' />
                      </label>
                    </div>
                    <Select<IOption>
                      {...this.getFrequenciesSelectizeConfig()}
                      className={classes['target-reach-selector-select']}
                      isDisabled={!targetReachIsOpen}
                    />
                  </div>

                  <div className='col-lg-6'>
                    <div>
                      <input
                        type='radio'
                        id='reachOptions2'
                        name='reachOptions'
                        checked={!targetReachIsOpen}
                        onChange={this.handleChangeReachOptions}
                        value='closed'
                      />
                      <label className={classes.label} htmlFor='reachOptions2'>
                        <LocalizedMessage id='annualPlanEdit.fields.goalSwitcher.closedFrequencies' />
                      </label>
                    </div>
                    <div className={classes['target-reach-range-select']}>
                      <Select<IOption>
                        {...this.getFrequenciesSelectizeConfig({
                          isClosedFrequencies: true,
                          range: 'min'
                        })}
                        className={classes['target-reach-selector-select']}
                        isDisabled={targetReachIsOpen}
                      />
                      <Select<IOption>
                        {...this.getFrequenciesSelectizeConfig({
                          isClosedFrequencies: true,
                          range: 'max'
                        })}
                        className={classes['target-reach-selector-select']}
                        isDisabled={targetReachIsOpen}
                      />
                    </div>
                    {!isValidClosedFrequencies && (
                    <div>
                      <small className='text-danger'>
                        <LocalizedMessage id='annualPlanEdit.errors.invalidFrequencyRange' />
                      </small>
                    </div>
                    )}
                  </div>
                </div>
              ) : (
                <Select
                  {...this.getFrequenciesSelectizeConfig()}
                  className={classes['target-reach-selector-select']}
                />
              )}
            </div>
          </div>
        </div>

      {!isInternetOnlyAllowed && (
        <>
        <div className='form-horizontal m-b-md'>
          <h3 className='block-title'>Настройки Capacity</h3>
          <label
            className={cx(
              'checkbox-inline',
              'm-r-lg',
              classes['one-row-form-control']
            )}
          >
            <span>Минимальный capacity в Prime</span>
            <NumericInput
              className={cx('form-control', classes['form-control'], classes.goalInput)}
              name='percent'
              onChange={e => {
                onUpdateGoal({
                  ...goal,
                  capacity: {
                    ...goalCapacity,
                    minPrimeCapacity: parseFloat(e.target.value) || 0
                  }
                });
              }}
              value={goalCapacity.minPrimeCapacity || 0}
              min={0}
              max={100}
              step={0.01}
            />
          </label>
        </div>

        <div className='form-horizontal m-b-md'>
          <label
            className={cx(
              'checkbox-inline',
              'm-r-lg',
              classes['one-row-form-control']
            )}
          >
            <span>Минимальный capacity в OffPrime</span>
            <NumericInput
              className={cx('form-control', classes['form-control'], classes.goalInput)}
              name='percent'
              min={0}
              onChange={e => {
                onUpdateGoal({
                  ...goal,
                  capacity: {
                    ...goalCapacity,
                    minOffPrimeCapacity: parseFloat(e.target.value) || 0
                  }
                });
              }}
              value={goalCapacity.minOffPrimeCapacity || 0}
              max={100}
              step={0.01}
            />
          </label>
        </div>


        <div className='form-horizontal m-b-md'>
          <label
            className={cx(
              'checkbox-inline',
              'm-r-lg',
              classes['one-row-form-control']
            )}
          >
            <span>Минимальный affinity(в % от среднеканального)</span>
            <NumericInput
              className={cx('form-control', classes['form-control'], classes.goalInput)}
              name='percent'
              min={0}
              onChange={e => {
                onUpdateGoal({
                  ...goal,
                  capacity: {
                    ...goalCapacity,
                    affinityPercent: parseFloat(e.target.value) || 0
                  }
                });
              }}
              value={goalCapacity.affinityPercent || 0}
              max={100}
              step={0.01}
            />
          </label>
        </div>

        <h3 className='block-title'>Дополнительные отчеты</h3>
        <div className='form-horizontal m-b-md'>
          <label
            className={cx(
              'checkbox-inline',
              'm-r-lg',
            )}
          >
            <Checkbox
              checkboxClass='icheckbox_square-green'
              name='tv-reach-by-palomars-report'
              onChange={onChangeCalculatePalomars}
              checked={isCalculatePalomarsReach}
            />
            <span className={classes.label}>
              <LocalizedMessage id='annualPlanEdit.fields.tv-reach-by-palomars-report' />
            </span>
          </label>
          <DateRangePicker
            startDate={palomarsRetroDates.startDate}
            startDateId='palomars-retro-start-date'
            endDate={palomarsRetroDates.endDate}
            endDateId='palomars-retro-end-date'
            onDatesChange={onUpdatePalomarsRetroDates}
            focusedInput={this.state.focusedPeriodInput}
            onFocusChange={this.handleFocusChange}
            small
            withPortal
            hideKeyboardShortcutsPanel
            firstDayOfWeek={1}
            isOutsideRange={() => false}
            displayFormat={() => 'DD/MM/YY'}
            disabled={!isCalculatePalomarsReach}
          />
        </div>

        <div className='form-horizontal m-b-md'>
          <label
            className={cx(
              'checkbox-inline',
              'm-r-lg',
            )}
          >
            <Checkbox
              checkboxClass='icheckbox_square-green'
              name='tv-reach-by-palomars-report'
              onChange={e => onChangeIsTradeWhiteList(e.target.checked)}
              checked={isTradeWhiteList}
            />
            <span className={classes.label}>Сформировать Trade white list</span>
          </label>

          <Select<IOption<IPerformanceCalculationType>>
            options={performanceCalculationTypeOptions}
            onChange={(frequency: IOption<IPerformanceCalculationType>) => {
              onChangePerformanceCalculationType(frequency.value);
            }}
            className={cx(
              'checkbox-inline',
              classes['trade-white-list-config']
            )}
            value={
              (performanceCalculationType &&
                performanceCalculationTypeOptions.find(
                  it => it.value === performanceCalculationType
                )!!) ||
              null
            }
            isDisabled={!isTradeWhiteList}
          />
        </div>
        </>
      )}

        <div className={cx('form-horizontal', 'm-b-md', 'd-flex')}>
          <label className={cx('checkbox-inline', 'm-r-lg')}>
            <Checkbox
              checkboxClass='icheckbox_square-green'
              checked={customUniverse.isEnabled}
              onChange={e => onCustomUniverseChange('isEnabled', e.target.checked)}
            />
            <span className={classes.label}>
              <LocalizedMessage id='annualPlanEdit.fields.customUniverse' />
            </span>
          </label>
          <NumericInput
            className={cx('form-control', classes['form-control'], classes.goalInput)}
            min={0.01}
            step={0.01}
            disabled={!customUniverse.isEnabled}
            value={customUniverse.value !== null ? Number(customUniverse.value) : ''}
            onChange={e => onCustomUniverseChange('value', e.target.value)}
          />
        </div>
      </div>
    );
  }
}

export default CampaignGoals;
