import React, { Component } from 'react';
import cx from 'classnames';
import { Link, RouteChildrenProps } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import LocalizedMessage, { localizeMessage } from 'components/LocalizedMessage';

import Breadcrumbs from 'components/Breadcrumbs';
import Alert from 'helpers/alert';
import API from 'api';
import { getMonthNames } from 'helpers/utils';
import AdCampaignsList from 'routes/AnnualPlan/components/Panels/AdCampaignsList';
import AdCampaignsCalcResults from 'routes/AnnualPlan/components/Panels/AdCampaignsCalcResults';
import TVDealCalculation from 'routes/AnnualPlan/components/Panels/DealCalculation';
import OLVDealCalculation from 'routes/AnnualPlan/components/Panels/OLVDealCalculation';
import {
  CalculationWarnings,
  IFrontendAnnualPlan,
  OlvFrontendDataHolder,
  OlvFrontendRepresentation,
  TargetAudience
} from 'types';

import classes from './AnnualPlan.module.scss';

export interface OlvCalculated {
  platforms: ({ name: string } & OlvFrontendRepresentation)[];
  total: OlvFrontendRepresentation;
}
type IProps = RouteChildrenProps<{ id?: string }> & {};

const filledArray = (len: number, fill: number) => new Array(len).fill(fill);

export interface AnnualPlanData extends Omit<IFrontendAnnualPlan, 'additionalTaOlvCalculated'> {
  olvCalculated: OlvCalculated | null;
  additionalTaOlvCalculated: Record<string, OlvCalculated | null>;
}

interface IState {
  data: AnnualPlanData | null;
  errors: any;
  targetAudience: TargetAudience | undefined;
  nameToDownload?: string;
}
class AnnualPlan extends Component<IProps, IState> {
  static propTypes = {};

  state: IState = {
    data: null,
    targetAudience: undefined,
    errors: {}
  };

  months = getMonthNames().map((monthName, index) => ({
    id: index,
    name: monthName
  }));

  mounted = false;

  componentDidMount () {
    this.mounted = true;

    this.loadData();
  }

  componentWillUnmount () {
    this.mounted = false;
  }

  async loadData () {
    const { match } = this.props;
    const params = match?.params;

    if (!params?.id) {
      return;
    }

    try {
      const annualPlan = await API.annualPlans.getWithCalculations(params.id);

      if (!this.mounted) {
        return;
      }

      const convertAdditionalOLVCalculated = (): Record<
      string,
      OlvCalculated
      > | null => {
        const olvCalculated =
          annualPlan.additionalTaOlvCalculated;

        if (!olvCalculated) {
          return null;
        }

        const olvConverted: Record<string, OlvCalculated> = {};
        Object.entries(olvCalculated).forEach(
          ([key, value]: [string, OlvFrontendDataHolder]) => {
            const { platforms, total } = value;

            olvConverted[key] = {
              platforms: Object.keys(platforms).map(key => ({
                name: key,
                ...platforms[key]
              })),
              total
            };
          }
        );

        return olvConverted;
      };

      const convertOLVCalculated = (): OlvCalculated | null => {
        const olvCalculated =
          annualPlan.mediaplans &&
          annualPlan.mediaplans[0] &&
          annualPlan.mediaplans[0].olvCalculated;

        if (!olvCalculated) {
          return null;
        }
        const { platforms, total } = olvCalculated;

        return {
          platforms: Object.keys(platforms).map(key => ({
            name: key,
            ...platforms[key]
          })),
          total
        };
      };

      const channelMonthsBudget =
        annualPlan.channelMonthsBudgets && annualPlan.channelMonthsBudgets[0]
          ? { ...annualPlan.channelMonthsBudgets[0] }
          : {};

      for (const key in channelMonthsBudget) {
        if (channelMonthsBudget.hasOwnProperty(key)) {
          channelMonthsBudget[key] = Array.isArray(channelMonthsBudget[key])
            ? channelMonthsBudget[key].map(() => null)
            : null;
        }
      }

      const warnings = annualPlan.mediaplans?.[0]?.warnings
        ? Object.values(
          annualPlan.mediaplans[0].warnings as CalculationWarnings
        )
        : [];

      const channelMonthsBudgets = warnings.length
        ? [
          ...annualPlan.channelMonthsBudgets,
          ...warnings
            .filter(
              ([channelWarnings]) => !annualPlan.channelMonthsBudgets.some(
                x => Number(x.channel.id) ===
                      Number(channelWarnings.channel.id)
              )
            )
            .map(([channelWarnings]) => ({
              ...channelMonthsBudget,
              channel: {
                ...channelWarnings.channel,
                nameWithType: `${
                  channelWarnings.channel.name
                } (${channelWarnings.channel.type.substr(0, 3)})`,
                isNotData: true
              }
            }))
        ]
        : annualPlan.channelMonthsBudgets;

      this.setState({
        data: {
          ...annualPlan,
          channelMonthsBudgets,
          olvCalculated: convertOLVCalculated(),
          additionalTaOlvCalculated: convertAdditionalOLVCalculated()
        },
        nameToDownload: annualPlan.name.replace(/ /g, '_')
      });
    } catch (error) {
      console.error(error);

      Alert.error(localizeMessage({ id: 'errors.errorLoadingData' }));
    }
  }

  getBreadcrumbs () {
    return [
      {
        title: <LocalizedMessage id='home' />,
        link: '/app'
      },
      {
        title: <LocalizedMessage id='site.title.annualPlans' />,
        link: '/app/annualPlans'
      },
      {
        title: <LocalizedMessage id='site.title.annualPlan' />
      }
    ];
  }

  getClassByMonthId = (monthId: number) => {
    switch (monthId) {
      case 0:
      case 1:
      case 2:
        return 'info';
      case 3:
      case 4:
      case 5:
        return 'warning';
      case 6:
      case 7:
      case 8:
        return 'active';
      case 9:
      case 10:
      case 11:
      default:
        return 'success';
    }
  };

  render () {
    const { data, nameToDownload, errors, targetAudience } = this.state;
    const { match } = this.props;

    const params = match?.params;

    const sumMonthsBudgets =
      data && data.monthsTVBudgets && data.monthsInternetBudgets
        ? data.monthsTVBudgets.reduce((a, b) => a + b) +
          data.monthsInternetBudgets.reduce((a, b) => a + b)
        : null;

    const thisTaOlvCalculated =
      targetAudience === undefined
        ? data?.olvCalculated
        : data?.additionalTaOlvCalculated?.[targetAudience.name];

    return (
      <>
        <LocalizedMessage
          id={['site.title.annualPlan', 'site.title.annualPlan.edit']}
        >
          {(localizedMessage, localizedEditAnnualPlanMessage) => (
            <>
              <Helmet title={data ? data.name : localizedMessage} />
              <Breadcrumbs
                title={data ? data.name : localizedMessage}
                data={this.getBreadcrumbs()}
                className='col-lg-8'
              >
                <div className='col-lg-4'>
                  {data ? (
                    <div className='title-action'>
                      <Link
                        className='btn btn-success'
                        to={`/app/annualPlans/${data.code}/edit`}
                      >
                        <i className='fa fa-pencil fa-lg' />
                        {localizedEditAnnualPlanMessage}
                      </Link>
                    </div>
                  ) : null}
                </div>
              </Breadcrumbs>
            </>
          )}
        </LocalizedMessage>

        {data ? (
          <div className='wrapper wrapper-content'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='ibox'>
                  <div className='ibox-title'>
                    <h5>
                      <LocalizedMessage id='annualPlan.basePlanningParameters' />
                    </h5>
                  </div>
                  <div className='ibox-content'>
                    <div className='form-horizontal'>
                      <div className='row'>
                        <div
                          className={cx(
                            'form-group',
                            classes['form-group'],
                            'col-lg-6'
                          )}
                        >
                          <div className='row'>
                            <label className='col-lg-3 control-label'>
                              <LocalizedMessage id='media' />
                            </label>
                            <div className='col-lg-9'>
                              {data.type === 'MULTIMEDIA' ? (
                                <div
                                  className={cx(
                                    'form-value',
                                    classes['form-value']
                                  )}
                                >
                                  <span>
                                    Multimedia (TV + Internet)&nbsp;&nbsp;
                                    <i className='fa fa-television' />
                                    {' + '}
                                    <i className='fa fa-globe' />
                                  </span>
                                </div>
                              ) : null}
                              {data.type === 'TV' ? (
                                <div
                                  className={cx(
                                    'form-value',
                                    classes['form-value']
                                  )}
                                >
                                  <span>
                                    TV&nbsp;&nbsp;
                                    <i className='fa fa-television' />
                                  </span>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className='col-lg-6' />
                      </div>

                      <div className='row'>
                        <div
                          className={cx(
                            'form-group',
                            classes['form-group'],
                            'col-lg-6',
                            {
                              [errors.campaignName]: 'has-error'
                            }
                          )}
                        >
                          <div className='row'>
                            <label className='col-lg-3 control-label'>
                              <LocalizedMessage id='period' />
                            </label>
                            <div className='col-lg-9'>
                              <div
                                className={cx(
                                  'form-value',
                                  classes['form-value']
                                )}
                              >
                                {/* {data.annualPeriod} fixme: doesn't exist in api */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className={cx(
                            'form-group',
                            classes['form-group'],
                            'col-lg-6'
                          )}
                        >
                          <div className='row'>
                            <label className='col-lg-3 control-label'>
                              <LocalizedMessage id='advertiser' />
                            </label>
                            <div className='col-lg-9'>
                              <div
                                className={cx(
                                  'form-value',
                                  classes['form-value']
                                )}
                              >
                                {data.advertiser.name}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <AdCampaignsList
              onChangeTargetAudience={ta => this.setState({ targetAudience: ta })}
              targetAudience={targetAudience}
              mediaplans={data.mediaplans}
            />

            {!!(data.mediaplans && data.mediaplans[0]) && (
              <AdCampaignsCalcResults
                mediaplan={data.mediaplans[0]}
                planId={params?.id}
                months={this.months}
                monthsTVBudgets={
                  targetAudience
                    ? data.additionalTAMonthTVBudgets[targetAudience.name] || filledArray(12, 0)
                    : data.monthsTVBudgets
                }
                monthsTVTRPs={
                  targetAudience
                    ? data.additionalTAMonthTVTRPs[targetAudience.name] || filledArray(12, 0)
                    : data.monthsTVTRPs
                }
                monthsInternetBudgets={
                  targetAudience
                    ? data.additionalTAMonthInternetBudgets[targetAudience.name] || filledArray(12, 0)
                    : data.monthsInternetBudgets
                }
                monthsInternetTRPs={
                  targetAudience
                    ? data.additionalTAMonthInternetTRPs[targetAudience.name] || filledArray(12, 0)
                    : data.monthsInternetTRPs
                }
                monthsBudgetBarChartData={data.monthsBudgets}
                monthsMediaBudgetBarChartData={[
                  data.monthsTVBudgets,
                  data.monthsInternetBudgets
                ]}
                mediaBudgetPieChartData={[
                  Math.round(
                    (100 * data.monthsTVBudgets.reduce((a, b) => a + b)) /
                      (sumMonthsBudgets || 1)
                  ),
                  Math.round(
                    (100 * data.monthsInternetBudgets.reduce((a, b) => a + b)) /
                      (sumMonthsBudgets || 1)
                  )
                ]}
                getClassByMonthId={this.getClassByMonthId}
                taId={
                  targetAudience
                    ? targetAudience.id
                    : data.mediaplans[0].targetAudience.id!
                }
              />
            )}

            {Boolean(
              data.mediaplans &&
                data.mediaplans[0] &&
                data.mediaplans[0].internetBudgetPercent !== 100
            ) && (
              <TVDealCalculation
                months={this.months}
                data={data}
                targetAudience={targetAudience}
                getClassByMonthId={this.getClassByMonthId}
                nameToDownload={nameToDownload}
              />
            )}

            {thisTaOlvCalculated && (
              <OLVDealCalculation olvCalculated={thisTaOlvCalculated} />
            )}
          </div>
        ) : null}
      </>
    );
  }
}

export default AnnualPlan;
