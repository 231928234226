import moment from 'moment';
import numeral from 'numeral';

export const wait = ms => new Promise(resolve => {
  setTimeout(resolve, ms);
});

export const isJSON = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }

  return true;
};

export const UUID = a => (
  // eslint-disable-next-line no-mixed-operators
  a ? (a ^ Math.random() * 16 >> a / 4).toString(16) : ([1e7] + -1e3 + -4e3 + -8e3 + -1e11)
    .replace(/[018]/g, UUID)
);

export const escape = (str) => (`${str}`)
  .replace(/&/g, '&amp;')
  .replace(/</g, '&lt;')
  .replace(/>/g, '&gt;')
  .replace(/"/g, '&quot;');

export const removeDuplicates = (myArr, prop) => myArr
  .filter((obj, pos, arr) => arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos);

export const clamp = (value, min, max) => Math.min(Math.max(value, min), max);

export const arrayToObject = (sourceArray, keyName) => {
  const resultObject = {};

  for (let i = 0; i < sourceArray.length; i++) {
    resultObject[sourceArray[i][keyName]] = sourceArray[i];
  }

  return resultObject;
};

const requestTimeoutsStorage = {};

export const safeApiRequest = (requestFunction, ...args) => {
  const argsStr = JSON.stringify(args);

  if (!requestTimeoutsStorage[requestFunction]) {
    requestTimeoutsStorage[requestFunction] = {};
  }

  if (!requestTimeoutsStorage[requestFunction][argsStr]) {
    requestTimeoutsStorage[requestFunction][argsStr] = 0;
  }

  return new Promise((resolve, reject) => {
    clearTimeout(requestTimeoutsStorage[requestFunction][argsStr]);
    requestTimeoutsStorage[requestFunction][argsStr] = setTimeout(() => {
      requestFunction(...args).then(resolve).catch(reject);
    }, 300);
  });
};

export const capitalizeFirstLetter = sourceString => (
  sourceString.charAt(0).toUpperCase() + sourceString.slice(1).toLowerCase()
);

export const tryParseJSON = (str) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return undefined;
  }
};

export const formatNumberToNumber = (input, fractionSize = 2) => Number(Number(input).toFixed(fractionSize));

export const formattedNumber = (input, fractionSize = 2) => numeral(input)
  .format(`0,0.${new Array(fractionSize).fill(0).join('')}`);

export const formattedNumberWithZero = (input, fractionSize = 2) => {
  const fixedInput = Number(input.toFixed(fractionSize)); // Number((1.419e-7).toFixed(2)); -> 0
  if (fixedInput % 1 > 0) {
    return numeral(fixedInput).format(`0,0.${new Array(fractionSize).fill(0).join('')}`);
  }

  return numeral(fixedInput).format('0,0');
};

export const percentOrHyphen = (input, fractionSize = 0) => {
  if (typeof input !== 'number') {
    return '-';
  }

  return `${numeral(input).format(`0,0.${fractionSize}`)} %`;
};

export const getMonthNames = () => (
  moment.months().map(capitalizeFirstLetter)
);

export const getMonthShortNames = () => (
  moment.monthsShort().map(capitalizeFirstLetter)
);

export const getWeekShortNames = () => (
  moment.weekdaysShort().map(capitalizeFirstLetter)
);

export const getYearOfDate = (date = new Date()) => {
  if (date instanceof Date) {
    return date.getFullYear();
  }

  return new Date(date).getFullYear();
};

export const getColorScheme = (index) => `hsl(${index * 20 + 140}, 40%, 75%)`;
