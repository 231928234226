import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import LocalizedMessage, { localizeMessage } from 'components/LocalizedMessage';
import { saveAs } from 'file-saver';

import Breadcrumbs from 'components/Breadcrumbs';

import API from 'api';
import Alert from 'helpers/alert';

interface IDataMediaplanTemplate {
  type?: {
    name:
    | 'YEAR'
    | 'INDIVIDUAL'
    | 'CAMPAIGNS_IMP'
    | 'DEAL_RES_IMP'
    | 'CHANNEL_RES_IMP';
    nameTranslation?: string;
  };
  data: File | null;
}

interface IState {
  data: {
    mediaplanTemplate: IDataMediaplanTemplate
    campaignsImportTemplate: IDataMediaplanTemplate
    dealRestrictionsImportTemplate: IDataMediaplanTemplate
    channelRestrictionsImportTemplate: IDataMediaplanTemplate
  }
}
class MediaplanTemplates extends Component<{}, IState> {
  static propTypes = {};

  state: IState = {
    data: {
      mediaplanTemplate: { type: { name: 'INDIVIDUAL' }, data: null },
      campaignsImportTemplate: { type: { name: 'CAMPAIGNS_IMP' }, data: null },
      dealRestrictionsImportTemplate: {
        type: { name: 'DEAL_RES_IMP' },
        data: null
      },
      channelRestrictionsImportTemplate: {
        type: { name: 'CHANNEL_RES_IMP' },
        data: null
      }
    }
  };

  mounted = false;

  _form: HTMLFormElement | null = null;

  componentDidMount () {
    this.mounted = true;

    this.loadData();
  }

  componentWillUnmount () {
    this.mounted = false;
  }

  setFormRef = ref => {
    this._form = ref;
  };

  async loadData () {
    try {
      const mediaplanTemplates = await API.mediaplanTemplates.list();

      if (!this.mounted) {
        return;
      }

      const { data } = this.state;

      mediaplanTemplates.forEach(template => {
        Object.keys(data).forEach(type => {
          if (template.type.name === data[type].type.name) {
            data[type] = {
              ...template,
              data: null
            };
          }
        });
      });

      this.setState({
        data
      });
    } catch (error) {
      console.error(error);

      Alert.error(localizeMessage({ id: 'errors.errorLoadingData' }));
    }
  }

  getBreadcrumbs () {
    return [
      {
        title: <LocalizedMessage id='home' />,
        link: '/app'
      },
      {
        title: <LocalizedMessage id='site.title.mediaplanTemplates' />
      }
    ];
  }

  handleFileChange = e => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: {
          ...this.state.data[e.target.name],
          data: e.target.files[0]
        }
      }
    });
  };

  clearFileField (key) {
    const { data } = this.state;

    data[key].data = null;
    if (this._form) (this._form[key] as HTMLInputElement).value = '';

    this.setState({
      data
    });
  }

  getFileDownloadUrl = async (e, key) => {
    e.preventDefault();

    const { data } = this.state;

    try {
      const response = await API.mediaplanTemplates.getFile(data[key].id);

      const blob = await response.blob();

      const contentDisposition = response.headers.get('content-disposition');
      const filenameData = contentDisposition
        ? contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)
        : null;
      const filename = filenameData
        ? filenameData[1].replace(/['"]+/g, '')
        : data[key].templateName;

      saveAs(blob, filename);

      Alert.success(
        localizeMessage({
          id: 'mediaplanTemplates.alerts.template-successfully-export'
        })
      );
    } catch (error) {
      console.error(error);

      Alert.error(
        localizeMessage(
          { id: 'mediaplanTemplates.alerts.template-failed-export' },
          { id: data[key].templateName }
        )
      );
    }
  };

  uploadFile (template) {
    if (!template.data) {
      return Promise.resolve();
    }

    const formData = new FormData();
    formData.append('type', template.type.name);
    formData.append('file', template.data);

    return API.mediaplanTemplates.saveOrUpdate(formData);
  }

  onSubmit = async e => {
    e.preventDefault();

    if (!this.validate()) {
      return;
    }

    const { data } = this.state;

    try {
      await Promise.all(
        Object.keys(data).map(type => this.uploadFile(data[type]))
      );

      Alert.success(
        localizeMessage({ id: 'mediaplanTemplates.alerts.successfullySaved' })
      );

      this.resetForm();
    } catch (error) {
      console.error(error);

      Alert.warn(
        localizeMessage({ id: 'mediaplanTemplates.alerts.errorSaving' })
      );
    }
  };

  resetForm = () => {
    this._form?.reset();

    const { data } = this.state;

    Object.keys(data).forEach(type => {
      data[type].data = null;
    });

    this.setState({
      data
    });
  };

  validate = () => {
    const { data } = this.state;

    return Object.keys(data).some(type => data[type].data);
  };

  render () {
    const { data } = this.state;

    return (
      <>
        <LocalizedMessage id='site.title.mediaplanTemplates'>
          {localizedMessage => (
            <>
              <Helmet title={localizedMessage} />
              <Breadcrumbs
                title={localizedMessage}
                data={this.getBreadcrumbs()}
              />
            </>
          )}
        </LocalizedMessage>

        <div className='wrapper wrapper-content'>
          <div className='ibox'>
            <div className='ibox-content'>
              <form ref={this.setFormRef}>
                <div className='form-horizontal'>
                  {Object.keys(data).map(type => (
                    <>
                      <div className='form-group'>
                        <label className='col-lg-4 control-label'>
                          <LocalizedMessage
                            id={`mediaplanTemplates.current.${type}`}
                          />
                          :
                        </label>
                        <div className='col-lg-8'>
                          {data[type].id ? (
                            <p className='form-control-static'>
                              <a
                                href={`/api/mediaplans/templates/file/${data[type].id}`}
                                onClick={e => this.getFileDownloadUrl(e, type)}
                              >
                                {data[type].templateName}
                              </a>
                            </p>
                          ) : (
                            <p className='form-control-static'>
                              <LocalizedMessage
                                id={`mediaplanTemplates.notLoaded.${type}`}
                              />
                            </p>
                          )}
                        </div>
                      </div>
                      <div className='form-group'>
                        <label className='col-lg-4 control-label'>
                          <LocalizedMessage
                            id={`mediaplanTemplates.loadNew.${type}`}
                          />
                          :
                        </label>
                        <div className='col-lg-8'>
                          <div className='file-input-container'>
                            <div>
                              <input
                                type='file'
                                className='form-control'
                                name={type}
                                onChange={this.handleFileChange}
                                accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                              />
                            </div>
                            {data[type].data ? (
                              <div>
                                <button
                                  type='button'
                                  className='btn btn-default'
                                  onClick={() => this.clearFileField(type)}
                                >
                                  <LocalizedMessage id='remove' />
                                </button>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>

                      <div className='hr-line-dashed' />
                    </>
                  ))}

                  <div className='form-group'>
                    <div className='col-lg-4' />
                    <div className='col-lg-8'>
                      <button
                        type='submit'
                        className='btn btn-primary'
                        disabled={!this.validate()}
                        onClick={this.onSubmit}
                      >
                        <>
                          <i className='fa fa-chevron-down fa-lg' />
                          <span>
                            <LocalizedMessage id='save' />
                          </span>
                        </>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default MediaplanTemplates;
