import { fetchResponse } from './helpers/fakeFetch';
import Client from '../release/HTTPClient';

import annualPlansList from './data/annualPlansList.json';
import annualPlansFilterItems from './data/annualPlansFilterItems.json';
import annualPlansPalomarsStatus from './data/annualPlansPalomarsStatus.json';
import annualPlanDetails from './data/annualPlanDetails.json';

const annualPlansAPI = {
  list (request) {
    console.log('api.annualPlans.list(', request, ')');

    return fetchResponse(1000, annualPlansList);
  },

  get (id) {
    console.log('api.annualPlans.get(', id, ')');

    return fetchResponse(1000, annualPlanDetails);
  },

  getWithCalculations (id) {
    console.log('api.annualPlans.getWithCalculations(', id, ')');

    return fetchResponse(1000, annualPlanDetails);
  },

  getLatestVersion (id) {
    console.log('api.annualPlans.getLatestVersion(', id, ')');

    return Client.get(`/api/annualPlans/${id}/version`);
  },

  getByIdAndVersion (id, version) {
    console.log('api.annualPlans.getByIdAndVersion(', id, ', ', version, ')');

    return Client.get(`/api/annualPlans/${id}/versions/${version}`);
  },

  getTrpBudgetRate () {
    console.log('api.annualPlans.getTrpBudgetRate()');

    return fetchResponse(1000, {
      trpBudgetRate: 32500.0,
    });
  },

  getRetroDiffs (date) {
    console.log('api.annualPlans.getRetroDiffs(', date, ')');

    return fetchResponse(1000, {
      from: 3,
      to: 2,
    });
  },

  copy (id) {
    console.log('api.annualPlans.copy(', id, ')');

    return Client.get(`/api/annualPlans/${id}/copy/`);
  },

  delete (id) {
    console.log('api.annualPlans.delete(', id, ')');

    return Client.delete(`/api/annualPlans/${id}/`);
  },

  getFilterItems (request) {
    console.log('api.annualPlans.getFilterItems(', request, ')');

    return fetchResponse(1000, annualPlansFilterItems);
  },

  save (id, data) {
    console.log('api.annualPlans.save(', id, ', ', data, ')');

    if (!id) {
      return Client.post('/api/annualPlans/new', data);
    }

    return Client.put(`/api/annualPlans/${id}`, data);
  },

  uploadCampaigns (data) {
    console.log('api.annualPlans.uploadCampaigns(', data, ')');

    return Client.post('/api/annualPlans/campaigns/upload/', data, true, { isJson: false, isMultipart: true });
  },

  uploadDealRestrictions (data) {
    console.log('api.annualPlans.uploadDealRestrictions(', data, ')');

    return Client.post('/api/annualPlans/dealRestrictions/upload/', data, true, { isJson: false, isMultipart: true });
  },

  uploadCampaignRestrictions (data) {
    console.log('api.annualPlans.uploadCampaignRestrictions(', data, ')');

    return Client.post('/api/annualPlans/campaignRestrictions/upload/', data, true, {
      isJson: false, isMultipart: true,
    });
  },

  exportDealBriefTable (id) {
    console.log('api.annualPlans.exportDealBriefTable(', id, ')');

    return Client.get(`/api/annualPlans/${id}/exportDealBriefTable/`, {}, true, {
      isJson: false,
    });
  },

  exportProgrammingTable (id) {
    console.log('api.annualPlans.exportProgrammingTable(', id, ')');

    return Client.get(`/api/annualPlans/${id}/exportProgrammingTable/`, {}, true, {
      isJson: false,
    });
  },

  exportCoverBuildUpTable (id) {
    console.log('api.annualPlans.exportCoverBuildUpTable(', id, ')');

    return Client.get(`/api/annualPlans/${id}/exportCoverBuildUpTable/`, {}, true, {
      isJson: false,
    });
  },

  exportPalomarsReport (id) {
    console.log('api.annualPlans.exportPalomarsReport(', id, ')');

    return fetchResponse(1000, {});
  },

  getPalomarsStatus (id) {
    console.log('api.annualPlans.getPalomarsStatus(', id, ')');

    return fetchResponse(1000, annualPlansPalomarsStatus);
  },

  getItemDetails (id) {
    console.log('api.annualPlans.getItemDetails(', id, ')');

    return Client.get(`/api/mediaplans/items/${id}/details/`);
  },

  saveItemDetails (id, data) {
    console.log('api.annualPlans.saveItemDetails(', id, ', ', data, ')');

    if (!id) {
      return Client.get('/api/mediaplans/items/details', data);
    }

    return Client.put(`/api/mediaplans/items/details/${id}/`);
  },

  json (id) {
    console.log('api.annualPlans.json(', id, ')');

    return Client.get(`/api/annualPlans/${id}/json/`, {}, true, {
      isJson: false,
    });
  },

  exportMediatorsExcel (data) {
    console.log('api.annualPlans.exportMediatorsExcel(', data, ')');

    return fetchResponse(1000, {});
  },

  uploadMediatorsData (id, formData) {
    console.log('api.annualPlans.uploadMediatorsData(', id, ')');

    return fetchResponse(1000, {});
  },

  exportPrevMediatorsData (id) {
    console.log('api.annualPlans.exportPrevMediatorsData(', id, ')');

    return fetchResponse(1000, {});
  },

  resetMediatorsData (id) {
    console.log('api.annualPlans.resetMediatorsData(', id, ')');

    return fetchResponse(1000, {});
  },
};

export default annualPlansAPI;
