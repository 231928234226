import { fetchResponse } from './helpers/fakeFetch';
import Client from '../release/HTTPClient';

import goalsList from './data/goalsList.json';
import goalDetails from './data/goalDetails.json';

const goalsAPI = {
  list (request) {
    console.log('api.goals.list(', request, ')');

    return fetchResponse(1000, goalsList);
  },

  get (id) {
    console.log('api.goals.get(', id, ')');

    return fetchResponse(1000, goalDetails);
  },

  save (id, data) {
    console.log('api.goals.save(', id, ', ', data, ')');

    if (!id) {
      return Client.post('/api/goals/new', data);
    }

    return Client.put(`/api/goals/${id}`, data);
  },
};

export default goalsAPI;
