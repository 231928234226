import isNumber from 'lodash/isNumber';
import { IFilterItem } from 'types';

export interface IProcessedFilterItem extends IFilterItem {
  idUnique: string;
  text: string;
}
const FilterItems = {
  toUniqueId (item: IFilterItem): string {
    return `${item.type}_${item.id}`;
  },

  uniqueIdToObject (uniqueId): { type: string; id: string;} {
    const pos = uniqueId.lastIndexOf('_');
    let id = uniqueId.substring(pos + 1);

    if (isNumber(parseInt(id))) {
      id = parseInt(id);
    }

    return {
      type: uniqueId.substring(0, pos),
      id
    };
  },

  postProcess (item: IFilterItem): IProcessedFilterItem {
    return {
      ...item,
      idUnique: FilterItems.toUniqueId(item),
      text: `${item.typeTranslation} ${item.label}`
    };
  },

  getDataAndPostProcess (response): IProcessedFilterItem[] {
    return response.map(FilterItems.postProcess);
  }
};

export default FilterItems;
