import React from 'react';
import store from 'store';

export const localizeMessage = (
  { id }: { id: string; },
  values = {},
  messages: Record<string, string> = store.getState().locale.messages,
) => {
  if (typeof messages[id] === 'undefined') {
    console.warn(new Error(`Localized string '${id}' not found.`));

    return '';
  }

  let resultMessage = messages[id];

  for (const variable in values) {
    if (values.hasOwnProperty(variable)) {
      const re = new RegExp(`{${variable}}`, 'g');

      resultMessage = resultMessage.replace(re, values[variable]);
    }
  }

  return resultMessage;
};

interface IProps {
  id: string | string[];
  values?: any;
  messages?: Record<string, string>;
  children?: React.ReactNode;
}

const LocalizedMessage = ({ children, id, values = {}, messages }: IProps) => {
  if (typeof children === 'function') {
    return children.apply(
      children,
      Array.isArray(id)
        ? id.map(localId => localizeMessage({ id: localId }, values, messages))
        : [localizeMessage({ id }, values, messages)],
    );
  }

  if (Array.isArray(id)) {
    return id.map(localId => localizeMessage({ id: localId }, values, messages)).join(' ');
  }

  return localizeMessage({ id }, values, messages);
};

export default LocalizedMessage;
