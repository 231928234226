export const fileSaver = (response: Promise<{body: ReadableStream}>, fileName: string, extension: string) => {
  response
    .then((response) => {
      const reader = response.body.getReader();

      return new ReadableStream({
        start (controller) {
          return pump();
          function pump () {
            return reader.read().then(({ done, value }) => {
              if (done) {
                controller.close();

                return;
              }
              controller.enqueue(value);

              return pump();
            });
          }
        },
      });
    })
    .then((stream) => new Response(stream))
    .then((response) => response.blob())
    .then((blob) => URL.createObjectURL(blob))
    .then((url) => {
      const link = document.createElement('a');
      link.href = url;
      link.download = `${fileName}.${extension}`;
      link.click();
      link.remove();
    })
    .catch((err) => console.error(err));
}
