import Client from 'api/release/HTTPClient';
import { ICalculationTemplate, IServerPage } from 'types';

const calculationTemplatesAPI = {
  list (advertiserId: number): Promise<IServerPage<ICalculationTemplate>> {
    return Client.post(`/api/clients/${advertiserId}/calculationTemplate/`);
  },

  get (advertiserId: number, id): Promise<ICalculationTemplate> {
    return Client.get(`/api/clients/${advertiserId}/calculationTemplate/${id}`);
  },

  delete (advertiserId: number, id): Promise<void> {
    return Client.delete(
      `/api/clients/${advertiserId}/calculationTemplate/${id}`
    );
  },

  save (advertiserId: number, data): Promise<ICalculationTemplate> {
    return Client.put(
      `/api/clients/${advertiserId}/calculationTemplate/new`,
      data
    );
  }
};

export default calculationTemplatesAPI;
