import { fetchResponse } from './helpers/fakeFetch';
import Client from '../release/HTTPClient';

import channelsList from './data/channelsList.json';
import channelsTypes from './data/channelsTypes.json';
import channelsNaturalSplit from './data/channelsNaturalSplit.json';
import channelsFilterItems from './data/channelsFilterItems.json';

const channelsAPI = {
  list (request) {
    console.log('api.channels.list(', request, ')');

    return fetchResponse(1000, channelsList);
  },

  getTypes () {
    console.log('api.channels.getTypes()');

    return fetchResponse(1000, channelsTypes);
  },

  get (id) {
    console.log('api.channels.get(', id, ')');

    return Client.get(`/api/channels/${id}`);
  },

  getNaturalSplit (eventStart, eventEnd, targetAudience, channelType) {
    console.log(
      'api.channels.getNaturalSplit(',
      eventStart, ', ',
      eventEnd, ', ',
      targetAudience, ', ',
      channelType,
      ')',
    );

    return fetchResponse(1000, channelsNaturalSplit);
  },

  getFilterItems (request) {
    console.log('api.channels.getFilterItems(', request, ')');

    return fetchResponse(1000, channelsFilterItems);
  },
};

export default channelsAPI;
