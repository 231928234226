export function cloneObject<T extends {}> (object: T): T {
  let copy;

  // Handle the 3 simple types, and null or undefined
  if (object === null || typeof object !== 'object') return object;

  // Handle Date
  if (object instanceof Date) {
    copy = new Date();
    copy.setTime(object.getTime());

    return copy;
  }

  // Handle Array
  if (object instanceof Array) {
    copy = [];

    for (let i = 0, len = object.length; i < len; i++) {
      copy[i] = cloneObject(object[i]);
    }

    return copy;
  }

  // Handle Object
  if (object instanceof Object) {
    copy = {};

    for (const attr in object) {
      if (object.hasOwnProperty(attr)) copy[attr] = cloneObject(object[attr]);
    }

    return copy;
  }

  throw new Error("Unable to copy obj! Its type isn't supported.");
}
