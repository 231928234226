import React from 'react';
import { DebounceInput, DebounceInputProps } from 'react-debounce-input';

import alert from 'helpers/alert';
import { MIN_INT_VALUE, MAX_INT_VALUE } from 'helpers/constants';
import { localizeMessage } from 'components/LocalizedMessage';

type InputProps = DebounceInputProps<HTMLInputElement, React.InputHTMLAttributes<HTMLInputElement>>;

type Props = Omit<InputProps, 'value' | 'min' | 'max' | 'onChange'> & {
  value?: number | string;
  min?: number;
  max?: number;
  onChange(e: React.ChangeEvent<HTMLInputElement>): void;
};

export const NumericInput: React.FC<Props> = ({
  value,
  min = MIN_INT_VALUE,
  max = MAX_INT_VALUE,
  onChange,
  ...restProps
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = +e.target.value;

    if (e.target.value === '' || val <= max && val >= min) {
      onChange(e);
    } else if (val > max) {
      e.target.value = String(value);
      alert.warn(localizeMessage({ id: 'errors.number.maxExceed' }, { maxValue: max }));
    } else if (val < min) {
      e.target.value = String(value);
      alert.warn(localizeMessage({ id: 'errors.number.minExceed' }, { minValue: min }));
    }
  };

  return (
    <DebounceInput
      {...restProps}
      type='number'
      value={value}
      min={min}
      max={max}
      debounceTimeout={600}
      onChange={handleChange}
    />
  );
};
