import FilterItems, { IProcessedFilterItem } from 'helpers/filterItems';
import Client from 'api/release/HTTPClient';
import { IStrategicMediaplanningRepresentation } from 'types';

const strategicMediaplanningsAPI = {
  list (request) {
    return Client.post('/api/strategicMediaplannings/', request);
  },

  getMediaplansWithItemsByAdvertiserIdAndPeriod (): Promise<IStrategicMediaplanningRepresentation> {
    return Client.get('/api/strategicMediaplannings/mediaplans');
  },

  async getFilterItems (request): Promise<IProcessedFilterItem[]> {
    const result = await Client.get('/api/strategicMediaplannings/filter-items', request);

    return FilterItems.getDataAndPostProcess(result);
  },
};

export default strategicMediaplanningsAPI;
