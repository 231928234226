import React, { PureComponent } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps, RouteProps } from 'react-router';

import { checkAuth as checkAuthAction } from 'modules/auth';
import Loader from 'components/Loader';
import { IProfile, Locale } from 'types';

const defaultRoles = ['SUPER_ADMIN', 'ADMIN', 'PLANNER', 'BUYER'];

interface IOwnProps {
  component: React.ComponentType<any>;
  roles?: string[];
}

type IProps = RouteComponentProps & RouteProps & IOwnProps & IStateProps & IActionProps;

class PrivateRoute extends PureComponent<IProps> {
  componentDidMount () {
    this.checkAuth(this.props);
  }

  UNSAFE_componentWillReceiveProps (nextProps: IProps) {
    this.checkAuth(nextProps);
  }

  async checkAuth (props: IProps) {
    const { history, roles = defaultRoles, isAuthorizing, isAuthorized, authorizingError, profile, checkAuth } = props;

    if (isAuthorized && !roles.includes(profile.role.name)) {
      history.replace('/app');

      return;
    }

    if (isAuthorizing || isAuthorized || authorizingError) {
      return;
    }

    try {
      const status = await checkAuth();

      if (!status) {
        history.replace({ pathname: '/app/auth', search: props.location.search });
      }
    } catch (error) {
      console.error(error);
    }
  }

  render () {
    const { exact, component: WrappedComponent, isAuthorizing, isAuthorized, authorizingError, ...rest } = this.props;

    if (isAuthorizing || (!isAuthorized && !authorizingError)) {
      return (<Loader />);
    }

    return (
      <Route
        {...rest}
        render={props => (isAuthorized
          ? (<WrappedComponent {...props} />)
          : (
            <Redirect
              to={{
                pathname: '/app/auth',
                search: props.location.search,
                state: { from: props.location },
              }}
            />
          )
        )}
      />
    );
  }
}

interface IActionProps {
  checkAuth: () => boolean;
}

const mapDispatchToProps: IActionProps = {
  checkAuth: checkAuthAction,
};

interface IStateProps {
  isAuthorizing: boolean;
  isAuthorized: boolean;
  profile: IProfile;
  authorizingError: unknown;
  lang: Locale;
}

const mapStateToProps = (state): IStateProps => ({
  isAuthorizing: state.auth.isAuthorizing,
  isAuthorized: state.auth.isAuthorized,
  profile: state.auth.profile,
  authorizingError: state.auth.authorizingError,
  lang: state.locale.lang,
});
export { PrivateRoute };
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PrivateRoute));
