import { fetchResponse } from './helpers/fakeFetch';
import Client from '../release/HTTPClient';

import targetAudiencesList from './data/targetAudiencesList.json';
import targetAudiencesVolumeByRetroPeriod from './data/targetAudiencesVolumeByRetroPeriod.json';

const targetAudiencesAPI = {
  list (request) {
    console.log('api.targetAudiences.list(', request, ')');

    return fetchResponse(1000, targetAudiencesList);
  },

  volumeByRetroPeriod (request, withoutLoader) {
    console.log('api.targetAudiences.volumeByRetroPeriod(', request, ', ', withoutLoader, ')');

    return fetchResponse(1000, targetAudiencesVolumeByRetroPeriod);
  },

  getVolumeType (targetAudienceId) {
    console.log('api.targetAudiences.getVolumeType(', targetAudienceId, ')');

    return Client.get(`/api/targetAudiences/${targetAudienceId}/volume`);
  },
};

export default targetAudiencesAPI;
