import React, { Component } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { IProfile } from 'types';


interface IProps {
  profile: IProfile;
}
class Main extends Component<RouteComponentProps & IProps> {
  render () {
    return (
      <Redirect
        to={{
          pathname: '/app/annualPlans',
        }}
      />
    );
  }
}

export default Main;
