import { connect } from 'react-redux';
import { checkRoles } from 'modules/auth';
import Advertisers from '../components/Advertisers';

const mapStateToProps = (state) => ({
  user: state.auth.profile
});

const mapDispatchToProps = {
  checkRoles,
};

export default connect(mapStateToProps, mapDispatchToProps)(Advertisers);
