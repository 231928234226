import React, {
  Component,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import cx from 'classnames';
import { saveAs } from 'file-saver';
import Tooltip from 'rc-tooltip';
import Switch from 'react-switchery';
import { Tab, Tabs } from 'react-bootstrap';
import LocalizedMessage, { localizeMessage } from 'components/LocalizedMessage';
import Loader from 'components/Loader';
import { formattedNumber, formattedNumberWithZero } from 'helpers/utils';
import Alert from 'helpers/alert';
import API from 'api';
import DealCalculationQualityTab from 'routes/AnnualPlan/components/Panels/components/DealCalculationQualityTab';
import MonthHover from 'routes/AnnualPlan/components/Panels/components/MonthHover';
import classes from '../AnnualPlan.module.scss';
import { AnnualPlanData } from 'routes/AnnualPlan/components/AnnualPlan';
import {
  IBackendChannel,
  IChannelMonthBudget,
  IOption,
  IPerformanceCalculationType,
  IPerformanceResultStatus,
  TargetAudience
} from 'types';

import { useClickOutside } from 'helpers/useOutsideClick';
import RecalculatePalomars from 'routes/AnnualPlan/components/Panels/components/RecalculatePalomars';
import { fileSaver } from 'libs/file-saver';

interface IState {
  channelMonthsBudgets: IChannelMonthBudgetLocal[];
  dealCalculationSelector: string;
  showChannelsWithZeroQFields: boolean;
  palomarsStatus: string;
  channelRestrictionWarnings: any;
  groupRestrictionWarnings: any;
}
interface IProps {
  months: { id: number; name: string }[];
  data: AnnualPlanData;
  nameToDownload?: string;
  targetAudience?: TargetAudience;
  getClassByMonthId: (id: number) => string;
}

export interface IChannelMonthBudgetLocal extends IChannelMonthBudget {
  channel: IBackendChannel & {
    nameWithType?: string;
    isWarning?: boolean;
    tooltip?: string;
    isNotData?: string;
  };
}

const TradeWhiteListButtons = ({ code }: { code: string }) => {
  const [isActive, setIsActive] = useState();
  const [status, setStatus] = useState<IPerformanceResultStatus | undefined>(
    undefined
  );

  const isSuccess = status !== 'SUCCESS';

  const r = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    const checkStatus = () => {
      API.annualPlans
        .performanceStatus(code)
        .then(it => {
          setStatus(it);
          clearInterval(interval);
        })
        .catch(() => setStatus(undefined));
    };

    interval = setInterval(checkStatus, 5000);
    checkStatus();

    return () => clearInterval(interval);
  }, []);

  useClickOutside(r, () => {
    setIsActive(false);
  });

  const exportExcelResult = (type: 'raw' | 'result') => () => {
    try {
      fileSaver(API.annualPlans.exportExcelResult<{body: ReadableStream}>(code, type), `${code}-${type}`, 'xlsx');
    } catch (error) {
      console.error(error.message);
    }
  };

  const onOptionSelection = useCallback(
    (e: IOption<IPerformanceCalculationType>) => {
      API.annualPlans
        .calculateTradeWhiteList(code, e.value)
        .then(() => setIsActive(false));
    },
    [code]
  );

  const options: IOption<IPerformanceCalculationType>[] = [
    { value: 'FIX_PRIORITIZE', label: 'Приоритезировать фикс' },
    { value: 'QUALITY_PROPORTION', label: 'Сохранить пропорции качества' }
  ];

  return (
    <>
      <span>
        <button
          disabled={isSuccess}
          className='btn btn-info btn-sm'
          onClick={exportExcelResult('result')}
        >
          <span>Получить данные Trade</span>
        </button>
      </span>

      <span>
        <button
          disabled={isSuccess}
          className='btn btn-info btn-sm'
          onClick={exportExcelResult('raw')}
        >
          <span>Скачать исп. данные Trade</span>
        </button>
      </span>
      <span ref={r} className={classes['trade-white-list-button']}>
        <span className='btn btn-info btn-sm'>
          <span onClick={() => setIsActive(!isActive)}>
            Сформировать Trade white lists
          </span>
        </span>
        {isActive && (
          <div className={cx(classes['trade-white-list-dropdown'])}>
            {options.map(option => (
              <div
                onClick={() => onOptionSelection(option)}
                className={classes['trade-white-list-option']}
              >
                {option.label}
              </div>
            ))}
          </div>
        )}
      </span>
    </>
  );
};

class TVDealCalculation extends Component<IProps, IState> {
  refreshPalomarsStatusTimer?: NodeJS.Timer;

  state: IState = {
    channelMonthsBudgets: [],
    dealCalculationSelector: 'BUDGET',
    showChannelsWithZeroQFields: false,
    palomarsStatus: 'NOT_PALOMARS',
    channelRestrictionWarnings: {},
    groupRestrictionWarnings: {}
  };
  private static calc (
    props: IProps,
    budgets: IChannelMonthBudget[]
  ): IChannelMonthBudgetLocal[] {
    return budgets.map(channel => {
      const channelCopy: IChannelMonthBudgetLocal = {
        ...channel
      };

      if (channelCopy.channel) {
        channelCopy.channel.nameWithType = channelCopy.channel.name;

        const { mediaplans } = props.data;

        const channelWarnings =
          mediaplans?.[0]?.warnings &&
          Object.values(mediaplans[0].warnings).find(
            ([channelWarning]) => Number(channelWarning.channel.id) ===
                Number(channelCopy.channel.id) &&
              channelWarning.channel.type === channelCopy.channel.type
          );

        if (channelWarnings) {
          channelCopy.channel.isWarning = true;
          channelCopy.channel.tooltip = channelCopy.channel.isNotData
            ? localizeMessage({
              id: 'annualPlan.dealCalculation.channel.is-not-data'
            })
            : channelWarnings.map(warnings => warnings.warning).join('\n');
        }

        // TODO: Remove after backend switching
        if (typeof channelCopy.channel.type === 'string') {
          if (
            channelCopy.channel.type === 'ORBITAL' &&
            channelCopy.channel.nameWithType.slice(-3) === '- 0'
          ) {
            channelCopy.channel.nameWithType = channelCopy.channel.nameWithType.slice(
              0,
              -3
            );
          }

          if (channelCopy.channel.type === 'LOCAL') {
            if (channelCopy.channel.nameWithType.slice(-3) === '- L') {
              channelCopy.channel.nameWithType = channelCopy.channel.nameWithType.slice(
                0,
                -3
              );
            }

            return channelCopy;
          }

          channelCopy.channel.nameWithType += ` (${channelCopy.channel.type.slice(
            0,
            3
          )})`;
        }

        if (typeof channelCopy.channel.type === 'object') {
          if (
            channelCopy.channel.type === 'ORBITAL' &&
            channelCopy.channel.nameWithType.slice(-3) === '- 0'
          ) {
            channelCopy.channel.nameWithType = channelCopy.channel.nameWithType.slice(
              0,
              -3
            );
          }

          if (channelCopy.channel.type === 'LOCAL') {
            if (channelCopy.channel.nameWithType.slice(-3) === '- L') {
              channelCopy.channel.nameWithType = channelCopy.channel.nameWithType.slice(
                0,
                -3
              );
            }

            return channelCopy;
          }

          // channelCopy.channel.nameWithType += ` (${channelCopy.channel.type.nameTranslation.slice(
          //   0,
          //   3
          // )})`;
        }
      }

      return channelCopy;
    });
  }

  static getDerivedStateFromProps (props: IProps) {
    const budgetSource = props.targetAudience
      ? props.data.additionalTaChannelMonthBudgets[props.targetAudience.name]
      : props.data.channelMonthsBudgets;

    return {
      channelMonthsBudgets: TVDealCalculation.calc(props, budgetSource)
    };
  }

  componentDidMount () {
    const { data } = this.props;

    if (data.isCalculatePalomarsReach) {
      this.getPalomarsStatus();
      this.refreshPalomarsStatusTimer = setInterval(
        () => this.getPalomarsStatus(),
        45000
      );
    }

    if (
      data.mediaplans &&
      data.mediaplans[0] &&
      data.mediaplans[0].restrictionWarnings
    ) {
      const { restrictionWarnings } = data.mediaplans[0];

      if (restrictionWarnings.channelWarnings) {
        const channelRestrictionWarnings = restrictionWarnings.channelWarnings.reduce(
          (acc, warning) => {
            const { channel, ...restProps } = warning;
            if (!(channel && channel.id)) {
              return;
            }

            return {
              ...acc,
              [channel.id]: {
                ...restProps,
                type: channel.type
              }
            };
          },
          {}
        );
        this.setState({ channelRestrictionWarnings });
      }

      if (restrictionWarnings.groupWarnings) {
        const groupRestrictionWarnings = restrictionWarnings.groupWarnings.reduce(
          (acc, warning) => {
            const { group, ...restProps } = warning;
            if (!(group && group.name)) {
              return;
            }

            return {
              ...acc,
              [group.name]: { ...restProps }
            };
          },
          {}
        );
        this.setState({ groupRestrictionWarnings });
      }
    }
  }

  componentWillUnmount () {
    if (this.refreshPalomarsStatusTimer) {
      clearInterval(this.refreshPalomarsStatusTimer);
    }
  }

  selectTab = dealCalculationSelector => {
    this.setState({
      dealCalculationSelector
    });
  };

  exportDealBriefTable = async () => {
    const { data } = this.props;

    try {
      const response = await API.annualPlans.exportDealBriefTable(data.code);

      const blob = await response.blob();

      const contentDisposition = response.headers.get('content-disposition');
      const filenameData = contentDisposition
        ? contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)
        : null;
      const filename = filenameData
        ? filenameData[1].replace(/['"]+/g, '')
        : `${this.props.nameToDownload}_deal_brief_table.xlsx`;

      saveAs(blob, filename);

      Alert.success(
        localizeMessage({
          id: 'annualPlan.dealCalculation.dealBriefTable-successfully-export'
        })
      );
    } catch (error) {
      console.error(error);

      Alert.error(
        `${localizeMessage({
          id: 'annualPlan.dealCalculation.dealBriefTable-failed-export'
        })}:
         "${data.name}" [${data.code}]` // FIXME: WHERE THE FUCK IS .TITLE IN DATA
      );
    }
  };

  exportProgrammingTable = async () => {
    const { data } = this.props;
    const { showChannelsWithZeroQFields } = this.state;

    try {
      const response = await API.annualPlans.exportProgrammingTable(
        data.code,
        !showChannelsWithZeroQFields
      );

      const blob = await response.blob();

      const contentDisposition = response.headers.get('content-disposition');
      const filenameData = contentDisposition
        ? contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)
        : null;
      const filename = filenameData
        ? filenameData[1].replace(/['"]+/g, '')
        : `${this.props.nameToDownload}_programming_table.xlsx`;

      saveAs(blob, filename);

      Alert.success(
        localizeMessage({
          id: 'annualPlan.dealCalculation.programmingTable-successfully-export'
        })
      );
    } catch (error) {
      console.error(error);

      Alert.error(
        `${localizeMessage({
          id: 'annualPlan.dealCalculation.programmingTable-failed-export'
        })}:
         "${data.name}" [${data.code}]` // fixme same .title
      );
    }
  };

  exportCoverBuildUpTable = async () => {
    const { data } = this.props;

    try {
      const response = await API.annualPlans.exportCoverBuildUpTable(data.code);

      const blob = await response.blob();

      const contentDisposition = response.headers.get('content-disposition');
      const filenameData = contentDisposition
        ? contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)
        : null;
      const filename = filenameData
        ? filenameData[1].replace(/['"]+/g, '')
        : `${this.props.nameToDownload}_cbu_table.xlsx`;

      saveAs(blob, filename);

      Alert.success(
        localizeMessage({
          id: 'annualPlan.dealCalculation.cbuTable-successfully-export'
        })
      );
    } catch (error) {
      console.error(error);

      Alert.error(
        `${localizeMessage({
          id: 'annualPlan.dealCalculation.cbuTable-failed-export'
        })}:
         "${data.name}" [${data.code}]` // fixme same .title
      );
    }
  };

  exportPalomarsReport = async () => {
    const { data } = this.props;

    try {
      const response = await API.annualPlans.exportPalomarsReport(data.code);

      const blob = await response.blob();

      const contentDisposition = response.headers.get('content-disposition');
      const filenameData = contentDisposition
        ? contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)
        : null;
      const filename = filenameData
        ? filenameData[1].replace(/['"]+/g, '')
        : `${this.props.nameToDownload}_palomars_report.xlsx`;

      saveAs(blob, filename);

      Alert.success(
        localizeMessage({
          id: 'annualPlan.dealCalculation.palomars-report-successfully-export'
        })
      );
    } catch (error) {
      console.error(error);

      Alert.error(
        `${localizeMessage({
          id: 'annualPlan.dealCalculation.palomars-report-failed-export'
        })}:
         "${data.name}" [${data.code}]` // fixme same .title
      );
    }
  };

  getPalomarsStatus = async () => {
    const { data } = this.props;

    try {
      const palomarsStatus = await API.annualPlans.getPalomarsStatus(data.code);

      this.setState({
        palomarsStatus: palomarsStatus || 'NOT_PALOMARS'
      });

      if (
        palomarsStatus &&
        palomarsStatus.name === 'SUCCESS' &&
        this.refreshPalomarsStatusTimer
      ) {
        clearInterval(this.refreshPalomarsStatusTimer);
      }
    } catch (error) {
      console.error(error);
    }
  };

  getWarningMessage (restrictions, type) {
    const min = type === 'channel' ? restrictions.trpMin : restrictions.min;
    const max = type === 'channel' ? restrictions.trpMax : restrictions.max;
    const postfix = type === 'channel' ? '% TRP' : '%';
    const minPercent = +(min * 100).toFixed();
    const maxPercent = +(max * 100).toFixed();
    const share = `${!isNaN(minPercent) ? minPercent : '?'} - ${
      !isNaN(maxPercent) ? maxPercent : '?'
    } ${postfix}`;
    const reason = restrictions.reason;
    const reasonStr =
      reason && reason.length
        ? Array.isArray(reason)
          ? reason.join('\n')
          : reason
        : null;

    const introMsg = localizeMessage({
      id: `annualPlan.dealCalculation.${type}-limitation-violated`
    });
    const shareMsg = `${localizeMessage({
      id: 'annualPlan.dealCalculation.target-share'
    })}: ${share}`;
    const reasonMsg = reasonStr
      ? `${localizeMessage({
        id: 'annualPlan.dealCalculation.reason'
      })}: ${reasonStr}`
      : '';

    return `${introMsg}\n${shareMsg}\n${reasonMsg}`;
  }

  handleShowChannelsWithoutQFieldsCheckboxChange = showChannelsWithZeroQFields => {
    this.setState({ showChannelsWithZeroQFields });
  };

  render () {
    const { months, data, getClassByMonthId, targetAudience } = this.props;
    const {
      dealCalculationSelector,
      showChannelsWithZeroQFields,
      palomarsStatus,
      channelRestrictionWarnings: channelWarnings,
      groupRestrictionWarnings: groupWarnings
    } = this.state;
    const movieDuration =
      data.mediaplans && data.mediaplans[0] && data.mediaplans[0].movieDuration;
    const isSuccessPalomarsStatus = palomarsStatus === 'SUCCESS';

    const totalTvTrp = targetAudience
      ? this.state.channelMonthsBudgets.reduce((a, b) => a + b.totalTrp, 0)
      : data.tvTrp;

    const isErrorPalomarsStatus = palomarsStatus === 'ERROR';
    const isLoadingPalomarsStatus = ['IN_PROGRESS', 'IN_QUEUE'].includes(
      palomarsStatus
    );

    return (
      <div className='row'>
        <div className='col-lg-12'>
          <div className='ibox'>
            <div className='ibox-title'>
              <h5>
                <LocalizedMessage id='annualPlan.dealCalculation.title' />
              </h5>
            </div>
            <div className='ibox-content'>
              <div className='row m-b-sm'>
                <div className='col-md-12'>
                  <div className='pull-left'>
                    <RecalculatePalomars id={data.code} />
                  </div>
                  <div className='pull-right'>
                    {data.isCalculatePalomarsReach &&
                      palomarsStatus !== 'NOT_PALOMARS' && (
                        <span onClick={this.exportPalomarsReport}>
                          <button
                            className={cx('btn btn-sm btn-inline-flex', {
                              'btn-primary': isSuccessPalomarsStatus,
                              'btn-secondary': isLoadingPalomarsStatus,
                              'btn-danger': isErrorPalomarsStatus
                            })}
                            disabled={!isSuccessPalomarsStatus}
                          >
                            {isLoadingPalomarsStatus && (
                              <>
                                <Loader position='inline' isSmall />
                                &nbsp;
                              </>
                            )}
                            <span>
                              <LocalizedMessage
                                id={
                                  isErrorPalomarsStatus
                                    ? 'annualPlan.dealCalculation.buttons.palomars-calculation-error'
                                    : 'annualPlan.dealCalculation.buttons.export-tv-reach-by-palomars-report'
                                }
                              />
                            </span>
                          </button>
                        </span>
                    )}
                    {' '}
                    <span onClick={this.exportDealBriefTable}>
                      <span className='btn btn-info btn-sm'>
                        <i className='fa fa-file-excel-o fa-lg' />
                        <span>
                          <LocalizedMessage id='annualPlan.dealCalculation.buttons.export' />
                        </span>
                      </span>
                    </span>
                    {' '}
                    <span onClick={this.exportProgrammingTable}>
                      <span className='btn btn-info btn-sm'>
                        <i className='fa fa-file-excel-o fa-lg' />
                        <span>
                          <LocalizedMessage id='annualPlan.dealCalculation.buttons.exportProgramming' />
                        </span>
                      </span>
                    </span>
                    {' '}
                    <span onClick={this.exportCoverBuildUpTable}>
                      <span className='btn btn-info btn-sm'>
                        <i className='fa fa-file-excel-o fa-lg' />
                        <span>
                          <LocalizedMessage id='annualPlan.dealCalculation.buttons.exportCBU' />
                        </span>
                      </span>
                    </span>
                  </div>
                </div>
              </div>

              <div className='row m-b-sm'>
                <div className='col-md-12'>
                  <div className={cx('pull-left', classes['trade-white-list'])}>
                    <TradeWhiteListButtons code={this.props.data.code} />
                  </div>
                </div>
              </div>
              {dealCalculationSelector === 'PROGRAMMING' && (
                <div className='row m-b-sm'>
                  <div className='col-md-12'>
                    <div className='text-nowrap pull-right'>
                      <label className='form-control-static'>
                        <LocalizedMessage id='annualPlan.dealCalculation.switchShowWithoutQFields' />
                      </label>
                      &nbsp;&nbsp;
                      <label className='_cursor--pointer'>
                        <Switch
                          className='js-switch'
                          onChange={
                            this.handleShowChannelsWithoutQFieldsCheckboxChange
                          }
                          options={{ color: '#1AB394' }}
                          checked={showChannelsWithZeroQFields}
                        />
                      </label>
                    </div>
                  </div>
                </div>
              )}
              <div className='row'>
                <div className='col-md-12'>
                  <div className='tabs-container'>
                    <LocalizedMessage
                      id={[
                        'annualPlan.dealCalculation.tabs.monthlyBudgets',
                        'annualPlan.dealCalculation.tabs.monthlyTrp',
                        'annualPlan.dealCalculation.tabs.monthlyWGRP',
                        'annualPlan.dealCalculation.tabs.quality',
                        'annualPlan.dealCalculation.tabs.budgetByMonthsAndTa',
                        'annualPlan.dealCalculation.tabs.programming'
                      ]}
                    >
                      {(
                        localizedBudgets,
                        localizedTrp,
                        localizedGrp30,
                        localizedQuality,
                        localizedBudgetByMonthsAndTa,
                        localizedProgramming
                      ) => (
                        <Tabs
                          id='deal-calc-tab'
                          defaultActiveKey={dealCalculationSelector}
                          mountOnEnter
                          unmountOnExit
                          onSelect={this.selectTab}
                        >
                          <Tab eventKey='BUDGET' title={localizedBudgets}>
                            <div className={classes['display-flex']}>
                              <div>
                                <table
                                  className={cx(
                                    'table',
                                    classes.table,
                                    'table-bordered',
                                    classes['centered-cells']
                                  )}
                                >
                                  <thead>
                                    <tr>
                                      <th className='text-nowrap'>
                                        <i className='fa fa-television' />
                                        {' '}
                                        <span>
                                          <LocalizedMessage id='channel' />
                                        </span>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.channelMonthsBudgets.map(
                                      (channel, index) => {
                                        const row = (
                                          <MonthHover
                                            key={index}
                                            id={`monthsFixedRow${index}`}
                                            tag='tr'
                                            dependentRowId={`monthsScrollRow${index}`}
                                            className={cx({
                                              [classes.tableRowWarning]:
                                                channel.channel.isWarning
                                            })}
                                          >
                                            <td className='text-nowrap'>
                                              {channel.channel
                                                ? channel.channel.nameWithType
                                                : 'Unexpected'}
                                              {/* fixme : channel.site.name doesnt exist */}
                                            </td>
                                          </MonthHover>
                                        );

                                        return channel.channel.isWarning ? (
                                          <Tooltip
                                            key={index}
                                            placement='top'
                                            overlay={channel.channel.tooltip}
                                          >
                                            {row}
                                          </Tooltip>
                                        ) : (
                                          row
                                        );
                                      }
                                    )}
                                  </tbody>
                                </table>
                              </div>
                              <div className={classes['right-flex-table']}>
                                <table
                                  className={cx(
                                    'table',
                                    classes.table,
                                    'table-bordered',
                                    classes['centered-cells']
                                  )}
                                >
                                  <thead>
                                    <tr>
                                      <th className='text-nowrap'>
                                        <i className='fa fa-rub' />
                                        {' '}
                                        <span>
                                          <LocalizedMessage id='total' />
                                        </span>
                                      </th>
                                      <th className='text-nowrap'>
                                        <i className='fa fa-percent' />
                                        {' '}
                                        <span>
                                          <LocalizedMessage id='total' />
                                        </span>
                                      </th>
                                      {months.map(month => (
                                        <th
                                          key={month.id}
                                          className={cx(
                                            classes['month-budget-column'],
                                            getClassByMonthId(month.id)
                                          )}
                                        >
                                          {month.name}
                                        </th>
                                      ))}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.channelMonthsBudgets.map(
                                      (channel, index) => {
                                        const row = (
                                          <MonthHover
                                            key={index}
                                            id={`monthsScrollRow${index}`}
                                            tag='tr'
                                            dependentRowId={`monthsFixedRow${index}`}
                                            className={cx({
                                              [classes.tableRowWarning]:
                                                channel.channel.isWarning
                                            })}
                                          >
                                            <td className='text-nowrap'>
                                              <span>
                                                {channel.total !== null
                                                  ? formattedNumberWithZero(
                                                    channel.total
                                                  )
                                                  : '-'}
                                              </span>
                                            </td>
                                            <td className='text-nowrap'>
                                              <span>
                                                {channel.total !== null
                                                  ? formattedNumber(
                                                    (channel.total * 100) /
                                                        data.tvBudget,
                                                    1
                                                  )
                                                  : '-'}
                                              </span>
                                            </td>
                                            {months.map(month => (
                                              <td
                                                key={month.id}
                                                className={cx(
                                                  'text-nowrap',
                                                  getClassByMonthId(month.id)
                                                )}
                                              >
                                                <span>
                                                  {channel.months[month.id] !==
                                                  null
                                                    ? formattedNumberWithZero(
                                                      channel.months[month.id]
                                                    )
                                                    : '-'}
                                                </span>
                                              </td>
                                            ))}
                                          </MonthHover>
                                        );

                                        return channel.channel.isWarning ? (
                                          <Tooltip
                                            key={index}
                                            placement='top'
                                            overlay={channel.channel.tooltip}
                                          >
                                            {row}
                                          </Tooltip>
                                        ) : (
                                          row
                                        );
                                      }
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </Tab>

                          <Tab eventKey='TRP' title={localizedTrp}>
                            <div className={classes['display-flex']}>
                              <div>
                                <table
                                  className={cx(
                                    'table',
                                    classes.table,
                                    'table-bordered',
                                    classes['centered-cells']
                                  )}
                                >
                                  <thead>
                                    <tr>
                                      <th className='text-nowrap'>
                                        <i className='fa fa-television' />
                                        {' '}
                                        <span>
                                          <LocalizedMessage id='channel' />
                                        </span>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.channelMonthsBudgets.map(
                                      (channel, index) => {
                                        const row = (
                                          <MonthHover
                                            key={index}
                                            id={`monthsFixedRow${index}`}
                                            dependentRowId={`monthsScrollRow${index}`}
                                            className={cx({
                                              [classes.tableRowWarning]:
                                                channel.channel.isWarning
                                            })}
                                          >
                                            <td className='text-nowrap'>
                                              {channel.channel
                                                ? channel.channel.nameWithType
                                                : 'Unexpected'}
                                              {/* fixme : channel.site.name doesnt exist */}
                                            </td>
                                          </MonthHover>
                                        );

                                        return channel.channel.isWarning ? (
                                          <Tooltip
                                            key={index}
                                            placement='top'
                                            overlay={channel.channel.tooltip}
                                          >
                                            {row}
                                          </Tooltip>
                                        ) : (
                                          row
                                        );
                                      }
                                    )}
                                  </tbody>
                                </table>
                              </div>
                              <div className={classes['right-flex-table']}>
                                <table
                                  className={cx(
                                    'table',
                                    classes.table,
                                    'table-bordered',
                                    classes['centered-cells']
                                  )}
                                >
                                  <thead>
                                    <tr>
                                      <th className='text-nowrap'>
                                        <span>
                                          <LocalizedMessage id='total' />
                                        </span>
                                      </th>
                                      <th className='text-nowrap'>
                                        <i className='fa fa-percent' />
                                        {' '}
                                        <span>
                                          <LocalizedMessage id='total' />
                                        </span>
                                      </th>
                                      {months.map(month => (
                                        <th
                                          key={month.id}
                                          className={cx(
                                            classes['month-budget-column'],
                                            getClassByMonthId(month.id)
                                          )}
                                        >
                                          {month.name}
                                        </th>
                                      ))}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.channelMonthsBudgets.map(
                                      (channel, index) => {
                                        const id =
                                          channel.channel && channel.channel.id;
                                        const type =
                                          channel.channel &&
                                          channel.channel.type;
                                        const warnings =
                                          channelWarnings[id] &&
                                          channelWarnings[id].type === type
                                            ? channelWarnings[id]
                                            : {};
                                        const { trpRestrictions } = warnings;
                                        const trpWarning = trpRestrictions
                                          ? this.getWarningMessage(
                                            trpRestrictions,
                                            'channel'
                                          )
                                          : null;

                                        const row = (
                                          <MonthHover
                                            key={index}
                                            id={`monthsScrollRow${index}`}
                                            dependentRowId={`monthsFixedRow${index}`}
                                            className={cx({
                                              [classes.tableRowWarning]:
                                                channel.channel.isWarning
                                            })}
                                          >
                                            <td className='text-nowrap'>
                                              <span>
                                                {channel.totalTrp !== null
                                                  ? formattedNumber(
                                                    channel.totalTrp,
                                                    1
                                                  )
                                                  : '-'}
                                              </span>
                                            </td>
                                            <td
                                              className={cx('text-nowrap', {
                                                [classes.tableCellWarning]: trpWarning
                                              })}
                                              title={trpWarning || undefined}
                                            >
                                              <span>
                                                {channel.totalTrp !== null
                                                  ? formattedNumber(
                                                    (channel.totalTrp * 100) /
                                                        (totalTvTrp +
                                                          (targetAudience
                                                            ? 0
                                                            : data.internetTrp)), // <-- This is костыль
                                                    1
                                                  )
                                                  : '-'}
                                              </span>
                                            </td>
                                            {months.map(month => (
                                              <td
                                                key={month.id}
                                                className={getClassByMonthId(
                                                  month.id
                                                )}
                                              >
                                                <span>
                                                  {channel.monthsTrp[
                                                    month.id
                                                  ] !== null
                                                    ? formattedNumber(
                                                      channel.monthsTrp[
                                                        month.id
                                                      ],
                                                      1
                                                    )
                                                    : '-'}
                                                </span>
                                              </td>
                                            ))}
                                          </MonthHover>
                                        );

                                        return channel.channel.isWarning ? (
                                          <Tooltip
                                            key={index}
                                            placement='top'
                                            overlay={channel.channel.tooltip}
                                          >
                                            {row}
                                          </Tooltip>
                                        ) : (
                                          row
                                        );
                                      }
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </Tab>

                          <Tab eventKey='GRP30' title={localizedGrp30}>
                            <div className={classes['display-flex']}>
                              <div>
                                <table
                                  className={cx(
                                    'table',
                                    classes.table,
                                    'table-bordered',
                                    classes['centered-cells']
                                  )}
                                >
                                  <thead>
                                    <tr>
                                      <th className='text-nowrap'>
                                        <i className='fa fa-television' />
                                        {' '}
                                        <span>
                                          <LocalizedMessage id='channel' />
                                        </span>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.channelMonthsBudgets.map(
                                      (channel, index) => {
                                        const row = (
                                          <MonthHover
                                            key={index}
                                            id={`monthsFixedRow${index}`}
                                            dependentRowId={`monthsScrollRow${index}`}
                                            className={cx({
                                              [classes.tableRowWarning]:
                                                channel.channel.isWarning
                                            })}
                                          >
                                            <td className='text-nowrap'>
                                              {channel.channel
                                                ? channel.channel.nameWithType
                                                : // : channel.site.name}
                                                'channel.site.name ah shit'}
                                            </td>
                                          </MonthHover>
                                        );

                                        return channel.channel.isWarning ? (
                                          <Tooltip
                                            key={index}
                                            placement='top'
                                            overlay={channel.channel.tooltip}
                                          >
                                            {row}
                                          </Tooltip>
                                        ) : (
                                          row
                                        );
                                      }
                                    )}
                                  </tbody>
                                </table>
                              </div>
                              <div className={classes['right-flex-table']}>
                                <table
                                  className={cx(
                                    'table',
                                    classes.table,
                                    'table-bordered',
                                    classes['centered-cells']
                                  )}
                                >
                                  <thead>
                                    <tr>
                                      <th className='text-nowrap'>
                                        <span>
                                          <LocalizedMessage id='total' />
                                        </span>
                                      </th>
                                      <th className='text-nowrap'>
                                        <i className='fa fa-percent' />
                                        {' '}
                                        <span>
                                          <LocalizedMessage id='total' />
                                        </span>
                                      </th>
                                      {months.map(month => (
                                        <th
                                          key={month.id}
                                          className={cx(
                                            classes['month-budget-column'],
                                            getClassByMonthId(month.id)
                                          )}
                                        >
                                          {month.name}
                                        </th>
                                      ))}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.channelMonthsBudgets.map(
                                      (channel, index) => {
                                        const row = (
                                          <MonthHover
                                            key={index}
                                            id={`monthsScrollRow${index}`}
                                            dependentRowId={`monthsFixedRow${index}`}
                                            className={cx({
                                              [classes.tableRowWarning]:
                                                channel.channel.isWarning
                                            })}
                                          >
                                            <td className='text-nowrap'>
                                              <span>
                                                {channel.totalGrp30 !== null
                                                  ? formattedNumber(
                                                    channel.totalGrp30,
                                                    1
                                                  )
                                                  : '-'}
                                              </span>
                                            </td>
                                            <td className='text-nowrap'>
                                              <span>
                                                {channel.totalGrp30 !== null
                                                  ? formattedNumber(
                                                    (channel.totalGrp30 *
                                                        100) /
                                                        data.tvGrp30,
                                                    1
                                                  )
                                                  : '-'}
                                              </span>
                                            </td>
                                            {months.map(month => (
                                              <td
                                                key={month.id}
                                                className={getClassByMonthId(
                                                  month.id
                                                )}
                                              >
                                                <span>
                                                  {channel.monthsGrp30[
                                                    month.id
                                                  ] !== null
                                                    ? formattedNumber(
                                                      channel.monthsGrp30[
                                                        month.id
                                                      ],
                                                      1
                                                    )
                                                    : '-'}
                                                </span>
                                              </td>
                                            ))}
                                          </MonthHover>
                                        );

                                        return channel.channel.isWarning ? (
                                          <Tooltip
                                            key={index}
                                            placement='top'
                                            overlay={channel.channel.tooltip}
                                          >
                                            {row}
                                          </Tooltip>
                                        ) : (
                                          row
                                        );
                                      }
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </Tab>

                          <Tab eventKey='QUALITY' title={localizedQuality}>
                            <DealCalculationQualityTab
                              months={months}
                              channelMonthsBudgets={data.channelMonthsBudgets}
                              getClassByMonthId={getClassByMonthId}
                              channelRestrictionWarnings={channelWarnings}
                              getWarningMessage={this.getWarningMessage}
                            />
                          </Tab>

                          <Tab
                            eventKey='BUDGET_BY_MONTHS_AND_TA'
                            title={localizedBudgetByMonthsAndTa}
                          >
                            <div>
                              <table
                                className={cx(
                                  'table',
                                  classes.table,
                                  'table-striped',
                                  classes['centered-cells']
                                )}
                              >
                                <thead>
                                  <tr>
                                    <th rowSpan={2} className='text-nowrap'>
                                      <i className='fa fa-calendar' />
                                      {' '}
                                      <span>
                                        <LocalizedMessage id='month' />
                                      </span>
                                    </th>
                                    <th
                                      colSpan={
                                        data.targetAudiencesMonthsBudgets.length
                                      }
                                      className='text-center'
                                    >
                                      <i className='fa fa-rub' />
                                      {' '}
                                      <span>
                                        <LocalizedMessage id='budget' />
                                      </span>
                                    </th>
                                  </tr>
                                  <tr>
                                    {data.targetAudiencesMonthsBudgets.map(
                                      (audience, index) => (
                                        <th key={index} className='text-center'>
                                          {audience.name}
                                        </th>
                                      )
                                    )}
                                  </tr>
                                </thead>
                                <tbody>
                                  {months.map(month => (
                                    <tr key={month.id}>
                                      <td>{month.name}</td>
                                      {data.targetAudiencesMonthsBudgets.map(
                                        (audience, index) => (
                                          <td
                                            key={index}
                                            className='text-center'
                                          >
                                            {formattedNumber(
                                              audience.months[month.id],
                                              2
                                            )}
                                          </td>
                                        )
                                      )}
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </Tab>

                          <Tab
                            eventKey='PROGRAMMING'
                            title={localizedProgramming}
                          >
                            <div>
                              <table
                                className={cx(
                                  'table',
                                  classes.table,
                                  'table-hover',
                                  classes['centered-cells']
                                )}
                              >
                                <thead>
                                  <tr>
                                    <th>Position Type</th>
                                    <th>
                                      <LocalizedMessage id='channel' />
                                    </th>
                                    <th>
                                      <LocalizedMessage id='week' />
                                    </th>
                                    <th>Prime/Off-prime</th>
                                    <th>
                                      <LocalizedMessage id='timeStart' />
                                    </th>
                                    <th>
                                      <LocalizedMessage id='timeFinish' />
                                    </th>
                                    <th>
                                      <LocalizedMessage id='movieDuration' />
                                    </th>
                                    <th>
                                      <LocalizedMessage id='category' />
                                    </th>
                                    <th>
                                      <LocalizedMessage id='trp' />
                                    </th>
                                    <th>
                                      <LocalizedMessage id='wGRP' />
                                    </th>
                                    <th>
                                      <LocalizedMessage id='budget' />
                                    </th>
                                    <th>
                                      <LocalizedMessage id='price' />
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {data.programming.map((row, index) => (!showChannelsWithZeroQFields &&
                                    (!row.trp ||
                                      !row.grp ||
                                      !row.budget) ? null : (
                                        <tr key={index}>
                                          <td>{row['fix/float'].name}</td>
                                          <td>{row.channel.name}</td>
                                          <td
                                            className={cx({
                                              'text-warning':
                                              row.week === 'workday'
                                            })}
                                          >
                                            {row.week}
                                          </td>
                                          <td>{row['prime/off-prime'].name}</td>
                                          <td>{row.from}</td>
                                          <td>{row.to}</td>
                                          <td>{movieDuration}</td>
                                          <td>{row.programCategory}</td>
                                          <td>
                                            {typeof row.trp === 'number'
                                              ? formattedNumber(row.trp, 2)
                                              : '-'}
                                          </td>
                                          <td>
                                            {typeof row.grp === 'number'
                                              ? formattedNumber(row.grp, 2)
                                              : '-'}
                                          </td>
                                          <td className='text-nowrap'>
                                            {typeof row.budget === 'number'
                                              ? formattedNumber(row.budget, 2)
                                              : '-'}
                                          </td>
                                          <td className='text-nowrap'>
                                            {typeof row.price === 'number'
                                              ? formattedNumber(row.price, 2)
                                              : '-'}
                                          </td>
                                        </tr>
                                    ))
                                  )}
                                  {Object.keys(groupWarnings).length ? (
                                    <tr className={classes.tableRowError}>
                                      <td colSpan={12}>
                                        <LocalizedMessage id='annualPlan.dealCalculation.group-restrictions-not-met' />
                                        :&nbsp;
                                        {Object.keys(groupWarnings).join(', ')}
                                      </td>
                                    </tr>
                                  ) : null}
                                </tbody>
                              </table>
                            </div>
                          </Tab>
                        </Tabs>
                      )}
                    </LocalizedMessage>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TVDealCalculation;
