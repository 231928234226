import FilterItems from 'helpers/filterItems';
import Client from '../release/HTTPClient';

const strategicMediaplanningsAPI = {
  list (request) {
    return Client.post('/api/strategicMediaplannings/', request);
  },

  getMediaplansWithItemsByAdvertiserIdAndPeriod () {
    return Client.get('/api/strategicMediaplannings/mediaplans');
  },

  async getFilterItems (request) {
    const result = await Client.get('/api/strategicMediaplannings/filter-items', request);

    return FilterItems.getDataAndPostProcess(result);
  },
};

export default strategicMediaplanningsAPI;
