import React, { Component, PropsWithChildren } from 'react';

interface IProps {
  tag: 'tr' | 'td';
  direction: 'left' | 'right';
  dependentIds: string[];
  className?: string;
  id?: string;
  rowSpan?: number;
  colSpan?: number;
}
class TrTdHover extends Component<
PropsWithChildren<IProps>,
{}
> {
  static defaultProps = {
    direction: 'left'
  };

  _element: HTMLElement | null = null;

  leftTableColor = '#fff';

  leftTableHoverColor = '#f5f5f5';

  rightTableColors = [
    '#d9edf7',
    '#d9edf7',
    '#d9edf7',
    '#fcf8e3',
    '#fcf8e3',
    '#fcf8e3',
    '#f5f5f5',
    '#f5f5f5',
    '#f5f5f5',
    '#dff0d8',
    '#dff0d8',
    '#dff0d8'
  ];

  rightTableHoverColors = [
    '#c4e3f3',
    '#c4e3f3',
    '#c4e3f3',
    '#faf2cc',
    '#faf2cc',
    '#faf2cc',
    '#e8e8e8',
    '#e8e8e8',
    '#e8e8e8',
    '#d0e9c6',
    '#d0e9c6',
    '#d0e9c6'
  ];

  componentDidMount () {
    if (!this._element) {
      return;
    }

    this._element.addEventListener('mouseenter', this.onMouseEnter);
    this._element.addEventListener('mouseleave', this.onMouseLeave);
  }

  componentWillUnmount () {
    if (!this._element) {
      return;
    }

    this._element.removeEventListener('mouseenter', this.onMouseEnter);
    this._element.removeEventListener('mouseleave', this.onMouseLeave);
  }

  setTdRef = ref => {
    this._element = ref;
  };

  onMouseEnter = () => {
    const { direction, dependentIds } = this.props;

    const isLeft = direction === 'left';

    const dependentElements: HTMLElement[] = [];
    dependentIds.forEach(id => {
      dependentElements.push(document.getElementById(id)!!);
    });

    if (!this._element) return;
    if (isLeft) {
      this._element.style.backgroundColor = this.leftTableHoverColor;
    } else {
      for (let i = 0; i < this._element.children.length; i++) {
        (this._element.children[
          i
        ] as HTMLElement).style.backgroundColor = this.rightTableHoverColors[i];
      }
    }

    // managing dependent elements
    if (isLeft) {
      dependentElements.forEach(depEl => {
        if (depEl.localName === 'td') {
          depEl.style.backgroundColor = this.leftTableHoverColor;
        } else {
          for (let j = 0; j < depEl.children.length; j++) {
            (depEl.children[
              j
            ] as HTMLElement).style.backgroundColor = this.rightTableHoverColors[
              j
            ];
          }
        }
      });
    } else {
      dependentElements.forEach(depEl => {
        if (depEl.localName === 'td') {
          depEl.style.backgroundColor = this.leftTableHoverColor;
        } else {
          for (let j = 0; j < depEl.children.length; j++) {
            (depEl.children[
              j
            ] as HTMLElement).style.backgroundColor = this.leftTableHoverColor;
          }
        }
      });
    }
  };

  onMouseLeave = () => {
    const { direction, dependentIds } = this.props;

    const isLeft = direction === 'left';

    const dependentElements: HTMLElement[] = [];
    dependentIds.forEach(id => {
      dependentElements.push(document.getElementById(id)!!);
    });

    if (!this._element) return;
    if (isLeft) {
      this._element.style.backgroundColor = this.leftTableColor;
    } else {
      for (let i = 0; i < this._element.children.length; i++) {
        (this._element.children[
          i
        ] as HTMLElement).style.backgroundColor = this.rightTableColors[i];
      }
    }

    // managing dependent elements
    if (isLeft) {
      dependentElements.forEach(depEl => {
        if (depEl.localName === 'td') {
          depEl.style.backgroundColor = this.leftTableColor;
        } else {
          for (let j = 0; j < depEl.children.length; j++) {
            (depEl.children[
              j
            ] as HTMLElement).style.backgroundColor = this.rightTableColors[j];
          }
        }
      });
    } else {
      dependentElements.forEach(depEl => {
        if (depEl.localName === 'td') {
          depEl.style.backgroundColor = this.leftTableColor;
        } else {
          for (let j = 0; j < depEl.children.length; j++) {
            (depEl.children[
              j
            ] as HTMLElement).style.backgroundColor = this.leftTableColor;
          }
        }
      });
    }
  };

  render () {
    const { children, tag, direction, dependentIds, ...props } = this.props;

    return (
      <this.props.tag ref={this.setTdRef} {...props}>
        {children}
      </this.props.tag>
    );
  }
}

export default TrTdHover;
