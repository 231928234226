import Client from '../release/HTTPClient';
import { fetchResponse } from './helpers/fakeFetch';

import sitesList from './data/sitesList.json';
import sitesFilterItems from './data/sitesFilterItems.json';

const sitesAPI = {
  list (request) {
    console.log('api.sites.list(', request, ')');

    return fetchResponse(1000, sitesList);
  },

  programmatic () {
    console.log('api.sites.programmatic()');

    return Client.get('/api/sites/programmatic/');
  },

  get (id) {
    console.log('api.sites.get(', id, ')');

    return Client.get(`/api/sites/${id}`);
  },

  getFilterItems (request) {
    console.log('api.sites.getFilterItems(', request, ')');

    return fetchResponse(1000, sitesFilterItems);
  },
};

export default sitesAPI;
