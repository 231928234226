import { fetchResponse } from './helpers/fakeFetch';
import adPlatformsList from './data/adPlatformsList.json';

const adPlatformsAPI = {
  list (request) {
    console.log('api.adPlatforms.list(', request, ')');

    return fetchResponse(1000, adPlatformsList);
  },

  create (request) {
    return fetchResponse(1000, {});
  },

  get (id) {
    return fetchResponse(1000, {});
  },

  update (id) {
    return fetchResponse(1000, {});
  },
};

export default adPlatformsAPI;
