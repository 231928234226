import FilterItems, { IProcessedFilterItem } from 'helpers/filterItems';
import Client from 'api/release/HTTPClient';
import { IAdvertiser, IGenericListRequest, IMediaplanTemplate, IServerPage } from 'types';

const advertisersAPI = {
  list (request: IGenericListRequest): Promise<IServerPage<IAdvertiser>> {
    return Client.post('/api/clients/', request);
  },

  get (id: number): Promise<IAdvertiser> {
    return Client.get(`/api/clients/${id}`);
  },

  getWhiteList (id: number): Promise<number[]> {
    return Client.get(`/api/clients/${id}/whiteList`);
  },

  getTemplates (id: number): Promise<IMediaplanTemplate[]> {
    return Client.get(`/api/mediaplans/templates/clients/${id}`);
  },

  saveWhiteList (id: number, data): Promise<number[]> {
    return Client.post(`/api/clients/${id}/whiteList`, data);
  },

  saveOrUpdateTemplate (data, config): Promise<void> {
    return Client.post('/api/mediaplans/templates/', data, config);
  },

  async getFilterItems (agencyId): Promise<IProcessedFilterItem[]> {
    const result = await Client.get(`/api/clients/filter-items?agencyKeycloakId=${agencyId}`);

    return FilterItems.getDataAndPostProcess(result);
  }
};

export default advertisersAPI;
