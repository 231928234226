import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Main from 'routes/Main/components/Main';

// noinspection JSUnusedGlobalSymbols
const mapDispatchToProps = {
};

const mapStateToProps = (state) => ({
  profile: state.auth.profile,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main));
