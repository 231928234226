import Client from 'api/release/HTTPClient';
// not used
const divisionsAPI = {
  list (request) {
    return Client.post('/api/divisions/', request);
  },

  get (id: number, request) {
    return Client.get(`/api/divisions/${id}`, request);
  },
};

export default divisionsAPI;
