import React, { PureComponent } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import LocalizedMessage, { localizeMessage } from 'components/LocalizedMessage';
import URLFiltersHoc, { Props } from 'components/URLFiltersHoc';

import { Checkbox } from 'react-ui-icheck';
import Breadcrumbs from 'components/Breadcrumbs';
import FilterList from 'components/FilterList';
import TableList from 'components/TableList';

import API from 'api';
import Alert from 'helpers/alert';

interface IState {
  advertiser: any | null;
  whiteList: any[];
  filterItems: any[],
  dataList: any[],
  dataListTotal: number;
  isLoading: boolean;
}

interface IOwnProps {
  match: { params: any };
}

class AdvertiserWhiteList extends PureComponent<Props & IOwnProps, IState> {
  state: IState = {
    advertiser: null,
    whiteList: [],
    filterItems: [],
    dataList: [],
    dataListTotal: 1,
    isLoading: false,
  };

  mounted = false;
  refreshListTimer: any = 0;
  refreshListTimerDelay = 500;

  componentDidMount () {
    this.mounted = true;
    this.loadFilters();
    this.refreshList();
  }

  componentDidUpdate (prevProps) {
    if (prevProps.filters !== this.props.filters) {
      this.refreshList();
    }
  }

  componentWillUnmount () {
    this.mounted = false;
    clearTimeout(this.refreshListTimer);
  }

  async loadFilters () {
    const {
      match: {
        params,
      },
    } = this.props;

    if (!params || !params.id) {
      return;
    }

    this.setState({
      isLoading: true,
    });

    try {
      const [advertiser, whiteList, filterItems] = await Promise.all([
        API.advertisers.get(params.id),
        API.advertisers.getWhiteList(params.id),
        API.sites.getFilterItems(),
      ]);

      if (!this.mounted) {
        return;
      }

      this.setState({
        advertiser,
        whiteList,
        filterItems,
        isLoading: false,
      });
    } catch (error) {
      console.error(error);

      Alert.error(
        localizeMessage({ id: 'errors.errorLoadingData' }),
      );

      this.setState({
        isLoading: false,
      });
    }
  }

  refreshList () {
    clearTimeout(this.refreshListTimer);
    const { currentPage, currentSort, max, items } = this.props.filters;
    const dataListRequest = {
      page: currentPage,
      order: currentSort,
      max,
      filter: { items },
    };

    this.refreshListTimer = setTimeout(async () => {
      this.setState({ isLoading: true });

      const dataList = await API.sites.list(dataListRequest);

      if (!this.mounted) {
        return;
      }

      this.setState({
        dataList: dataList.items,
        dataListTotal: dataList.total,
        isLoading: false,
      });
    }, this.refreshListTimerDelay);
  }

  getBreadcrumbs () {
    const {
      match: {
        params,
      },
    } = this.props;

    return [
      {
        title: <LocalizedMessage id='home' />,
        link: '/app',
      },
      {
        title: <LocalizedMessage id='advertisers' />,
        link: '/app/dictionaries/advertisers',
      },
      {
        title: <LocalizedMessage id='advertiser-detail' />,
        link: `/app/dictionaries/advertisers/${params.id}`,
      },
      {
        title: <LocalizedMessage id='advertiser-white-list' />,
      },
    ];
  }

  handleCheckboxChange = (id) => {
    const { whiteList } = this.state;

    if (whiteList.indexOf(id) > -1) {
      whiteList.splice(whiteList.indexOf(id), 1);

      this.setState({
        whiteList,
      });
    } else {
      this.setState({
        whiteList: [
          ...whiteList,
          id,
        ].sort((a, b) => b - a),
      });
    }
  };

  saveWhiteList = async () => {
    const {
      match: {
        params,
      },
      history,
    } = this.props;
    const { whiteList } = this.state;

    try {
      await API.advertisers.saveWhiteList(params.id, whiteList);

      Alert.success(
        localizeMessage({ id: 'advertiser-white-list.alerts.update-successfully' }),
      );

      history.push(`/app/dictionaries/advertisers/${params.id}`);
    } catch (error) {
      console.error(error);

      Alert.success(
        localizeMessage({ id: 'advertiser-white-list.alerts.saving-error' }),
      );
    }
  };

  render () {
    const {
      match: {
        params,
      },
      onUpdateSort, onUpdatePage, filters, onChangeFilterItems,
    } = this.props;
    const {
      advertiser,
      filterItems,
      dataList,
      dataListTotal,
      whiteList,
      isLoading,
    } = this.state;

    return (
      <>
        <LocalizedMessage
          id='site.title.dictionaries.advertiser-white-list'
          values={{
            advertiserName: advertiser !== null ? advertiser.name : '',
          }}
        >
          {localizedMessage => (
            <>
              <Helmet
                title={localizedMessage}
              />
              <Breadcrumbs
                title={localizedMessage}
                data={this.getBreadcrumbs()}
              />
            </>
          )}
        </LocalizedMessage>

        <div className='wrapper wrapper-content'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='ibox float-e-margins'>
                <div className='ibox-content'>
                  <div className='row'>
                    <div className='col-sm-12 m-b-sm d-flex'>
                      <div className='flex-grow-1 m-r'>
                        <FilterList
                          placeholder={<LocalizedMessage id='advertiser-white-list.filter.placeholder' />}
                          options={filterItems}
                          onChange={onChangeFilterItems}
                          value={filters.items}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='table-responsive'>
                    <LocalizedMessage id={['name', 'url', 'white-list']}>
                      {(localizedName, localizedUrl, localizedWhiteList) => (
                        <TableList
                          currentPage={filters.currentPage}
                          currentSort={filters.currentSort}
                          totalItems={dataListTotal}
                          itemsCountPerPage={filters.max}
                          onUpdateSort={onUpdateSort}
                          onUpdatePage={onUpdatePage}
                          isLoading={isLoading}
                          head={[
                            {
                              label: localizedName,
                              sort: 'name',
                            },
                            {
                              label: localizedUrl,
                              sort: 'url',
                            },
                            {
                              label: localizedWhiteList,
                              props: {
                                className: 'text-center',
                              },
                            },
                          ]}
                        >
                          {
                            dataList.map((row, rowIndex) => (
                              <tr key={`${rowIndex}_${row.name}`}>
                                <td>
                                  <span>{row.name}</span>
                                </td>
                                <td>
                                  <span>{row.url}</span>
                                </td>
                                <td
                                  className='text-center'
                                  style={{ width: '120px' }}
                                >
                                  <label className='checkbox-inline'>
                                    <Checkbox
                                      checkboxClass='icheckbox_square-green'
                                      name={`enabledWhiteList-${row.id}`}
                                      onChange={() => this.handleCheckboxChange(row.id)}
                                      checked={whiteList.indexOf(row.id) > -1}
                                    />
                                  </label>
                                </td>
                              </tr>
                            ))
                          }
                        </TableList>
                      )}
                    </LocalizedMessage>
                  </div>

                  <div className='hr-line-dashed' />

                  <div className='form-group'>
                    <Link
                      className='btn btn-white'
                      to={`/app/dictionaries/advertisers/${params.id}`}
                    >
                      <span><LocalizedMessage id='cancel' /></span>
                    </Link>
                    &nbsp;&nbsp;
                    <button
                      type='submit'
                      className='btn btn-primary'
                      onClick={this.saveWhiteList}
                    >
                      <>
                        <i className='fa fa-chevron-down fa-lg' />
                        <span><LocalizedMessage id='save' /></span>
                      </>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default URLFiltersHoc(AdvertiserWhiteList);
