import { fetchResponse } from './helpers/fakeFetch';
import campaignSites from './data/campaignSites.json';

const campaignSitesAPI = {
  list () {
    console.log('api.campaignSites.list()');

    return fetchResponse(1000, campaignSites);
  },
};

export default campaignSitesAPI;
