import { fetchResponse } from './helpers/fakeFetch';

import personaOlapSettingsList from './data/personaOlapSettingsList.json';

const personaOlapSettingsAPI = {
  list (request) {
    console.log('api.personaOlapSettings.list(', request, ')');

    return fetchResponse(1000, personaOlapSettingsList);
  },
};

export default personaOlapSettingsAPI;
