import React from 'react';
import { Switch, Route } from 'react-router-dom';
import PrivateRoute from 'components/PrivateRoute';
import Auth from 'routes/Auth';
import Sync from 'routes/Settings/Sync';
import AnnualPlans from 'routes/AnnualPlans';
import AnnualPlan from 'routes/AnnualPlan';
import AnnualPlanEdit from './AnnualPlanEdit';
import AnnualBudgets from './AnnualBudgets';
import AnnualBudget from './AnnualBudget';
import Goals from 'routes/Goals';
import Goal from 'routes/Goal';
import Commitments from 'routes/Commitments';
import Commitment from 'routes/Commitment';
import MediaplanTemplates from 'routes/MediaplanTemplates';
import AdFormats from 'routes/Dictionaries/AdFormats';
import Advertisers from 'routes/Dictionaries/Advertisers';
import Advertiser from 'routes/Dictionaries/Advertiser';
import AdvertiserWhiteList from 'routes/Dictionaries/AdvertiserWhiteList';
import Agencies from 'routes/Dictionaries/Agencies';
import Brands from 'routes/Dictionaries/Brands';
import Channels from 'routes/Dictionaries/Channels';
import CommunicationChannels from 'routes/Dictionaries/CommunicationChannels';
import Sites from 'routes/Dictionaries/Sites';
import Targetings from 'routes/Dictionaries/Targetings';
import TargetAudiences from 'routes/Dictionaries/TargetAudiences';
import UnitTypes from 'routes/Dictionaries/UnitTypes';
import Main from 'routes/Main';
import NotFound from 'routes/NotFound';
import PricingUpload from 'routes/PricingUpload';

export const Routes = () => (
  <Switch>
    <Route path='/app/auth' component={Auth} />
    <PrivateRoute
      exact
      roles={['SUPER_ADMIN', 'ADMIN', 'PLANNER', 'BUYER']}
      path='/app/annualPlans'
      component={AnnualPlans}
    />
    <PrivateRoute
      exact
      roles={['SUPER_ADMIN', 'ADMIN', 'PLANNER', 'BUYER']}
      path='/app/annualPlans/create/:type'
      component={AnnualPlanEdit}
    />
    <PrivateRoute
      exact
      roles={['SUPER_ADMIN', 'ADMIN', 'PLANNER', 'BUYER']}
      path='/app/annualPlans/:id'
      component={AnnualPlan}
    />
    <PrivateRoute
      exact
      roles={['SUPER_ADMIN', 'ADMIN', 'PLANNER', 'BUYER']}
      path='/app/annualPlans/:id/edit'
      component={AnnualPlanEdit}
    />
    <PrivateRoute
      exact
      roles={['SUPER_ADMIN', 'ADMIN']}
      path='/app/annualBudgets'
      component={AnnualBudgets}
    />
    <PrivateRoute
      exact
      roles={['SUPER_ADMIN', 'ADMIN']}
      path='/app/annualBudgets/create'
      component={AnnualBudget}
    />
    <PrivateRoute
      exact
      roles={['SUPER_ADMIN', 'ADMIN']}
      path='/app/annualBudgets/:id'
      component={AnnualBudget}
    />
    <PrivateRoute
      exact
      roles={['SUPER_ADMIN']}
      path='/app/goals'
      component={Goals}
    />
    <PrivateRoute
      exact
      roles={['SUPER_ADMIN']}
      path='/app/goals/create'
      component={Goal}
    />
    <PrivateRoute
      exact
      roles={['SUPER_ADMIN']}
      path='/app/goals/:id'
      component={Goal}
    />
    <PrivateRoute
      exact
      roles={['SUPER_ADMIN', 'ADMIN']}
      path='/app/commitments'
      component={Commitments}
    />
    <PrivateRoute
      exact
      roles={['SUPER_ADMIN', 'ADMIN']}
      path='/app/commitments/create'
      component={Commitment}
    />
    <PrivateRoute
      exact
      roles={['SUPER_ADMIN', 'ADMIN']}
      path='/app/commitments/:id'
      component={Commitment}
    />
    <PrivateRoute
      exact
      roles={['SUPER_ADMIN']}
      path='/app/mediaplanTemplates'
      component={MediaplanTemplates}
    />
    <PrivateRoute
      exact
      roles={['SUPER_ADMIN']}
      path='/app/pricing'
      component={PricingUpload}
    />
    <PrivateRoute
      exact
      roles={['SUPER_ADMIN', 'ADMIN']}
      path='/app/settings/sync'
      component={Sync}
    />
    <PrivateRoute
      exact
      roles={['SUPER_ADMIN', 'ADMIN']}
      path='/app/dictionaries/adFormats'
      component={AdFormats}
    />
    <PrivateRoute
      exact
      roles={['SUPER_ADMIN', 'ADMIN']}
      path='/app/dictionaries/advertisers'
      component={Advertisers}
    />
    <PrivateRoute
      exact
      roles={['SUPER_ADMIN', 'ADMIN']}
      path='/app/dictionaries/advertisers/:id'
      component={Advertiser}
    />
    <PrivateRoute
      exact
      roles={['SUPER_ADMIN', 'ADMIN']}
      path='/app/dictionaries/advertisers/:id/whiteList'
      component={AdvertiserWhiteList}
    />
    <PrivateRoute
      exact
      roles={['SUPER_ADMIN']}
      path='/app/dictionaries/agencies'
      component={Agencies}
    />
    <PrivateRoute
      exact
      roles={['SUPER_ADMIN', 'ADMIN']}
      path='/app/dictionaries/brands'
      component={Brands}
    />
    <PrivateRoute
      exact
      roles={['SUPER_ADMIN', 'ADMIN']}
      path='/app/dictionaries/channels'
      component={Channels}
    />
    <PrivateRoute
      exact
      roles={['SUPER_ADMIN', 'ADMIN']}
      path='/app/dictionaries/communicationChannels'
      component={CommunicationChannels}
    />
    <PrivateRoute
      exact
      roles={['SUPER_ADMIN', 'ADMIN']}
      path='/app/dictionaries/sites'
      component={Sites}
    />
    <PrivateRoute
      exact
      roles={['SUPER_ADMIN', 'ADMIN']}
      path='/app/dictionaries/targetings'
      component={Targetings}
    />
    <PrivateRoute
      exact
      roles={['SUPER_ADMIN', 'ADMIN']}
      path='/app/dictionaries/targetAudiences'
      component={TargetAudiences}
    />
    <PrivateRoute
      exact
      roles={['SUPER_ADMIN', 'ADMIN']}
      path='/app/dictionaries/unitTypes'
      component={UnitTypes}
    />
    <PrivateRoute
      exact
      path='/'
      component={Main}
    />
    <PrivateRoute
      exact
      path='/app'
      component={Main}
    />
    <PrivateRoute
      roles={['SUPER_ADMIN', 'ADMIN', 'PLANNER', 'BUYER']}
      component={NotFound}
    />
  </Switch>
);

export default Routes;
