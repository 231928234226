import React, { PureComponent } from 'react';
import cx from 'classnames';

import { IMediaplanBaseParameters } from 'routes/AnnualPlanEdit/types';
import LocalizedMessage from 'components/LocalizedMessage';
import Select from 'components/Select';
import { NumericInput } from 'components/NumericInput';
import { Props as SelectProps } from 'react-select';
import { yearOptions, actualYear } from 'helpers/yearOptions';
import { IOption } from 'types';

interface IProps {
  isShowErrors: boolean;
  form: IMediaplanBaseParameters;
  advertisersOptions: IOption[];
  onUpdateAdvertiser: (advertiser: IOption) => void;
  onUpdateForm: (name: keyof IMediaplanBaseParameters, value: any) => void;
}

class BaseParameters extends PureComponent<IProps> {
  handleNameInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    this.props.onUpdateForm('name', value);
  };

  handleIterationsInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    this.props.onUpdateForm('iterations', Number(value));
  };

  handleChangeAdvertiserSelect = (advertiser: IOption) => {
    this.props.onUpdateForm('advertiser', advertiser);
    this.props.onUpdateAdvertiser(advertiser);
  };

  getSelectAdvertiserConfig (localizedPlaceholder): SelectProps<IOption> {
    const { advertisersOptions, form: { advertiser } } = this.props;

    return {
      isSearchable: false,
      isMulti: false,
      value: advertiser,
      options: advertisersOptions,
      onChange: this.handleChangeAdvertiserSelect,
      placeholder: localizedPlaceholder,
      inputId: 'advertiser',
      dataTestAttr: 'advertiser',
    };
  }

  handleChangeYearSelect = ({ value }: IOption) => {
    this.props.onUpdateForm('year', value);
  };

  getSelectYearConfig = (localizedPlaceholder): SelectProps<IOption> => {
    const { form: { year } } = this.props;

    return {
      isSearchable: false,
      isMulti: false,
      value: (
        yearOptions.find((option) => option.value === year) ||
        {
          value: actualYear,
          label: actualYear.toString()
        }
      ),
      options: yearOptions,
      onChange: this.handleChangeYearSelect,
      placeholder: localizedPlaceholder,
      inputId: 'year',
    };
  };

  render () {
    const {
      form,
      isShowErrors,
    } = this.props;

    return (
      <div className='form-horizontal'>
        <div className='row form-group'>
          <div className='col-lg-6'>
            <div className='row'>
              <LocalizedMessage id='name'>
                {localizedMessage => (
                  <>
                    <label
                      className='col-lg-5 control-label'
                      htmlFor='name'
                    >
                      {localizedMessage}
                    </label>
                    <div className='col-lg-7'>
                      <input
                        id='name'
                        type='text'
                        name='name'
                        placeholder={localizedMessage}
                        className={cx(
                          'form-control',
                          {
                            error: isShowErrors && !form.name,
                          },
                        )}
                        maxLength={256}
                        value={form.name}
                        onChange={this.handleNameInputChange}
                        required
                      />
                    </div>
                  </>
                )}
              </LocalizedMessage>
            </div>
            {
              isShowErrors && !form.name ? (
                <div className='row'>
                  <div className='col-lg-5' />
                  <div className='col-lg-7'>
                    <small className='text-danger'>
                      <LocalizedMessage id='annualPlanEdit.errors.nameIsMissing' />
                    </small>
                  </div>
                </div>
              )
                : null
            }
          </div>
          <div className='col-lg-6'>
            <div className='row'>
              <LocalizedMessage id='advertiser'>
                {localizedMessage => (
                  <>
                    <label
                      className='col-lg-5 control-label'
                      htmlFor='advertiser'
                    >
                      {localizedMessage}
                    </label>
                    <div className='col-lg-7'>
                      <Select<IOption>
                        {...this.getSelectAdvertiserConfig(localizedMessage)}
                        onChange={this.handleChangeAdvertiserSelect}
                        className={cx(
                          { error: isShowErrors && !form.advertiser },
                        )}
                      />
                    </div>
                  </>
                )}
              </LocalizedMessage>
            </div>
            {
              isShowErrors && !form.advertiser ? (
                <div className='row'>
                  <div className='col-lg-5' />
                  <div className='col-lg-7'>
                    <small className='text-danger'>
                      <LocalizedMessage id='annualPlanEdit.errors.advertiserIsMissing' />
                    </small>
                  </div>
                </div>
              )
                : null
            }
          </div>
        </div>

        <div className='row'>
          <div className='col-lg-6'>
            <div className='row'>
              <LocalizedMessage
                id={['annualPlanEdit.form.numberOfIterations', 'annualPlanEdit.form.numberOfIterations.description']}
              >
                {(localizedMessage, tooltipMessage) => (
                  <>
                    <label className='col-lg-5 control-label' htmlFor='iterations'>
                      <span>{localizedMessage}</span>
                      {' '}
                      <i className='glyphicon glyphicon-info-sign' title={tooltipMessage} />
                    </label>
                    <div className='col-lg-7'>
                      <NumericInput
                        id='iterations'
                        name='iterations'
                        placeholder={localizedMessage}
                        className={cx(
                          'form-control',
                          {
                            error: isShowErrors && !form.iterations,
                          },
                        )}
                        min={1}
                        max={1000000}
                        value={form.iterations}
                        onChange={this.handleIterationsInputChange}
                        required
                      />
                    </div>
                  </>
                )}
              </LocalizedMessage>
            </div>
            {
              isShowErrors && !form.iterations ? (
                <div className='row'>
                  <div className='col-lg-5' />
                  <div className='col-lg-7'>
                    <small className='text-danger'>
                      <LocalizedMessage id='annualPlanEdit.errors.iterationsIsMissing' />
                    </small>
                  </div>
                </div>
              )
                : null
            }
          </div>
          <div className='col-lg-6'>
            <div className='row'>
              <label className='col-lg-5 control-label' htmlFor='year'>
                Year
              </label>
              <div className='col-lg-7'>
                <LocalizedMessage id='year'>
                  {localizedMessage => (
                    <Select<IOption>
                      {...this.getSelectYearConfig(localizedMessage)}
                    />
                  )}
                </LocalizedMessage>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BaseParameters;
