import Client from 'api/release/HTTPClient';
import { AdPlatformUpdateCreateRequest, IAdPlatform, IGenericListRequest, IServerPage } from 'types';

const adPlatformsAPI = {
  list (request: IGenericListRequest): Promise<IServerPage<IAdPlatform>> {
    return Client.post('/api/adPlatforms/', request);
  },

  create (request: AdPlatformUpdateCreateRequest): Promise<IAdPlatform> {
    return Client.post('/api/adPlatforms/new', request);
  },

  get (id: number): Promise<IAdPlatform> {
    return Client.get(`/api/adPlatforms/${id}`);
  },

  update (id: number, request: AdPlatformUpdateCreateRequest): Promise<IAdPlatform> {
    return Client.put(`/api/adPlatforms/${id}`, request);
  },
};

export default adPlatformsAPI;
