const { origin } = location;

export const __DEV__ = origin.includes('localhost');

const Config = {
  api: {
    host: __DEV__ ? process.env.REACT_APP_API_URL : `${origin}/backend-api`,
    authHost: __DEV__
      ? (process.env.REACT_APP_API_AUTH_URL || process.env.REACT_APP_API_URL)
      : `${origin}/backend-api`,
    headers: {
      // 'Pragma': 'no-cache',
      // 'Cache-control': 'no-cache'
    },
  },
  appOptions: {
  },
  services: {
    yandexMetrika: {
      account: process.env.REACT_APP_YANDEX_METRIKA_ACCOUNT,
      options: {
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true,
      },
    },
  },
};

export default Config;
