import Client from 'api/release/HTTPClient';
import { IGenericListRequest, IGoal, IServerPage } from 'types';

const goalsAPI = {
  list (request: IGenericListRequest): Promise<IServerPage<IGoal>> {
    return Client.post('/api/goals/', request);
  },

  get (id: number): Promise<IGoal> {
    return Client.get(`/api/goals/${id}`);
  },

  save (id: number, data): Promise<IGoal> {
    if (!id) {
      return Client.post('/api/goals/new', data);
    }

    return Client.put(`/api/goals/${id}`, data);
  }
};

export default goalsAPI;
