import React, { PureComponent } from 'react';
import { Bar } from 'react-chartjs-2';
import cx from 'classnames';
import LocalizedMessage from 'components/LocalizedMessage';
import { formattedNumberWithZero, getMonthNames } from 'helpers/utils';
import { IBrandBudget } from 'routes/AnnualPlanEdit/types';

import classes from '../../AnnualPlanEdit.module.scss';

interface IProps {
  monthsBudgetBarChartData: number[][];
  monthsBudgetBarChartSeries: string[];
  brandsBudget: IBrandBudget[];
}

const monthsBudgetBarChartLabels = getMonthNames();

class CalculatedValues extends PureComponent<IProps> {
  render () {
    const { monthsBudgetBarChartData, monthsBudgetBarChartSeries, brandsBudget } = this.props;

    return (
      <div>
        <div className='row'>
          <div className='col-lg-12'>
            <LocalizedMessage id='budgets'>
              {localizedMessage => (
                <Bar
                  data={{
                    datasets: monthsBudgetBarChartData.map((data, index) => ({
                      label: monthsBudgetBarChartSeries[index],
                      data,
                    })),
                    labels: monthsBudgetBarChartLabels,
                  }}
                  options={{
                    responsive: true,
                    legend: { display: true },
                    maintainAspectRatio: false,
                    title: {
                      display: true,
                      text: localizedMessage,
                      position: 'top',
                    },
                    scales: {
                      yAxes: [{
                        ticks: { beginAtZero: true },
                      }],
                    },
                  }}
                  height={200}
                />
              )}
            </LocalizedMessage>
          </div>
        </div>
        <div className='hr-line-dashed' />
        <div className='table-responsive'>
          <table
            className={cx(
              'table',
              classes.table,
              'c-smart-table',
              classes['centered-cells'],
            )}
          >
            <thead>
              <tr>
                <th colSpan={1} className='text-nowrap'>
                  <i className='fa fa-flag' />
                  {' '}
                  <span><LocalizedMessage id='brand' /></span>
                </th>
                {monthsBudgetBarChartLabels.map((monthName, index) => (
                  <th
                    key={monthName}
                    className={cx(
                      { active: index < 3 || (index >= 6 && index < 9) },
                    )}
                  >
                    {monthName}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {brandsBudget.map(row => (
                <tr key={row.brand.id}>
                  <td rowSpan={1} className='table-cell-vertical-align-middle'>
                    {row.brand.name}
                  </td>
                  {monthsBudgetBarChartLabels.map((monthName, index) => (
                    <td
                      key={monthName}
                      className={cx(
                        { active: index < 3 || (index >= 6 && index < 9) },
                      )}
                    >
                      {formattedNumberWithZero(row.budget[index])}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default CalculatedValues;
