import auth from './auth';
import adFormats from './adFormats';
import adPlatforms from './adPlatforms';
import advertisers from './advertisers';
import agencies from './agencies';
import annualBudgets from './annualBudgets';
import annualPlans from './annualPlans';
import brands from './brands';
import calculationTemplates from './calculationTemplates';
import campaignPriceTemplates from './campaignPriceTemplates';
import campaignSites from './campaignSites';
import channels from './channels';
import commitments from './commitments';
import communicationChannels from './communicationChannels';
import divisions from './divisions';
import goals from './goals';
import mediaplanTemplates from './mediaplanTemplates';
import personaOlapSettings from './personaOlapSettings';
import personaTargetAudience from './personaTargetAudience';
import regions from './regions';
import sellers from './sellers';
import sites from './sites';
import strategicMediaplannings from './strategicMediaplannings';
import sync from './sync';
import targetAudiences from './targetAudiences';
import targetings from './targetings';
import tv from './tv';
import tvPrices from './tvPrices';
import unitTypes from './unitTypes';
import users from './users';

export default {
  auth,
  adFormats,
  adPlatforms,
  advertisers,
  agencies,
  annualBudgets,
  annualPlans,
  brands,
  calculationTemplates,
  campaignPriceTemplates,
  campaignSites,
  channels,
  commitments,
  communicationChannels,
  divisions,
  goals,
  mediaplanTemplates,
  personaOlapSettings,
  personaTargetAudience,
  regions,
  sellers,
  sites,
  strategicMediaplannings,
  sync,
  targetAudiences,
  targetings,
  tv,
  tvPrices,
  unitTypes,
  users,
};
