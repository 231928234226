import Client from 'api/release/HTTPClient';
import { IChannelType } from 'types';


// it's a redirect
const personaTargetAudienceAPI = {
  list (request, channelType: IChannelType) {
    return Client.get(`/api/v2/personaTargetAudience/${channelType}`, request);
  },
};

export default personaTargetAudienceAPI;
