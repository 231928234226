import { fetchResponse } from './helpers/fakeFetch';

import advertisersList from './data/advertisersList.json';
import advertiserDetails from './data/advertiserDetails.json';
import advertiserTemplate from './data/advertiserTemplate.json';

const advertisersAPI = {
  list () {
    console.log('api.advertisers.list()');

    return fetchResponse(1000, advertisersList);
  },

  get (id) {
    console.log('api.advertisers.get(', id, ')');

    return fetchResponse(1000, advertiserDetails);
  },

  getTemplates (id) {
    console.log('api.advertisers.getTemplate(', id, ')');

    return fetchResponse(1000, advertiserTemplate);
  },

  getWhiteList (id) {
    console.log('api.advertisers.getWhiteList(', id, ')');

    return fetchResponse(1000, [834]);
  },

  saveWhiteList (id, data) {
    console.log('api.advertisers.saveWhiteList(', id, ', ', data, ')');

    return fetchResponse(1000, data);
  },

  saveOrUpdateTemplate (data, config) {
    console.log('api.advertisers.saveOrUpdateTemplate(', data, ', ', config, ')');

    return fetchResponse(1000, data);
  },

  getFilterItems (request) {
    console.log('api.advertisers.getFilterItems(', request, ')');

    return fetchResponse(1000, []);
  },
};

export default advertisersAPI;
