import React, { PureComponent } from 'react';
import set from 'lodash/set';
import property from 'lodash/property';
import { HotTable, HotTableProps } from '@handsontable/react';
import moment from 'moment';
import Handsontable from 'handsontable';

import { formatNumberToNumber } from 'helpers/utils';
import LocalizedMessage, { localizeMessage } from 'components/LocalizedMessage';
import { discountDefaultValue } from '../../../../helpers/olv';
import { IPlatformConfig } from 'routes/AnnualPlanEdit/types';
import { RowErrors, Validator } from '../OlvCampaignParams';

export const numberColumnIDs = Array.from({ length: 12 }).map((e, index) => `restrictions.seasonalDiscounts.${index}`);

interface IProps {
  platforms: IPlatformConfig[]
  validate: Validator;
}

class Discounts extends PureComponent<IProps> {
  monthNames = moment.monthsShort();
  cellsErrors: RowErrors[] = [];

  getClassByMonthId = (monthIndex) => {
    switch (monthIndex) {
      case 0:
      case 1:
      case 2:
        return 'c-calendar-winter';
      case 3:
      case 4:
      case 5:
        return 'c-calendar-spring';
      case 6:
      case 7:
      case 8:
        return 'c-calendar-summer';
      case 9:
      case 10:
      case 11:
        return 'c-calendar-autumn';
      default:
        return null;
    }
  };

  generateTableOptions = (): HotTableProps => {
    const { platforms } = this.props;

    return {
      data: platforms,
      colHeaders: true,
      nestedHeaders: [
        [
          localizeMessage({ id: 'site' }),
          ...this.monthNames,
        ],
      ],
      columns: [
        {
          data: 'name',
          readOnly: true,
        },
        ...this.monthNames.map((month, index) => ({
          data: `restrictions.seasonalDiscounts.${index}`,
          type: 'numeric',
          numericFormat: {
            pattern: {
              mantissa: 2,
              trimMantissa: true,
            } as any,
          },
        })),
      ],
      cells: (rowIndex, column, columnID: string) => {
        const cellProperties: any = {
          errorIDs: this.cellsErrors[rowIndex]
            ? this.cellsErrors[rowIndex][columnID] || []
            : [],
        };

        const foundMatch = columnID.match(/restrictions.seasonalDiscounts.([0-9]+)/);
        if (foundMatch) {
          const monthIndex = Number(foundMatch[1]);
          cellProperties.className = this.getClassByMonthId(monthIndex);
        }

        if (property('restrictions.isExcludePlatform')(this.props.platforms[rowIndex])) {
          cellProperties.readOnly = true;
          cellProperties.className = 'htDisabled';
        } else if (numberColumnIDs.includes(columnID)) {
          cellProperties.renderer = this.commentsRenderer;
        }

        if (cellProperties.errorIDs.length) {
          cellProperties.className += ' invalid';
        }

        return cellProperties;
      },
    };
  };

  commentsRenderer = (...args) => {
    // [instance, td, row, col, prop, value, cellProperties]
    const [, td,,,,, cellProperties] = args;
    Handsontable.renderers.NumericRenderer.apply(this, args);
    const msg = cellProperties.errorIDs.map(id => localizeMessage({ id })).join('\n');
    td.setAttribute('title', msg);
  };

  beforeChange = (changes, source) => {
    changes.forEach((change, index) => {
      // [row, columnID, oldValue, newValue]
      const [row,,, newValue] = change;
      if (!this.props.platforms[row]) {
        changes[index] = null;
      }
      if (!newValue?.length) {
        change[3] = discountDefaultValue;
      }
    });
  };

  afterChange = (changes) => {
    if (!changes) {
      return;
    }

    changes.forEach(([row, columnID, oldValue, newValue]) => {
      if (typeof newValue === 'number') {
        set(this.props.platforms[row], columnID, formatNumberToNumber(newValue));
      }
    });
  };

  beforeRender = () => {
    this.cellsErrors = this.props.validate('discounts', numberColumnIDs, this.validateCell);
  };

  validateCell = (row, columnID) => {
    const errorIDs: string[] = [];
    if (numberColumnIDs.includes(columnID)) {
      const stringValue = property<any, string>(columnID)(row);
      const value = parseFloat(stringValue);
      if (Number.isNaN(value) || Number.isNaN(Number(stringValue))) {
        // parseFloat считает строку `123asdas` за число и вторая проверка на isNaN это фиксит
        errorIDs.push('annualPlanEdit.errors.notNumber');
      } else if (value > 100) {
        errorIDs.push('annualPlanEdit.errors.exceed100');
      } else if (value < 0) {
        errorIDs.push('annualPlanEdit.errors.notPositive');
      }
    }

    return errorIDs;
  };

  render () {
    return (
      <>
        <p className='_text-align--center m-t m-b'>
          <LocalizedMessage id='annualPlanEdit.OLVCampaignParams.discounts' />
        </p>

        <HotTable
          {...this.generateTableOptions()}
          tableClassName='table'
          afterChange={this.afterChange}
          beforeChange={this.beforeChange}
          beforeRender={this.beforeRender}
          stretchH='all'
          width='100%'
          height='auto'
          autoWrapRow
          licenseKey='non-commercial-and-evaluation'
        />
      </>
    );
  }
}

export default Discounts;
