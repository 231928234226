import React, { PureComponent } from 'react';
import cx from 'classnames';
import { Tab, Tabs } from 'react-bootstrap';
import Switch from 'react-switchery';

import LocalizedMessage from 'components/LocalizedMessage';
import Select from 'components/Select';
import Restrictions from './Tabs/Restrictions';
import Cpp from './Tabs/Cpp';
import Discounts from './Tabs/Discounts';
import ExtraCharge from './Tabs/ExtraCharge';
import ChannelGroupEditor from './ChannelGroupEditor/ChannelGroupEditor';
import ButtonsGroup from './ButtonsGroup/ButtonsGroup';
import {
  loadTvPricesByChannelAndDistributionAndMonths, makeSelectChannelsToDisplay, makeSelectChannelSource,
  convertToFullChannelConfig
} from '../../../helpers/tv';
import {
  FullChannelType, ChannelType, IdToChannelConfigMap, ITargetAudience,
  IChannel, isRegionalChannel, ICampaign, IChannelGroup, CppData,
} from 'routes/AnnualPlanEdit/types';
import { IOption, isNotNull } from 'types';
import classes from '../../AnnualPlanEdit.module.scss';

interface IProps {
  campaignID: string | number;
  params: IdToChannelConfigMap;
  sellerRestrictions: Record<string, { min: number; max: number }>;
  channelTypes: FullChannelType[];
  restrictionBase: 'BUDGET_GRP' | 'TRP_GRP';


  campaignChannel: ICampaign['channel'];
  allChannels: IChannel[];
  channelGroups: IChannelGroup[];

  year: number;
  retroFrom: string;
  retroTo: string;
  targetAudience?: ITargetAudience;
  isLoadingPrices: boolean;

  onUpdateIsOldRestrictions: (isOld: boolean) => void; // todo add campaignID for check all campaigns
  onUpdateGroupsValidationStatus: (campaignID: string | number, isValid: boolean) => void;
  onUpdateValidationStatus: (campaignID: string | number, isValid: boolean, tab: string) => void;
  onUpdatePricesLoadingStatus: (isLoading: boolean) => void;
  onUpdateCampaign: (campaignID: string | number, update: Partial<ICampaign>) => void;
}

interface IState {
  selectedTab: TabType;
  isShowEmptyLines: boolean;
  channelTypeSelected: ChannelType;
  cpp: CppData | null;
}

type TabType = 'restrictions' | 'cpp' | 'discounts' | 'extraCharge';
type ChannelTypeOption = IOption<ChannelType>;

class TvCampaignParams extends PureComponent<IProps, IState> {
  state: IState = {
    selectedTab: 'restrictions',
    isShowEmptyLines: true,
    channelTypeSelected: 'ALL',
    cpp: null,
  };

  componentDidMount () {
    this.loadData();
  }

  UNSAFE_componentWillReceiveProps (nextProps: IProps) {
    if (nextProps.year && nextProps.year !== this.props.year) {
      this.loadData(nextProps);
    }

    if (nextProps.params && JSON.stringify(nextProps.params) !== JSON.stringify(this.props.params)) {
      if (!this.state.isShowEmptyLines) {
        this.setState({
          isShowEmptyLines: true,
        });
      }
    }
  }

  componentDidUpdate (prevProps: IProps, prevState: IState) {
    if (
      prevProps.campaignID !== this.props.campaignID ||
      prevProps.campaignChannel !== this.props.campaignChannel ||
      prevProps.allChannels !== this.props.allChannels
    ) {
      this.updateChannelsForCpp();
      this.checkIsOldRestrictions();
    }
    if (prevProps.campaignChannel !== this.props.campaignChannel) {
      this.resetChannelTypeSelected();
    }
  }

  loadData = async (actualProps: IProps = this.props) => {
    const { year, onUpdatePricesLoadingStatus } = actualProps;
    onUpdatePricesLoadingStatus(true);
    const cpp = await loadTvPricesByChannelAndDistributionAndMonths(year, true);
    this.setState({
      cpp,
    }, () => {
      this.updateChannelsForCpp();
      onUpdatePricesLoadingStatus(false);
    });
  };

  resetChannelTypeSelected = () => {
    this.setState({ channelTypeSelected: 'ALL' });
  };

  updateChannelsForCpp = () => {
    const { cpp } = this.state;
    if (cpp) {
      const { params, campaignChannel, allChannels, onUpdateCampaign, campaignID } = this.props;
      const source = this.selectChannnelSource(campaignChannel, allChannels);

      const updatedparams = source.reduce((acc, channel) => {
        const prevConfig = params[channel.id];

        return { ...acc, [channel.id]: convertToFullChannelConfig(channel, prevConfig, cpp!) };
      }, {});
      onUpdateCampaign(campaignID, { tv: { byChannel: updatedparams } });
    }
  };

  checkIsOldRestrictions = () => {
    const { params, onUpdateIsOldRestrictions, campaignChannel, allChannels } = this.props;

    let isOldTvRestrictions = false;
    const source = this.selectChannnelSource(campaignChannel, allChannels);

    source.forEach(channel => {
      const currentMediaPlan: any = params[channel.id];

      if (!currentMediaPlan) {
        return;
      }

      if (
        currentMediaPlan && currentMediaPlan.restrictions && (
          currentMediaPlan.restrictions.min ||
          currentMediaPlan.restrictions.max ||
          currentMediaPlan.restrictions.fixOffPrimeMin ||
          currentMediaPlan.restrictions.fixOffPrimeMax ||
          currentMediaPlan.restrictions.floatPrimeMin ||
          currentMediaPlan.restrictions.floatPrimeMax ||
          currentMediaPlan.restrictions.floatOffPrimeMin ||
          currentMediaPlan.restrictions.floatOffPrimeMax
        )
      ) {
        isOldTvRestrictions = true;
      }
    });

    onUpdateIsOldRestrictions(isOldTvRestrictions);
  };

  selectchannelsToDisplay = makeSelectChannelsToDisplay();
  selectChannnelSource = makeSelectChannelSource();

  selectChannelTypeOptions = (): ChannelTypeOption[] => {
    const { campaignChannel, channelTypes } = this.props;

    if (isRegionalChannel(campaignChannel)) {
      return [
        {
          value: 'ALL',
          label: 'Все каналы',
        },
        {
          value: 'GRP',
          label: 'Каналы по GRP (рег. блоки)',
        },
        {
          value: 'MINUTES',
          label: 'Минутные каналы (рег. блоки)',
        },
      ];
    }

    return channelTypes
      .filter(channelType => (
        channelType.name !== 'LOCAL' &&
        channelType.name !== 'REGIONAL'
      ))
      .map((option): ChannelTypeOption => ({
        value: option.name,
        label: option.nameTranslation,
      }));
  };

  updateForChildComponents = () => {
    this.forceUpdate();
  };

  selectTab = (selectedTab: TabType) => {
    this.setState({ selectedTab });
  };

  changeRestrictionBase = (useTRP: boolean) => {
    this.props.onUpdateCampaign(this.props.campaignID, {
      restrictionBase: useTRP ? 'TRP_GRP' : 'BUDGET_GRP'
    });
  };

  handleShowEmptyLinesCheckboxChange = (isShowEmptyLines: boolean) => {
    this.setState({ isShowEmptyLines });
  };

  handleChangeChannelTypeSelect = (option: ChannelTypeOption) => {
    this.setState({ channelTypeSelected: option.value });
  };

  handleUpdateGroupsValidationStatus = (isValid: boolean) => {
    const { campaignID, onUpdateGroupsValidationStatus } = this.props;
    onUpdateGroupsValidationStatus(campaignID, isValid);
  };

  handleUpdateValidationStatus = (isValid: boolean, tab: string) => {
    const { campaignID, onUpdateValidationStatus } = this.props;
    onUpdateValidationStatus(campaignID, isValid, tab);
  };

  getSelectChannelTypesConfig = () => {
    const { channelTypeSelected } = this.state;
    const options = this.selectChannelTypeOptions();
    const value = options.find(option => option.value === channelTypeSelected);

    return {
      isSearchable: false,
      isMulti: false,
      value,
      options,
      onChange: this.handleChangeChannelTypeSelect,
      inputId: 'channelType',
    };
  };

  getSelectedTVChannels = () => {
    const { params, allChannels } = this.props;

    return Object.entries(params)
      .filter(([, channel]) => !channel.restrictions.isExcludedChannel)
      .map(([id]) => {
        const channel = allChannels.find(x => x.id === Number(id));

        if (channel === undefined) return null;

        return {
          id: channel.externalId,
          name: channel.name,
          type: channel.type.name,
        };
      })
      .filter(isNotNull);
  };

  render () {
    const {
      campaignChannel,
      restrictionBase,
      params,
      targetAudience,
      retroFrom,
      retroTo,
      channelGroups,
      isLoadingPrices,
      allChannels,
      sellerRestrictions,
    } = this.props;
    const {
      selectedTab,
      isShowEmptyLines,
      cpp,
      channelTypeSelected,
    } = this.state;

    const useTRPRestrictionBase = restrictionBase === 'TRP_GRP';

    const channelsToDisplay = this.selectchannelsToDisplay(
      campaignChannel, allChannels, channelTypeSelected, isShowEmptyLines, params
    );
    const sourceChannels = this.selectChannnelSource(campaignChannel, allChannels);

    const channelType = isRegionalChannel(campaignChannel)
      ? 'REGIONAL'
      : channelTypeSelected;

    const selectedTVChannels = this.getSelectedTVChannels();

    return (
      <>
        <div className='row'>
          <div className='col-md-12'>
            <div className={cx(
              'form-inline',
              classes['deal-restrictions-under-table-controls'],
            )}
            >
              <div className='form-group'>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <label
                  htmlFor='channelType'
                  className='form-control-static'
                >
                  <LocalizedMessage id='annualPlanEdit.tvCampaignParams.fields.show' />
                </label>
                &nbsp;&nbsp;
                <Select<ChannelTypeOption>
                  {...this.getSelectChannelTypesConfig()}
                  className={classes['deal-restrictions-under-table-controls-select']}
                />
              </div>
            </div>
            <div className={cx(
              'form-inline',
              classes['deal-restrictions-under-table-controls'],
            )}
            >
              <div className='form-group text-nowrap'>
                <label className='form-control-static'>
                  <LocalizedMessage id='annualPlanEdit.tvCampaignParams.fields.showExcludedChannels' />
                </label>
                &nbsp;&nbsp;
                <label className='_cursor--pointer'>
                  <Switch
                    className='js-switch'
                    onChange={this.handleShowEmptyLinesCheckboxChange}
                    options={{ color: '#1AB394' }}
                    checked={isShowEmptyLines}
                  />
                </label>
              </div>
            </div>

            <div className={cx(
              'form-inline',
              classes.trpBudgetSwitch,
            )}
            >
              <div className='form-group text-nowrap'>
                <label className={cx('form-control-static')}>
                  <LocalizedMessage id='annualPlanEdit.restrictionBaseLabel' />
                </label>
                &nbsp;&nbsp;
                <label className={cx('form-control-static',
                  classes.restrictionBaseLeftValue,
                  restrictionBase === 'TRP_GRP' && classes.restrictionValueSecondary)}
                >
                  <LocalizedMessage id='annualPlanEdit.BUDGET_GRP' />
                </label>
                <label className='_cursor--pointer'>
                  <Switch
                    className='js-switch'
                    onChange={this.changeRestrictionBase}
                    options={{ color: '#1AB394' }}
                    checked={useTRPRestrictionBase}
                  />
                </label>
                <label className={cx('form-control-static',
                  classes.restrictionBaseValue,
                  restrictionBase === 'BUDGET_GRP' && classes.restrictionValueSecondary)}
                >
                  <LocalizedMessage id='annualPlanEdit.TRP_GRP' />
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <ChannelGroupEditor
            groups={channelGroups}
            sourceChannels={channelsToDisplay}
            params={params}
            restrictionBase={restrictionBase}
            onUpdateValidationStatus={this.handleUpdateGroupsValidationStatus}
            updateForResrtictions={this.updateForChildComponents}
          />
        </div>
        <div className='row'>
          <ButtonsGroup
            mediaType={isRegionalChannel(campaignChannel) ? 'LOCAL' : 'FEDERAL'}
            retroFrom={retroFrom}
            retroTo={retroTo}
            channels={selectedTVChannels}
          />
        </div>
        <div className='row'>
          <div className='tabs-container'>
            <LocalizedMessage id={[
              'annualPlanEdit.tvCampaignParams.tabs.restrictions',
              'annualPlanEdit.tvCampaignParams.tabs.cpp',
              'annualPlanEdit.tvCampaignParams.tabs.discounts',
              'annualPlanEdit.tvCampaignParams.tabs.extraCharge',
            ]}
            >
              {(
                localizedRestrictionsTab,
                localizedCppTab,
                localizedDiscountsTab,
                localizedExtraChargeTab,
              ) => (
                <Tabs
                  id='tv-campaign-params'
                  defaultActiveKey={selectedTab}
                  mountOnEnter
                  unmountOnExit
                  onSelect={this.selectTab}
                >

                  <Tab eventKey='restrictions' title={localizedRestrictionsTab}>
                    <Restrictions
                      params={params}
                      sellerRestrictions={sellerRestrictions}
                      restrictionBase={restrictionBase}
                      channelsToDisplay={channelsToDisplay}
                      sourceChannels={sourceChannels}
                      retroFrom={retroFrom}
                      retroTo={retroTo}
                      targetAudienceId={targetAudience ? targetAudience.id : null}
                      channelType={channelType}
                      regionalId={isRegionalChannel(campaignChannel) ? campaignChannel.rgn : null}
                      isShowEmptyLines={isShowEmptyLines}
                      onUpdateValidationStatus={this.handleUpdateValidationStatus}
                      onUpdateForChannelGroupEditor={this.updateForChildComponents}
                      channelGroups={channelGroups}
                    />
                  </Tab>

                  <Tab eventKey='cpp' title={localizedCppTab}>
                    <Cpp
                      params={params}
                      cpp={cpp}
                      channelsToDisplay={channelsToDisplay}
                      isLoadingPrices={isLoadingPrices}
                      onUpdateValidationStatus={this.handleUpdateValidationStatus}
                    />
                  </Tab>

                  <Tab eventKey='discounts' title={localizedDiscountsTab}>
                    <Discounts
                      params={params}
                      channelsToDisplay={channelsToDisplay}
                      isLoadingPrices={isLoadingPrices}
                      onUpdateValidationStatus={this.handleUpdateValidationStatus}
                    />
                  </Tab>

                  <Tab eventKey='extraCharge' title={localizedExtraChargeTab}>
                    <ExtraCharge
                      params={params}
                      channelsToDisplay={channelsToDisplay}
                      isLoadingPrices={isLoadingPrices}
                      onUpdateValidationStatus={this.handleUpdateValidationStatus}
                    />
                  </Tab>

                </Tabs>
              )}
            </LocalizedMessage>
          </div>
        </div>
      </>
    );
  }
}

export default TvCampaignParams;
