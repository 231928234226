import { ChartData, Line } from 'react-chartjs-2';
import React, { useEffect, useState } from 'react';
import classes from '../../../AnnualPlan.module.scss';
import cx from 'classnames';
import { localizeMessage } from 'components/LocalizedMessage';
import LocalizedMessage from 'components/LocalizedMessage/LocalizedMessage';
import Range from 'components/Range';
import Select from 'components/Select';
import { IOption } from 'types';
import * as chartjs from 'chart.js';
import {
  reachChartColors,
  useDownloadChartCallback,
  useExportCbuExcelCallback,
  usePlotData
} from './charts-shared';
import { NullableVector, Vector } from 'types/util';


interface CbuReachPlotData {
  reach: Vector;
  TRP: Vector;
  budget: Vector;
}

interface IProps {
  planId: string;
}

interface IChartSettings {
  data: ChartData<chartjs.ChartData>;
  options: chartjs.ChartOptions;
  reachOptions: IOption<string>[];
}

type XAxis = 'budget' | 'trp';

interface IPlotDisplaySetup {
  ta?: number;
  min: number;
  max: number;
  crop: boolean;
  reach?: string;
  base: XAxis;
}

const formatBudget = (value: number) => {
  const step = 100000; // 100k

  return (value - (value % step)).toLocaleString();
  // Math.round(value).toLocaleString()
};

/**
 * Checks whether saved json from backend is valid for current version
 * @param json - json from backend or undefined if not loaded
 */
const isChartJsonValid = (json: any | undefined) => {
  if (json === undefined) return true;
  const keys = Object.keys(json);

  return keys.reduce((a, b) => a && !Number.isNaN(parseInt(b)), true);
  // only accept
  // return (
  //   keys.filter(key => {
  //     const isValidKey = key.startsWith('reach');
  //     const keysLength = Object.keys(json[key]).length;
  //
  //     // keysLength === 1 means tv or olv is 100%
  //     // keysLength === 3 means neither tv or olv is 100%
  //     const isValidContents = keysLength === 1 || keysLength === 3;
  //
  //     const isContentValid = () => !Object.values(json[key]).some(
  //       it => Array.isArray(it) && typeof it[0] === 'number'
  //     );
  //
  //     return isValidKey && isValidContents && isContentValid();
  //   }).length === keys.length
  // );
};

type ICbuPlots = Record<
number,
{
  name: string;
  plots: Record<
  string,
  {
    tvCbu: CbuReachPlotData;
  }
  >;
}
>;

export default ({ planId }: IProps) => {
  const data = usePlotData<ICbuPlots>(planId, 'CBU');
  const [settings, setSettings] = useState<IPlotDisplaySetup>({
    min: 0,
    max: 100,
    reach: undefined,
    base: 'trp',
    crop: true,
    ta: undefined
  });

  const [chartId, downloadChart] = useDownloadChartCallback(
    planId,
    `${planId}-cbu-chart-${settings.reach}`
  );

  const exportExcel = useExportCbuExcelCallback(
    planId,
    settings.ta || Object.keys(data || {})[0] || '',
    settings.base
  );

  useEffect(() => {
    if (data) {
      const keys = Object.keys(data);
      const ta = keys[0];
      const reach = Object.keys(data[ta].plots.cbuPlot)[0];
      setSettings({
        ...settings,
        reach,
        ta: Number(ta)
      });
    }
  }, [data]);

  const taOptions = Object.entries(data || {}).map(([key, val]) => ({
    label: val.name,
    value: Number(key)
  }));

  // Do not uncomment yet! This is a performance experiment
  // data not loaded yet => we need to show button to lazy-load it
  if (!isChartJsonValid(data)) {
    return (
      <div className={cx('row', classes['ad-campaigns-calc-res-charts'])}>
        <div className={classes['outdated-chart']}>
          <LocalizedMessage id='annualPlan.chartsPanel.outdatedData' />
        </div>
      </div>
    );
  }

  const getChartData = (): IChartSettings => {
    if (!data || !settings.reach) {
      return {
        options: {},
        data: {},
        reachOptions: []
      };
    }
    const reachData = data[settings.ta!!].plots.cbuPlot[settings.reach];

    console.log(reachData);

    const chartLabels = Object.keys(reachData);

    let labels: number[];

    const datasets: any = {};
    if (settings.base === 'budget') {
      const highestBudgets = Object.values(reachData).map(
        (it: CbuReachPlotData) => it.budget[it.budget.length - 1]
      );
      highestBudgets.sort((a, b) => a - b);
      const lowestHighBudget = highestBudgets[0];

      let sortedBudgets = Object.values(reachData).flatMap(
        (it: CbuReachPlotData) => it.budget
      );
      sortedBudgets.sort((a, b) => a - b);
      if (settings.crop) {
        sortedBudgets = sortedBudgets.filter(it => it < lowestHighBudget);
      }

      labels = sortedBudgets;
      Object.entries(reachData).forEach(([label, some]: any) => {
        datasets[label] = {
          reach: some['reach'],
          budget: some['budget']
        };
      });
    } else {
      const anyKey = chartLabels[0];
      labels = reachData[anyKey]['TRP'];
    }

    const chartOptions = {
      legend: {
        display: true
      },
      tooltips: {
        callbacks: {
          label: item => `${localizeMessage({
            id: `annualPlan.cbuChart.${chartLabels[item.datasetIndex]}`
          })}: ${Math.round(item.value * 100) / 100}%`,
          title: item => `${localizeMessage({
            id: `annualPlan.cbuChart.${settings.base}`
          })} ${parseInt(item[0].label).toLocaleString()}`,
          afterTitle: item => {
            const reachDataSet =
              reachData[Object.keys(reachData)[item[0].datasetIndex]];

            let index: number;
            if (settings.base === 'trp') {
              // we address reach
              index = reachDataSet['TRP'].findIndex(
                it => it.toString() === item[0].label
              );
            } else {
              index = reachDataSet['budget'].findIndex(
                it => it.toString() === item[0].label
              );
            }
            // budget, we need to find index

            if (settings.base === 'trp') {
              return (
                `${localizeMessage({ id: 'annualPlan.cbuChart.budget' })} ` +
                `${formatBudget(reachDataSet['budget'][index])}`
              );
            }

            return `${localizeMessage({ id: 'annualPlan.cbuChart.trp' })} ${
              reachDataSet['TRP'][index]
            }`;
          }
        }
      },
      scales: {
        xAxes: [
          {
            id: 'B',
            ticks: {
              display: true,

              callback: value => {
                if (value >= 1000000) {
                  // after 10k ticks are nasty, need to clarify them a little
                  return formatBudget(value);
                }

                return value;
              }
            },
            scaleLabel: {
              display: true,
              labelString: localizeMessage({
                id: `annualPlan.cbuChart.${settings.base}`
              })
            }
          }
        ],
        yAxes: [
          {
            display: true,
            id: 'A',
            position: 'left',
            ticks: {
              callback: value => `${value}%`,
              min: settings.min,
              max: settings.max
            },
            scaleLabel: {
              display: true,
              labelString: `${localizeMessage({
                id: 'annualPlan.tvOlvChart.reach'
              })} %`
            }
          }
        ]
      }
    };

    const chartData = {
      labels,
      datasets: [
        ...chartLabels.map((chartLabel, index) => {
          let transformedData: NullableVector;
          if (settings.base === 'budget') {
            let i = 0;
            transformedData = labels.map((reach, index) => {
              const point = datasets[chartLabel];
              if (labels[index] !== point.budget[i]) {
                return null;
              }

              return datasets[chartLabel]['reach'][i++];
            });
          } else {
            transformedData = [...reachData[chartLabel]['reach']];
          }

          return {
            label: localizeMessage({ id: `annualPlan.cbuChart.${chartLabel}` }),
            data: transformedData,
            yAxisID: 'A',
            spanGaps: true,
            borderColor:
              reachChartColors[index + (1 % reachChartColors.length) - 1],
            backgroundColor: 'transparent'
          };
        })
      ]
    };

    return {
      options: chartOptions,
      data: chartData,
      reachOptions: Object.keys(data[settings.ta!!].plots.cbuPlot).map(key => ({
        label: `${key.replace(/[^0-9]/g, '')}+`,
        value: key
      }))
    };
  };

  const {
    reachOptions,
    options: tvOlvOptions,
    data: tvOlvData
  } = getChartData();

  const baseOptions = [
    { label: localizeMessage({ id: 'annualPlan.cbuChart.trp' }), value: 'trp' },
    {
      label: localizeMessage({ id: 'annualPlan.cbuChart.budget' }),
      value: 'budget'
    }
  ];

  return (
    <div className={cx('row', classes['ad-campaigns-calc-res-charts'])}>
      <div className='col-lg-6'>
        <Line
          id={chartId}
          data={tvOlvData}
          options={tvOlvOptions}
          height={200}
        />
      </div>
      <div className='col-lg-6 form-group'>
        <div className='form-group'>
          <label htmlFor='reachKey'>
            <LocalizedMessage id='annualPlan.cbuChart.xAxis' />
          </label>
          <Select
            options={baseOptions}
            value={baseOptions.find(t => t.value === settings.base)}
            onChange={e => {
              setSettings({
                ...settings,
                base: (e as IOption<string>).value as XAxis
              });
            }}
          />
        </div>
        <div className='form-group'>
          <label htmlFor='reachKey'>
            <LocalizedMessage id='annualPlan.tvOlvChart.reach' />
          </label>
          <Select
            options={reachOptions}
            value={reachOptions.find(t => t.value === settings.reach)}
            onChange={e => {
              setSettings({
                ...settings,
                reach: (e as IOption<string>).value
              });
            }}
          />
        </div>
        <div className='form-group'>
          <label htmlFor='reachKey'>
            {/* <LocalizedMessage id='annualPlan.tvOlvChart.reach' /> */}
            Target audience
          </label>
          <Select
            options={taOptions}
            value={taOptions.find(t => t.value === settings.ta)}
            onChange={e => {
              setSettings({
                ...settings,
                ta: (e as IOption<number>).value
              });
            }}
          />
        </div>
        <div className='form-group'>
          <label htmlFor='min'>
            <LocalizedMessage id={'annualPlan.tvOlvChart.reach'} />
            {' '}
            {settings.min}
%
            {' - '}
            {' '}
            {settings.max}
%
          </label>
          <Range
            min={0}
            max={100}
            minValue={settings.min}
            maxValue={settings.max}
            onChange={e => setSettings({ ...settings, min: e.min, max: e.max })}
          />
        </div>

        <div className='form-group'>
          <button onClick={downloadChart} className='btn btn-white'>
            <LocalizedMessage id='annualPlan.chartsPanel.downloadChart' />
          </button>
        </div>
        <div className='form-group'>
          <button onClick={exportExcel} className='btn btn-white'>
            <LocalizedMessage id='annualPlan.chartsPanel.exportChart' />
          </button>
        </div>
      </div>
    </div>
  );
};
