import { fetchResponse } from './helpers/fakeFetch';
import Client from '../release/HTTPClient';

import mediaplanTemplatesList from './data/mediaplanTemplatesList.json';

const mediaplanTemplatesAPI = {
  list () {
    console.log('api.mediaplanTemplates.list()');

    return fetchResponse(1000, mediaplanTemplatesList);
  },

  get (id) {
    console.log('api.mediaplanTemplates.get(', id, ')');

    return Client.get(`/api/mediaplans/templates/${id}`);
  },

  getDefaultTemplates () {
    console.log('api.mediaplanTemplates.getDefaultTemplates()');

    return fetchResponse(1000, []);
  },

  saveOrUpdate (data) {
    console.log('api.mediaplanTemplates.saveOrUpdate(', data, ')');

    return Client.post('/api/mediaplans/templates/', data, true, { isJson: false, isMultipart: true });
  },

  getFile (id) {
    console.log('api.mediaplanTemplates.getFile(', id, ')');

    return Client.get(`/api/mediaplans/templates/file/${id}`, {}, true, {
      isJson: false,
    });
  },
};

export default mediaplanTemplatesAPI;
