import decode from 'jwt-decode';
import { AllowedMediaStatus, IAgency, IAuthTokens, IProfile, MediaType } from 'types';
import { getLocalizedLabel } from '../../helpers/getLocalizedLabel';
import { addTokensToCookies, getCookie } from 'libs/cookies';

interface ITokenUserData {
  realm_access: {
    roles: string[];
  };
  resource_access: {
    account: {
      roles: string[];
    }
  };
  medias: MediaType[];
  advertisers: number[];
  agencies: number[];
  preferred_username: string,
  given_name?: string,
  family_name?: string,
  email: string,
}

const getAllowedMediaStatus = (medias: MediaType[]): AllowedMediaStatus => {
  if (medias.includes(MediaType.TV) && medias.includes(MediaType.WEB)) return 'all';
  if (medias.includes(MediaType.TV)) return 'tv';
  if (medias.includes(MediaType.WEB)) return 'internet';
  return 'none';
}

const getRole = (userAccesses: string[]) => {
  if (userAccesses.includes('superadmin')) return {
    name: 'SUPER_ADMIN', 
    nameTranslation: getLocalizedLabel('role.superadmin')
  }

  if (userAccesses.includes('admin')) return {
    name: 'ADMIN',
    nameTranslation: getLocalizedLabel('role.admin')
  }

  if (userAccesses.includes('user')) return {
    name: 'PLANNER',
    nameTranslation: getLocalizedLabel('role.planner')
  }

  return {
    name: 'BUYER',
    nameTranslation: getLocalizedLabel('role.buyer')
  }
}

export const jwtTokenConverter = (accessToken?: string): Omit<IProfile, 'agencies'> | null => {
  if (!accessToken) return null;
  const {preferred_username, email, medias, agencies, advertisers, realm_access, given_name, family_name} = decode(accessToken) as ITokenUserData;

  return {
    id: 0,
    advertiserIds: advertisers,
    mail: email,
    firstName: given_name || preferred_username,
    lastName: family_name || '',
    allowedMedias: medias,
    allowedMediaStatus: getAllowedMediaStatus(medias),
    role: getRole(realm_access.roles),
    agencyIds: agencies,
    createdAt: new Date().toUTCString(),
    lastLoginAt: '',
    disabled: false,
    extend: {
      agency: {
        id: agencies[0],
        name: ''
      }
    }
  };
};

export const tokenUpdater = (
  refreshToken: string,
  agencies: Required<IAgency>[],
  getNewTokenFunc: (refreshToken: string, withoutToken?: boolean) => IAuthTokens,
  updateProfile: (newProfile: Omit<IProfile, 'agencies'> | IProfile) => void,
  getNewAgencies: (withoutToken?: boolean) => Required<IAgency>[],
  linkTimer?: ReturnType<typeof setTimeout>
  ) => {
    if (linkTimer) clearTimeout(linkTimer);
    const accessTokenExpireIn = new Date(String(getCookie('accessTokenExpireIn'))).getTime() - new Date().getTime();

    const tokenUpdateTimer = setTimeout(async () => {
      if (!refreshToken || !getCookie('refreshToken')) {
        clearTimeout(tokenUpdateTimer);
        return;
      };
      const currentRefreshToken = getCookie('refreshToken') || ''
      const authResponse = await getNewTokenFunc(currentRefreshToken, true);
      if (!authResponse) {
        clearTimeout(tokenUpdateTimer);
        return;
      }

      addTokensToCookies(authResponse);
      const newProfile = jwtTokenConverter(authResponse.access_token);
      if (!newProfile) return;

      const newAgencies = await getNewAgencies(true);

      const hasAgenciesChanged = newAgencies.some(({id}, index) => id !== agencies[index].id) &&
      agencies.some(({id}, index) => id !== newAgencies[index].id)

      updateProfile(hasAgenciesChanged ? {...newProfile, agencies: newAgencies} : newProfile)

      tokenUpdater(
        authResponse.refresh_token, 
        hasAgenciesChanged ? newAgencies : agencies, 
        getNewTokenFunc,
        updateProfile,
        getNewAgencies, 
        tokenUpdateTimer,
      );
    }, accessTokenExpireIn);
  }
