import API from 'api';
import {
  IChannel, CppData, ICppValue, ICampaign, isRegionalChannel, ChannelType, IFullChannelConfig,
  IChannelConfig, IdToChannelConfigMap
} from '../types';
import { createSelector } from 'reselect';

export const loadTvPricesByChannelAndDistributionAndMonths = async (year: number, withoutLoader: boolean) => {
  const extraChargePositionMap = {
    FLOAT_OFF_PRIME: 'floatOffprime',
    FLOAT_PRIME: 'floatPrime',
    FIX_OFF_PRIME: 'fixOffprime',
    FIX_PRIME: 'fixPrime',
  };

  const prices = await API.tvPrices.getPrices(year, withoutLoader);

  // eslint-disable-next-line no-return-assign, no-sequences
  const pricesByChannelId = prices.reduce((r, v, i, a, k = v.externalId) => ((r[k] || (r[k] = [])).push(v), r), {});
  const answer: CppData = {};

  for (const channelId in pricesByChannelId) {
    if (pricesByChannelId.hasOwnProperty(channelId)) {
      const prices = pricesByChannelId[channelId];

      answer[channelId] = (() => {
        const pricesByDistributionAndMonth = {};

        prices.forEach(priceForMonth => {
          const distribution = priceForMonth.distribution === 'REGIONAL'
            ? 'LOCAL'
            : priceForMonth.distribution;
          const cMonth = new Date(priceForMonth.dateMonth).getMonth();

          if (!pricesByDistributionAndMonth[distribution]) {
            pricesByDistributionAndMonth[distribution] = [];
          }

          if (!pricesByDistributionAndMonth[distribution][cMonth]) {
            pricesByDistributionAndMonth[distribution][cMonth] = {};
          }

          if (!pricesByDistributionAndMonth[distribution][cMonth].extraCharge) {
            pricesByDistributionAndMonth[distribution][cMonth].extraCharge = {};
          }

          if (priceForMonth.positionType.name === 'FLOAT_OFF_PRIME') {
            pricesByDistributionAndMonth[distribution][cMonth].cpp = priceForMonth.baseCppWithouDiscounts;
            pricesByDistributionAndMonth[distribution][cMonth].discount = priceForMonth.seasonRate;
          }

          pricesByDistributionAndMonth[distribution][cMonth]
            .extraCharge[extraChargePositionMap[priceForMonth.positionType.name]] = priceForMonth.positionCoefficient;
        });

        return pricesByDistributionAndMonth;
      })();
    }
  }

  return answer;
};

export const getPricesWithDiscountsAndExtraChargesByMonthsForSite = (
  channel: IChannel, tvPricesByChannelAndDistributionMonths: CppData,
  channelConfig: IFullChannelConfig, withDefaultDiscount?: boolean,
): Array<number | null> => {
  const seasonalDiscounts = channelConfig.seasonalDiscounts || {};
  const defaultSeasonalDiscounts = channelConfig.defaultSeasonalDiscounts || {};
  const extraCharge = ((channelConfig.extraCharge || {}).floatOffprime) || 1;
  const { advertiserBasePrice } = channelConfig.cpp;

  const data: ICppValue[] =
    tvPricesByChannelAndDistributionMonths[channel.externalId] &&
    tvPricesByChannelAndDistributionMonths[channel.externalId][channel.type.name]
      ? tvPricesByChannelAndDistributionMonths[channel.externalId][channel.type.name]
      : [];

  return new Array(12).fill(0).map((z, month) => {
    const cppForMonth = data[month] ? data[month].cpp : undefined;

    if (typeof cppForMonth !== 'number') {
      // console.warn(`no cpp for channel ${channel.externalId}, ${channel.type.name} ${channel.name}`);

      return null;
    }
    const discountForMonth = withDefaultDiscount
      ? defaultSeasonalDiscounts[month] || 1
      : seasonalDiscounts[month] || 1;

    if (typeof advertiserBasePrice !== 'number') {
      return Math.round(cppForMonth * discountForMonth * extraCharge * 100) / 100;
    }

    return Math.round(advertiserBasePrice * discountForMonth * extraCharge * 100) / 100;
  });
};

export const getPrices = (
  tvPricesByChannelAndDistributionAndMonths: CppData, channels: IChannel[], params: Record<number, IFullChannelConfig>,
) => channels.reduce((acc, channel) => {
  const { advertiserBasePrice } = params[channel.id].cpp;

  let array: Array<number | null>;

  if (typeof advertiserBasePrice !== 'number') {
    const channelConfig = params[channel.id];
    const pricesByMonths = getPricesWithDiscountsAndExtraChargesByMonthsForSite(
      channel, tvPricesByChannelAndDistributionAndMonths, channelConfig,
    );

    array = new Array(12).fill(0).map((z, month) => pricesByMonths[month]);
  } else {
    array = new Array(12).fill(0).map((z, month) => advertiserBasePrice);
  }

  return { ...acc, [channel.id]: array };
}, {});

export const makeSelectChannelSource = () => createSelector(
  (campaignChannel: ICampaign['channel']) => campaignChannel,
  (_, allChannels: IChannel[]) => allChannels,
  (campaignChannel, allChannels) => {
    if (isRegionalChannel(campaignChannel)) {
      return allChannels.filter(currentChannel => (
        currentChannel.region.id === campaignChannel.value &&
        currentChannel.region.rgn === campaignChannel.rgn
      ));
    }

    return allChannels.filter(channel => (
      channel.type.name !== 'LOCAL'
    ));
  }
);

export const makeSelectChannelsToDisplay = () => {
  const selectChannnelSource = makeSelectChannelSource();

  return createSelector(
    selectChannnelSource,
    (_: ICampaign['channel'], __: IChannel[], channelType: ChannelType) => channelType,
    (_: ICampaign['channel'], __: IChannel[], ___: ChannelType, isShowEmptyLines: boolean) => isShowEmptyLines,
    (_: ICampaign['channel'], __: IChannel[], ___: ChannelType, ____: boolean, params: IdToChannelConfigMap) => params,
    (source, channelType, isShowEmptyLines, params) => {
      const current = (channelType === 'ALL') ? source : source.filter(channel => {
        if (channelType === 'MINUTES') {
          return channel.inventoryType === 'MIN';
        }
        if (channelType === 'GRP') {
          return channel.inventoryType === 'GRP';
        }

        return channel.type.name === channelType;
      });

      return isShowEmptyLines
        ? current
        : current.filter(channel => !params[channel.id].restrictions.isExcludedChannel);
    }
  );
};

export const convertToFullChannelConfig = (
  channel: IChannel, config: IChannelConfig | IFullChannelConfig, cpp: CppData
): IFullChannelConfig => {
  const cppData: ICppValue[] = cpp[channel.externalId] && cpp[channel.externalId][channel.type.name] ||
    new Array(12).fill({});

  const defaultSeasonalDiscounts: Record<number, number | null> = new Array(12).fill(0).reduce((acc, _, monthIndex) => {
    const discount: number | null = cppData[monthIndex].discount !== undefined ? cppData[monthIndex].discount : null;

    return { ...acc, [monthIndex]: discount };
  }, {} as Record<number, number | null>);

  const seasonalDiscounts: Record<number, number | null> = config.seasonalDiscounts
    ? config.seasonalDiscounts
    : { ...defaultSeasonalDiscounts };

  const defaultExtraCharge = cppData[0].extraCharge || {
    floatOffprime: null,
    floatPrime: null,
    fixOffprime: null,
    fixPrime: null,
  };

  const extraCharge = { ...defaultExtraCharge, ...config.extraCharge };

  return {
    ...config,
    seasonalDiscounts,
    defaultSeasonalDiscounts,
    defaultExtraCharge,
    extraCharge,
  };
};

export const defaultSeasonalDiscounts: Record<number, number | null> = new Array(12).fill(0).reduce(
  (acc, _, monthIndex) => ({ ...acc, [monthIndex]: null }),
  {} as Record<number, number | null>
);
