import React, { Children } from 'react';
import { VariableSizeList as List } from 'react-window';
import cx from 'classnames';
import uniqBy from 'lodash/unionBy';
import { createSelector } from 'reselect';
import { MenuListComponentProps } from 'react-select';

import * as utils from 'helpers/utils';
import Select, { components } from '../Select';

const GROUP_HEADER_HEIGHT = 13;
const itemHeight = 55;

const MenuList = (props: MenuListComponentProps<any>) => {
  const { options, getValue } = props;
  const [value] = getValue();
  const initialOffset = options.indexOf(value) * itemHeight;
  const children = Children.toArray(props.children);

  function getOptionSize (option): number {
    if (option && option.options) {
      return option.options.length * itemHeight + GROUP_HEADER_HEIGHT;
    }

    return itemHeight;
  }

  function getItemSize (i) {
    return getOptionSize(options[i]);
  }

  const totalHeight = children.reduce<number>((height: number, option) => height + getOptionSize(option), 0);

  const estimatedItemSize = totalHeight / children.length;

  return (
    <List
      height={Math.min(totalHeight, 300)}
      itemCount={children.length}
      itemSize={getItemSize}
      estimatedItemSize={estimatedItemSize}
      initialScrollOffset={initialOffset}
      className='react-select__menu-list'
    >
      {({ index, style }) => <div key={index} style={style}>{children[index]}</div>}
    </List>
  );
};

interface IOptionProps {
  data: { type: string; label: string; typeTranslation: string; };
  children: React.ReactNode;
}

const Option = ({ children, ...props }: IOptionProps) => (
  <components.Option {...props}>
    <div className='item'>
      <div>{utils.escape(props.data.label)}</div>
      <div className={cx(`m-mp-filter-type _${props.data.type}`)}>{props.data.typeTranslation}</div>
    </div>
  </components.Option>
);

interface IMultiValueLabelProps {
  data: { type: string; label: string; };
  children: React.ReactNode;
}

const MultiValueLabel = ({ children, ...props }: IMultiValueLabelProps) => (
  <components.MultiValueLabel {...props}>
    <div className='item'>
      <div className={cx(`m-mp-filter-type _${props.data.type}`)}>{utils.escape(props.data.label)}</div>
    </div>
  </components.MultiValueLabel>
);

const makeChangeHandler = createSelector(
  (onChange: (newValue: string[]) => void) => onChange,
  (_, options: IOption[]) => options,
  (onChange, options) => (selectedItem: IOption | null) => {
    if (selectedItem === null) {
      onChange([]);
    } else {
      const searchedItems = options.filter(o => o.label === selectedItem.label);
      onChange(searchedItems.map(item => item.idUnique));
    }
  }
);

const selectUniqOptions = createSelector(
  (options: IOption[]) => options,
  (options) => uniqBy(options, (option: IOption) => option.label),
);

export interface IOption {
  id: string;
  type: string;
  typeTranslation: string;
  label: string;
  idUnique: string;
  text: string;
}

interface IProps {
  options: IOption[];
  placeholder: React.ReactNode;
  value: string[];
  onChange: (newValue: string[]) => void;
}

const FilterList = (props: IProps) => {
  const { options, placeholder, value = [], onChange } = props;
  const selectedItems = options.filter(item => value.includes(item.idUnique));

  return (
    <Select<IOption>
      components={{ MenuList, Option, MultiValueLabel }}
      options={selectUniqOptions(options)}
      getOptionValue={(option) => (option.idUnique)}
      onChange={makeChangeHandler(onChange, options)}
      value={selectedItems}
      placeholder={placeholder}
      isClearable
    />
  );
};

export default FilterList;
