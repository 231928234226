import { fetchResponse } from './helpers/fakeFetch';
import Client from '../release/HTTPClient';

import unitTypesList from './data/unitTypesList.json';

const unitTypesAPI = {
  list (request) {
    console.log('api.unitTypes.list(', request, ')');

    return fetchResponse(1000, unitTypesList);
  },

  get (id) {
    console.log('api.unitTypes.get(', id, ')');

    return Client.get(`/api/unitTypes/${id}`);
  },
};

export default unitTypesAPI;
