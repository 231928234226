import React, { useCallback, useEffect, useState } from 'react';
import { concat, omit, property, some } from 'lodash';
import {
  ICampaign,
  IPlatformConfig,
  ITargetAudience
} from 'routes/AnnualPlanEdit/types';
import { IOption, TargetAudience } from 'types';
import Select from 'components/Select';
import classes from 'routes/AnnualPlanEdit/components/AnnualPlanEdit.module.scss';
import LocalizedMessage, { localizeMessage } from 'components/LocalizedMessage';
import Switch from 'react-switchery';
import { Tab, Tabs } from 'react-bootstrap';
import cx from 'classnames';
import Restrictions, { restrictionsNullableProps } from './Tabs/Restrictions';
import Cpm, { cpmNullableProps } from './Tabs/Cpm';
import Discounts from './Tabs/Discounts';
import { createNewPlatform } from 'routes/AnnualPlanEdit/helpers/olv';
import Alert from 'helpers/alert';
import { cloneObject } from 'helpers/cloneObject';

export const platformNullableProps = [...restrictionsNullableProps, ...cpmNullableProps];

interface IProps {
  platforms: IPlatformConfig[];
  additionalTA: TargetAudience[] | null;
  additionalTaOlv: Record<string, IPlatformConfig[]>;
  targetAudience: ITargetAudience;
  campaignID: string | number;
  onUpdateCampaign: (
    campaignID: string | number,
    update: Partial<ICampaign>
  ) => void;
  onUpdatePlatforms: (
    campaignID: string | number,
    platforms: IPlatformConfig[]
  ) => void;
  onUpdateValidationStatus: (
    campaignID: string | number,
    isValid: boolean,
    type: string
  ) => void;
}

export default (props: IProps) => {
  const {
    platforms,
    onUpdatePlatforms,
    onUpdateValidationStatus,
    campaignID,
    targetAudience,
    additionalTaOlv,
    additionalTA,
    onUpdateCampaign
  } = props;
  const [isShowEmptyLines, setIsShowEmptyLines] = useState(true);
  const [selectedTab, setSelectedTab] = useState<string>('restrictions');
  // @ts-ignore
  const [selectedTA, setSelectedTA] = useState<TargetAudience>(targetAudience);

  const isMainTa = selectedTA.id === targetAudience.id;

  const platformsToDisplay: IPlatformConfig[] = isMainTa
    ? platforms
    : additionalTaOlv[selectedTA.id] || [];

  const restTAPlatforms = isMainTa
    ? Object.values(additionalTaOlv).flat()
    : concat(platforms, Object.values(omit(additionalTaOlv, selectedTA.id)).flat());

  useEffect(() => {
    let changed = false;
    let additionalTaOlvNew = {
      ...additionalTaOlv
    };

    (additionalTA || []).forEach(ta => {
      // if no olv for this ta, we create from main

      if (!additionalTaOlv[ta.id]) {
        const defaultPlatformsCopy: IPlatformConfig[] = cloneObject(platforms);
        additionalTaOlvNew = {
          ...additionalTaOlvNew,
          [ta.id]: defaultPlatformsCopy
        };
        changed = true;
      }
    });

    Object.keys(additionalTaOlvNew).forEach(key => {
      if (!additionalTA?.some(it => it.id.toString() === key.toString())) {
        delete additionalTaOlvNew[key];
        changed = true;
      }
    });

    if (changed) {
      onUpdateCampaign(campaignID, {
        additionalTaOlv: additionalTaOlvNew
      });
    }
  }, [onUpdateCampaign, additionalTaOlv, additionalTA]);

  const onChangeTargetAudience = (t: TargetAudience) => {
    const existing = additionalTaOlv[t.id];
    if (!existing) {
      // we need to copy default
      const defaultPlatformsCopy: IPlatformConfig[] = cloneObject(platforms);
      onUpdateCampaign(campaignID, {
        additionalTaOlv: {
          ...additionalTaOlv,
          [t.id]: defaultPlatformsCopy
        }
      });
    }
    setSelectedTA(t);
  };

  const handleAddPlatform = useCallback(() => {
    const platform = createNewPlatform();
    onUpdatePlatforms(campaignID, [...platforms, platform]);

    if (isMainTa) {
      // add to all tas
      const taOlv = {
        ...additionalTaOlv
      };
      Object.keys(additionalTaOlv).forEach(key => {
        taOlv[key].push(platform);
      });

      onUpdateCampaign(campaignID, {
        additionalTaOlv: taOlv
      });
    }
    Alert.success(
      localizeMessage({ id: 'annualPlanEdit.alerts.notForgetWritePriceCPM' })
    );
  }, [
    campaignID,
    onUpdateCampaign,
    platformsToDisplay,
    isMainTa,
    onUpdatePlatforms,
    platforms
  ]);

  const taOptions = [props.targetAudience, ...(props.additionalTA || [])].map(
    it => ({
      label: it.name,
      value: it
    })
  );

  const selectedTAOption: IOption<TargetAudience | null> = {
    label: selectedTA?.name || '',
    value: selectedTA
  };

  const getPlatforms = useCallback(
    (platforms: IPlatformConfig[], needFilter = false) => (needFilter && !isShowEmptyLines
      ? platforms.filter(platform => !platform.restrictions.isExcludePlatform)
      : platforms),
    [isShowEmptyLines]);

  const validateTab = useCallback(
    (tabName: TabName, validationColumns: string[], cellValidator: CellValidator) => {
      const validateRow = (row: IPlatformConfig) => validationColumns.reduce<RowErrors>(
        (acc, columnID) => {
          const isExcludePlatform = property('restrictions.isExcludePlatform')(row);
          const errorIDs = isExcludePlatform ? [] : cellValidator(row, columnID);

          return { ...acc, [columnID]: errorIDs };
        }, {},
      );

      const needFilter = tabName !== 'restrictions';
      const errors = getPlatforms(platformsToDisplay, needFilter).map(validateRow);
      const restTAPlatformErrors = getPlatforms(restTAPlatforms, needFilter).map(validateRow);
      const isValidTable = ![...errors, ...restTAPlatformErrors].some(row => some(row, 'length'));
      onUpdateValidationStatus(campaignID, isValidTable, tabName);

      return errors;
    },
    [restTAPlatforms, getPlatforms, onUpdateValidationStatus],
  );

  return (
    <div>
      <div className='row'>
        <div className='col-md-12'>
          <Select
            onChange={(e: IOption<TargetAudience>) => onChangeTargetAudience(e.value)}
            value={selectedTAOption}
            options={taOptions}
          />
        </div>
        <div className='col-md-12'>
          <div
            className={cx(
              'form-inline',
              classes['deal-restrictions-under-table-controls']
            )}
          >
            <div className='form-group text-nowrap'>
              <label className='form-control-static'>
                <LocalizedMessage id='annualPlanEdit.OLVCampaignParams.fields.showExcludedChannels' />
              </label>
              &nbsp;&nbsp;
              <label className='_cursor--pointer'>
                <Switch
                  className='js-switch'
                  onChange={setIsShowEmptyLines}
                  options={{
                    color: '#1AB394'
                  }}
                  checked={isShowEmptyLines}
                />
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='tabs-container'>
          <LocalizedMessage
            id={[
              'annualPlanEdit.OLVCampaignParams.tabs.restrictions',
              'annualPlanEdit.OLVCampaignParams.tabs.cpm',
              'annualPlanEdit.OLVCampaignParams.tabs.discounts'
            ]}
          >
            {(
              localizedRestrictionsTab,
              localizedCpmTab,
              localizedDiscountsTab
            ) => (
              <Tabs
                id='web-campaign-platforms'
                defaultActiveKey={selectedTab}
                mountOnEnter
                unmountOnExit
                onSelect={setSelectedTab}
              >
                <Tab eventKey='restrictions' title={localizedRestrictionsTab}>
                  <Restrictions
                    isMainTa={isMainTa}
                    onUpdatePlatforms={platforms => {
                      if (isMainTa) {
                        onUpdatePlatforms(campaignID, platforms);

                        // propagate min, max and exclude

                        const newAdditionalTaOlv = cloneObject(additionalTaOlv);
                        Object.keys(additionalTaOlv).forEach(key => {
                          const aTaPlatforms = newAdditionalTaOlv[key];
                          platforms.forEach((platform, index) => {
                            const match = aTaPlatforms[index];
                            if (match) {
                              match.name = platform.name;
                              match.restrictions.budgetPercent =
                                platform.restrictions.budgetPercent;
                              match.restrictions.isExcludePlatform =
                                platform.restrictions.isExcludePlatform;
                            }
                          });
                        });

                        onUpdateCampaign(campaignID, {
                          additionalTaOlv: newAdditionalTaOlv
                        });
                      } else {
                        onUpdateCampaign(campaignID, {
                          additionalTaOlv: {
                            ...additionalTaOlv,
                            [selectedTA.id]: platforms
                          }
                        });
                      }
                    }}
                    platforms={getPlatforms(platformsToDisplay)}
                    areExcludePlatformsVisible={isShowEmptyLines}
                    validate={validateTab}
                  />
                </Tab>
                <Tab eventKey='cpm' title={localizedCpmTab}>
                  <Cpm
                    platforms={getPlatforms(platformsToDisplay, true)}
                    validate={validateTab}
                  />
                </Tab>
                <Tab eventKey='discounts' title={localizedDiscountsTab}>
                  <Discounts
                    platforms={getPlatforms(platformsToDisplay, true)}
                    validate={validateTab}
                  />
                </Tab>
              </Tabs>
            )}
          </LocalizedMessage>
        </div>
      </div>
      <div className='row'>
        <button
          type='button'
          className='btn btn-success step-button btn-sm'
          style={{ marginTop: '20px' }}
          onClick={handleAddPlatform}
          data-test='add-platform'
        >
          <i className='fa fa-plus-circle' />
          {' '}
          <span className='bold'>
            <LocalizedMessage id='annualPlanEdit.OLVCampaignParams.buttons.addPlatform' />
          </span>
        </button>
      </div>
    </div>
  );
};

type TabName = 'restrictions' | 'discounts' | 'cpm';

type CellValidator = (row: IPlatformConfig, columnID: string) => string[];

export type RowErrors = Record<string, string[]>;

export type Validator =
  (tabName: string, validationColumns: string[], cellValidator: CellValidator) => RowErrors[];
