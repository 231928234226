import Client from 'api/release/HTTPClient';
import { IServerPage, ITargetAudience } from 'types';

const targetAudiencesAPI = {
  list (request): Promise<IServerPage<ITargetAudience>> {
    return Client.post('/api/targetAudiences/', request);
  },

  volumeByRetroPeriod (request, withoutLoader = true): Promise<any> {
    return Client.post('/api/targetAudiences/volume', request, true, {}, true, null, {
      withoutLoader,
    });
  },

  getVolumeType (targetAudienceid): Promise<any> {
    return Client.get(`/api/targetAudiences/${targetAudienceid}/volume`);
  },
};

export default targetAudiencesAPI;
