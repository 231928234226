import { Bar, Pie } from 'react-chartjs-2';
import React, { useState } from 'react';
import cx from 'classnames';
import classes from '../../AnnualPlan.module.scss';
import { localizeMessage } from 'components/LocalizedMessage';
import { Tab, Tabs } from 'react-bootstrap';
import TvOlvChart from './charts/TvOlvChart';
import CbuChart from './charts/CbuChart';
import { IPlotsInfo } from 'types';

interface IProps {
  monthsBudgetBarChartData: any[];
  months: { id: number; name: string }[];
  monthsMediaBudgetBarChartData: any[];
  mediaBudgetPieChartData: any[];
  planId?: string;
  taId: number;
  plots: IPlotsInfo | null
  disableTvOlv?: boolean;
}


const monthsMediaBudgetBarChartColors = ['#7cb5ec', '#434448'];

const monthsBudgetBarChartColors = [
  '#7cb5ec',
  '#7cb5ec',
  '#7cb5ec',
  '#7cb5ec',
  '#7cb5ec',
  '#7cb5ec',
  '#7cb5ec',
  '#7cb5ec',
  '#7cb5ec',
  '#7cb5ec',
  '#7cb5ec',
  '#7cb5ec'
];

const mediaBudgetPieChartLabels = [
  localizeMessage({ id: 'tvPercent' }),
  localizeMessage({ id: 'internetPercent' })
];

const mediaBudgetPieChartOptions = {
  responsive: true,
  legend: { display: false },
  showAllTooltips: true,
  title: {
    display: true,
    text: localizeMessage({ id: 'mediaSplit' }),
    position: 'top'
  },
  tooltips: { backgroundColor: 'rgba(204,204,204,0.8)' }
};


const mediaBudgetPieChartColors = ['#7cb5ec', '#434448'];

const monthsMediaBudgetBarChartSeries = [
  localizeMessage({ id: 'tv' }),
  localizeMessage({ id: 'internet' })
];


export default (props: IProps) => {
  const {
    monthsBudgetBarChartData,
    months,
    monthsMediaBudgetBarChartData,
    mediaBudgetPieChartData,
    planId,
    taId,
    plots,
    disableTvOlv
  } = props;

  const [activeTab, setActiveTab] = useState('budgetChart');


  const monthsMediaBudgetBarChartLabels = months.map(month => month.name);
  const monthsBudgetBarChartOptions = {
    responsive: true,
    legend: {
      display: false
    },
    title: {
      display: true,
      text: localizeMessage({ id: 'monthsBudget' }),
      position: 'top'
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true
          }
        }
      ]
    }
  };
  const monthsMediaBudgetBarChartOptions = {
    responsive: true,
    legend: {
      display: true
    },
    title: {
      display: true,
      text: localizeMessage({ id: 'monthsBudgetSplit' }),
      position: 'top'
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true
          }
        }
      ]
    }
  };


  const isTvOlvDisabled = disableTvOlv || !plots || !plots['tvOlvPlot'] || plots.tvOlvPlot?.enable === false;

  return (
    <Tabs
      className={classes['tabs']}
      id='charts-tabs'
      defaultActiveKey={activeTab}
      mountOnEnter
      unmountOnExit
      onSelect={setActiveTab}
    >
      <Tab title={'Budgets'} eventKey='budgetChart'>
        <div className={cx('row', classes['ad-campaigns-calc-res-charts'])}>
          <div className='col-lg-4'>
            <Bar
              data={{
                datasets: [
                  {
                    data: monthsBudgetBarChartData,
                    backgroundColor: monthsBudgetBarChartColors
                  }
                ],
                labels: monthsMediaBudgetBarChartLabels
              }}
              options={monthsBudgetBarChartOptions}
              height={200}
            />
          </div>
          <div className='col-lg-4'>
            <Bar
              data={{
                datasets: monthsMediaBudgetBarChartData.map((data, index) => ({
                  label: monthsMediaBudgetBarChartSeries[index],
                  data,
                  backgroundColor: monthsMediaBudgetBarChartColors[index]
                })),
                labels: monthsMediaBudgetBarChartLabels
              }}
              options={monthsMediaBudgetBarChartOptions}
              // series={monthsMediaBudgetBarChartSeries}
              height={200}
            />

          </div>
          <div className='col-lg-4'>
            <Pie
              data={{
                datasets: [
                  {
                    data: mediaBudgetPieChartData,
                    backgroundColor: mediaBudgetPieChartColors
                  }
                ],
                labels: mediaBudgetPieChartLabels
              }}
              options={mediaBudgetPieChartOptions}
              height={200}
            />
          </div>
        </div>
      </Tab>
      <Tab
        disabled={!plots || !plots['cbuPlot'] || plots.cbuPlot?.enable === false}
        title={'CBU'}
        eventKey='cbuChart'
      >
        <CbuChart planId={planId!!} />
      </Tab>
      <Tab
        disabled={isTvOlvDisabled}
        title={'TV/OLV'}
        eventKey='tvOlvChart'
      >
        <TvOlvChart planId={planId!!} taId={taId} />
      </Tab>
    </Tabs>
  );
};
