import { IBrand } from 'types';

interface ITvOlvPlot {
  enable: boolean | null;
  min: number | null;
  max: number | null;
  step: number | null;
  freqs: number[] | null;
}

type ICalculationBase = 'TRP' | 'budget';

type ICBUPlot = {
  enable: boolean | null;
  calculationBase: ICalculationBase | null; // или "budget"
  min: number | null;
  max: number | null;
  step: number | null;
  freqs: number[] | null;
};

export interface ICampaignPlots {
  tvOlvPlot?: ITvOlvPlot;
  cbuPlot?: ICBUPlot;
}

export interface ICampaign {
  isConverseOptimization?: boolean;
  brand?: IBrand;
  name: string;
  goals: [IGoal];
  lengthShares: ILengthShare[];
  tvBudgetPercent: number;
  internetBudgetPercent: number;
  from: string;
  to: string;
  retroFrom: string;
  retroTo: string;
  olv: IPlatformConfig[];
  tv: { byChannel: Record<number, IChannelConfig> };
  groupRestrictions: IChannelGroup[];
  id: CampaignID;
  plots?: ICampaignPlots;
  externalId?: string;
  channel: IFederalChannel | IRegionalChannel;
  budget: number;
  targetAudience: ITargetAudience;

  additionalTaOlv: Record<string, IPlatformConfig[]>;
  additionalTA: ITargetAudience[] | null;
  restrictionBase: 'TRP_GRP' | 'BUDGET_GRP';
}

export type CampaignID = string | number;

export enum GoalType {
  Budget = 'BUDGET',
  Reach = 'REACH',
  Conversion = 'CONVERSION'
}

export interface IRegionalChannel {
  value: number;
  label: string;
  rgn: number | null;
}

export interface IFederalChannel {
  value: 'federal-channels';
  label: string;
}

export const isRegionalChannel = (
  channel: ICampaign['channel']
): channel is IRegionalChannel => channel.value !== 'federal-channels';

export interface CapacityRepresentation {
  minPrimeCapacity: number;
  minOffPrimeCapacity: number;
  affinityPercent: number;

}

export interface IGoal {
  type: GoalType;
  goalReachShare?: number | null;
  targetCpp?: number | null;
  reachFrequencyMin?: { id: number; name: string } | null;
  reachFrequencyMax?: { id: number; name: string } | null;
  capacity?: CapacityRepresentation;
}

export interface ILengthShare {
  length: number;
  share: number;
}

export interface IChannelGroup {
  group: { name: string };
  budget: { channelPercent: { min: number | null; max: number | null } };
  channels: IChannelForGroup[];
}

export interface IChannelForGroup {
  name: string;
  type: string;
  id: number;
}

export type IdToChannelConfigMap =
  | Record<number, IChannelConfig>
  | Record<number, IFullChannelConfig>;

export interface IChannelConfig {
  restrictions: {
    isExcludedChannel: boolean;
    fixPercentMin?: number;
    fixPercentMax?: number;
    fixPrimePercentMin?: number;
    fixPrimePercentMax?: number;
    floatPrimePercentMin?: number;
    floatPrimePercentMax?: number;
    channelPercentMin?: number;
    channelPercentMax?: number;
  };
  cpp: {
    additionalDiscount: number | null;
    advertiserBasePrice: number | null;
  };
  seasonalDiscounts?: Record<number, number | null>;
  extraCharge: {
    floatOffprime?: number | null;
    floatPrime?: number | null;
    fixOffprime?: number | null;
    fixPrime?: number | null;
    isSFix?: boolean;
  };
}

export interface IFullChannelConfig extends IChannelConfig {
  cpp: {
    additionalDiscount: number | null;
    advertiserBasePrice: number | null;
  };
  seasonalDiscounts: Record<number, number | null>;
  defaultSeasonalDiscounts: Record<number, number | null>;
  extraCharge: {
    floatOffprime: number | null;
    floatPrime: number | null;
    fixOffprime: number | null;
    fixPrime: number | null;
    isSFix: boolean;
  };
  defaultExtraCharge: {
    floatOffprime: number | null;
    floatPrime: number | null;
    fixOffprime: number | null;
    fixPrime: number | null;
    isSFix: boolean;
  };
}

export const isFullChannelConfig = (
  config: IChannelConfig | IFullChannelConfig
): config is IFullChannelConfig => (config as IFullChannelConfig).defaultSeasonalDiscounts !== undefined;

export interface IPlatformConfig {
  name: string;
  restrictions: {
    customColumns?: Record<string, string>;
    advertiserBasePrice?: number;
    averageFrequency?: number;
    bigCitiesShare?: number;
    budgetPercent: { min: number; max: number };
    isExcludePlatform: boolean;
    maxReachPercent?: number;
    quality?: number;
    realUserConversion?: number;
    seasonalDiscounts: number[];
    targetHit: { targetHitValue?: number };
  };
}

export interface ITargetAudience {
  id: number;
  name: string;
  displayedName: string;
}

export interface ICppValue {
  extraCharge: IFullChannelConfig['extraCharge'];
  cpp: number;
  discount: number;
}

export type CppData = Record<
number,
{ LOCAL: ICppValue[] } | { FEDERAL: ICppValue[]; ORBITAL: ICppValue[] }
>;

export interface IBrandBudget {
  brand: IBrand;
  budget: number[];
}

type CampaignEditableKeys =
  | 'name'
  | 'channel'
  | 'brand'
  | 'from'
  | 'to'
  | 'retroFrom'
  | 'retroTo'
  | 'budget'
  | 'tvBudgetPercent'
  | 'internetBudgetPercent'
  | 'targetAudience'
  | 'additionalTA';

export type CampaignUpdate = Pick<ICampaign, CampaignEditableKeys>;
