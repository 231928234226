import React, { Component } from 'react';
import SweetAlert from '@sweetalert/with-react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import LocalizedMessage, { localizeMessage } from 'components/LocalizedMessage';

import Breadcrumbs from 'components/Breadcrumbs';
import Select from 'components/Select';

import API from 'api';
import { IDictionary, IOption } from 'types';

interface IProps {
  checkRoles: (role: string) => boolean;
}
interface IState {
  dictionariesOptions: IOption<IDictionary>[];
  dictionariesValue: IOption[] | null;
}

class Sync extends Component<IProps, IState> {
  state: IState = {
    dictionariesValue: null,
    dictionariesOptions: []
  };

  mounted = false;

  componentDidMount () {
    this.mounted = true;

    this.loadDictionaries();
  }

  componentWillUnmount () {
    this.mounted = false;
  }

  async loadDictionaries () {
    const dictionaries = await API.sync.getDictionaries();

    if (!this.mounted) {
      return;
    }

    const dictionariesOptions: IOption<IDictionary>[] = dictionaries.map(
      dictionary => ({
        value: dictionary.name,
        label: dictionary.name
      })
    );

    this.setState({
      dictionariesValue: null,
      dictionariesOptions
    });
  }

  getBreadcrumbs () {
    return [
      {
        title: <LocalizedMessage id='home' />,
        link: '/app'
      },
      {
        title: <LocalizedMessage id='menu.settings.sync' />
      }
    ];
  }

  handleChangeDictionariesSelect = dictionariesValue => {
    this.setState({
      dictionariesValue
    });
  };

  getSelectDictionariesConfig () {
    const { dictionariesValue, dictionariesOptions } = this.state;

    return {
      isSearchable: false,
      isMulti: true,
      value: dictionariesValue,
      onChange: this.handleChangeDictionariesSelect,
      options: dictionariesOptions,
      placeholder: (
        <LocalizedMessage id='settings.dictionariesSynchronization.dictionaries.placeholder' />
      )
    };
  }

  onClickSynchronize = async () => {
    const { dictionariesValue } = this.state;

    if (!dictionariesValue || !dictionariesValue.length) {
      SweetAlert({
        icon: 'error',
        title: localizeMessage({ id: 'validation-errors.title' }),
        content: (
          <p>
            {localizeMessage({
              id:
                'settings.dictionariesSynchronization.dictionaries.placeholder'
            })}
          </p>
        ),
        buttons: {
          cancel: 'Ok'
        }
      });

      return;
    }

    await API.sync.sync(dictionariesValue.map(d => d.value));
  };

  render () {
    const { checkRoles } = this.props;

    return (
      <>
        <LocalizedMessage id='site.title.settings.sync'>
          {localizedMessage => (
            <>
              <Helmet title={localizedMessage} />
              <Breadcrumbs
                title={localizedMessage}
                data={this.getBreadcrumbs()}
              />
            </>
          )}
        </LocalizedMessage>

        <div className='row'>
          <div className='col-lg-12'>
            <div className='wrapper wrapper-content'>
              <div className='ibox'>
                <div className='ibox-title'>
                  <h5>
                    <LocalizedMessage id='settings.dictionariesSynchronization.description' />
                  </h5>
                </div>
                <div className='ibox-content'>
                  <div className='form-horizontal'>
                    <div className='form-group'>
                      <label className='col-lg-2 control-label'>
                        <LocalizedMessage id='settings.dictionariesSynchronization.dictionaries.label' />
                      </label>
                      <div className='col-lg-10'>
                        <Select {...this.getSelectDictionariesConfig()} />
                      </div>
                    </div>

                    <div className='form-group'>
                      <div className='col-lg-2' />
                      <div className='col-lg-10'>
                        <Link to='/app' className='btn btn-white'>
                          <span>
                            <LocalizedMessage id='cancel' />
                          </span>
                        </Link>
                        &nbsp;&nbsp;
                        {checkRoles('SUPER_ADMIN') ? (
                          <button
                            type='button'
                            className='btn btn-primary'
                            onClick={this.onClickSynchronize}
                          >
                            <i className='fa fa-chevron-down fa-lg' />
                            <span>
                              <LocalizedMessage id='settings.dictionariesSynchronization.synchronizeBtn' />
                            </span>
                          </button>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Sync;
