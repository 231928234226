import Client from 'api/release/HTTPClient';
import { IServerPage, IUnitType } from 'types';

const unitTypesAPI = {
  list (request): Promise<IServerPage<IUnitType>> {
    return Client.post('/api/unitTypes/', request);
  },

  get (id): Promise<IUnitType> {
    return Client.get(`/api/unitTypes/${id}`);
  },
};

export default unitTypesAPI;
