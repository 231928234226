import auth from './auth';
import adFormats from './adFormats';
import adPlatforms from './adPlatforms';
import pricingUpload from './pricingUpload';
import advertisers from './advertisers';
import agencies from './agencies';
import annualBudgets from './annualBudgets';
import annualPlans from './annualPlans';
import brands from './brands';
import calculationTemplates from './calculationTemplates';
import campaignPriceTemplates from './campaignPriceTemplates';
import campaignSites from './campaignSites';
import channels from './channels';
import commitments from './commitments';
import communicationChannels from './communicationChannels';
import divisions from './divisions';
import goals from './goals';
import mediaplanTemplates from 'api/release/mediaplanTemplates';
import personaOlapSettings from 'api/release/personaOlapSettings';
import personaTargetAudience from 'api/release/personaTargetAudience';
import regions from 'api/release/regions';
import sellers from 'api/release/sellers';
import sites from 'api/release/sites';
import strategicMediaplannings from 'api/release/strategicMediaplannings';
import sync from 'api/release/sync';
import targetAudiences from 'api/release/targetAudiences';
import targetings from 'api/release/targetings';
import tv from 'api/release/tv';
import tvPrices from 'api/release/tvPrices';
import unitTypes from 'api/release/unitTypes';
import users from 'api/release/users';

export default {
  auth,
  adFormats,
  adPlatforms,
  advertisers,
  agencies,
  annualBudgets,
  annualPlans,
  brands,
  calculationTemplates,
  campaignPriceTemplates,
  campaignSites,
  channels,
  commitments,
  communicationChannels,
  divisions,
  goals,
  mediaplanTemplates,
  personaOlapSettings,
  personaTargetAudience,
  regions,
  pricingUpload,
  sellers,
  sites,
  strategicMediaplannings,
  sync,
  targetAudiences,
  targetings,
  tv,
  tvPrices,
  unitTypes,
  users,
};
