import React, { Component, PropsWithChildren, HTMLAttributes } from 'react';

type IProps = {
  tag: 'tr' | 'td';
  hoverClass: string;
  hoverActiveClass: string;
  isActiveRow: boolean;
  dependentRowId: string;
} & HTMLAttributes<HTMLElement>;

class TrTdHover extends Component<PropsWithChildren<IProps>> {
  _element: HTMLElement | null = null;

  componentDidMount () {
    if (!this._element) {
      return;
    }

    this._element.addEventListener('mouseenter', this.onMouseEnter);
    this._element.addEventListener('mouseleave', this.onMouseLeave);
  }

  componentWillUnmount () {
    if (!this._element) {
      return;
    }

    this._element.removeEventListener('mouseenter', this.onMouseEnter);
    this._element.removeEventListener('mouseleave', this.onMouseLeave);
  }

  shouldComponentUpdate (nextProps) {
    const { isActiveRow, dependentRowId, hoverActiveClass } = this.props;

    if (isActiveRow && !nextProps.isActiveRow) {
      const dependentElement = document.getElementById(dependentRowId);

      this._element?.classList.remove(hoverActiveClass);

      dependentElement?.classList.remove(hoverActiveClass);
    }

    return true;
  }

  setTdRef = ref => {
    this._element = ref;
  };

  onMouseEnter = () => {
    const { dependentRowId, isActiveRow, hoverClass, hoverActiveClass } = this.props;

    const dependentElement = document.getElementById(dependentRowId);

    if (isActiveRow) {
      this._element?.classList.add(hoverActiveClass);

      if (dependentElement) {
        dependentElement.classList.add(hoverActiveClass);
      }
    } else {
      this._element?.classList.add(hoverClass);

      if (dependentElement) {
        dependentElement.classList.add(hoverClass);
      }
    }
  };

  onMouseLeave = () => {
    const { dependentRowId, isActiveRow, hoverClass, hoverActiveClass } = this.props;

    const dependentElement = document.getElementById(dependentRowId);

    if (isActiveRow) {
      this._element?.classList.remove(hoverActiveClass);
      this._element?.classList.remove(hoverClass);

      if (dependentElement) {
        dependentElement.classList.remove(hoverActiveClass);
        dependentElement.classList.remove(hoverClass);
      }
    } else {
      this._element?.classList.remove(hoverClass);

      if (dependentElement) {
        dependentElement.classList.remove(hoverClass);
      }
    }
  };

  render () {
    const { children, tag, hoverClass, hoverActiveClass, isActiveRow, dependentRowId, ...props } = this.props;

    return (
      <this.props.tag
        ref={this.setTdRef}
        {...props}
      >
        {children}
      </this.props.tag>
    );
  }
}

export default TrTdHover;
