import { fetchResponse } from './helpers/fakeFetch';

import tvPrices from './data/tvPrices.json';

const tvPricesAPI = {
  getPrices (year) {
    console.log('api.tvPrices.getPrices(', year, ')');

    return fetchResponse(1000, tvPrices);
  },
};

export default tvPricesAPI;
