import React, { Component } from 'react';
import cx from 'classnames';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';

import LocalizedMessage from 'components/LocalizedMessage';
import { IProfile } from 'types';
import classes from './Sidebar.module.scss';
import { ReactComponent as SkLogo } from './assets/sk-logo.svg';
import { ReactComponent as SkLogoSmall } from './assets/sk-logo-small.svg';
import { isOnPointsGroup } from 'config/constants';

type IProps = RouteComponentProps & IStateProps;

interface IState {
  dictionariesIsOpen: boolean;
  rendered: boolean;
}

class Sidebar extends Component<IProps, IState> {
  state = {
    dictionariesIsOpen: this.getDefaultDictionariesState(this.props),
    rendered: false,
  };

  items = [
    {
      id: 'annualPlans',
      icon: 'th-list',
      roles: ['SUPER_ADMIN', 'ADMIN', 'PLANNER', 'BUYER'],
    },
    {
      id: 'goals',
      icon: 'rocket',
      roles: ['SUPER_ADMIN'],
    },
    {
      id: 'mediaplanTemplates',
      icon: 'files-o',
      roles: ['SUPER_ADMIN'],
    },
    {
      id: 'sync',
      type: 'settings',
      icon: 'refresh',
      roles: ['SUPER_ADMIN'],
    },
    {
      id: 'commitments',
      icon: 'money',
      roles: ['SUPER_ADMIN', 'ADMIN'],
    },
    {
      id: 'annualBudgets',
      icon: 'bars',
      roles: ['SUPER_ADMIN', 'ADMIN'],
    },
    {
      id: 'pricing',
      icon: 'bars',
      roles: ['SUPER_ADMIN'],
    },
  ];

  dictionaries = [
    {
      id: 'agencies',
      icon: 'university',
      roles: ['SUPER_ADMIN'],
    },
    {
      id: 'advertisers',
      icon: 'users',
      roles: ['SUPER_ADMIN', 'ADMIN', 'PLANNER', 'BUYER'],
    },
    {
      id: 'brands',
      icon: 'flag',
      roles: ['SUPER_ADMIN', 'ADMIN', 'PLANNER', 'BUYER'],
    },
    {
      id: 'channels',
      icon: 'tv',
      roles: ['SUPER_ADMIN', 'ADMIN', 'PLANNER', 'BUYER'],
    },
    {
      id: 'communicationChannels',
      icon: 'link',
      roles: ['SUPER_ADMIN', 'ADMIN', 'PLANNER', 'BUYER'],
    },
    {
      id: 'sites',
      icon: 'sitemap',
      roles: ['SUPER_ADMIN', 'ADMIN', 'PLANNER', 'BUYER'],
    },
    {
      id: 'targetings',
      icon: 'crosshairs',
      roles: ['SUPER_ADMIN', 'ADMIN', 'PLANNER', 'BUYER'],
    },
    {
      id: 'targetAudiences',
      icon: 'bullseye',
      roles: ['SUPER_ADMIN', 'ADMIN', 'PLANNER', 'BUYER'],
    },
    {
      id: 'adFormats',
      icon: 'arrows',
      roles: ['SUPER_ADMIN', 'ADMIN', 'PLANNER', 'BUYER'],
    },
    {
      id: 'unitTypes',
      icon: 'th',
      roles: ['SUPER_ADMIN', 'ADMIN', 'PLANNER', 'BUYER'],
    },
  ];

  componentDidMount () {
    this.setState({
      rendered: true,
    });
  }

  componentWillUnmount () {
    this.setState({
      rendered: false,
    });
  }

  static getDerivedStateFromProps (props) {
    if (!props.isOpenMenu) {
      return {
        dictionariesIsOpen: false,
      };
    }

    return null;
  }

  getDefaultDictionariesState (props) {
    const { location } = props;

    return location.pathname.match(/\/app\/dictionaries\/([0-9a-zA-Z]+)/);
  }

  toggleDictionaries = () => {
    if (!this.props.isOpenMenu) {
      return;
    }

    this.setState((prevState: IState) => ({
      dictionariesIsOpen: !prevState.dictionariesIsOpen,
    }));
  };

  render () {
    const { profile, isAuthorized, isOpenMenu } = this.props;
    const { dictionariesIsOpen, rendered } = this.state;

    if (!isAuthorized) {
      return false;
    }

    return (
      <div
        className={cx(
          classes.Container,
          {
            [classes.ContainerClosed]: !isOpenMenu,
          },
        )}
      >
        <div className={classes.Header}>
          <img
            src={`/img/logo${isOnPointsGroup ? '_prizma.svg' : '.png'}`}
            alt={`${isOnPointsGroup ? 'Prizma' : 'Aizek'}.Algorithm`}
            className={classes.Logo}
          />
        </div>
        <ul className={classes.List}>
          {
            this.items
              .filter(item => item.roles.includes(profile.role.name))
              .map(item => (
                <li
                  key={`item${(item.type ? (`-${item.type}`) : '')}-${item.id}`}
                  className={classes.Item}
                >
                  <LocalizedMessage
                    id={`menu${(item.type ? (`.${item.type}`) : '')}.${item.id}`}
                  >
                    {localizedMessage => (
                      <NavLink
                        to={`/app${(item.type ? (`/${item.type}`) : '')}/${item.id}`}
                        className={classes.Link}
                        activeClassName={classes.LinkActive}
                        title={localizedMessage}
                      >
                        <i className={cx(
                          'fa',
                          `fa-${item.icon}`,
                          classes.LinkIcon,
                        )}
                        />
                        <span className={classes.LinkText}>
                          {localizedMessage}
                        </span>
                      </NavLink>
                    )}
                  </LocalizedMessage>
                </li>
              ))
          }
          <li
            className={cx(
              classes.Item,
              classes.ItemSubList,
            )}
          >
            <LocalizedMessage
              id='menu.dictionaries'
            >
              {localizedMessage => (
                <span
                  className={cx(
                    classes.Link,
                    classes.LinkSubList,
                    {
                      [classes.LinkActive]: dictionariesIsOpen,
                    },
                  )}
                  onClick={this.toggleDictionaries}
                  title={localizedMessage}
                >
                  <i className={cx(
                    'fa',
                    'fa-cubes',
                    classes.LinkIcon,
                  )}
                  />
                  <span className={classes.LinkText}>
                    {localizedMessage}
                  </span>
                  <span className={cx(classes.LinkArrow, 'fa', 'arrow')} />
                </span>
              )}
            </LocalizedMessage>
            <ul
              className={cx(
                classes.SubList,
                {
                  [classes.SubListHidden]: rendered && !dictionariesIsOpen,
                },
              )}
            >
              {
                this.dictionaries
                  .filter(dictionary => dictionary.roles.includes(profile.role.name))
                  .map(dictionary => (
                    <li
                      key={`dictionary-${dictionary.id}`}
                      className={classes.Item}
                    >
                      <LocalizedMessage
                        id={`menu.dictionaries.${dictionary.id}`}
                      >
                        {localizedMessage => (
                          <NavLink
                            to={`/app/dictionaries/${dictionary.id}`}
                            className={classes.Link}
                            activeClassName={classes.LinkActive}
                            title={localizedMessage}
                          >
                            <i className={cx(
                              'fa',
                              `fa-${dictionary.icon}`,
                              classes.LinkIcon,
                            )}
                            />
                            <span className={classes.LinkText}>
                              {localizedMessage}
                            </span>
                          </NavLink>
                        )}
                      </LocalizedMessage>
                    </li>
                  ))
              }
            </ul>
          </li>
        </ul>
        <a className={classes.SkLink} href='https://sk.ru/' target='_blank' rel='noopener noreferrer'>
          {isOpenMenu
            ? <SkLogo className={classes.SkLogo} />
            : <SkLogoSmall className={classes.SkLogo} />}
        </a>
      </div>
    );
  }
}

interface IStateProps {
  profile: IProfile,
  isAuthorized: boolean,
  isOpenMenu: boolean,
}

const mapStateToProps = (state): IStateProps => ({
  isAuthorized: state.auth.isAuthorized,
  isOpenMenu: state.menu.isOpen,
  profile: state.auth.profile,
});

export default withRouter(connect(mapStateToProps)(Sidebar));
