import React, { PureComponent } from 'react';
import { Helmet } from 'react-helmet';
import LocalizedMessage from 'components/LocalizedMessage';
import URLFiltersHoc, { Props } from 'components/URLFiltersHoc';

import Breadcrumbs from 'components/Breadcrumbs';
import FilterList from 'components/FilterList';
import TableList from 'components/TableList';

import API from 'api';
import { Link } from 'react-router-dom';

interface IState {
  filterItems: any[],
  dataList: any[],
  dataListTotal: number;
  isLoading: boolean;
}

type CommitmentsProps = Props & {
  agencyId: number
};

class Commitments extends PureComponent<CommitmentsProps, IState> {
  static propTypes = {};

  state: IState = {
    filterItems: [],
    dataList: [],
    dataListTotal: 1,
    isLoading: false,
  };

  mounted = false;
  refreshListTimer = 0;
  refreshListTimerDelay = 500;

  componentDidMount () {
    this.mounted = true;
    this.loadData();
    this.refreshList();
  }

  componentDidUpdate (prevProps) {
    if (prevProps.filters !== this.props.filters) {
      this.refreshList();
    }
    if (prevProps.agencyId !== this.props.agencyId) {
      this.loadData();
      this.refreshList();
    }
  }

  componentWillUnmount () {
    this.mounted = false;
    clearTimeout(this.refreshListTimer);
  }

  async loadData () {
    const filterItems = await API.commitments.getFilterItems(this.props.agencyId);

    if (!this.mounted) {
      return;
    }

    this.setState({
      filterItems,
    });
  }

  refreshList () {
    clearTimeout(this.refreshListTimer);
    const { currentPage, currentSort, max, items } = this.props.filters;
    const dataListRequest = {
      page: currentPage,
      order: currentSort,
      max,
      filter: { items },
    };

    this.refreshListTimer = window.setTimeout(async () => {
      this.setState({ isLoading: true });

      dataListRequest.filter.items = dataListRequest.filter.items
        .filter((item) => !item.includes('AGENCY'));

      dataListRequest.filter.items.push(`AGENCY_${this.props.agencyId}`);

      dataListRequest.filter.items = [...new Set(dataListRequest.filter.items)];

      const dataList = await API.commitments.list(dataListRequest);

      if (!this.mounted) {
        return;
      }

      this.setState({
        dataList: dataList.items,
        dataListTotal: dataList.total,
        isLoading: false,
      });
    }, this.refreshListTimerDelay);
  }

  getBreadcrumbs () {
    return [
      {
        title: <LocalizedMessage id='home' />,
        link: '/app',
      },
      {
        title: <LocalizedMessage id='commitments' />,
        link: '/app/commitments',
      },
    ];
  }

  editCommitment (id) {
    const { history } = this.props;
    history.push(`/app/commitments/${id}`);
  }

  render () {
    const {
      filterItems,
      dataListTotal,
      dataList,
      isLoading,
    } = this.state;
    const { onUpdateSort, onUpdatePage, filters, onChangeFilterItems } = this.props;

    return (
      <>
        <LocalizedMessage
          id={[
            'site.title.commitments',
            'site.title.commitments.add',
          ]}
        >
          {(localizedMessage, localizedAddCommitmentMessage) => (
            <>
              <Helmet
                title={localizedMessage}
              />
              <Breadcrumbs
                title={localizedMessage}
                data={this.getBreadcrumbs()}
                className='col-lg-8'
              >
                <div className='col-lg-4'>
                  <div className='title-action'>
                    <Link
                      className='btn btn-warning'
                      to='/app/commitments/create'
                    >
                      <i className='fa fa-plus fa-lg' />
                      {localizedAddCommitmentMessage}
                    </Link>
                  </div>
                </div>
              </Breadcrumbs>
            </>
          )}
        </LocalizedMessage>

        <div className='wrapper wrapper-content'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='ibox float-e-margins'>
                <div className='ibox-content'>
                  <div className='row'>
                    <div className='col-sm-12 m-b-sm d-flex'>
                      <div className='flex-grow-1 m-r'>
                        <FilterList
                          placeholder={<LocalizedMessage id='commitments.commitmentsFilter.placeholder' />}
                          options={filterItems}
                          onChange={onChangeFilterItems}
                          value={filters.items}
                        />
                      </div>
                    </div>
                  </div>

                  <div className='table-responsive'>
                    <LocalizedMessage id={['advertiser', 'site', 'adUnit', 'adFormat', 'period', 'commitment']}>
                      {(localizedAdvertiser, localizedSite, localizedAdUnit,
                        localizedAdFormat, localizedPeriod, localizedCommitment) => (
                          <TableList
                            currentPage={filters.currentPage}
                            currentSort={filters.currentSort}
                            totalItems={dataListTotal}
                            itemsCountPerPage={filters.max}
                            onUpdateSort={onUpdateSort}
                            onUpdatePage={onUpdatePage}
                            isLoading={isLoading}
                            head={[
                              {
                                label: localizedAdvertiser,
                                sort: 'advertiser_keycloak_id',
                              },
                              {
                                label: localizedSite,
                                sort: 'site_id',
                              },
                              {
                                label: localizedAdUnit,
                                sort: 'ad_unit',
                              },
                              {
                                label: localizedAdFormat,
                                sort: 'ad_format_id',
                              },
                              {
                                label: localizedPeriod,
                                sort: 'period',
                              },
                              {
                                label: localizedCommitment,
                                sort: 'commitment',
                              },
                            ]}
                          >
                            {
                              dataList.map(commitment => (
                                <tr
                                  key={`${commitment.id}_${commitment.name}`}
                                  onClick={() => this.editCommitment(commitment.id)}
                                  className='_cursor--pointer'
                                >
                                  <td>
                                    {commitment.extend.advertiser.name}
                                  </td>
                                  <td>
                                    {commitment.extend.site.name}
                                  </td>
                                  <td>
                                    {commitment.adUnit}
                                  </td>
                                  <td>
                                    {commitment.extend.adFormat.name}
                                  </td>
                                  <td>
                                    {commitment.period}
                                  </td>
                                  <td>
                                    {commitment.commitment}
                                  </td>
                                </tr>
                              ))
                            }
                          </TableList>
                      )}
                    </LocalizedMessage>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default URLFiltersHoc(Commitments, 'advertiser_keycloak_id');
