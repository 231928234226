import { fetchResponse } from './helpers/fakeFetch';
import Client from '../release/HTTPClient';

import adFormatsList from './data/adFormatsList.json';

const adFormatsAPI = {
  list (request) {
    console.log('api.adFormats.list(', request, ')');

    return fetchResponse(1000, adFormatsList);
  },

  get (id) {
    return Client.get(`/api/adFormats/${id}`);
  },
};

export default adFormatsAPI;
