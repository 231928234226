import React, { Component, PropsWithChildren } from 'react';

interface IProps {
  tag: 'tr' | 'td';
  dependentRowId: string;
  isShortTable?: boolean;
  id?: string;
  className?: string;
}
class MonthHover extends Component<PropsWithChildren<IProps>> {
  static defaultProps = {
    tag: 'tr'
  };

  _element: HTMLElement | null = null;

  colors = [
    '#fff',
    '#fff',
    '#d9edf7',
    '#d9edf7',
    '#d9edf7',
    '#fcf8e3',
    '#fcf8e3',
    '#fcf8e3',
    '#f5f5f5',
    '#f5f5f5',
    '#f5f5f5',
    '#dff0d8',
    '#dff0d8',
    '#dff0d8'
  ];

  hoverColors = [
    '#f5f5f5',
    '#f5f5f5',
    '#c4e3f3',
    '#c4e3f3',
    '#c4e3f3',
    '#faf2cc',
    '#faf2cc',
    '#faf2cc',
    '#e8e8e8',
    '#e8e8e8',
    '#e8e8e8',
    '#d0e9c6',
    '#d0e9c6',
    '#d0e9c6'
  ];

  colorsForShortTable = [
    '#fff',
    '#d9edf7',
    '#d9edf7',
    '#d9edf7',
    '#fcf8e3',
    '#fcf8e3',
    '#fcf8e3',
    '#f5f5f5',
    '#f5f5f5',
    '#f5f5f5',
    '#dff0d8',
    '#dff0d8',
    '#dff0d8'
  ];

  hoverColorsForShortTable = [
    '#f5f5f5',
    '#c4e3f3',
    '#c4e3f3',
    '#c4e3f3',
    '#faf2cc',
    '#faf2cc',
    '#faf2cc',
    '#e8e8e8',
    '#e8e8e8',
    '#e8e8e8',
    '#d0e9c6',
    '#d0e9c6',
    '#d0e9c6'
  ];

  defaultColor = '#fff';

  defaultHoverColor = '#f5f5f5';

  componentDidMount () {
    if (!this._element) {
      return;
    }

    this._element.addEventListener('mouseenter', this.onMouseEnter);
    this._element.addEventListener('mouseleave', this.onMouseLeave);
  }

  componentWillUnmount () {
    if (!this._element) {
      return;
    }

    this._element.removeEventListener('mouseenter', this.onMouseEnter);
    this._element.removeEventListener('mouseleave', this.onMouseLeave);
  }

  setTdRef = ref => {
    this._element = ref;
  };

  onMouseEnter = () => {
    const { dependentRowId, isShortTable } = this.props;

    const dependentElement = document.getElementById(dependentRowId);

    if (!this._element) return;
    for (let i = 0; i < this._element.children.length; i++) {
      if (isShortTable) {
        (this._element.children[i] as HTMLElement).style.backgroundColor =
          this.hoverColorsForShortTable[i] || this.defaultHoverColor;
      } else {
        (this._element.children[i] as HTMLElement).style.backgroundColor =
          this.hoverColors[i] || this.defaultHoverColor;
      }
    }

    if (dependentElement) {
      for (let j = 0; j < dependentElement.children.length; j++) {
        if (isShortTable) {
          (dependentElement.children[j] as HTMLElement).style.backgroundColor =
            this.hoverColorsForShortTable[j] || this.defaultHoverColor;
        } else {
          (dependentElement.children[j] as HTMLElement).style.backgroundColor =
            this.hoverColors[j] || this.defaultHoverColor;
        }
      }
    }
  };

  onMouseLeave = () => {
    const { dependentRowId, isShortTable } = this.props;

    const dependentElement = document.getElementById(dependentRowId);

    if (!this._element) return;
    for (let i = 0; i < this._element.children.length; i++) {
      if (isShortTable) {
        (this._element.children[i] as HTMLElement).style.backgroundColor =
          this.colorsForShortTable[i] || this.defaultColor;
      } else {
        (this._element.children[i] as HTMLElement).style.backgroundColor =
          this.colors[i] || this.defaultColor;
      }
    }
    if (dependentElement) {
      for (let j = 0; j < dependentElement.children.length; j++) {
        if (isShortTable) {
          (dependentElement.children[j] as HTMLElement).style.backgroundColor =
            this.colorsForShortTable[j] || this.defaultColor;
        } else {
          (dependentElement.children[j] as HTMLElement).style.backgroundColor =
            this.colors[j] || this.defaultColor;
        }
      }
    }
  };

  render () {
    const { children, tag, dependentRowId, ...props } = this.props;

    return (
      <this.props.tag ref={this.setTdRef} {...props}>
        {children}
      </this.props.tag>
    );
  }
}

export default MonthHover;
