import Client from 'api/release/HTTPClient';
import { ICampaignPriceTemplate, IServerPage } from 'types';

const campaignPriceTemplatesAPI = {
  list (advertiserId: number): Promise<IServerPage<ICampaignPriceTemplate>> {
    return Client.post(
      `/api/clients/${advertiserId}/campaignPriceTemplates/`,
      {}
    );
  },

  get (advertiserId: number, id): Promise<ICampaignPriceTemplate> {
    return Client.get(
      `/api/clients/${advertiserId}/campaignPriceTemplates/${id}`
    );
  },

  delete (advertiserId: number, id): Promise<void> {
    return Client.delete(
      `/api/clients/${advertiserId}/campaignPriceTemplates/${id}`
    );
  },

  save (advertiserId: number, data): Promise<ICampaignPriceTemplate> {
    return Client.put(
      `/api/clients/${advertiserId}/campaignPriceTemplates/new`,
      data
    );
  }
};

export default campaignPriceTemplatesAPI;
