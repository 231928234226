import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Tooltip from 'rc-tooltip';
import LocalizedMessage from 'components/LocalizedMessage';
import { formattedNumber, percentOrHyphen } from 'helpers/utils';
import MonthHoverQualityTable from 'routes/AnnualPlan/components/Panels/components/MonthHoverQualityTable';
import classes from '../../AnnualPlan.module.scss';
import { IChannelMonthBudgetLocal } from '../DealCalculation';
import { IChannelWarning } from 'types';
import { ChannelType } from 'routes/AnnualPlanEdit/types';

interface IProps {
  getClassByMonthId: (id: number) => string;
  getWarningMessage: (restrictions: any, type: any) => string;
  months: { id: number; name: string }[];
  channelMonthsBudgets: IChannelMonthBudgetLocal[];

  channelRestrictionWarnings: Record<
  number,
  IChannelWarning & { type: ChannelType }
  >;
}

class DealCalculationQualityTab extends Component<IProps> {
  static propTypes = {
    channelRestrictionWarnings: PropTypes.object.isRequired
  };

  state = {
    floatColSpan: 3,
    fixColSpan: 3,
    isFloatExpand: false,
    isFloatOffPrimeExpand: false,
    isFloatPrimeExpand: false,
    isFixExpand: false,
    isFixOffPrimeExpand: false,
    isFixPrimeExpand: false,
    bgColors: ['#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff'],
    bgHoverColors: [
      '#f5f5f5',
      '#f5f5f5',
      '#f5f5f5',
      '#f5f5f5',
      '#f5f5f5',
      '#f5f5f5',
      '#f5f5f5'
    ]
  };

  _tr: HTMLTableRowElement | null = null;

  _th: HTMLTableHeaderCellElement | null = null;

  componentDidMount () {
    this.updateHeight();

    window.addEventListener('resize', this.updateHeight);
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.updateHeight);
  }

  setResizingTrRef = ref => {
    this._tr = ref;
  };

  setCalculatingThRef = ref => {
    this._th = ref;
  };

  updateHeight = () => {
    if (this._tr) this._tr.style.height = `${this._th?.offsetHeight}px`;
  };

  calculateFloatColSpan () {
    const {
      isFloatExpand,
      isFloatOffPrimeExpand,
      isFloatPrimeExpand
    } = this.state;

    this.setState({
      floatColSpan:
        3 +
        Number(isFloatExpand) * 12 +
        Number(isFloatOffPrimeExpand) * 12 +
        Number(isFloatPrimeExpand) * 12
    });
  }

  calculateFixColSpan () {
    const { isFixExpand, isFixOffPrimeExpand, isFixPrimeExpand } = this.state;

    this.setState({
      fixColSpan:
        3 +
        Number(isFixExpand) * 12 +
        Number(isFixOffPrimeExpand) * 12 +
        Number(isFixPrimeExpand) * 12
    });
  }

  adjustColors () {
    const {
      isFixPrimeExpand,
      isFixOffPrimeExpand,
      isFixExpand,
      isFloatPrimeExpand,
      isFloatOffPrimeExpand,
      isFloatExpand
    } = this.state;

    const bgColors = ['#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff'];
    const bgHoverColors = [
      '#f5f5f5',
      '#f5f5f5',
      '#f5f5f5',
      '#f5f5f5',
      '#f5f5f5',
      '#f5f5f5',
      '#f5f5f5'
    ];
    const monthColors = [
      '#d9edf7',
      '#d9edf7',
      '#d9edf7',
      '#fcf8e3',
      '#fcf8e3',
      '#fcf8e3',
      '#f5f5f5',
      '#f5f5f5',
      '#f5f5f5',
      '#dff0d8',
      '#dff0d8',
      '#dff0d8'
    ];
    const monthHoverColors = [
      '#c4e3f3',
      '#c4e3f3',
      '#c4e3f3',
      '#faf2cc',
      '#faf2cc',
      '#faf2cc',
      '#e8e8e8',
      '#e8e8e8',
      '#e8e8e8',
      '#d0e9c6',
      '#d0e9c6',
      '#d0e9c6'
    ];

    // moving from end to start
    if (isFixPrimeExpand) {
      bgColors.splice.apply(bgColors, [7, 0, ...monthColors]);
      bgHoverColors.splice.apply(bgHoverColors, [7, 0, ...monthHoverColors]);
    }

    if (isFixOffPrimeExpand) {
      bgColors.splice.apply(bgColors, [6, 0, ...monthColors]);
      bgHoverColors.splice.apply(bgHoverColors, [6, 0, ...monthHoverColors]);
    }
    if (isFixExpand) {
      bgColors.splice.apply(bgColors, [5, 0, ...monthColors]);
      bgHoverColors.splice.apply(bgHoverColors, [5, 0, ...monthHoverColors]);
    }
    if (isFloatPrimeExpand) {
      bgColors.splice.apply(bgColors, [4, 0, ...monthColors]);
      bgHoverColors.splice.apply(bgHoverColors, [4, 0, ...monthHoverColors]);
    }
    if (isFloatOffPrimeExpand) {
      bgColors.splice.apply(bgColors, [3, 0, ...monthColors]);
      bgHoverColors.splice.apply(bgHoverColors, [3, 0, ...monthHoverColors]);
    }
    if (isFloatExpand) {
      bgColors.splice.apply(bgColors, [2, 0, ...monthColors]);
      bgHoverColors.splice.apply(bgHoverColors, [2, 0, ...monthHoverColors]);
    }

    this.setState({
      bgColors,
      bgHoverColors
    });
  }

  switchQualityFloatAll = () => {
    const {
      isFloatExpand,
      isFloatOffPrimeExpand,
      isFloatPrimeExpand
    } = this.state;

    const nextStatus = !(
      isFloatExpand ||
      isFloatOffPrimeExpand ||
      isFloatPrimeExpand
    );

    this.setState(
      {
        isFloatExpand: nextStatus,
        isFloatOffPrimeExpand: nextStatus,
        isFloatPrimeExpand: nextStatus
      },
      () => {
        this.calculateFloatColSpan();
        this.adjustColors();
        this.updateHeight();
      }
    );
  };

  switchQualityFixAll = () => {
    const { isFixExpand, isFixOffPrimeExpand, isFixPrimeExpand } = this.state;

    const nextStatus = !(
      isFixExpand ||
      isFixOffPrimeExpand ||
      isFixPrimeExpand
    );

    this.setState(
      {
        isFixExpand: nextStatus,
        isFixOffPrimeExpand: nextStatus,
        isFixPrimeExpand: nextStatus
      },
      () => {
        this.calculateFixColSpan();
        this.adjustColors();
        this.updateHeight();
      }
    );
  };

  switchQualityFloatRow = type => {
    this.setState(
      {
        [type]: !this.state[type]
      },
      () => {
        this.calculateFloatColSpan();
        this.adjustColors();
        this.updateHeight();
      }
    );
  };

  switchQualityFixRow = type => {
    this.setState(
      {
        [type]: !this.state[type]
      },
      () => {
        this.calculateFixColSpan();
        this.adjustColors();
        this.updateHeight();
      }
    );
  };

  render () {
    const {
      months,
      channelMonthsBudgets,
      getClassByMonthId,
      channelRestrictionWarnings,
      getWarningMessage
    } = this.props;
    const {
      floatColSpan,
      fixColSpan,
      isFloatExpand,
      isFloatOffPrimeExpand,
      isFloatPrimeExpand,
      isFixExpand,
      isFixOffPrimeExpand,
      isFixPrimeExpand,
      bgColors,
      bgHoverColors
    } = this.state;

    return (
      <div className={classes['display-flex']}>
        <div>
          <table
            className={cx(
              'table',
              classes.table,
              'table-bordered',
              classes['centered-cells'],
              classes['quality-fixed-table']
            )}
          >
            <thead>
              <tr ref={this.setResizingTrRef}>
                <th
                  className={cx(
                    'text-nowrap',
                    classes['no-border-bottom'],
                    classes['channel-col']
                  )}
                >
                  <i className='fa fa-television' />
                  {' '}
                  <span>
                    <LocalizedMessage id='channel' />
                  </span>
                </th>
                <th className={cx('text-nowrap', classes['no-border-bottom'])}>
                  <LocalizedMessage id='inventoryType' />
                </th>
              </tr>
            </thead>
            <tbody>
              {channelMonthsBudgets.map((channel, index) => {
                const row = (
                  <MonthHoverQualityTable
                    key={index}
                    id={`monthsFixedRow${index}`}
                    dependentRowId={`monthsScrollRow${index}`}
                    colors={bgColors}
                    hoverColors={bgHoverColors}
                    className={cx({
                      [classes.tableRowWarning]: channel.channel.isWarning
                    })}
                  >
                    <td className='text-nowrap'>
                      {channel.channel
                        ? channel.channel.nameWithType
                        : // : channel.site.name}
                        'Unexpected'}
                      {/*    channel.site.name doesn't exist */}
                    </td>
                    <td className='text-nowrap'>{channel.channel.inventoryType}</td>
                  </MonthHoverQualityTable>
                );

                return channel.channel.isWarning ? (
                  <Tooltip placement='top' overlay={channel.channel.tooltip}>
                    {row}
                  </Tooltip>
                ) : (
                  row
                );
              })}
            </tbody>
          </table>
        </div>
        <div className={classes['right-flex-table']}>
          <table
            className={cx(
              'table',
              classes.table,
              'table-bordered',
              classes['centered-cells'],
              classes['quality-fixed-table']
            )}
          >
            <thead ref={this.setCalculatingThRef}>
              <tr>
                <th
                  colSpan={fixColSpan}
                  className='_cursor--pointer'
                  onClick={this.switchQualityFixAll}
                >
                  <span>
                    <LocalizedMessage id='annualPlan.dealCalculation.quality.superfix' />
                  </span>
                </th>
                <th
                  colSpan={floatColSpan}
                  className='_cursor--pointer'
                  onClick={this.switchQualityFloatAll}
                >
                  <span>
                    <LocalizedMessage id='annualPlan.dealCalculation.quality.fix' />
                  </span>
                </th>
              </tr>
              <tr>
                <th
                  className={cx(classes['percent-col'], '_cursor--pointer')}
                  onClick={() => this.switchQualityFloatRow('isFloatExpand')}
                >
                  <span>
                    <LocalizedMessage id='annualPlan.dealCalculation.quality.share' />
                  </span>
                </th>
                {isFloatExpand
                  ? months.map(month => (
                    <th
                      key={month.id}
                      className={cx(
                        classes['month-budget-column'],
                        getClassByMonthId(month.id)
                      )}
                    >
                      {month.name}
                    </th>
                  ))
                  : null}
                <th
                  className={cx(classes['percent-col'], '_cursor--pointer')}
                  onClick={() => this.switchQualityFloatRow('isFloatOffPrimeExpand')}
                >
                  <span>
                    <LocalizedMessage id='annualPlan.dealCalculation.quality.off-prime-share' />
                  </span>
                </th>
                {isFloatOffPrimeExpand
                  ? months.map(month => (
                    <th
                      key={month.id}
                      className={cx(
                        classes['month-budget-column'],
                        getClassByMonthId(month.id)
                      )}
                    >
                      {month.name}
                    </th>
                  ))
                  : null}
                <th
                  className={cx(classes['percent-col'], '_cursor--pointer')}
                  onClick={() => this.switchQualityFloatRow('isFloatPrimeExpand')}
                >
                  <span>
                    <LocalizedMessage id='annualPlan.dealCalculation.quality.prime-share' />
                  </span>
                </th>
                {isFloatPrimeExpand
                  ? months.map(month => (
                    <th
                      key={month.id}
                      className={cx(
                        classes['month-budget-column'],
                        getClassByMonthId(month.id)
                      )}
                    >
                      {month.name}
                    </th>
                  ))
                  : null}
                <th
                  className={cx(classes['percent-col'], '_cursor--pointer')}
                  onClick={() => this.switchQualityFixRow('isFixExpand')}
                >
                  <span>
                    <LocalizedMessage id='annualPlan.dealCalculation.quality.share' />
                  </span>
                </th>
                {isFixExpand
                  ? months.map(month => (
                    <th
                      key={month.id}
                      className={cx(
                        classes['month-budget-column'],
                        getClassByMonthId(month.id)
                      )}
                    >
                      {month.name}
                    </th>
                  ))
                  : null}
                <th
                  className={cx(classes['percent-col'], '_cursor--pointer')}
                  onClick={() => this.switchQualityFixRow('isFixOffPrimeExpand')}
                >
                  <span>
                    <LocalizedMessage id='annualPlan.dealCalculation.quality.off-prime-share' />
                  </span>
                </th>
                {isFixOffPrimeExpand
                  ? months.map(month => (
                    <th
                      key={month.id}
                      className={cx(
                        classes['month-budget-column'],
                        getClassByMonthId(month.id)
                      )}
                    >
                      {month.name}
                    </th>
                  ))
                  : null}
                <th
                  className={cx(classes['percent-col'], '_cursor--pointer')}
                  onClick={() => this.switchQualityFixRow('isFixPrimeExpand')}
                >
                  <span>
                    <LocalizedMessage id='annualPlan.dealCalculation.quality.prime-share' />
                  </span>
                </th>
                {isFixPrimeExpand
                  ? months.map(month => (
                    <th
                      key={month.id}
                      className={cx(
                        classes['month-budget-column'],
                        getClassByMonthId(month.id)
                      )}
                    >
                      {month.name}
                    </th>
                  ))
                  : null}
              </tr>
            </thead>
            <tbody>
              {channelMonthsBudgets.map((channel, index) => {
                const id = channel.channel && channel.channel.id;
                const type = channel.channel && channel.channel.type;
                const qualityRestrictions = (channelRestrictionWarnings[id] &&
                  channelRestrictionWarnings[id].type === type &&
                  channelRestrictionWarnings[id].qualityRestrictions) || {
                  fixPercentExp: undefined,
                  fixPercentObs: undefined,
                  floatPrimeExp: undefined,
                  reason: undefined,
                  fixPrimeExp: undefined
                };
                const {
                  fixPercentExp: fixPercent,
                  fixPrimeExp: fixPrime,
                  floatPrimeExp: floatPrime,
                  reason
                } = qualityRestrictions;
                const fixPercentWarning = fixPercent
                  ? getWarningMessage({ ...fixPercent, reason }, 'quality')
                  : null;
                const fixPrimeWarning = fixPrime
                  ? getWarningMessage({ ...fixPrime, reason }, 'quality')
                  : null;
                const floatPrimeWarning = floatPrime
                  ? getWarningMessage({ ...floatPrime, reason }, 'quality')
                  : null;

                const row = (
                  <MonthHoverQualityTable
                    key={index}
                    id={`monthsScrollRow${index}`}
                    dependentRowId={`monthsFixedRow${index}`}
                    colors={bgColors}
                    hoverColors={bgHoverColors}
                    className={cx({
                      [classes.tableRowWarning]: channel.channel.isWarning
                    })}
                  >
                    <td
                      className={cx('text-nowrap', {
                        [classes.tableCellWarning]: fixPercentWarning
                      })}
                      title={fixPercentWarning || undefined}
                    >
                      <strong>
                        {formattedNumber(channel.fixSharePercent, 1)}
                      </strong>
                      %
                    </td>
                    {isFixExpand
                      ? months.map(month => (
                        <td
                          key={month.id}
                          className={cx(
                            'text-nowrap',
                            getClassByMonthId(month.id)
                          )}
                        >
                          <span>
                            {percentOrHyphen(
                              channel.monthsFixShare[month.id] * 100 ||
                                  undefined,
                              1
                            )}
                          </span>
                        </td>
                      ))
                      : null}
                    <td>
                      <span>
                        {formattedNumber(channel.fixOffPrimeSharePercent, 1)}
                      </span>
                      %
                    </td>
                    {isFixOffPrimeExpand
                      ? months.map(month => (
                        <td
                          key={month.id}
                          className={cx(
                            'text-nowrap',
                            getClassByMonthId(month.id)
                          )}
                        >
                          <span>
                            {percentOrHyphen(
                              channel.monthsFixOffPrimeShare[month.id] *
                                  100 || undefined,
                              1
                            )}
                          </span>
                        </td>
                      ))
                      : null}
                    <td
                      className={cx('text-nowrap', {
                        [classes.tableCellWarning]: fixPrimeWarning
                      })}
                      title={fixPrimeWarning || undefined}
                    >
                      <span>
                        {formattedNumber(channel.fixPrimeSharePercent, 1)}
                      </span>
                      %
                    </td>
                    {isFixPrimeExpand
                      ? months.map(month => (
                        <td
                          key={month.id}
                          className={cx(
                            'text-nowrap',
                            getClassByMonthId(month.id)
                          )}
                        >
                          <span>
                            {percentOrHyphen(
                              channel.monthsFixPrimeShare[month.id] * 100 ||
                                  undefined,
                              1
                            )}
                          </span>
                        </td>
                      ))
                      : null}

                    <td>
                      <strong>
                        {formattedNumber(channel.floatSharePercent, 1)}
                      </strong>
                      %
                    </td>
                    {isFloatExpand
                      ? months.map(month => (
                        <td
                          key={month.id}
                          className={cx(
                            'text-nowrap',
                            getClassByMonthId(month.id)
                          )}
                        >
                          <span>
                            {percentOrHyphen(
                              channel.monthsFloatShare[month.id] * 100 ||
                                  undefined,
                              1
                            )}
                          </span>
                        </td>
                      ))
                      : null}
                    <td
                      className={cx('text-nowrap', {
                        [classes.tableCellWarning]: floatPrimeWarning
                      })}
                      title={floatPrimeWarning || undefined}
                    >
                      <span>
                        {formattedNumber(channel.floatOffPrimeSharePercent, 1)}
                      </span>
                      %
                    </td>
                    {isFloatOffPrimeExpand
                      ? months.map(month => (
                        <td
                          key={month.id}
                          className={cx(
                            'text-nowrap',
                            getClassByMonthId(month.id)
                          )}
                        >
                          <span>
                            {percentOrHyphen(
                              channel.monthsFloatOffPrimeShare[month.id] *
                                  100 || undefined,
                              1
                            )}
                          </span>
                        </td>
                      ))
                      : null}
                    <td>
                      <span>
                        {formattedNumber(channel.floatPrimeSharePercent, 1)}
                      </span>
                      %
                    </td>
                    {isFloatPrimeExpand
                      ? months.map(month => (
                        <td
                          key={month.id}
                          className={cx(
                            'text-nowrap',
                            getClassByMonthId(month.id)
                          )}
                        >
                          <span>
                            {percentOrHyphen(
                              channel.monthsFloatPrimeShare[month.id] * 100 ||
                                  undefined,
                              1
                            )}
                          </span>
                        </td>
                      ))
                      : null}
                  </MonthHoverQualityTable>
                );

                return channel.channel.isWarning ? (
                  <Tooltip placement='top' overlay={channel.channel.tooltip}>
                    {row}
                  </Tooltip>
                ) : (
                  row
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default DealCalculationQualityTab;
