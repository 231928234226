import React, { PureComponent } from 'react';
import set from 'lodash/set';
import property from 'lodash/property';
import { HotTable, HotTableProps } from '@handsontable/react';
import moment from 'moment';
import Handsontable from 'handsontable';

import { MAX_INT_VALUE } from 'helpers/constants';
import { formatNumberToNumber } from 'helpers/utils';
import { IPlatformConfig } from 'routes/AnnualPlanEdit/types';
import LocalizedMessage, { localizeMessage } from 'components/LocalizedMessage';
import { RowErrors, Validator } from '../OlvCampaignParams';

const basePriceProp = 'restrictions.advertiserBasePrice';
export const cpmNullableProps = [basePriceProp];

interface IProps {
  platforms: IPlatformConfig[];
  validate: Validator;
}

class Cpm extends PureComponent<IProps> {
  cellsErrors: RowErrors[] = [];
  monthNames = moment.monthsShort();

  getCpp = (rowIndex: number, monthIndex: number): string => {
    const discountCoefficient = this.props.platforms[rowIndex].restrictions.seasonalDiscounts[monthIndex];
    const basePrice = this.props.platforms[rowIndex].restrictions.advertiserBasePrice;
    if (!basePrice) {
      return '';
    }

    return String(Math.round(basePrice * discountCoefficient * 100) / 100);
  };

  getClassByMonthId = (monthIndex: number) => {
    switch (monthIndex) {
      case 0:
      case 1:
      case 2:
        return 'c-calendar-winter';
      case 3:
      case 4:
      case 5:
        return 'c-calendar-spring';
      case 6:
      case 7:
      case 8:
        return 'c-calendar-summer';
      case 9:
      case 10:
      case 11:
        return 'c-calendar-autumn';
      default:
        return null;
    }
  };

  generateTableOptions = (): HotTableProps => {
    const { platforms } = this.props;

    return {
      data: platforms,
      colHeaders: true,
      nestedHeaders: [
        [
          localizeMessage({ id: 'site' }),
          localizeMessage({ id: 'annualPlanEdit.OLVCampaignParams.cpm.price' }),
          ...this.monthNames,
        ],
      ],
      columns: [
        {
          data: 'name',
          readOnly: true,
        },
        {
          data: basePriceProp,
          type: 'numeric',
          numericFormat: {
            pattern: {
              mantissa: 2,
              trimMantissa: true,
            } as any,
          },
        },
        ...this.monthNames.map((month, index) => ({
          data: `restrictions.cpmCalculated.${index}`,
          type: 'numeric',
          renderer: this.cellRenderer,
          readOnly: true,
          numericFormat: {
            pattern: {
              mantissa: 2,
              trimMantissa: true,
            } as any,
          },
        })),
      ],
      cells: (rowIndex, col, columnID: string) => {
        const cellProperties: any = {
          className: '',
          errorIDs: this.cellsErrors[rowIndex]
            ? this.cellsErrors[rowIndex][columnID] || []
            : [],
        };

        const foundMatch = columnID.match(/restrictions.cpmCalculated.([0-9]+)/);
        if (foundMatch) {
          const monthIndex = Number(foundMatch[1]);
          cellProperties.className = this.getClassByMonthId(monthIndex);
        }

        if (property('restrictions.isExcludePlatform')(this.props.platforms[rowIndex])) {
          cellProperties.readOnly = true;
          cellProperties.className = 'htDisabled';
        }
        if (columnID === basePriceProp && cellProperties.errorIDs.length) {
          cellProperties.renderer = this.commentsRenderer;
          cellProperties.className += ' invalid';
        }

        return cellProperties;
      },
    };
  };

  cellRenderer = (...args) => {
    const td = args[1];
    const row = args[2];
    const prop = args[4];
    const cellProperties = args[6];

    Handsontable.renderers.TextRenderer.apply(this, args);

    if (td && cellProperties.className) {
      const monthIndex = parseInt(prop.replace('restrictions.cpmCalculated.', ''), 10);
      td.innerHTML = this.getCpp(row, monthIndex);
    }
  };

  commentsRenderer = (...args) => {
    // [instance, td, row, col, prop, value, cellProperties]
    const [, td,,,,, cellProperties] = args;
    Handsontable.renderers.NumericRenderer.apply(this, args);
    const msg = cellProperties.errorIDs.map(id => localizeMessage({ id })).join('\n');
    td.setAttribute('title', msg);
  };

  validateCell = (row, columnID) => {
    const errorIDs: string[] = [];
    const stringValue = property<any, string>(columnID)(row);
    const value = parseFloat(stringValue);
    if (Number.isNaN(value) || Number.isNaN(Number(stringValue))) {
      // parseFloat считает строку `123asdas` за число и вторая проверка на isNaN это фиксит
      errorIDs.push('annualPlanEdit.errors.notNumber');
    } else if (value < 0) {
      errorIDs.push('annualPlanEdit.errors.notPositive');
    } else if (value > MAX_INT_VALUE) {
      errorIDs.push('annualPlanEdit.errors.exceedMaxInteger');
    }

    return errorIDs;
  };

  afterGetColHeader = (col, th) => {
    if (col === 1 || col === 2) {
      th.style.whiteSpace = 'normal';
    }

    return th;
  };

  beforeChange = (changes) => {
    if (!changes) {
      return;
    }

    changes.forEach(([row, prop, oldVal, newVal], index) => {
      if (!this.props.platforms[row]) {
        changes[index] = null;
      }
      if (prop === 'cpm.advertiserBasePrice' && newVal) {
        const value = parseInt(newVal.replace(/[^0-9]/g, ''), 10);

        if (isNaN(value)) {
          changes[index][3] = '';
        } else {
          changes[index][3] = value;
          changes[index][3] = Math.min(changes[index][3], 100);
          changes[index][3] = Math.max(changes[index][3], 0);
        }
      }
    });
  };

  afterChange = (changes) => {
    if (!changes) {
      return;
    }

    changes.forEach(([row, columnID, oldValue, newValue]) => {
      if (columnID === basePriceProp && typeof newValue === 'number') {
        set(this.props.platforms[row], columnID, formatNumberToNumber(newValue));
      }
    });
  };

  beforeRender = () => {
    this.cellsErrors = this.props.validate('cpm', cpmNullableProps, this.validateCell);
  };

  render () {
    return (
      <>
        <p className='_text-align--center m-t m-b'>
          <LocalizedMessage id='annualPlanEdit.OLVCampaignParams.cpm' />
        </p>

        <HotTable
          {...this.generateTableOptions()}
          stretchH='all'
          width='100%'
          height='auto'
          autoWrapRow
          licenseKey='non-commercial-and-evaluation'
          afterGetColHeader={this.afterGetColHeader}
          beforeChange={this.beforeChange}
          afterChange={this.afterChange}
          beforeRender={this.beforeRender}
          tableClassName='table'
        />
      </>
    );
  }
}

export default Cpm;
