import React, { PureComponent } from 'react';
import cx from 'classnames';

import { formatNumberToNumber } from 'helpers/utils';
import Select from 'components/Select';
import LocalizedMessage from 'components/LocalizedMessage';
import { NumericInput } from 'components/NumericInput';
import { ILengthShare } from 'routes/AnnualPlanEdit/types';
import classes from './FilmDurations.module.scss';

interface IOwnProps {
  lengthShares: ILengthShare[];
  timeSelectOptions: ({value: number, label: string, isDisabled: boolean})[];
  onUpdateLengthShares: (val: ILengthShare[]) => void;
}

interface IState {
  length: number | null;
  share: number | null;
  isShowErrors: boolean;
  isShowErrorSumOfShares: boolean;
}

class FilmDurations extends PureComponent<IOwnProps, IState> {
  state: IState = { length: null, share: null, isShowErrors: false, isShowErrorSumOfShares: false };

  render () {
    const { lengthShares: durations, timeSelectOptions } = this.props;
    const { length, share, isShowErrors } = this.state;
    const selectedOption = length
      ? timeSelectOptions.find(({ value }) => value === length)
      : null;
    const isShareError = isShowErrors && (share === null || share > 100 || share < 0);
    const isLengthError = isShowErrors && length === null;
    const shareErrorTextLocalizedId = share === null
      ? 'annualPlanEdit.fields.share.error'
      : (share > 100
        ? 'annualPlanEdit.errors.exceed100percent'
        : 'annualPlanEdit.errors.notPositive');

    return (
      <LocalizedMessage
        id={[shareErrorTextLocalizedId, 'annualPlanEdit.fields.share.placeholder',
          'annualPlanEdit.fields.duration.error', 'annualPlanEdit.fields.duration.placeholder',
          'sec', 'annualPlanEdit.fields.duration.hint',
        ]}
      >
        {((shareErrorText, sharePlaceholder, durationError, durationPlaceholder, sec, durationHint) => (
          <div className={classes.filmDurations}>
            <div className={classes.newDuration}>
              <NumericInput
                step='0.01'
                className={cx(classes.newDurationShare, { [classes.error]: isShareError })}
                name='percent'
                min={0}
                max={100}
                value={share || ''}
                onChange={this.handlePercentChange}
                onInput={this.handlePercentInput}
                placeholder={sharePlaceholder}
                title={isShareError ? shareErrorText : undefined}
                data-test='clip-share'
              />
              <div
                title={isLengthError ? durationError : undefined}
                className={cx({ [classes.error]: isLengthError })}
              >
                <Select
                  className={classes.newDurationLength}
                  value={selectedOption}
                  options={timeSelectOptions}
                  onChange={this.handleSelectChange}
                  placeholder={durationPlaceholder}
                  dataTestAttr='clip-length'
                />
              </div>
            </div>
            <button
              className={classes.addDuration}
              onClick={this.handleButtonClick}
              data-test='add-clip'
            >
              +
            </button>
            {durations.map((d, index) => (
              <div key={index} className={classes.durationItem}>
                {`${d.length} ${sec}, ${d.share}%`}
                <button
                  className={classes.durationItemCross}
                  onClick={this.makeCrossClickHandler(index)}
                >
                  <i className='fa fa-times' />
                </button>
              </div>
            ))}
            {
              this.state.isShowErrorSumOfShares ? (
                <div className={classes.durationsError}>
                  <small className='text-danger'>
                    {durationHint}
                  </small>
                </div>
              )
                : null
            }
          </div>
        ))}
      </LocalizedMessage>
    );
  }

  handleButtonClick = () => {
    const { length, share } = this.state;
    if (length && share && !(share > 100 || share < 0)) {
      this.props.onUpdateLengthShares([...this.props.lengthShares, { length, share }]);
      this.setState({ length: null, share: null });
    } else {
      this.setState({ isShowErrors: true });
    }
  };

  handlePercentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    this.setState({ share: value === '' ? null : formatNumberToNumber(value), isShowErrors: false });
  };

  handlePercentInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = formatNumberToNumber(e.target.value);
    this.validateLengthShares(this.props.lengthShares, value);
  };

  handleSelectChange = ({ value }: { value: number, label: string }) => {
    this.setState({ length: value, isShowErrors: false });
  };

  makeCrossClickHandler = (itemIndex) => () => {
    const lengthShares = this.props.lengthShares.filter((_, index) => index !== itemIndex);
    this.props.onUpdateLengthShares(lengthShares);
    this.validateLengthShares(lengthShares);
  };

  validateLengthShares = (lengthShares, value = 0) => {
    const sumOfShares = (lengthShares.reduce((acc, { share }) => acc + share * 10, 0) + value * 10) / 10;
    this.setState({
      isShowErrorSumOfShares: sumOfShares !== 100,
    });
  };
}

export default FilmDurations;
