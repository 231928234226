import React, { PureComponent } from 'react';
import { Helmet } from 'react-helmet';
import LocalizedMessage from 'components/LocalizedMessage';
import URLFiltersHoc, { Props } from 'components/URLFiltersHoc';
import Breadcrumbs from 'components/Breadcrumbs';
import TableList from 'components/TableList';

import API from 'api';

interface IState {
  dataList: any[],
  dataListTotal: number;
  isLoading: boolean;
}

class UnitTypes extends PureComponent<Props, IState> {
  state: IState = {
    dataList: [],
    dataListTotal: 1,
    isLoading: false,
  };

  mounted = false;

  refreshListTimer = 0;
  refreshListTimerDelay = 500;

  componentDidMount () {
    this.mounted = true;
    this.refreshList();
  }

  componentDidUpdate (prevProps) {
    if (prevProps.filters !== this.props.filters) {
      this.refreshList();
    }
  }

  componentWillUnmount () {
    this.mounted = false;
    clearTimeout(this.refreshListTimer);
  }

  refreshList () {
    clearTimeout(this.refreshListTimer);
    const { currentPage, currentSort, max, items } = this.props.filters;
    const dataListRequest = {
      page: currentPage,
      order: currentSort,
      max,
      filter: { items },
    };

    this.refreshListTimer = window.setTimeout(async () => {
      this.setState({ isLoading: true });

      const dataList = await API.unitTypes.list(dataListRequest);

      if (!this.mounted) {
        return;
      }

      this.setState({
        dataList: dataList.items,
        dataListTotal: dataList.total,
        isLoading: false,
      });
    }, this.refreshListTimerDelay);
  }

  getBreadcrumbs () {
    return [
      {
        title: <LocalizedMessage id='home' />,
        link: '/app',
      },
      {
        title: <LocalizedMessage id='unitTypes' />,
        link: '/app/dictionaries/unitTypes',
      },
    ];
  }

  render () {
    const { onUpdateSort, onUpdatePage, filters } = this.props;
    const {
      dataListTotal,
      isLoading,
      dataList,
    } = this.state;

    return (
      <>
        <LocalizedMessage
          id='site.title.dictionaries.unitTypes'
        >
          {localizedMessage => (
            <>
              <Helmet
                title={localizedMessage}
              />
              <Breadcrumbs
                title={localizedMessage}
                data={this.getBreadcrumbs()}
              />
            </>
          )}
        </LocalizedMessage>

        <div className='wrapper wrapper-content'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='ibox float-e-margins'>
                <div className='ibox-content'>
                  <div className='table-responsive'>
                    <LocalizedMessage id={['name', 'dataType']}>
                      {(localizedName, localizedDataType) => (
                        <TableList
                          currentPage={filters.currentPage}
                          currentSort={filters.currentSort}
                          totalItems={dataListTotal}
                          itemsCountPerPage={filters.max}
                          onUpdateSort={onUpdateSort}
                          onUpdatePage={onUpdatePage}
                          isLoading={isLoading}
                          head={[
                            {
                              label: localizedName,
                              sort: 'name',
                            },
                            {
                              label: localizedDataType,
                              sort: 'data_type',
                            },
                          ]}
                        >
                          {
                            dataList.map((unitType) => (
                              <tr key={`${unitType.name}_${unitType.id}`}>
                                <td>
                                  {unitType.name}
                                </td>
                                <td>
                                  {unitType.dataType}
                                </td>
                              </tr>
                            ))
                          }
                        </TableList>
                      )}
                    </LocalizedMessage>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </>
    );
  }
}

export default URLFiltersHoc(UnitTypes);
