import Client from 'api/release/HTTPClient';
import { ISellerRestriction } from 'types';

const sellersAPI = {
  restrictions (year: number): Promise<ISellerRestriction[]> {
    return Client.get(`/api/sellers/restrictions/${year}`);
  },
};

export default sellersAPI;
