import Client from '../release/HTTPClient';

const tvAPI = {
  getPrices (year) {
    return Client.get(`/api/tvPrices/?year=${year}`);
  },

  getPricesAndCache (year) {
    return Client.get(`/api/tvPrices/?year=${year}`);
  },
};

export default tvAPI;
