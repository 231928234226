import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import LocalizedMessage, { localizeMessage } from 'components/LocalizedMessage';

import { Tab, Tabs } from 'react-bootstrap';
import 'libs/chart.js/chart-fix-tips';
import { formattedNumber, formattedNumberWithZero } from 'helpers/utils';
import TrTdHover from 'routes/AnnualPlan/components/Panels/components/TrTdHover';

import classes from '../AnnualPlan.module.scss';
import AdCampaignsChartsTab from './components/AdCampaignsChartsTab';
import { OutMediaplanRepresentation } from 'types';

interface IProps {
  mediaplan: OutMediaplanRepresentation;
  planId?: string;
  taId: number;
  months: { id: number; name: string }[];

  monthsInternetBudgets: number[];
  monthsInternetTRPs: number[];
  monthsTVBudgets: number[];
  monthsTVTRPs: number[];
  monthsBudgetBarChartData: number[];
  monthsMediaBudgetBarChartData: [number[], number[]];
  mediaBudgetPieChartData: number[];
  getClassByMonthId: (id: number) => string;
}
class AdCampaignsCalcResults extends Component<IProps> {
  static propTypes = {
    months: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired
      })
    ),
    mediaplan: PropTypes.object.isRequired,
    planId: PropTypes.string,
    monthsTVBudgets: PropTypes.array.isRequired,
    monthsTVTRPs: PropTypes.array.isRequired,
    monthsInternetBudgets: PropTypes.array.isRequired,
    monthsInternetTRPs: PropTypes.array.isRequired,
    monthsBudgetBarChartData: PropTypes.array.isRequired,
    monthsMediaBudgetBarChartData: PropTypes.array.isRequired,
    mediaBudgetPieChartData: PropTypes.array.isRequired,
    getClassByMonthId: PropTypes.func.isRequired
  };

  state = {
    adCampaignsCalcResultsSelector: 'FLOWCHART'
  };

  monthsBudgetBarChartLabels = this.props.months.map(month => month.name);

  monthsBudgetBarChartOptions = {
    responsive: true,
    legend: {
      display: false
    },
    title: {
      display: true,
      text: localizeMessage({ id: 'monthsBudget' }),
      position: 'top'
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true
          }
        }
      ]
    }
  };

  monthsBudgetBarChartColors = [
    '#7cb5ec',
    '#7cb5ec',
    '#7cb5ec',
    '#7cb5ec',
    '#7cb5ec',
    '#7cb5ec',
    '#7cb5ec',
    '#7cb5ec',
    '#7cb5ec',
    '#7cb5ec',
    '#7cb5ec',
    '#7cb5ec'
  ];

  monthsMediaBudgetBarChartLabels = this.props.months.map(month => month.name);

  monthsMediaBudgetBarChartOptions = {
    responsive: true,
    legend: {
      display: true
    },
    title: {
      display: true,
      text: localizeMessage({ id: 'monthsBudgetSplit' }),
      position: 'top'
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true
          }
        }
      ]
    }
  };

  monthsMediaBudgetBarChartSeries = [
    localizeMessage({ id: 'tv' }),
    localizeMessage({ id: 'internet' })
  ];

  monthsMediaBudgetBarChartColors = ['#7cb5ec', '#434448'];

  mediaBudgetPieChartLabels = [
    localizeMessage({ id: 'tvPercent' }),
    localizeMessage({ id: 'internetPercent' })
  ];

  mediaBudgetPieChartOptions = {
    responsive: true,
    legend: { display: false },
    showAllTooltips: true,
    title: {
      display: true,
      text: localizeMessage({ id: 'mediaSplit' }),
      position: 'top'
    },
    tooltips: { backgroundColor: 'rgba(204,204,204,0.8)' }
  };

  mediaBudgetPieChartColors = ['#7cb5ec', '#434448'];

  selectTab = adCampaignsCalcResultsSelector => {
    this.setState({
      adCampaignsCalcResultsSelector
    });
  };

  render () {
    const {
      months,
      monthsTVBudgets,
      monthsTVTRPs,
      monthsInternetBudgets,
      monthsInternetTRPs,
      monthsBudgetBarChartData,
      monthsMediaBudgetBarChartData,
      mediaBudgetPieChartData,
      getClassByMonthId,
      planId,
      taId,
      mediaplan
    } = this.props;
    const { adCampaignsCalcResultsSelector } = this.state;

    return (
      <div className='row'>
        <div className='col-lg-12'>
          <div className='ibox'>
            <div className='ibox-title'>
              <h5>
                <LocalizedMessage id='annualPlan.adCampaignCalcResults.title' />
              </h5>
            </div>
            <div className='ibox-content'>
              <div className='tabs-container'>
                <LocalizedMessage
                  id={[
                    'annualPlan.adCampaignCalcResults.tabs.flowchart',
                    'annualPlan.adCampaignCalcResults.tabs.charts'
                  ]}
                >
                  {(localizedFlowchart, localizedCharts) => (
                    <Tabs
                      id='ad-campaigns-calc-result-tabs'
                      defaultActiveKey={adCampaignsCalcResultsSelector}
                      mountOnEnter
                      unmountOnExit
                      onSelect={this.selectTab}
                    >
                      <Tab eventKey='FLOWCHART' title={localizedFlowchart}>
                        <div className={classes['display-flex']}>
                          <div>
                            <table
                              className={cx(
                                'table',
                                classes.table,
                                'table-bordered',
                                classes['centered-cells']
                              )}
                            >
                              <thead>
                                <tr>
                                  <th className='text-nowrap' colSpan={2}>
                                    <span>
                                      <LocalizedMessage id='media' />
                                    </span>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <TrTdHover
                                    id='tvHeader'
                                    className='text-nowrap'
                                    rowSpan={2}
                                    tag='td'
                                    direction='left'
                                    dependentIds={[
                                      'rightRow1',
                                      'rightRow2',
                                      'tvBudgetHeader',
                                      'tvTrp'
                                    ]}
                                  >
                                    <LocalizedMessage id='tv' />
                                  </TrTdHover>
                                  <TrTdHover
                                    id='tvBudgetHeader'
                                    className='text-nowrap'
                                    tag='td'
                                    direction='left'
                                    dependentIds={['rightRow1', 'tvHeader']}
                                  >
                                    <LocalizedMessage id='budget' />
                                  </TrTdHover>
                                </tr>
                                <tr>
                                  <TrTdHover
                                    id='tvTrp'
                                    className='text-nowrap'
                                    tag='td'
                                    direction='left'
                                    dependentIds={['rightRow2', 'tvHeader']}
                                  >
                                    TRP
                                  </TrTdHover>
                                </tr>
                                <tr>
                                  <TrTdHover
                                    id='internetHeader'
                                    className='text-nowrap'
                                    rowSpan={2}
                                    tag='td'
                                    direction='left'
                                    dependentIds={[
                                      'rightRow3',
                                      'rightRow4',
                                      'internetBudgetHeader',
                                      'internetTrpHeader'
                                    ]}
                                  >
                                    <LocalizedMessage id='internet' />
                                  </TrTdHover>
                                  <TrTdHover
                                    id='internetBudgetHeader'
                                    className='text-nowrap'
                                    tag='td'
                                    direction='left'
                                    dependentIds={[
                                      'rightRow3',
                                      'internetHeader'
                                    ]}
                                  >
                                    <LocalizedMessage id='budget' />
                                  </TrTdHover>
                                </tr>
                                <tr>
                                  <TrTdHover
                                    id='internetTrpHeader'
                                    className='text-nowrap'
                                    tag='td'
                                    direction='left'
                                    dependentIds={[
                                      'rightRow4',
                                      'internetHeader'
                                    ]}
                                  >
                                    <LocalizedMessage id='trp' />
                                  </TrTdHover>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className={classes['right-flex-table']}>
                            <table
                              className={cx(
                                'table',
                                classes.table,
                                'table-bordered',
                                classes['centered-cells']
                              )}
                            >
                              <thead>
                                <tr>
                                  {months.map(month => (
                                    <th
                                      key={month.id}
                                      className={cx(
                                        classes['month-budget-column'],
                                        getClassByMonthId(month.id)
                                      )}
                                    >
                                      {month.name}
                                    </th>
                                  ))}
                                </tr>
                              </thead>
                              <tbody>
                                <TrTdHover
                                  id='rightRow1'
                                  tag='tr'
                                  direction='right'
                                  dependentIds={['tvHeader', 'tvBudgetHeader']}
                                >
                                  {months.map(month => (
                                    <td
                                      key={month.id}
                                      className={getClassByMonthId(month.id)}
                                    >
                                      {formattedNumberWithZero(
                                        monthsTVBudgets[month.id]
                                      )}
                                    </td>
                                  ))}
                                </TrTdHover>
                                <TrTdHover
                                  id='rightRow2'
                                  tag='tr'
                                  direction='right'
                                  dependentIds={['tvHeader', 'tvTrp']}
                                >
                                  {months.map(month => (
                                    <td
                                      key={month.id}
                                      className={getClassByMonthId(month.id)}
                                    >
                                      {formattedNumber(
                                        monthsTVTRPs[month.id],
                                        0
                                      )}
                                    </td>
                                  ))}
                                </TrTdHover>
                                <TrTdHover
                                  id='rightRow3'
                                  tag='tr'
                                  direction='right'
                                  dependentIds={[
                                    'internetHeader',
                                    'internetBudgetHeader'
                                  ]}
                                >
                                  {months.map(month => (
                                    <td
                                      key={month.id}
                                      className={getClassByMonthId(month.id)}
                                    >
                                      {formattedNumberWithZero(
                                        monthsInternetBudgets[month.id]
                                      )}
                                    </td>
                                  ))}
                                </TrTdHover>
                                <TrTdHover
                                  id='rightRow4'
                                  tag='tr'
                                  direction='right'
                                  dependentIds={[
                                    'internetHeader',
                                    'internetTrpHeader'
                                  ]}
                                >
                                  {months.map(month => (
                                    <td
                                      key={month.id}
                                      className={getClassByMonthId(month.id)}
                                    >
                                      {formattedNumber(
                                        monthsInternetTRPs[month.id],
                                        0
                                      )}
                                    </td>
                                  ))}
                                </TrTdHover>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </Tab>

                      <Tab eventKey='CHARTS' title={localizedCharts}>
                        <AdCampaignsChartsTab
                          disableTvOlv={
                            mediaplan.tvBudgetPercent === 100 ||
                            mediaplan.internetBudgetPercent === 100
                          }
                          planId={planId}
                          taId={taId}
                          plots={mediaplan.plots}
                          mediaBudgetPieChartData={mediaBudgetPieChartData}
                          months={months}
                          monthsBudgetBarChartData={monthsBudgetBarChartData}
                          monthsMediaBudgetBarChartData={
                            monthsMediaBudgetBarChartData
                          }
                        />
                      </Tab>
                    </Tabs>
                  )}
                </LocalizedMessage>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AdCampaignsCalcResults;
