import Client from 'api/release/HTTPClient';


// it's a redirect
const pricingUploadAPI = {
  federal (formData) {
    return Client.put('/api/pricing-upload/federal', formData, true, { isJson: false, isMultipart: true });
  },
  local (formData) {
    return Client.put('/api/pricing-upload/local', formData, true, { isJson: false, isMultipart: true });
  },
  thematic (formData) {
    return Client.put('/api/pricing-upload/thematic', formData, true, { isJson: false, isMultipart: true });
  },
};

export default pricingUploadAPI;
