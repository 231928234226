import React, { Component, PropsWithChildren } from 'react';

interface IProps {
  tag: 'tr' | 'td';
  dependentRowId: string;
  hoverColors: string[];
  colors: string[];
  id?: string;
  className?: string;
}
class MonthHoverQualityTable extends Component<PropsWithChildren<IProps>> {
  static defaultProps = {
    tag: 'tr'
  };

  _element: HTMLElement | null = null;

  componentDidMount () {
    if (!this._element) {
      return;
    }

    this._element.addEventListener('mouseenter', this.onMouseEnter);
    this._element.addEventListener('mouseleave', this.onMouseLeave);
  }

  componentWillUnmount () {
    if (!this._element) {
      return;
    }

    this._element.removeEventListener('mouseenter', this.onMouseEnter);
    this._element.removeEventListener('mouseleave', this.onMouseLeave);
  }

  setTdRef = ref => {
    this._element = ref;
  };

  onMouseEnter = () => {
    const { dependentRowId, hoverColors } = this.props;

    const dependentElement = document.getElementById(dependentRowId);
    if (!this._element) return;

    for (let i = 0; i < this._element.children.length; i++) {
      (this._element.children[i] as HTMLElement).style.backgroundColor = hoverColors[i];
    }

    if (dependentElement) {
      for (let j = 0; j < dependentElement.children.length; j++) {
        (dependentElement.children[j] as HTMLElement).style.backgroundColor = hoverColors[j];
      }
    }
  };

  onMouseLeave = () => {
    const { dependentRowId, colors } = this.props;

    const dependentElement = document.getElementById(dependentRowId);
    if (!this._element) return;
    for (let i = 0; i < this._element.children.length; i++) {
      (this._element.children[i] as HTMLElement).style.backgroundColor = colors[i];
    }
    if (dependentElement) {
      for (let j = 0; j < dependentElement.children.length; j++) {
        (dependentElement.children[j] as HTMLElement).style.backgroundColor = colors[j];
      }
    }
  };

  render () {
    const {
      children,
      tag,
      dependentRowId,
      colors,
      hoverColors,
      ...props
    } = this.props;

    return (
      <this.props.tag ref={this.setTdRef} {...props}>
        {children}
      </this.props.tag>
    );
  }
}

export default MonthHoverQualityTable;
