import React, { PureComponent } from 'react';

import Select from 'components/Select';
import LocalizedMessage from 'components/LocalizedMessage';
import { IOption } from 'types';
import { ITargetAudience, FullTargetAudience } from '../../../types';
import classes from '../../AnnualPlanEdit.module.scss';

interface IProps {
  value: ITargetAudience | null;
  index: number;
  targetAudiences: FullTargetAudience[];
  makeChangeHandler: (index: number) => (option: IOption) => void;
}

interface IState {
  options: IOption[];
}

class TargetAudienceSelect extends PureComponent<IProps, IState> {
  state: IState = {
    options: this.props.targetAudiences.map(ta => ({
      label: ta.name,
      value: ta.id,
    })),
  };

  componentDidUpdate (prevProps: IProps) {
    const { targetAudiences } = this.props;

    if (prevProps.targetAudiences !== targetAudiences) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        options: targetAudiences.map(ta => ({
          label: ta.name,
          value: ta.id,
        })),
      });
    }
  }

  render () {
    const { index, makeChangeHandler, value } = this.props;
    const { options } = this.state;

    return (
      <LocalizedMessage id='annualPlanEdit.fields.selectTargetAudience.placeholder'>
        {(localizedSelectTargetAudience) => (
          <Select<IOption>
            isSearchable
            isMulti={false}
            placeholder={localizedSelectTargetAudience}
            onChange={makeChangeHandler(index)}
            options={options}
            value={value ? options.find(option => (option.value === value.id)) : null}
            className={classes['small-single-selectize']}
            dataTestAttr='target-audience'
          />
        )}
      </LocalizedMessage>
    );
  }
}

export default TargetAudienceSelect;
