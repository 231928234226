import Client from 'api/release/HTTPClient';
import { IAdFormat, IGenericListRequest, IServerPage } from 'types';

const adFormatsAPI = {
  list (request: IGenericListRequest): Promise<IServerPage<IAdFormat>> {
    return Client.post('/api/adFormats/', request);
  },

  get (id: number): Promise<IAdFormat> {
    return Client.get(`/api/adFormats/${id}`);
  }
};

export default adFormatsAPI;
