import Select from 'components/Select';
import React, { FC, useMemo } from 'react';
import { IAgency } from 'types';

import s from './AgenciesDropdown.module.scss';

type AgenciesDropdownProps = {
  items: Array<Required<IAgency>>
  currentAgencyId: number
  onSelect: (agencyId: number) => void
};

export const AgenciesDropdown: FC<AgenciesDropdownProps> = ({
  currentAgencyId,
  items,
  onSelect
}) => {
  if (items.length === 1) {
    return (
      <div className={s.container}>
        <span className='agency-name'>{items[0].name}</span>
      </div>
    );
  }

  const options = useMemo(() => items.map(({ id: value, name: label }) => ({ label, value })), [items]);
  const defaultValue = useMemo(
    () => options.find(({ value }) => value === currentAgencyId), [currentAgencyId, options]
  );

  const onChange = (option) => {
    if (!option) return;

    onSelect(option.value);
  };

  return (
    <div className={s.container}>
      <Select
        className={s.select}
        options={options}
        onChange={onChange}
        defaultValue={defaultValue}
        isSearchable={false}
      />
    </div>
  );
};
