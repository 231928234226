import Client from 'api/release/HTTPClient';
import { IServerPage, ITargeting } from 'types';

const targetingsAPI = {
  list (request): Promise<IServerPage<ITargeting>> {
    return Client.post('/api/targetings/', request);
  }
};

export default targetingsAPI;
