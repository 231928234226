import {
  defaultCbuValues,
  ICbuValues
} from 'routes/AnnualPlanEdit/components/Panels/Charts/Tabs/Cbu';
import {
  defaultTvOlvValues,
  ITvOLVValues
} from 'routes/AnnualPlanEdit/components/Panels/Charts/Tabs/TvOLV';
import { hasNullValues } from 'helpers/isContentNull';
import { ICampaignPlots } from 'routes/AnnualPlanEdit/types';

const isPlotValid = (plot: (ICbuValues | ITvOLVValues) | null) => {
  if (plot === null) return false;
  if (hasNullValues(plot)) return false;

  if (!plot.enable) return true;

  if ((plot.min || 0) >= (plot.max || 0)) return false;
  if (plot.step == null || plot.step === 0) return false;

  return plot.freqs != null && plot.freqs?.length > 0;
};
export const isCbuValid = (cbu?: ICbuValues) => {
  if (!cbu?.enable) return true;

  return isPlotValid(cbu);
};

export const isTvOlvValid = (tvOlv?: ITvOLVValues) => {
  if (!tvOlv) return false;
  const copy = { ...tvOlv };

  delete copy['calculationBase'];

  return isPlotValid(copy);
};

const fillNullValuesWithDefault = <T extends {}>(
  nullable: Partial<T>,
  defaultValues: T
) => {
  Object.entries(defaultValues).forEach(([key, value]) => {
    if (nullable[key] === null || nullable[key] === undefined) {
      nullable[key] = value;
    }
  });
};

export const insertDefaultPlotValues = (
  plots?: ICampaignPlots
): ICampaignPlots => {
  const correctCbuPlot: ICbuValues =
    (plots?.cbuPlot && {
      ...plots?.cbuPlot
    }) ||
    defaultCbuValues;
  fillNullValuesWithDefault(correctCbuPlot, defaultCbuValues);

  const correctTvOlvPlot: ITvOLVValues =
    (plots?.tvOlvPlot && {
      ...plots?.tvOlvPlot
    }) ||
    defaultTvOlvValues;
  fillNullValuesWithDefault(correctTvOlvPlot, defaultTvOlvValues);

  return {
    tvOlvPlot: correctTvOlvPlot,
    cbuPlot: correctCbuPlot
  };
};
