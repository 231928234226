import React, { Component } from 'react';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import LocalizedMessage, { localizeMessage } from 'components/LocalizedMessage';

import Select from 'components/Select';
import Breadcrumbs from 'components/Breadcrumbs';
import { NumericInput } from 'components/NumericInput';

import API from 'api';
import Alert from 'helpers/alert';
import { ICommitment, IOption } from 'types';
import { RouteChildrenProps } from 'react-router';

interface IState {
  data: ICommitment | null;

  advertisersOptions: IOption[];
  sitesOptions: IOption[];
  adFormatsOptions: IOption[];
  periodOptions: IOption[];

  budget?: number;
  advertiserId: IOption | null;
  site: IOption | null;
  adUnit: string;
  adFormat: IOption | null;
  period: IOption | null;
  commitment?: number;
}
class Commitment extends Component<
RouteChildrenProps<{ id?: string }> & { agencyId: number },
IState
> {
  static propTypes = {};

  years = {
    startYear: moment()
      .add(1, 'year')
      .year(),
    endYear: moment()
      .add(-5, 'year')
      .year()
  };

  state: IState = {
    data: null,

    advertisersOptions: [],
    sitesOptions: [],
    adFormatsOptions: [],
    periodOptions: this.getPeriodOptions(),

    advertiserId: null,
    site: null,
    adUnit: '',
    adFormat: null,
    period: null,
    commitment: 0
  };

  mounted = false;

  _form: HTMLFormElement | null = null;

  componentDidMount () {
    this.mounted = true;

    this.loadData();
  }

  componentDidUpdate (prevProps) {
    if (prevProps.agencyId !== this.props.agencyId) {
      this.loadData();
    }
  }

  componentWillUnmount () {
    this.mounted = false;
  }

  getPeriodOptions () {
    const years: IOption[] = [];

    for (let year = this.years.startYear; year >= this.years.endYear; year--) {
      years.push({
        value: year,
        label: year.toString()
      });
    }

    return years;
  }

  async loadData () {
    const { match } = this.props;
    const params = match?.params;

    try {
      const [advertisers, sites, adFormats, commitment] = await Promise.all([
        API.advertisers.list({ max: 0, filter: { items: [`AGENCY_${this.props.agencyId}`] } }),
        API.sites.list({ max: 0, order: 'name' }),
        API.adFormats.list({ max: 0 }),
        params?.id ? API.commitments.get(params.id) : Promise.resolve(null)
      ]);

      if (!this.mounted) {
        return;
      }

      const advertisersOptions = advertisers.items.map(item => ({
        value: item.id,
        label: item.name
      }));

      const sitesOptions = sites.items.map(item => ({
        value: item.id,
        label: item.name
      }));

      const adFormatsOptions = adFormats.items.map(item => ({
        value: item.id,
        label: item.name
      }));

      if (commitment) {
        this.setState({
          adFormatsOptions,

          data: commitment,
          advertiserId:
            advertisersOptions.find(
              advertiser => advertiser.value === commitment.advertiserId
            ) || null,
          site:
            sitesOptions.find(site => site.value === commitment.site) || null,
          adUnit: commitment.adUnit,
          adFormat:
            adFormatsOptions.find(
              adFormat => adFormat.value === commitment.adFormat
            ) || null,
          period: {
            label: commitment.period,
            value: commitment.period
          },
          commitment: commitment.commitment
        });
      } else {
        this.setState({
          advertisersOptions
        });
      }
    } catch (error) {
      console.error(error);

      Alert.error(localizeMessage({ id: 'errors.errorLoadingData' }));
    }
  }

  getBreadcrumbs () {
    return [
      {
        title: <LocalizedMessage id='home' />,
        link: '/app'
      },
      {
        title: <LocalizedMessage id='commitments' />,
        link: '/app/commitments'
      },
      {
        title: <LocalizedMessage id='commitment-detail' />
      }
    ];
  }

  setFormRef = ref => {
    this._form = ref;
  };

  handleChangeAdvertiserSelect = advertiserId => {
    this.setState({
      advertiserId
    });
  };

  getSelectAdvertiserConfig () {
    const { advertiserId, advertisersOptions } = this.state;

    return {
      isSearchable: false,
      isMulti: false,
      value: advertiserId,
      onChange: this.handleChangeAdvertiserSelect,
      options: advertisersOptions,
      placeholder: <LocalizedMessage id='commitment.advertiser.placeholder' />,
      inputId: 'advertiserId'
    };
  }

  handleChangeSiteSelect = site => {
    this.setState({
      site
    });
  };

  getSelectSiteConfig () {
    const { site, sitesOptions } = this.state;

    return {
      isSearchable: false,
      isMulti: false,
      value: site,
      onChange: this.handleChangeSiteSelect,
      options: sitesOptions,
      placeholder: <LocalizedMessage id='commitment.site.placeholder' />,
      inputId: 'site'
    };
  }

  handleChangeAdFormatSelect = adFormat => {
    this.setState({
      adFormat
    });
  };

  getSelectAdFormatConfig () {
    const { site, sitesOptions } = this.state;

    return {
      isSearchable: false,
      isMulti: false,
      value: site,
      onChange: this.handleChangeSiteSelect,
      options: sitesOptions,
      placeholder: <LocalizedMessage id='commitment.adFormat.placeholder' />,
      inputId: 'adFormat'
    };
  }

  handleChangePeriodSelect = period => {
    this.setState({
      period
    });
  };

  getSelectPeriodConfig () {
    const { period, periodOptions } = this.state;

    return {
      isSearchable: false,
      isMulti: false,
      value: period,
      onChange: this.handleChangePeriodSelect,
      options: periodOptions,
      placeholder: <LocalizedMessage id='commitment.period.placeholder' />,
      inputId: 'period'
    };
  }

  handleInputChange = e => {
    const key = e.target.name;
    if (key !== 'adUnit' && key !== 'commitment') return;

    const value = e.target.type === 'file' ? e.target.files[0] : e.target.value;

    if (key === 'adUnit') {
      this.setState({
        adUnit: value
      });
    } else if (key === 'commitment') {
      this.setState({
        commitment: Number(value)
      });
    }
  };

  resetClick = () => {
    this._form?.reset();

    this.setState({
      advertiserId: null,
      period: null,
      budget: 0
    });
  };

  onSubmit = async e => {
    e.preventDefault();

    const { match } = this.props;
    const params = match?.params;

    const {
      advertiserId,
      site,
      adUnit,
      adFormat,
      period,
      commitment
    } = this.state;

    if (
      !advertiserId ||
      !advertiserId.value ||
      !site ||
      !site.value ||
      !adUnit ||
      !adUnit.length ||
      !adFormat ||
      !adFormat.value ||
      !period ||
      !period.value ||
      !commitment
    ) {
      return;
    }

    try {
      await API.commitments.save(params?.id, {
        advertiserKeycloakId: advertiserId.value,
        site: site.value,
        adUnit,
        adFormat: adFormat.value,
        period: period.value,
        commitment
      });

      Alert.success(localizeMessage({ id: 'commitment.successfullyCreated' }));

      this.resetClick();
    } catch (error) {
      console.error(error);

      Alert.warn(localizeMessage({ id: 'commitment.errorCreating' }));
    }
  };

  render () {
    const {
      data,

      advertiserId,
      site,
      adUnit,
      adFormat,
      period,
      commitment
    } = this.state;

    return (
      <>
        <LocalizedMessage id='site.title.commitment'>
          {localizedMessage => (
            <>
              <Helmet title={localizedMessage} />
              <Breadcrumbs
                title={localizedMessage}
                data={this.getBreadcrumbs()}
              />
            </>
          )}
        </LocalizedMessage>

        <div className='wrapper wrapper-content'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='ibox float-e-margins'>
                <div className='ibox-title'>
                  <h5>
                    <LocalizedMessage
                      id={`commitment.${!data ? 'adding' : 'editing'}`}
                    />
                  </h5>
                </div>
                <div className='ibox-content'>
                  <form ref={this.setFormRef} onSubmit={this.onSubmit}>
                    <div className='form-horizontal'>
                      <div className='form-group'>
                        <label
                          className='col-lg-2 control-label'
                          htmlFor='advertiserId'
                        >
                          <LocalizedMessage id='advertiser' />
                        </label>
                        <div className='col-lg-10'>
                          <Select {...this.getSelectAdvertiserConfig()} />
                        </div>
                      </div>

                      <div className='form-group'>
                        <label
                          className='col-lg-2 control-label'
                          htmlFor='site'
                        >
                          <LocalizedMessage id='site' />
                        </label>
                        <div className='col-lg-10'>
                          <Select {...this.getSelectSiteConfig()} />
                        </div>
                      </div>

                      <div className='form-group'>
                        <label
                          className='col-lg-2 control-label'
                          htmlFor='adUnit'
                        >
                          <LocalizedMessage id='adUnit' />
                        </label>
                        <div className='col-lg-10'>
                          <LocalizedMessage id='commitment.adUnit.placeholder'>
                            {localizedPlaceholder => (
                              <input
                                id='adUnit'
                                type='text'
                                className='form-control'
                                name='adUnit'
                                value={adUnit || ''}
                                placeholder={localizedPlaceholder}
                                onChange={this.handleInputChange}
                              />
                            )}
                          </LocalizedMessage>
                        </div>
                      </div>

                      <div className='form-group'>
                        <label
                          className='col-lg-2 control-label'
                          htmlFor='adFormat'
                        >
                          <LocalizedMessage id='adFormat' />
                        </label>
                        <div className='col-lg-10'>
                          <Select {...this.getSelectAdFormatConfig()} />
                        </div>
                      </div>

                      <div className='form-group'>
                        <label
                          className='col-lg-2 control-label'
                          htmlFor='period'
                        >
                          <LocalizedMessage id='period' />
                        </label>

                        <div className='col-lg-10'>
                          <Select {...this.getSelectPeriodConfig()} />
                        </div>
                      </div>

                      <div className='form-group'>
                        <label
                          className='col-lg-2 control-label'
                          htmlFor='commitment'
                        >
                          <LocalizedMessage id='budget' />
                        </label>
                        <div className='col-lg-10'>
                          <LocalizedMessage id='commitment.commitment.placeholder'>
                            {localizedPlaceholder => (
                              <NumericInput
                                id='commitment'
                                className='form-control'
                                name='commitment'
                                value={commitment}
                                placeholder={localizedPlaceholder}
                                onChange={this.handleInputChange}
                              />
                            )}
                          </LocalizedMessage>
                        </div>
                      </div>

                      <div className='hr-line-dashed' />

                      <div className='form-group'>
                        <div className='col-lg-2' />
                        <div className='col-lg-10'>
                          <button
                            className='btn btn-white'
                            onClick={this.resetClick}
                          >
                            <LocalizedMessage id='reset' />
                          </button>
                          &nbsp;&nbsp;
                          <button
                            type='submit'
                            className='btn btn-primary'
                            disabled={
                              !advertiserId ||
                              !advertiserId.value ||
                              !site ||
                              !site.value ||
                              !adUnit ||
                              !adUnit.length ||
                              !adFormat ||
                              !adFormat.value ||
                              !period ||
                              !period.value ||
                              !commitment
                            }
                            onClick={this.onSubmit}
                          >
                            <>
                              <i className='fa fa-chevron-down fa-lg' />
                              <span>
                                <LocalizedMessage id='save' />
                              </span>
                            </>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Commitment;
