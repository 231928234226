import { fetchResponse } from './helpers/fakeFetch';

import divisionsList from './data/divisionsList.json';
import divisionsYears from './data/divisionsYears.json';
import divisionsItems from './data/divisionsItems.json';

const divisionsAPI = {
  list (request) {
    console.log('api.divisions.list(', request, ')');

    return fetchResponse(1000, divisionsList);
  },

  year (year) {
    console.log(`api.divisions.year(${year})`);

    return fetchResponse(1000, divisionsYears[year]);
  },

  get (id, request) {
    console.log(`api.divisions.get(${id}, `, request, ')');

    return fetchResponse(1000, divisionsItems[id]);
  },
};

export default divisionsAPI;
