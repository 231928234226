import Config from 'config';
import { serialize, fetchResponse } from 'helpers/fetch';
import { getCookie } from '../../libs/cookies';
import store from 'store';

const getHeaders = (withToken, isJson, isMultipart, withRealm, isStream) => {
  const headers = {
    ...Config.api.headers,
  };

  headers['Accept-Language'] = store.getState().locale.lang;

  if (isMultipart) {
    // headers['Content-Type'] = 'multipart/form-data';
  } else if (isJson) {
    headers.Accept = 'application/json';
    headers['Content-Type'] = 'application/json';
  } else {
    headers['Content-Type'] = 'application/x-www-form-urlencoded';
  }

  if (isStream) {
    headers['Content-Type'] = 'application/octet-stream';
  }

  const token = getCookie('accessToken');
  if (token && withToken) {
    headers.Authorization = `Bearer ${token}`;
  }

  if (withRealm || withToken) {
    headers.realm = 'ADV';
  }

  return headers;
};

const getBody = (data, isJson, isMultipart) => {
  if (typeof data === 'function') {
    data = data();
  }

  if (isMultipart) {
    return data;
  }

  return isJson ? JSON.stringify(data) : serialize(data);
};

export default {
  get (uri, data = {}, withToken = true, options = {}, showError, initController, otherParams = {}) {
    const query = Object.keys(data).length ? `?${serialize(data)}` : '';
    const { isJson = true, isMultipart = false, withRealm = false, isStream = false } = options;

    return fetchResponse((uri.charAt(0) === '/' ? Config.api.host : '') + uri + query, {
      headers: () => getHeaders(withToken, isJson, isMultipart, withRealm, isStream),
      credentials: 'include',
      initController,
      ...otherParams,
    }, showError);
  },

  post (uri, data = {}, withToken = true, options = {}, showError, initController, otherParams = {}) {
    const { isJson = true, isMultipart = false } = options;

    return fetchResponse((uri.charAt(0) === '/' ? Config.api.host : '') + uri, {
      method: 'POST',
      headers: () => getHeaders(withToken, isJson, isMultipart),
      body: () => getBody(data, isJson, isMultipart),
      credentials: 'include',
      initController,
      ...otherParams,
    }, showError);
  },

  put (uri, data = {}, withToken = true, options = {}, showError, initController, otherParams = {}) {
    const { isJson = true, isMultipart = false } = options;

    return fetchResponse((uri.charAt(0) === '/' ? Config.api.host : '') + uri, {
      method: 'PUT',
      headers: () => getHeaders(withToken, isJson, isMultipart),
      body: () => getBody(data, isJson, isMultipart),
      credentials: 'include',
      initController,
      ...otherParams,
    }, showError);
  },

  delete (uri, data = {}, withToken = true, options = {}, showError, initController, otherParams = {}) {
    const { isJson = true, isMultipart = false } = options;

    return fetchResponse((uri.charAt(0) === '/' ? Config.api.host : '') + uri, {
      method: 'DELETE',
      headers: () => getHeaders(withToken, isJson, isMultipart),
      body: () => getBody(data, isJson, isMultipart),
      credentials: 'include',
      initController,
      ...otherParams,
    }, showError);
  },
};
