import React, { PureComponent, Fragment } from 'react';
import cx from 'classnames';
import moment, { Moment } from 'moment';
import Tooltip from 'rc-tooltip';
import Cleave from 'cleave.js/react';
import { DateRangePicker } from 'react-dates';
import { v4 as uuid } from 'uuid';
import without from 'lodash/without';
import pick from 'lodash/pick';
import omit from 'lodash/omit';
import SweetAlert from '@sweetalert/with-react';

import LocalizedMessage, { localizeMessage } from 'components/LocalizedMessage';
import Loader from 'components/Loader';
import Select from 'components/Select';
import { NumericInput } from 'components/NumericInput';

import {
  formattedNumberWithZero,
  percentOrHyphen,
  getYearOfDate
} from 'helpers/utils';
import Alert from 'helpers/alert';
import { MAX_INT_VALUE } from 'helpers/constants';
import API from 'api';
import { AllowedMediaStatus, IBrand, IOption } from 'types';
import TargetAudienceSelect from './TargetAudienceSelect';
import TrTdHover from 'routes/AnnualPlanEdit/components/Panels/components/TrTdHover';
import { FEDERAL_CHANNEL } from '../../../helpers/constants';
import {
  ICampaign,
  FullTargetAudience,
  INullableMomentRange,
  IMomentRange,
  MapCampaignToTargetAudienceTypes,
  CampaignUpdate,
  ITargetAudience,
  NullableFromTo,
  IFederalChannel
} from '../../../types';
import classes from '../../AnnualPlanEdit.module.scss';

interface IProps {
  selectedCampaignID: string | number | null;
  regionsOptions: Array<{ value: number; label: string; rgn: number | null }>;
  brands: IBrand[];
  targetAudiences: FullTargetAudience[];
  year: number;
  allowedMediaStatus: AllowedMediaStatus;
  mapCampaignToTargetAudienceTypes: MapCampaignToTargetAudienceTypes;
  retroDiffs: NullableFromTo;

  getCampaigns: () => ICampaign[];
  onUpdateHasEditableCampaigns: (hasEditableCampaigns: boolean) => void;
  onSelectCampaign: (campaignID: string | number) => void;
  onUpdateAudienceList: (isFederalChannels: boolean) => void;
  onUpdateAudienceType: (
    campaignID: string | number,
    retroFrom: string,
    retroTo: string,
    targetAudience: ITargetAudience,
    isNewTaName: boolean
  ) => void;

  onEditCampaign: (
    campaignID: string | number,
    update: Partial<ICampaign>
  ) => void;
  onAddNewCampaign: (
    campaignID: string | number,
    update: CampaignUpdate
  ) => void;
  onRemoveCampaign: (campaignID: string | number) => void;
  onCheckRetroperiodRange(range: INullableMomentRange): void;
}
type IPartialCampaign = CampaignUpdate & { id: string | number };

interface IState {
  focusedPeriodInput: any[];
  focusedRetroPeriodInput: any[];
  campaigns: Array<IEditebleCampaign | IPartialCampaign>;
}

interface IEditebleCampaign extends ICampaignEditableParameters {
  id: string | number;
  editing: true;
  isNew: boolean;
  errors: Record<string, string | undefined>;
  showErrors: boolean;
}

interface ICampaignEditableParameters {
  name: string;
  channel: ICampaign['channel'] | null;
  brand: ICampaign['brand'];
  dateRangePickerData: INullableMomentRange;
  retroPeriodPickerData: IMomentRange;
  budget: number;
  tvBudgetPercent: number;
  internetBudgetPercent: number;
  targetAudience: ICampaign['targetAudience'] | null;
  additionalTA: (ICampaign['targetAudience'])[] | null;
}

const isEditableCampaign = (
  campaign: IEditebleCampaign | IPartialCampaign
): campaign is IEditebleCampaign => (campaign as IEditebleCampaign).editing === true;

const convertToPartialCampaign = (campaign: ICampaign): IPartialCampaign => pick(campaign, [
  'id',
  'name',
  'channel',
  'brand',
  'from',
  'to',
  'retroFrom',
  'additionalTA',
  'retroTo',
  'budget',
  'tvBudgetPercent',
  'internetBudgetPercent',
  'targetAudience'
]);

const convertToEditableCampaign = (
  campaign: IPartialCampaign
): IEditebleCampaign => ({
  id: campaign.id,
  editing: true,
  isNew: false,
  errors: {},
  showErrors: false,
  name: campaign.name,
  channel: campaign.channel,
  brand: campaign.brand,
  dateRangePickerData: {
    startDate: moment(campaign.from),
    endDate: moment(campaign.to)
  },
  retroPeriodPickerData: {
    startDate: moment(campaign.retroFrom),
    endDate: moment(campaign.retroTo)
  },
  budget: campaign.budget,
  tvBudgetPercent: campaign.tvBudgetPercent,
  internetBudgetPercent: campaign.internetBudgetPercent,
  targetAudience: campaign.targetAudience,
  additionalTA: campaign.additionalTA
});

const getNewEditableCampaign = (
  retroDiffs: NullableFromTo,
  mediaStatus: AllowedMediaStatus,
  channel: IFederalChannel | null,
): IEditebleCampaign => ({
  id: uuid(),
  editing: true,
  isNew: true,
  errors: {},
  showErrors: false,
  name: '',
  channel,
  brand: undefined,
  dateRangePickerData: {
    startDate: null,
    endDate: null
  },
  retroPeriodPickerData: {
    startDate: moment()
      .subtract(retroDiffs.from!, 'months')
      .startOf('month'),
    endDate: moment()
      .subtract(retroDiffs.to!, 'months')
      .endOf('month')
  },
  budget: 0,
  tvBudgetPercent: mediaStatus === 'tv' || mediaStatus === 'all' ? 100 : 0,
  internetBudgetPercent: mediaStatus === 'internet' ? 100 : 0,
  targetAudience: null,
  additionalTA: []
});

class CampaignsTable extends PureComponent<IProps, IState> {
  state: IState = {
    focusedPeriodInput: [],
    focusedRetroPeriodInput: [],
    campaigns: this.props.getCampaigns().map(convertToPartialCampaign)
  };

  minDate = moment(`${this.props.year}-01-01`);
  maxDate = moment(`${this.props.year}-12-31`);
  lastDate = moment(`${this.props.year + 1}-01-01`);

  currentYear: number = getYearOfDate();
  currentDate = moment();

  isOutsideRange = () => false;

  consts = {
    INDICATOR_MESSAGES: {
      LARGE: {
        text: localizeMessage({ id: 'annualPlanEdit.indicatorMessages.large' }),
        iconClass: 'text-info'
      },
      MEDIUM: {
        text: localizeMessage({
          id: 'annualPlanEdit.indicatorMessages.medium'
        }),
        iconClass: 'text-warning'
      },
      SMALL: {
        text: localizeMessage({ id: 'annualPlanEdit.indicatorMessages.small' }),
        iconClass: 'text-danger'
      },
      ERROR: {
        text: localizeMessage({ id: 'annualPlanEdit.indicatorMessages.error' }),
        iconClass: 'text-danger'
      }
    },
    DATE_FORMAT: 'YYYY-MM-DD',
    OLAP_TYPE_FUSION: 'FUSION',
    OLAP_TYPE_FUSION_REGIONS: 'FUSION_REGIONS',
    CAMPAIGN_CHANNEL_FEDERAL: FEDERAL_CHANNEL.value,
  };

  UNSAFE_componentWillReceiveProps (nextProps: IProps) {
    const { year } = nextProps;
    if (this.props.year !== year) {
      this.minDate = moment(`${year}-01-01`);
      this.maxDate = moment(`${year}-12-31`);
      this.lastDate = moment(`${year + 1}-01-01`);

      this.setState(prevState => ({
        ...prevState,
        campaigns: prevState.campaigns.map(campaign => (isEditableCampaign(campaign)
          ? {
            ...campaign,
            dateRangePickerData: {
              startDate: campaign.dateRangePickerData.startDate
                ? campaign.dateRangePickerData.startDate.year(year)
                : null,
              endDate: campaign.dateRangePickerData.endDate
                ? campaign.dateRangePickerData.endDate.year(year)
                : null
            }
          }
          : {
            ...campaign,
            from: moment(campaign.from)
              .year(year)
              .format('YYYY-MM-DD'),
            to: moment(campaign.to)
              .year(year)
              .format('YYYY-MM-DD')
          })
        )
      }));
    }
  }

  updateCampaignByIndex = (
    index: number,
    updater: (c: IEditebleCampaign) => IEditebleCampaign,
    callback?: () => void
  ) => {
    this.setState(
      prevState => ({
        campaigns: prevState.campaigns.map((campaign, i) => {
          if (i === index) {
            if (isEditableCampaign(campaign)) {
              return updater(campaign);
            }

            return campaign;
          }

          return campaign;
        })
      }),
      callback
    );
  };

  updateHasEditableCampaigns = () => {
    const hasEditableCampaigns = this.state.campaigns.some(isEditableCampaign);
    this.props.onUpdateHasEditableCampaigns(hasEditableCampaigns);
  };

  handleChangeChannelsSelect = (index: number) => (
    channel: ICampaign['channel']
  ) => {
    const { campaigns } = this.state;

    const prevChannel = campaigns[index] && campaigns[index].channel;
    const isChannelTypeChanged =
      !prevChannel ||
      (prevChannel.value !== channel.value &&
        (prevChannel.value === this.consts.CAMPAIGN_CHANNEL_FEDERAL ||
          channel.value === this.consts.CAMPAIGN_CHANNEL_FEDERAL));

    if (isChannelTypeChanged) {
      this.props.onUpdateAudienceList(
        channel.value === this.consts.CAMPAIGN_CHANNEL_FEDERAL
      );
    }

    this.updateCampaignByIndex(index, campaign => ({
      ...campaign,
      channel,
      errors: omit(campaign.errors, 'channel'),
      targetAudience: !isChannelTypeChanged ? campaign.targetAudience : null
    }));
  };

  getChannelsSelectizeConfig = (campaign: IEditebleCampaign, index: number) => {
    const { regionsOptions, allowedMediaStatus } = this.props;

    const options: ICampaign['channel'][] = [
      FEDERAL_CHANNEL,
      ...regionsOptions
    ];

    return {
      isSearchable: false,
      isMulti: false,
      isDisabled: allowedMediaStatus === 'internet',
      placeholder: localizeMessage({
        id: 'annualPlanEdit.fields.selectChannelsRegion.placeholder'
      }),
      onChange: this.handleChangeChannelsSelect(index),
      options,
      value: campaign.channel,
      dataTestAttr: 'region'
    };
  };

  handleChangeBrandSelect = (index: number) => (brand: IOption) => {
    const { brands } = this.props;

    const findedBrand = brands.find(b => b.id === brand.value);
    if (findedBrand) {
      this.updateCampaignByIndex(index, campaign => ({
        ...campaign,
        brand: findedBrand,
        errors: omit(campaign.errors, 'brand')
      }));
    }
  };

  getBrandSelectizeConfig (campaign: IEditebleCampaign, index: number) {
    const { brands } = this.props;
    const { brand } = campaign;

    const options: IOption[] = brands.map(brand => ({
      label: brand.name,
      value: brand.id
    }));

    return {
      isSearchable: false,
      isMulti: false,
      placeholder: localizeMessage({
        id: 'annualPlanEdit.fields.selectBrand.placeholder'
      }),
      onChange: this.handleChangeBrandSelect(index),
      options,
      value: brand ? options.find(option => option.value === brand.id) : null
    };
  }

  toggleCampaignError = (
    index: number,
    name: string,
    error?: string,
    showErrors?: boolean
  ) => {
    if (typeof showErrors === 'boolean') {
      this.updateCampaignByIndex(index, campaign => ({
        ...campaign,
        showErrors
      }));
    }
    if (!error) {
      this.updateCampaignByIndex(index, campaign => ({
        ...campaign,
        errors: omit(campaign.errors, name)
      }));
    } else {
      this.updateCampaignByIndex(index, campaign => ({
        ...campaign,
        errors: { ...campaign.errors, [name]: error }
      }));
    }
  };

  validateRetroPeriod = async (
    index: number,
    checkTargetAudience?: boolean
  ) => {
    const campaign = this.state.campaigns[index];
    if (!isEditableCampaign(campaign)) {
      return;
    }
    const retroPeriod = campaign.retroPeriodPickerData;
    const { channel } = campaign;

    let t;
    let channelDate;

    if (!channel || !retroPeriod || !retroPeriod.endDate) {
      return; // no channel or retroperiod selected, do nothing
    }

    this.toggleCampaignError(index, 'retroperiod', 'loading');

    const olapSettings = await API.personaOlapSettings.list();
    // result - массив
    // { olapType: FUSION|..., regional: true|false, endDate: '2018-07-10' }
    // endDate: "yyyy-MM-dd"

    const channelType = channel.value;

    try {
      t = olapSettings.filter(
        x => [
          this.consts.OLAP_TYPE_FUSION,
          this.consts.OLAP_TYPE_FUSION_REGIONS
        ].includes(x.olapType) &&
          ((x.regional &&
            channelType !== this.consts.CAMPAIGN_CHANNEL_FEDERAL) ||
            (!x.regional &&
              channelType === this.consts.CAMPAIGN_CHANNEL_FEDERAL))
      );
      if (!t || t.length !== 1) {
        throw new Error();
      }

      channelDate = moment(t[0].endDate, this.consts.DATE_FORMAT);
      if (!channelDate.isValid()) {
        throw new Error();
      }

      const isInvalidRetroperiodEndDate = retroPeriod.endDate.isAfter(channelDate, 'day');

      if (!isInvalidRetroperiodEndDate) {
        if (checkTargetAudience && campaign.targetAudience) {
          const retroFrom = campaign.retroPeriodPickerData.startDate.format(
            this.consts.DATE_FORMAT
          );
          const retroTo = campaign.retroPeriodPickerData.endDate.format(
            this.consts.DATE_FORMAT
          );

          this.props.onUpdateAudienceType(
            campaign.id,
            retroFrom,
            retroTo,
            campaign.targetAudience,
            false
          );
        }
      } else {
        Alert.error(
          localizeMessage(
            {
              id:
                'annualPlanEdit.campaignsTable.errors.retroperiodEndDateExceedsMaximumDate'
            },
            {
              date: channelDate.format(this.consts.DATE_FORMAT)
            }
          )
        );
      }

      const hasError = isInvalidRetroperiodEndDate;
      if (hasError) {
        await this.toggleCampaignError(index, 'retroperiod', 'isAfter', true);
      } else {
        await this.toggleCampaignError(index, 'retroperiod', undefined, true);
      }
    } catch (e) {
      Alert.error(
        localizeMessage({
          id: 'annualPlanEdit.campaignsTable.errors.errorGettingDataFromPersona'
        })
      );
    }
  };

  makeChangeTaSelectHandler = (index: number) => (ta: IOption) => {
    const { targetAudiences } = this.props;
    const targetAudience =
      targetAudiences.find(targetAudience => targetAudience.id === ta.value) ||
      null;

    this.updateCampaignByIndex(index, campaign => ({
      ...campaign,
      targetAudience,
      errors: omit(campaign.errors, 'targetAudience')
    }));
    if (targetAudience) {
      const campaign = this.state.campaigns[index] as IEditebleCampaign;
      const retroFrom = campaign.retroPeriodPickerData.startDate.format(
        this.consts.DATE_FORMAT
      );
      const retroTo = campaign.retroPeriodPickerData.endDate.format(
        this.consts.DATE_FORMAT
      );

      this.props.onUpdateAudienceType(
        campaign.id,
        retroFrom,
        retroTo,
        targetAudience,
        true
      );
    }
  };

  makeChangeAdditionalTaSelectHandler = (
    campaignIndex: number,
    taIndex: number
  ) => (ta: IOption) => {
    const { targetAudiences } = this.props;
    const targetAudience =
      targetAudiences.find(targetAudience => targetAudience.id === ta.value) ||
      null;


    if (!targetAudience) return;

    this.updateCampaignByIndex(campaignIndex, campaign => ({
      ...campaign,
      additionalTA: [...(campaign.additionalTA || [])].map((it, idx) => (idx === taIndex ? targetAudience : it)
      )
    }));
    if (targetAudience) {
      const campaign = this.state.campaigns[campaignIndex] as IEditebleCampaign;
      const retroFrom = campaign.retroPeriodPickerData.startDate.format(
        this.consts.DATE_FORMAT
      );
      const retroTo = campaign.retroPeriodPickerData.endDate.format(
        this.consts.DATE_FORMAT
      );

      this.props.onUpdateAudienceType(
        campaign.id,
        retroFrom,
        retroTo,
        targetAudience,
        true
      );
    }
  };

  handleEditCampaign = async (index: number, campaign: IPartialCampaign) => {
    const editableCampaign = convertToEditableCampaign(campaign);

    this.setState(
      prevProps => ({
        ...prevProps,
        campaigns: prevProps.campaigns.map(c => (c === campaign ? editableCampaign : c)
        )
      }),
      () => {
        this.validateRetroPeriod(index);
      }
    );

    const { campaigns } = this.state;
    const channel = campaigns[index] && campaigns[index].channel;
    this.props.onUpdateAudienceList(
      channel ? channel.value === this.consts.CAMPAIGN_CHANNEL_FEDERAL : false
    );
    this.props.onUpdateHasEditableCampaigns(true);
  };

  validateCampaign = (campaign: IEditebleCampaign) => {
    const errors: Record<string, string> = {};

    if (!campaign.name.length) {
      errors.name = 'empty';
    }

    if (!campaign.channel || !campaign.channel.value) {
      errors.channel = 'empty';
    }

    if (!campaign.targetAudience) {
      errors.targetAudience = 'empty';
    }

    if (!campaign.budget) {
      errors.budget = 'min';
    }

    if (
      !campaign.dateRangePickerData.startDate ||
      !campaign.dateRangePickerData.endDate
    ) {
      errors.period = 'empty';
    }

    if (
      !campaign.retroPeriodPickerData.startDate ||
      !campaign.retroPeriodPickerData.endDate
    ) {
      errors.retroperiod = 'empty';
    }

    return errors;
  };

  addAdditionalTA = (index: number, campaign: IEditebleCampaign) => {
    console.log(index, campaign);
    this.updateCampaignByIndex(index, c => ({
      ...c,
      additionalTA: !c.additionalTA ? [null] : [...c.additionalTA, null as any]
    }));
  };

  saveCampaign = (index: number, campaign: IEditebleCampaign) => {
    const errors = this.validateCampaign(campaign);

    if (Object.keys(campaign.errors).length || Object.keys(errors).length) {
      this.updateCampaignByIndex(index, campaign => ({
        ...campaign,
        additionalTA: (campaign.additionalTA || []).filter(Boolean),
        errors: {
          ...campaign.errors,
          ...errors
        },
        showErrors: true
      }));
    } else {
      const update: CampaignUpdate = {
        name: campaign.name,
        channel: campaign.channel!,
        brand: campaign.brand,
        from: campaign.dateRangePickerData.startDate!.format(
          this.consts.DATE_FORMAT
        ),
        to: campaign.dateRangePickerData.endDate!.format(
          this.consts.DATE_FORMAT
        ),
        retroFrom: campaign.retroPeriodPickerData.startDate.format(
          this.consts.DATE_FORMAT
        ),
        retroTo: campaign.retroPeriodPickerData.endDate.format(
          this.consts.DATE_FORMAT
        ),
        budget: campaign.budget,
        tvBudgetPercent: campaign.tvBudgetPercent,
        internetBudgetPercent: campaign.internetBudgetPercent,
        targetAudience: campaign.targetAudience!,
        additionalTA: (campaign.additionalTA || []).filter(Boolean),
      };

      if (campaign.isNew) {
        this.setState(
          prevState => ({
            campaigns: prevState.campaigns.map((c, i) => (i === index ? { id: campaign.id, ...update } : c)
            )
          }),
          this.updateHasEditableCampaigns
        );
        this.props.onAddNewCampaign(campaign.id, update);
      } else {
        this.setState(
          prevState => ({
            campaigns: prevState.campaigns.map((c, i) => (i === index ? { id: campaign.id, ...update } : c)
            )
          }),
          this.updateHasEditableCampaigns
        );
        this.props.onEditCampaign(campaign.id, update);
      }
    }
  };

  cancelEditCampaign = (index: number, campaign: IEditebleCampaign) => {
    if (campaign.isNew) {
      this.setState(
        prevState => ({ campaigns: without(prevState.campaigns, campaign) }),
        this.updateHasEditableCampaigns
      );
    } else {
      const initialCampaign = this.props
        .getCampaigns()
        .find(c => c.id === campaign.id);
      if (initialCampaign) {
        const partialCampaign = convertToPartialCampaign(initialCampaign);
        this.setState(
          prevState => ({
            campaigns: prevState.campaigns.map((c, i) => (i === index ? partialCampaign : c)
            )
          }),
          this.updateHasEditableCampaigns
        );
      } else {
        console.warn('not found initialCampaign');
      }
    }
  };

  handleRemoveCampaign = (index: number, campaign: IPartialCampaign) => {
    SweetAlert({
      title: localizeMessage({ id: 'annualPlanEdit.confirms.campaign-delete' }),
      text: localizeMessage({
        id: 'annualPlanEdit.confirms.campaign-will-be-deleted'
      }),
      buttons: {
        confirm: localizeMessage({ id: 'yes' }),
        cancel: localizeMessage({ id: 'cancel' })
      }
    }).then(async isConfirm => {
      if (isConfirm) {
        this.props.onRemoveCampaign(campaign.id);
        this.setState(prevState => ({
          campaigns: without(prevState.campaigns, campaign)
        }));
      }
    });
  };

  handleAddCampaign = () => {
    const isInternetOnlyAllowed = this.props.allowedMediaStatus === 'internet';
    const channel = isInternetOnlyAllowed ? FEDERAL_CHANNEL : null;
    const newCampaign = getNewEditableCampaign(this.props.retroDiffs, this.props.allowedMediaStatus, channel);

    this.setState(prevState => ({
      ...prevState,
      campaigns: [...prevState.campaigns, newCampaign]
    }));
    this.props.onUpdateHasEditableCampaigns(true);

    if (isInternetOnlyAllowed) {
      this.props.onUpdateAudienceList(true);
    }
  };

  handleDateChange = async (
    type: 'period' | 'retroperiod',
    index: number,
    range: INullableMomentRange
  ) => {
    if (type === 'retroperiod') {
      this.updateCampaignByIndex(
        index,
        campaign => ({
          ...campaign,
          retroPeriodPickerData: range as IMomentRange,
          errors: omit(campaign.errors, type)
        }),
        () => {
          this.validateRetroPeriod(index, true);
        }
      );
      this.props.onCheckRetroperiodRange(range);
    } else {
      this.updateCampaignByIndex(index, campaign => ({
        ...campaign,
        dateRangePickerData: range,
        errors: omit(campaign.errors, type)
      }));
    }
  };

  handleFocusChange = (
    type: 'period' | 'retroperiod',
    campaignID: number | string,
    value: 'startDate' | 'endDate' | null
  ) => {
    if (type === 'retroperiod') {
      this.setState(prevState => ({
        ...prevState,
        focusedRetroPeriodInput: {
          ...prevState.focusedRetroPeriodInput,
          [campaignID]: value
        }
      }));
    } else if (type === 'period') {
      this.setState(
        prevState => ({
          ...prevState,
          focusedPeriodInput: {
            ...prevState.focusedPeriodInput,
            [campaignID]: value
          }
        }),
        () => {
          this.clearInvalidInput(campaignID);
        }
      );
    }
  };

  clearInvalidInput = (campaignID: number | string) => {
    const campaign = this.state.campaigns.find(c => c.id === campaignID);
    if (campaign && isEditableCampaign(campaign)) {
      const { dateRangePickerData } = campaign;
      if (dateRangePickerData.startDate === null) {
        (document.querySelector(
          `input[id=period-start-date${campaignID}]`
        ) as HTMLInputElement).value = '';
      } else if (dateRangePickerData.endDate === null) {
        (document.querySelector(
          `input[id=period-end-date${campaignID}]`
        ) as HTMLInputElement).value = '';
      }
    }
  };

  handleInputChange = (e: React.ChangeEvent<any>, index: number) => {
    let error: string | null = null;

    let value = e.target.rawValue || e.target.value;
    const name = e.target.name;

    if (e.target.type === 'number' || e.target.rawValue) {
      value = parseInt(value.replace(/[^0-9]+/g, '')) || 0;

      if (e.target.min) {
        const min = parseInt(e.target.min.replace(/[^0-9]+/g, ''));

        value = Math.max(value, min);
      }

      if (e.target.max) {
        const max = parseInt(e.target.max.replace(/[^0-9]+/g, ''));

        value = Math.min(value, max);
      }
    } else if (e.target.required && e.target.type === 'text' && !value.length) {
      error = 'empty';
    }

    const editableCampaign = this.state.campaigns[index] as IEditebleCampaign;

    if (e.target.dataset.maxTv) {
      if (value + parseInt(e.target.dataset.maxTv) > 100) {
        error = 'maxTv';
      } else {
        delete editableCampaign.errors.internetBudgetPercent;
      }
    }

    if (e.target.dataset.maxInternet) {
      if (value + parseInt(e.target.dataset.maxInternet) > 100) {
        error = 'maxInternet';
      } else {
        delete editableCampaign.errors.tvBudgetPercent;
      }
    }

    if (error) {
      this.updateCampaignByIndex(index, campaign => ({
        ...campaign,
        [name]: value,
        showErrors: true,
        errors: { ...campaign.errors, [name]: error }
      }));
    } else {
      this.updateCampaignByIndex(index, campaign => ({
        ...campaign,
        [name]: value,
        errors: omit(campaign.errors, name)
      }));
    }
  };

  handleInputFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    if (e.target.type === 'number' && e.target.value === '0') {
      e.target.select();
    }
  };

  handleInputKeyDown = (
    e: React.KeyboardEvent,
    index: number,
    campaign: IEditebleCampaign
  ) => {
    switch (e.keyCode) {
      case 13:
        this.saveCampaign(index, campaign);

        break;
      case 27:
        this.cancelEditCampaign(index, campaign);

        break;
      default:
        break;
    }
  };

  isOutsideData = (date: Moment) => date.isBefore(this.minDate) || date.isAfter(this.lastDate);

  formatDate = (date: string, format: string = 'DD/MM/YY'): string => moment(date).format(format);

  render () {
    const {
      targetAudiences,
      selectedCampaignID,
      onSelectCampaign,
      allowedMediaStatus,
      mapCampaignToTargetAudienceTypes
    } = this.props;

    const { campaigns } = this.state;

    const showBudgetPercents = allowedMediaStatus === 'all';

    return (
      <>
        <div className='margin-bottom-9px'>
          {/* Временное отключение возможности добавлять доп. кампании */}
          {!campaigns.length && (
            <button
              type='button'
              onClick={this.handleAddCampaign}
              className='btn btn-success step-button btn-sm'
              data-test='add-new-campaign'
            >
              <i className='fa fa-plus-circle' />
              {' '}
              <span className='bold'>
                <LocalizedMessage id='annualPlanEdit.addCampaign' />
              </span>
            </button>
          )}
        </div>
        <div className={classes['display-flex']}>
          <div>
            <table
              className={cx(
                'table',
                classes.table,
                classes['table-selectable'],
                classes['annualPlan-campaigns-fixed-table']
              )}
            >
              <thead>
                <tr>
                  <th className='text-nowrap' style={{ minWidth: '145px' }}>
                    <div>
                      <br />
                      <div>
                        <div>
                          <span>
                            <LocalizedMessage id='name' />
                          </span>
                        </div>
                      </div>
                    </div>
                  </th>
                </tr>
                <tr>
                  <th>
                    <br />
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.state.campaigns.map((campaign, index) => (
                  <Fragment key={index}>
                    <TrTdHover
                      id={`campaignFixedRow${index}`}
                      tag='tr'
                      className={cx('campaigns-table-row-height', {
                        [classes['table-selected-row']]:
                          campaign.id === selectedCampaignID
                      })}
                      style={{ height: '49px' }}
                      hoverClass={classes['table-row-hover']}
                      hoverActiveClass={classes['table-selected-row-hover']}
                      isActiveRow={!!(campaign.id === selectedCampaignID)}
                      dependentRowId={`campaignScrollRow${index}`}
                      onClick={() => onSelectCampaign(campaign.id)}
                    >
                      <td
                        className={cx(
                          'text-nowrap',
                          classes['campaigns-table-cell-center']
                        )}
                      >
                        {isEditableCampaign(campaign) ? (
                          <div
                            className={cx(
                              'form-group-sm',
                              classes['padding-top-bottom-1px']
                            )}
                            style={{ width: '133px' }}
                          >
                            <LocalizedMessage id='name'>
                              {localizedMessage => (
                                <input
                                  type='text'
                                  className={cx(
                                    'form-control',
                                    classes['form-control'],
                                    {
                                      error:
                                        campaign.showErrors &&
                                        campaign.errors.name,
                                      [classes.error]:
                                        campaign.showErrors &&
                                        campaign.errors.name
                                    }
                                  )}
                                  placeholder={localizedMessage}
                                  name='name'
                                  onChange={e => this.handleInputChange(e, index)}
                                  onKeyDown={e => this.handleInputKeyDown(e, index, campaign)}
                                  value={campaign.name}
                                  required
                                  data-test='name'
                                />
                              )}
                            </LocalizedMessage>
                          </div>
                        ) : (
                          <span>{campaign.name || '-'}</span>
                        )}
                      </td>
                    </TrTdHover>
                    {isEditableCampaign(campaign) &&
                    Object.keys(campaign.errors).length ? (
                      <tr className={classes['fixed-campaigns-errors-row']}>
                        <td style={{ paddingTop: 0 }}>
                          {campaign.errors.internetBudgetPercent ||
                          campaign.errors.tvBudgetPercent ? (
                            <div>
                              <small>&nbsp;</small>
                            </div>
                            ) : null}
                        </td>
                      </tr>
                      ) : null}
                  </Fragment>
                ))}
              </tbody>
            </table>
          </div>

          <div className={classes['right-flex-table']}>
            <table
              className={cx(
                'table',
                classes.table,
                classes['centered-cells'],
                classes['table-selectable'],
                classes['campaigns-table']
              )}
            >
              <thead>
                <tr>
                  <th rowSpan={2} style={{ minWidth: '100px' }}>
                    <span>
                      <LocalizedMessage id='channels' />
                      /
                      <LocalizedMessage id='region' />
                    </span>
                  </th>
                  <th
                    rowSpan={2}
                    className='text-nowrap'
                    style={{ minWidth: '136px' }}
                  >
                    <span>
                      <LocalizedMessage id='brand' />
                    </span>
                  </th>
                  <th
                    rowSpan={2}
                    className='text-nowrap'
                    style={{ minWidth: '149px', width: '149px' }}
                  >
                    <span>
                      <LocalizedMessage id='period' />
                    </span>
                  </th>
                  <th
                    rowSpan={2}
                    className='text-nowrap'
                    style={{ minWidth: '149px', width: '149px' }}
                  >
                    <span>
                      <LocalizedMessage id='retroperiod' />
                    </span>
                    {' '}
                    <Tooltip
                      placement='top'
                      overlay={
                        <LocalizedMessage id='retroperiod.description' />
                      }
                    >
                      <i className='glyphicon glyphicon-info-sign' />
                    </Tooltip>
                  </th>
                  <th
                    colSpan={3}
                    className={cx(
                      'text-nowrap',
                      classes['light-green-background']
                    )}
                    style={{ borderBottomColor: 'transparent' }}
                  >
                    <span>
                      {!showBudgetPercents && <br />}
                      <LocalizedMessage id='budget' />
                    </span>
                  </th>
                  <th rowSpan={2} style={{ minWidth: '100px' }}>
                    <span>
                      <LocalizedMessage id='targetAudience' />
                    </span>
                  </th>
                  <th rowSpan={2} style={{ minWidth: '60px' }} />
                </tr>
                <tr>
                  <th
                    colSpan={showBudgetPercents ? 1 : 3}
                    className={cx(
                      'text-nowrap',
                      classes['light-green-background']
                    )}
                    style={{ minWidth: '145px', paddingRight: 0 }}
                  >
                    {showBudgetPercents && (
                      <span><LocalizedMessage id='campaign' /></span>
                    )}
                  </th>

                {showBudgetPercents && (<>
                  <th
                    className={cx(
                      'text-nowrap',
                      classes['light-green-background']
                    )}
                    style={{
                      paddingLeft: 0,
                      paddingRight: 0,
                      minWidth: '100px'
                    }}
                  >
                    <span>
                      <LocalizedMessage id='internet' />
                    </span>
                  </th>
                  <th
                    className={cx(
                      'text-nowrap',
                      classes['light-green-background']
                    )}
                    style={{ minWidth: '108px', paddingLeft: 0 }}
                  >
                    <span>
                      <LocalizedMessage id='tv' />
                    </span>
                  </th>
                </>)}
                </tr>
              </thead>

              <tbody>
                {this.state.campaigns.map(
                  (campaign: IPartialCampaign, index: number) => {
                    const targetAudience =
                      mapCampaignToTargetAudienceTypes[
                        campaign.targetAudience?.id
                      ];
                    const targetAudienceType =
                      targetAudience && targetAudience.type;
                    const targetAudienceVolume =
                      targetAudience && targetAudience.volumeSize;
                    const formattedDateFrom: string = this.formatDate(
                      campaign.from
                    );
                    const formattedDateTo: string = this.formatDate(
                      campaign.to
                    );
                    const formattedDateRetroFrom: string = this.formatDate(
                      campaign.retroFrom
                    );
                    const formattedDateRetroTo: string = this.formatDate(
                      campaign.retroTo
                    );

                    return (
                      <Fragment key={index}>
                        <TrTdHover
                          id={`campaignScrollRow${index}`}
                          tag='tr'
                          className={cx({
                            [classes['table-selected-row']]:
                              campaign.id === selectedCampaignID
                          })}
                          style={{ height: '49px' }}
                          hoverClass={classes['table-row-hover']}
                          hoverActiveClass={classes['table-selected-row-hover']}
                          isActiveRow={!!(campaign.id === selectedCampaignID)}
                          dependentRowId={`campaignFixedRow${index}`}
                          onClick={() => onSelectCampaign(campaign.id)}
                        >
                          <td className='text-nowrap'>
                            {isEditableCampaign(campaign) ? (
                              <div
                                className={cx(
                                  'form-group-sm',
                                  'text-nowrap',
                                  classes['channels-selectize-container'],
                                  {
                                    error:
                                      campaign.showErrors &&
                                      campaign.errors.channel
                                  }
                                )}
                              >
                                <Select
                                  {...this.getChannelsSelectizeConfig(
                                    campaign,
                                    index
                                  )}
                                  className={classes['small-single-selectize']}
                                />
                              </div>
                            ) : (
                              <span>{campaign.channel.label}</span>
                            )}
                          </td>
                          <td className='text-nowrap'>
                            {isEditableCampaign(campaign) ? (
                              <div className='form-group-sm'>
                                <Select<IOption>
                                  {...this.getBrandSelectizeConfig(
                                    campaign,
                                    index
                                  )}
                                  className={classes['small-single-selectize']}
                                />
                              </div>
                            ) : (
                              <span>
                                {campaign.brand
                                  ? campaign.brand.name || '-'
                                  : '-'}
                              </span>
                            )}
                          </td>
                          <td className='text-nowrap'>
                            {isEditableCampaign(campaign) ? (
                              <div
                                className={cx('form-group-sm', {
                                  'datepicker-container-error':
                                    campaign.showErrors &&
                                    campaign.errors.period
                                })}
                              >
                                <DateRangePicker
                                  startDate={
                                    getYearOfDate(
                                      campaign.dateRangePickerData.startDate
                                    ) === this.props.year
                                      ? campaign.dateRangePickerData.startDate
                                      : null
                                  }
                                  startDateId={`period-start-date${campaign.id}`}
                                  endDate={
                                    getYearOfDate(
                                      campaign.dateRangePickerData.endDate
                                    ) === this.props.year
                                      ? campaign.dateRangePickerData.endDate
                                      : null
                                  }
                                  endDateId={`period-end-date${campaign.id}`}
                                  onDatesChange={dates => this.handleDateChange(
                                    'period',
                                    index,
                                    dates
                                  )}
                                  focusedInput={
                                    this.state.focusedPeriodInput[campaign.id]
                                  }
                                  onFocusChange={focusedPeriodInput => this.handleFocusChange(
                                    'period',
                                    campaign.id,
                                    focusedPeriodInput
                                  )}
                                  small
                                  withPortal
                                  hideKeyboardShortcutsPanel
                                  initialVisibleMonth={() => (campaign.dateRangePickerData.startDate &&
                                    getYearOfDate(
                                      campaign.dateRangePickerData.startDate
                                    ) === this.props.year
                                    ? campaign.dateRangePickerData.startDate
                                    : this.currentYear === this.props.year
                                      ? this.currentDate
                                      : this.minDate)}
                                  firstDayOfWeek={1}
                                  isOutsideRange={this.isOutsideData}
                                  displayFormat={() => 'DD/MM/YY'}
                                />
                              </div>
                            ) : (
                              <span>
                                {formattedDateFrom}
                                {' - '}
                                {formattedDateTo}
                              </span>
                            )}
                          </td>
                          <td className='text-nowrap'>
                            {isEditableCampaign(campaign) ? (
                              <div
                                className={cx('form-group-sm', {
                                  'datepicker-container-error':
                                    campaign.showErrors &&
                                    campaign.errors.retroperiod
                                })}
                              >
                                <DateRangePicker
                                  startDate={
                                    campaign.retroPeriodPickerData.startDate
                                  }
                                  startDateId={`retroperiod-start-date${campaign.id}`}
                                  endDate={
                                    campaign.retroPeriodPickerData.endDate
                                  }
                                  endDateId={`retroperiod-end-date${campaign.id}`}
                                  onDatesChange={dates => this.handleDateChange(
                                    'retroperiod',
                                    index,
                                    dates
                                  )}
                                  focusedInput={
                                    this.state.focusedRetroPeriodInput[
                                      campaign.id
                                    ]
                                  }
                                  onFocusChange={focusedRetroPeriodInput => this.handleFocusChange(
                                    'retroperiod',
                                    campaign.id,
                                    focusedRetroPeriodInput
                                  )}
                                  small
                                  withPortal
                                  hideKeyboardShortcutsPanel
                                  firstDayOfWeek={1}
                                  isOutsideRange={this.isOutsideRange}
                                  displayFormat={() => 'DD/MM/YY'}
                                />
                              </div>
                            ) : (
                              <span>
                                {formattedDateRetroFrom}
                                {' - '}
                                {formattedDateRetroTo}
                              </span>
                            )}
                          </td>
                          <td
                            colSpan={showBudgetPercents ? 1 : 3}
                            className={classes['light-green-background']}
                            style={{ paddingRight: 0 }}
                          >
                            {isEditableCampaign(campaign) ? (
                              <div
                                className={cx(
                                  'form-group-sm',
                                  classes['budget-group'],
                                  classes.campaign
                                )}
                              >
                                <LocalizedMessage id='budget'>
                                  {localizedMessage => (
                                    <Cleave
                                      type='text'
                                      className={cx(
                                        'form-control',
                                        classes['form-control'],
                                        {
                                          error:
                                            campaign.showErrors &&
                                            campaign.errors.budget,
                                          [classes.error]:
                                            campaign.showErrors &&
                                            campaign.errors.budget
                                        }
                                      )}
                                      name='budget'
                                      placeholder={localizedMessage}
                                      onFocus={this.handleInputFocus}
                                      onKeyDown={e => this.handleInputKeyDown(
                                        e,
                                        index,
                                        campaign
                                      )}
                                      onChange={e => this.handleInputChange(e, index)}
                                      value={campaign.budget}
                                      max={MAX_INT_VALUE}
                                      required
                                      options={{
                                        numeral: true,
                                        numeralThousandsGroupStyle: 'thousand',
                                        delimiter: ' '
                                      }}
                                      data-test='budget'
                                    />
                                  )}
                                </LocalizedMessage>
                                {' '}
                                <span className='right-text'>
                                  <LocalizedMessage id='rur' />
                                </span>
                              </div>
                            ) : (
                              <span>
                                {formattedNumberWithZero(campaign.budget)}
                                {' '}
                                <span>
                                  <LocalizedMessage id='rur' />
                                </span>
                              </span>
                            )}
                          </td>

                        {showBudgetPercents && (<>
                          <td
                            className={classes['light-green-background']}
                            style={{ paddingLeft: 0, paddingRight: 0 }}
                          >
                            {isEditableCampaign(campaign) ? (
                              <div
                                className={cx(
                                  'form-group-sm',
                                  classes['budget-group'],
                                  classes.internet
                                )}
                              >
                                <span className='left-text'>
                                  <LocalizedMessage id='min' />
                                </span>
                                {' '}
                                <NumericInput
                                  className={cx(
                                    'form-control',
                                    classes['form-control'],
                                    {
                                      error:
                                        campaign.showErrors &&
                                        campaign.errors.internetBudgetPercent,
                                      [classes.error]:
                                        campaign.showErrors &&
                                        campaign.errors.internetBudgetPercent
                                    }
                                  )}
                                  name='internetBudgetPercent'
                                  onFocus={this.handleInputFocus}
                                  onKeyDown={e => this.handleInputKeyDown(e, index, campaign)}
                                  onChange={e => this.handleInputChange(e, index)}
                                  value={campaign.internetBudgetPercent}
                                  min={0}
                                  max={100}
                                  data-max-internet={campaign.tvBudgetPercent}
                                  required
                                  data-test='budget-internet'
                                />
                                {' '}
                                <span className='right-text'>%</span>
                              </div>
                            ) : (
                              <span>
                                <LocalizedMessage id='min' />
                                {' '}
                                {percentOrHyphen(
                                  campaign.internetBudgetPercent || 0,
                                  1
                                )}
                              </span>
                            )}
                          </td>
                          <td
                            className={classes['light-green-background']}
                            style={{ paddingLeft: 0 }}
                          >
                            {isEditableCampaign(campaign) ? (
                              <div
                                className={cx(
                                  'form-group-sm',
                                  classes['budget-group'],
                                  classes.tv
                                )}
                              >
                                <span className='left-text'>
                                  <LocalizedMessage id='min' />
                                </span>
                                {' '}
                                <NumericInput
                                  className={cx(
                                    'form-control',
                                    classes['form-control'],
                                    {
                                      error:
                                        campaign.showErrors &&
                                        campaign.errors.tvBudgetPercent,
                                      [classes.error]:
                                        campaign.showErrors &&
                                        campaign.errors.tvBudgetPercent
                                    }
                                  )}
                                  name='tvBudgetPercent'
                                  onFocus={this.handleInputFocus}
                                  onKeyDown={e => this.handleInputKeyDown(e, index, campaign)}
                                  onChange={e => this.handleInputChange(e, index)}
                                  value={campaign.tvBudgetPercent}
                                  min={0}
                                  max={100}
                                  data-max-tv={campaign.internetBudgetPercent}
                                  required
                                  data-test='budget-tv'
                                />
                                {' '}
                                <span className='right-text'>%</span>
                              </div>
                            ) : (
                              <span>
                                <LocalizedMessage id='min' />
                                {' '}
                                {percentOrHyphen(
                                  campaign.tvBudgetPercent || 0,
                                  1
                                )}
                              </span>
                            )}
                          </td>
                        </>)}

                          <td
                            className='text-nowrap'
                            style={{ textAlign: 'left' }}
                          >
                            <div
                              className={cx(
                                'form-group-sm',
                                'text-nowrap',
                                classes['target-audience-selectize-container'],
                                {
                                  error:
                                    isEditableCampaign(campaign) &&
                                    campaign.showErrors &&
                                    campaign.errors.targetAudience
                                }
                              )}
                            >
                              <div className={classes.taHolder}>
                                <div
                                  className={cx(
                                    classes['ta-with-volume'],
                                    classes.taWithVolumeMain
                                  )}
                                >
                                  {campaign.targetAudience && (
                                    <div
                                      className={classes['volume-indicator']}
                                    >
                                      {!targetAudienceType ? (
                                        <Loader
                                          isSmall
                                          className={
                                            classes['volume-indicator-loader']
                                          }
                                        />
                                      ) : (
                                        <Tooltip
                                          placement='top'
                                          overlay={
                                            this.consts.INDICATOR_MESSAGES[
                                              targetAudienceType
                                            ].text
                                          }
                                        >
                                          <i
                                            className={cx(
                                              'fa',
                                              this.consts.INDICATOR_MESSAGES[
                                                targetAudienceType
                                              ].iconClass
                                            )}
                                          >
                                            {targetAudienceVolume}
                                          </i>
                                        </Tooltip>
                                      )}
                                    </div>
                                  )}
                                  {isEditableCampaign(campaign) ? (
                                    <TargetAudienceSelect
                                      targetAudiences={targetAudiences}
                                      value={campaign.targetAudience}
                                      index={index}
                                      makeChangeHandler={
                                        this.makeChangeTaSelectHandler
                                      }
                                    />
                                  ) : (
                                    <span>
                                      {campaign.targetAudience!.displayedName ||
                                        '-'}
                                    </span>
                                  )}
                                </div>
                                {campaign.additionalTA?.map((ta, taIndex) => {
                                  if (!ta) {
                                    return (
                                      <TargetAudienceSelect
                                        key={taIndex}
                                        targetAudiences={targetAudiences}
                                        value={ta}
                                        index={index}
                                        makeChangeHandler={index => this.makeChangeAdditionalTaSelectHandler(
                                          index,
                                          taIndex
                                        )}
                                      />
                                    );
                                  }

                                  const targetAudience =
                                    mapCampaignToTargetAudienceTypes[ta.id];


                                  const targetAudienceType =
                                    targetAudience?.type;
                                  const targetAudienceVolumeSize =
                                    targetAudience?.volumeSize;

                                  return (
                                    <div className='ta-holder' key={taIndex}>
                                      <div
                                        className={classes['ta-with-volume']}
                                      >
                                        <div
                                          className={cx(
                                            classes['volume-indicator']
                                          )}
                                        >
                                          {!targetAudienceType ? (
                                            <Loader
                                              isSmall
                                              className={
                                                classes[
                                                  'volume-indicator-loader'
                                                ]
                                              }
                                            />
                                          ) : (
                                            <Tooltip
                                              placement='top'
                                              overlay={
                                                this.consts.INDICATOR_MESSAGES[
                                                  targetAudienceType
                                                ].text
                                              }
                                            >
                                              <i
                                                className={cx(
                                                  'fa',
                                                  this.consts
                                                    .INDICATOR_MESSAGES[
                                                      targetAudienceType
                                                    ].iconClass
                                                )}
                                              >
                                                {targetAudienceVolumeSize}
                                              </i>
                                            </Tooltip>
                                          )}
                                        </div>
                                        {isEditableCampaign(campaign) ? (
                                          <TargetAudienceSelect
                                            targetAudiences={targetAudiences}
                                            value={ta}
                                            index={index}
                                            makeChangeHandler={index => this.makeChangeAdditionalTaSelectHandler(
                                              index,
                                              taIndex
                                            )}
                                          />
                                        ) : (
                                          <span>{ta!.name || '-'}</span>
                                        )}
                                        {isEditableCampaign(campaign) && (
                                        <i
                                          onClick={() => {
                                            this.updateCampaignByIndex(
                                              index,
                                              c => {
                                                const deleted = [...(c.additionalTA || [])];
                                                deleted.splice(taIndex, 1);

                                                return {
                                                  ...c,
                                                  additionalTA:deleted
                                                };
                                              }
                                            );
                                          }}
                                          className={cx('fa', 'fa-times', classes.removeAdditionalTa)}
                                        />
                                        )}

                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </td>
                          <td
                            className={cx(
                              'text-nowrap',
                              classes['text-right-important']
                            )}
                          >
                            {isEditableCampaign(campaign) ? (
                              <div>
                                <Tooltip
                                  placement='top'
                                  overlay={'Добавить вторичную ЦА'}
                                >
                                  <i
                                    className={cx(
                                      'fa',
                                      'fa-plus',
                                      Object.keys(campaign.errors).length &&
                                        campaign.showErrors
                                        ? 'inline-button-disabled'
                                        : 'inline-button-confirm-active'
                                    )}
                                    onClick={() => this.addAdditionalTA(index, campaign)}
                                  />
                                </Tooltip>
                                &nbsp;&nbsp;
                                <Tooltip
                                  placement='top'
                                  overlay={<LocalizedMessage id='confirm' />}
                                >
                                  <i
                                    className={cx(
                                      'fa',
                                      'fa-check',
                                      Object.keys(campaign.errors).length &&
                                        campaign.showErrors
                                        ? 'inline-button-disabled'
                                        : 'inline-button-confirm-active'
                                    )}
                                    onClick={() => this.saveCampaign(index, campaign)}
                                  />
                                </Tooltip>
                                &nbsp;&nbsp;
                                <Tooltip
                                  placement='top'
                                  overlay={<LocalizedMessage id='cancel' />}
                                >
                                  <i
                                    className='fa fa-times-rectangle inline-button-cancel-active'
                                    onClick={() => this.cancelEditCampaign(index, campaign)}
                                  />
                                </Tooltip>
                              </div>
                            ) : (
                              <span>
                                &nbsp;&nbsp;
                                <Tooltip
                                  placement='top'
                                  overlay={<LocalizedMessage id='edit' />}
                                >
                                  <i
                                    className='fa fa-pencil inline-button-edit-active'
                                    onClick={() => this.handleEditCampaign(index, campaign)}
                                  />
                                </Tooltip>
                                &nbsp;&nbsp;
                                <Tooltip
                                  placement='top'
                                  overlay={<LocalizedMessage id='delete' />}
                                >
                                  <i
                                    className='fa fa-trash inline-button-delete-active'
                                    onClick={() => this.handleRemoveCampaign(index, campaign)}
                                  />
                                </Tooltip>
                              </span>
                            )}
                          </td>
                        </TrTdHover>
                        {isEditableCampaign(campaign) &&
                          Object.keys(campaign.errors).length > 0 && (
                            <tr
                              className={classes['scroll-campaigns-errors-row']}
                            >
                              <td />
                              <td />
                              <td />
                              <td />
                              <td colSpan={3} style={{ paddingTop: 0 }}>
                                {campaign.errors.internetBudgetPercent ||
                                campaign.errors.tvBudgetPercent ? (
                                  <div className='text-left text-nowrap'>
                                    {campaign.errors.internetBudgetPercent ===
                                      'maxInternet' ||
                                    campaign.errors.tvBudgetPercent ===
                                      'maxTv' ? (
                                        <small className='text-danger'>
                                          <LocalizedMessage id='annualPlanEdit.fields.internetBudgetPercent.errors.max' />
                                        </small>
                                      ) : null}
                                  </div>
                                  ) : null}
                              </td>
                              <td />
                              <td />
                            </tr>
                        )}
                      </Fragment>
                    );
                  }
                )}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }
}

export default CampaignsTable;
