import React, { Component } from 'react';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import LocalizedMessage, { localizeMessage } from 'components/LocalizedMessage';

import Select from 'components/Select';
import Breadcrumbs from 'components/Breadcrumbs';
import { NumericInput } from 'components/NumericInput';

import API from 'api';
import Alert from 'helpers/alert';
import { IAnnualBudget, IOption } from 'types';
import { RouteComponentProps } from 'react-router';

type IProps = RouteComponentProps<{ id: string }> & { agencyId: number };

interface IState {
  data: IAnnualBudget | null;
  advertisersOptions: IOption[];
  periodOptions: IOption[];

  advertiserId: IOption | null;
  period: any;
  budget?: number;
}

class AnnualBudget extends Component<IProps, IState> {
  static propTypes = {};

  years = {
    startYear: moment()
      .add(1, 'year')
      .year(),
    endYear: moment()
      .add(-5, 'year')
      .year()
  };

  state: IState = {
    data: null,

    advertisersOptions: [],
    periodOptions: this.getPeriodOptions(),

    advertiserId: null,
    period: null,
    budget: 0
  };

  mounted = false;

  _form: HTMLFormElement | null = null;

  componentDidMount () {
    this.mounted = true;

    this.loadData();
  }

  componentDidUpdate (prevProps) {
    if (prevProps.agencyId !== this.props.agencyId) {
      this.loadData();
    }
  }

  componentWillUnmount () {
    this.mounted = false;
  }

  getPeriodOptions () {
    const years: IOption[] = [];

    for (let year = this.years.startYear; year >= this.years.endYear; year--) {
      years.push({
        value: year,
        label: year.toString()
      });
    }

    return years;
  }

  async loadData () {
    const {
      match: { params }
    } = this.props;

    try {
      const [advertisers, annualBudget] = await Promise.all<
      { items: { id: number; name: string }[] },
      IAnnualBudget | null
      >([
        API.advertisers.list({ max: 0, filter: { items: [`AGENCY_${this.props.agencyId}`] } }),
        params.id ? API.annualBudgets.get(params.id) : Promise.resolve(null)
      ]);

      if (!this.mounted) {
        return;
      }

      const advertisersOptions: IOption[] = advertisers.items.map(item => ({
        value: item.id,
        label: item.name
      }));

      if (annualBudget) {
        this.setState({
          advertisersOptions,
          data: annualBudget,
          advertiserId:
            advertisersOptions.find(advertiser => advertiser.value === annualBudget.advertiserId) || null,
          period: {
            label: annualBudget.period,
            value: annualBudget.period,
          },
          budget: annualBudget.budget,
        });
      } else {
        this.setState({
          advertisersOptions
        });
      }
    } catch (error) {
      console.error(error);

      Alert.error(localizeMessage({ id: 'errors.errorLoadingData' }));
    }
  }

  getBreadcrumbs () {
    return [
      {
        title: <LocalizedMessage id='home' />,
        link: '/app'
      },
      {
        title: <LocalizedMessage id='annualBudgets' />,
        link: '/app/annualBudgets'
      },
      {
        title: <LocalizedMessage id='annualBudget' />
      }
    ];
  }

  setFormRef = ref => {
    this._form = ref;
  };

  handleChangeAdvertiserSelect = advertiserId => {
    this.setState({
      advertiserId
    });
  };

  getSelectAdvertiserConfig () {
    const { advertiserId, advertisersOptions } = this.state;

    return {
      isSearchable: false,
      isMulti: false,
      value: advertiserId,
      onChange: this.handleChangeAdvertiserSelect,
      options: advertisersOptions,
      placeholder: (
        <LocalizedMessage id='annualBudget.advertiser.placeholder' />
      ),
      inputId: 'advertiserId'
    };
  }

  handleChangePeriodSelect = period => {
    this.setState({
      period
    });
  };

  getSelectPeriodConfig () {
    const { period, periodOptions } = this.state;

    return {
      isSearchable: false,
      isMulti: false,
      value: period,
      onChange: this.handleChangePeriodSelect,
      options: periodOptions,
      placeholder: <LocalizedMessage id='annualBudget.period.placeholder' />,
      inputId: 'period'
    };
  }

  handleBudgetChange = e => {
    const value = e.target.type === 'file' ? e.target.files[0] : e.target.value;

    this.setState({
      budget: value
    });
  };

  resetClick = () => {
    this._form?.reset();

    this.setState({
      advertiserId: null,
      period: null,
      budget: 0
    });
  };

  onSubmit = async e => {
    e.preventDefault();

    const {
      match: { params }
    } = this.props;
    const { advertiserId, period, budget } = this.state;

    if (
      !advertiserId ||
      !advertiserId?.value ||
      !period || !period?.value ||
      !budget
    ) {
      return;
    }

    try {
      await API.annualBudgets.save(params.id, {
        agencyKeycloakId: this.props.agencyId,
        advertiserKeycloakId: advertiserId.value,
        period: period.value,
        budget
      });

      Alert.success(
        localizeMessage({ id: 'annualBudget.successfullyCreated' })
      );

      this.resetClick();
    } catch (error) {
      console.error(error);

      Alert.warn(localizeMessage({ id: 'annualBudget.errorCreating' }));
    }
  };

  render () {
    const {
      data,

      advertiserId,
      period,
      budget
    } = this.state;

    return (
      <>
        <LocalizedMessage id='site.title.annualBudget'>
          {localizedMessage => (
            <>
              <Helmet title={localizedMessage} />
              <Breadcrumbs
                title={localizedMessage}
                data={this.getBreadcrumbs()}
              />
            </>
          )}
        </LocalizedMessage>

        <div className='wrapper wrapper-content'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='ibox float-e-margins'>
                <div className='ibox-title'>
                  <h5>
                    <LocalizedMessage
                      id={`annualBudget.${!data ? 'adding' : 'editing'}`}
                    />
                  </h5>
                </div>
                <div className='ibox-content'>
                  <form ref={this.setFormRef} onSubmit={this.onSubmit}>
                    <div className='form-horizontal'>
                      <div className='form-group'>
                        <label
                          className='col-lg-2 control-label'
                          htmlFor='advertiserId'
                        >
                          <LocalizedMessage id='advertiser' />
                        </label>
                        <div className='col-lg-10'>
                          <Select {...this.getSelectAdvertiserConfig()} />
                        </div>
                      </div>

                      <div className='form-group'>
                        <label
                          className='col-lg-2 control-label'
                          htmlFor='period'
                        >
                          <LocalizedMessage id='period' />
                        </label>

                        <div className='col-lg-10'>
                          <Select {...this.getSelectPeriodConfig()} />
                        </div>
                      </div>

                      <div className='form-group'>
                        <label
                          className='col-lg-2 control-label'
                          htmlFor='budget'
                        >
                          <LocalizedMessage id='budgetRur' />
                        </label>
                        <div className='col-lg-10'>
                          <LocalizedMessage id='annualBudget.budget.placeholder'>
                            {localizedPlaceholder => (
                              <NumericInput
                                id='budget'
                                className='form-control'
                                name='budget'
                                value={budget}
                                placeholder={localizedPlaceholder}
                                onChange={this.handleBudgetChange}
                              />
                            )}
                          </LocalizedMessage>
                        </div>
                      </div>

                      <div className='hr-line-dashed' />

                      <div className='form-group'>
                        <div className='col-lg-2' />
                        <div className='col-lg-10'>
                          <button
                            className='btn btn-white'
                            onClick={this.resetClick}
                          >
                            <LocalizedMessage id='reset' />
                          </button>
                          &nbsp;&nbsp;
                          <button
                            type='submit'
                            className='btn btn-primary'
                            disabled={
                              !advertiserId ||
                              !advertiserId.value ||
                              !period || !period.value ||
                              !budget
                            }
                            onClick={this.onSubmit}
                          >
                            <>
                              <i className='fa fa-chevron-down fa-lg' />
                              <span>
                                <LocalizedMessage id='save' />
                              </span>
                            </>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AnnualBudget;
