import { IOption } from 'types';

const sourceYearOptions: IOption[] = [];

const now = new Date();
const currentMonth = now.getMonth() + 1; // 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12
const startYear = 2018;
const lastYear = now.getFullYear() + (currentMonth >= 11 ? 1 : 0);

let cYear = startYear;
while (cYear <= lastYear) {
  sourceYearOptions.push({
    label: cYear.toString(),
    value: cYear
  });

  cYear += 1;
}

export const yearOptions = sourceYearOptions;
export const actualYear = now.getFullYear() + (currentMonth >= 12 ? 1 : 0);
