import Client from '../release/HTTPClient';

const calculationTemplatesAPI = {
  list (advertiserId) {
    return Client.get(`/api/clients/${advertiserId}/calculationTemplate/`);
  },

  get (advertiserId, id) {
    return Client.get(`/api/clients/${advertiserId}/calculationTemplate/${id}`);
  },

  delete (advertiserId, id) {
    return Client.delete(`/api/clients/${advertiserId}/calculationTemplate/${id}`);
  },

  save (advertiserId, data) {
    return Client.put(`/api/clients/${advertiserId}/calculationTemplate/new`, data);
  },
};

export default calculationTemplatesAPI;
