import { fetchResponse } from './helpers/fakeFetch';

import sellerRestrictionsList from './data/sellerRestrictionsList.json';

const sellersAPI = {
  restrictions (year) {
    console.log('api.sellers.restrictions(', year, ')');

    return fetchResponse(1000, sellerRestrictionsList);
  },
};

export default sellersAPI;
