import React, { PureComponent } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import cx from 'classnames';
import { saveAs } from 'file-saver';

import API from 'api';
import Alert from 'helpers/alert';
import LocalizedMessage, { localizeMessage } from 'components/LocalizedMessage';
import { MediatorRequestData } from 'types';

import classes from './ButtonsGroup.module.scss';

type Props = RouteComponentProps<{ id: string }> & MediatorRequestData;

class ButtonsGroup extends PureComponent<Props> {
  getRequestData () {
    const { retroFrom, retroTo, mediaType, channels } = this.props;

    return {
      date_start: retroFrom,
      date_end: retroTo,
      media_type: mediaType,
      channels,
    };
  }

  async downloadFile (response: Response, name: string) {
    try {
      const blob = await response.blob();
      const contentDisposition = response.headers.get('content-disposition');
      const filenameData = contentDisposition
        ? contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)
        : null;
      const filename = filenameData
        ? filenameData[1].replace(/['"]+/g, '')
        : name;

      saveAs(blob, filename);
    } catch (error) {
      console.error(error);
      Alert.error(localizeMessage({ id: 'errors.downloadFailed' }));
    }
  }

  exportListOfPrograms = async () => {
    try {
      const data = this.getRequestData();
      const response = await API.annualPlans.exportMediatorsExcel(data);
      this.downloadFile(response, 'defaultMediatorsExcel.xlsx');
    } catch (error) {
      console.error(error);
    }
  };

  uploadProgrammingData = async (e) => {
    try {
      const { match: { params: { id } } } = this.props;
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append('file', file);

      await API.annualPlans.uploadMediatorsData(id, formData);
      Alert.success(localizeMessage({ id: 'annualPlanEdit.tvCampaignParams.alerts.dataLoadedSuccessfully' }));
    } catch (error) {
      console.error(error);
    }
  };

  exportPrevAppliedData = async () => {
    try {
      const { match: { params: { id } } } = this.props;

      const response = await API.annualPlans.exportPrevMediatorsData(id);
      this.downloadFile(response, 'mediatorsAppliedForCalculationExcel.xlsx');
    } catch (error) {
      console.error(error);
    }
  };

  resetData = async () => {
    try {
      const { match: { params: { id } } } = this.props;

      await API.annualPlans.resetMediatorsData(id);
      Alert.success(localizeMessage({ id: 'annualPlanEdit.tvCampaignParams.alerts.dataResetedSuccessfully' }));
    } catch (error) {
      console.error(error);
    }
  };

  render () {
    return (
      <div className={classes.buttonsGroup}>
        <button
          className={cx('btn', 'btn-info', classes.button)}
          onClick={this.exportListOfPrograms}
        >
          <LocalizedMessage id='annualPlanEdit.tvCampaignParams.buttons.getListOfPrograms' />
        </button>
        <label className={cx('btn', 'btn-info', classes.button, classes.upload)}>
          <input
            type='file'
            name='templateFile'
            onChange={this.uploadProgrammingData}
            accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          />
          <LocalizedMessage id='annualPlanEdit.tvCampaignParams.buttons.loadProgrammingData' />
        </label>
        <button
          className={cx('btn', 'btn-info', classes.button)}
          onClick={this.exportPrevAppliedData}
        >
          <LocalizedMessage id='annualPlanEdit.tvCampaignParams.buttons.downloadPreviouslyAppliedData' />
        </button>
        <button
          className={cx('btn', 'btn-info', classes.button)}
          onClick={this.resetData}
        >
          <LocalizedMessage id='annualPlanEdit.tvCampaignParams.buttons.resetData' />
        </button>
      </div>
    );
  }
}

export default withRouter(ButtonsGroup);
