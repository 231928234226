import React, { Component, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import LocalizedMessage from 'components/LocalizedMessage';

import Breadcrumbs from 'components/Breadcrumbs';
import { NumericInput } from 'components/NumericInput';

import API from 'api';

enum PricingType {
  FEDERAL = 'federal',
  LOCAL = 'local',
  THEMATIC = 'thematic'
}

interface IPricingConfig {
  data: File | null;
  year: number;
  agencyRate: number | null;
}

const PricingUploadComponent = ({ type }: { type: PricingType }) => {
  const DEFAULT_YEAR = 2022;

  const [error, setError] = useState<string | undefined>(undefined);
  const [state, setState] = useState<IPricingConfig>({
    data: null,
    year: DEFAULT_YEAR,
    agencyRate: 0.15
  });

  const isValid = state.data != null && (state.year >= 2020 && state.year <= 2030);

  const fileRef = useRef<HTMLInputElement | null>(null);

  const onSubmit = e => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file', state.data as Blob);
    formData.append('year', state.year.toString());

    if (type !== PricingType.FEDERAL) {
      formData.append('agencyRate', (state.agencyRate || 0).toString());
    }

    let some: Promise<any> = Promise.resolve();
    if (type === PricingType.FEDERAL) {
      some = API.pricingUpload.federal(formData);
    } else if (type === PricingType.LOCAL) {
      some = API.pricingUpload.local(formData);
    } else if (type === PricingType.THEMATIC) {
      some = API.pricingUpload.thematic(formData);
    }

    some.then().catch(e => {
      setError(e?.jsonResponse?.message || 'Неизвестная ошибка');
    });
  };

  return (
    <form className='form'>
      <div className='form-group'>
        <h2 className={'control-label col-lg-4'}>
          <LocalizedMessage id={`pricing.pricing-${type}`} />
        </h2>
      </div>
      <div className='form-group'>
        <label className='col-lg-4 control-label'>Excel файл:</label>
        <div className='col-lg-8'>
          <div className='file-input-container'>
            <div>
              <input
                type='file'
                ref={fileRef}
                className='form-control'
                name={'file'}
                onChange={e => {
                  setState({ ...state, data: e.target.files!![0] });
                  setError(undefined);
                }}
                accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
              />
            </div>
            {state.data ? (
              <div>
                <button
                  type='button'

                  className='btn btn-default'
                  onClick={() => {
                    setState({
                      ...state,
                      data: null
                    });
                    if (fileRef.current) {
                      fileRef.current.value = '';
                    }
                  }}
                >
                  <LocalizedMessage id='remove' />
                </button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      {error && (
        <div className='form-group'>
          <label className='col-lg-4 control-label' />
          <div className='col-lg-8 error-message' style={{ color: '#e31414' }}>
            <i className='fa fa-exclamation' style={{ marginRight: 4 }} />
            {error}
          </div>
        </div>
      )}
      <div className='form-group'>
        <label className='col-lg-4 control-label'>Год:</label>
        <div className='col-lg-8'>
          <div className='file-input-container'>
            <NumericInput
              className='form-control'
              value={state.year}
              min={2020}
              max={2100}
              onChange={e => setState({
                ...state,
                year: parseInt(e.target.value) || DEFAULT_YEAR
              })}
            />
          </div>
        </div>
      </div>

      {type !== PricingType.FEDERAL && (
        <div className='form-group'>
          <label className='col-lg-4 control-label'>
            Скидка агенства(в долях):
          </label>
          <div className='col-lg-8'>
            <div className='file-input-container'>
              <NumericInput
                className='form-control'
                value={state.agencyRate || ''}
                onChange={e => setState({
                  ...state,
                  agencyRate: parseFloat(e.target.value) || 0
                })}
              />
            </div>
          </div>
        </div>
      )}

      <div className='form-group'>
        <label className='col-lg-4 control-label' />

        <div className='col-lg-8'>
          <button
            type='submit'
            className='btn btn-primary'
            disabled={!isValid}
            onClick={onSubmit}
          >
            <>
              <span>
                <LocalizedMessage id='upload' />
              </span>
            </>
          </button>
        </div>
      </div>
    </form>
  );
};

class PricingUpload extends Component {
  getBreadcrumbs () {
    return [
      {
        title: <LocalizedMessage id='home' />,
        link: '/app'
      },
      {
        title: <LocalizedMessage id='site.title.pricing' />
      }
    ];
  }

  render () {
    // const data = this.state;

    return (
      <>
        <LocalizedMessage id='site.title.pricing'>
          {localizedMessage => (
            <>
              <Helmet title={localizedMessage} />
              <Breadcrumbs
                title={localizedMessage}
                data={this.getBreadcrumbs()}
              />
            </>
          )}
        </LocalizedMessage>

        <div className='wrapper wrapper-content'>
          <div className='ibox'>
            <div className='ibox-content'>
              <div className='form-horizontal'>
                <PricingUploadComponent type={PricingType.FEDERAL} />
                <PricingUploadComponent type={PricingType.LOCAL} />
                <PricingUploadComponent type={PricingType.THEMATIC} />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default PricingUpload;
