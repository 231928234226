import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { logIn, checkAuth } from 'modules/auth';
import Auth from '../components/Auth';

// noinspection JSUnusedGlobalSymbols
const mapDispatchToProps = {
  logIn,
  checkAuth,
};

const mapStateToProps = (state) => ({
  isAuthorizing: state.auth.isAuthorizing,
  isAuthorized: state.auth.isAuthorized,
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Auth));
