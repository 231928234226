import React, { PureComponent } from 'react';
import { Helmet } from 'react-helmet';
import LocalizedMessage from 'components/LocalizedMessage';
import URLFiltersHoc, { Props } from 'components/URLFiltersHoc';
import Breadcrumbs from 'components/Breadcrumbs';
import TableList from 'components/TableList';

import API from 'api';
import { Link } from 'react-router-dom';

interface IState {
  dataList: any[],
  dataListTotal: number;
  isLoading: boolean;
}

class Goals extends PureComponent<Props, IState> {
  state: IState = {
    dataList: [],
    dataListTotal: 1,
    isLoading: false,
  };

  mounted = false;
  refreshListTimer = 0;
  refreshListTimerDelay = 500;

  componentDidMount () {
    this.mounted = true;
    this.refreshList();
  }

  componentDidUpdate (prevProps) {
    if (prevProps.filters !== this.props.filters) {
      this.refreshList();
    }
  }

  componentWillUnmount () {
    this.mounted = false;
    clearTimeout(this.refreshListTimer);
  }

  refreshList () {
    clearTimeout(this.refreshListTimer);
    const { currentPage, currentSort, max } = this.props.filters;
    const dataListRequest = {
      page: currentPage,
      order: currentSort,
      max,
    };

    this.refreshListTimer = window.setTimeout(async () => {
      this.setState({ isLoading: true });

      const dataList = await API.goals.list(dataListRequest);

      if (!this.mounted) {
        return;
      }

      this.setState({
        dataList: dataList.items,
        dataListTotal: dataList.total,
        isLoading: false,
      });
    }, this.refreshListTimerDelay);
  }

  getBreadcrumbs () {
    return [
      {
        title: <LocalizedMessage id='home' />,
        link: '/app',
      },
      {
        title: <LocalizedMessage id='goals' />,
        link: '/app/goals',
      },
    ];
  }

  editGoal (id) {
    const { history } = this.props;
    history.push(`/app/goals/${id}`);
  }

  render () {
    const {
      dataListTotal,
      dataList,
      isLoading,
    } = this.state;
    const { onUpdateSort, onUpdatePage, filters } = this.props;

    return (
      <>
        <LocalizedMessage
          id={[
            'site.title.goals',
            'site.title.goals.add',
          ]}
        >
          {(localizedMessage, localizedAddGoalMessage) => (
            <>
              <Helmet
                title={localizedMessage}
              />
              <Breadcrumbs
                title={localizedMessage}
                data={this.getBreadcrumbs()}
                className='col-lg-8'
              >
                <div className='col-lg-4'>
                  <div className='title-action'>
                    <Link
                      className='btn btn-warning'
                      to='/app/goals/create'
                    >
                      <i className='fa fa-plus fa-lg' />
                      {localizedAddGoalMessage}
                    </Link>
                  </div>
                </div>
              </Breadcrumbs>
            </>
          )}
        </LocalizedMessage>

        <div className='wrapper wrapper-content'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='ibox float-e-margins'>
                <div className='ibox-content'>
                  <div className='table-responsive'>
                    <LocalizedMessage id={['id', 'name', 'kpi']}>
                      {(localizedId, localizedName, localizedKpi) => (
                        <TableList
                          currentPage={filters.currentPage}
                          currentSort={filters.currentSort}
                          totalItems={dataListTotal}
                          itemsCountPerPage={filters.max}
                          onUpdateSort={onUpdateSort}
                          onUpdatePage={onUpdatePage}
                          isLoading={isLoading}
                          head={[
                            {
                              label: localizedId,
                              sort: 'id',
                            },
                            {
                              label: localizedName,
                              sort: 'name',
                            },
                            {
                              label: localizedKpi,
                              sort: 'kpi',
                            },
                          ]}
                        >
                          {
                            dataList.map(goal => (
                              <tr
                                key={`${goal.id}_${goal.name}`}
                                onClick={() => this.editGoal(goal.id)}
                                className='_cursor--pointer'
                              >
                                <td>
                                  {goal.id}
                                </td>
                                <td>
                                  {goal.name}
                                </td>
                                <td className='font-italic'>
                                  {
                                    goal.kpi
                                      ? goal.kpi.map((kpi, index) => (
                                        <p key={index}>{kpi.name}</p>
                                      ))
                                      : ''
                                  }
                                </td>
                              </tr>
                            ))
                          }
                        </TableList>
                      )}
                    </LocalizedMessage>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default URLFiltersHoc(Goals);
