import FilterItems, { IProcessedFilterItem } from 'helpers/filterItems';
import Client from 'api/release/HTTPClient';
import { IChannel } from 'routes/AnnualPlanEdit/types';
import {
  IChannelType,
  IFilterItem,
  IGenericListRequest,
  INaturalDelivery,
  IServerPage
} from 'types';

const channelsAPI = {
  list (request: IGenericListRequest): Promise<IServerPage<IChannel>> {
    return Client.post('/api/channels/selling-by-tvr', request);
  },

  getTypes (): Promise<IChannelType[]> {
    return Client.get('/api/channels/types');
  },

  get (id: number): Promise<IChannel> {
    return Client.get(`/api/channels/${id}`);
  },

  getNaturalSplit (
    eventStart: string,
    eventEnd: string,
    expressionId: number,
    channelType: IChannelType,
    regionId: number | null
  ): Promise<INaturalDelivery[]> {
    return Client.get(
      '/api/channels/natural-split',
      {
        eventStart,
        eventEnd,
        expressionId,
        channelType,
        ...(regionId ? { regionId } : null)
      },
      true,
      {},
      true,
      null,
      {
        withoutLoader: true
      }
    );
  },

  async getFilterItems (request): Promise<IProcessedFilterItem[]> {
    const result: IFilterItem[] = await Client.get(
      '/api/channels/filter-items',
      request
    );

    return FilterItems.getDataAndPostProcess(result);
  }
};

export default channelsAPI;
