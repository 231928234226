import FilterItems, { IProcessedFilterItem } from 'helpers/filterItems';
import Client from 'api/release/HTTPClient';
import { IServerPage, IUserRepresentation, IUserRole } from 'types';

const usersAPI = {
  list (request): Promise<IServerPage<IUserRepresentation>> {
    return Client.post('/api/users/', request);
  },

  getRoles (request): Promise<IUserRole[]> {
    return Client.get('/api/users/roles/', request);
  },

  async getFilterItems (request): Promise<IProcessedFilterItem[]> {
    const result = await Client.get('/api/users/filter-items', request);

    return FilterItems.getDataAndPostProcess(result);
  },

  get (id): Promise<IUserRepresentation> {
    return Client.get(`/api/users/${id}`);
  },

  save (id, request): Promise<IUserRepresentation> {
    if (!id) {
      return Client.post('/api/users/new', request);
    }

    return Client.post(`/api/users/${id}`, request);
  },

  enable (id): Promise<void> {
    return Client.post(`/api/users/${id}/enable`);
  },

  disable (id):Promise<void> {
    return Client.post(`/api/users/${id}/disable`);
  },
};

export default usersAPI;
