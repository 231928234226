import Client from 'api/release/HTTPClient';
import { IMediaplanTemplate } from 'types';

const mediaplanTemplatesAPI = {
  list (): Promise<IMediaplanTemplate[]> {
    return Client.get('/api/mediaplans/templates/');
  },

  get (id: number): Promise<IMediaplanTemplate> {
    return Client.get(`/api/mediaplans/templates/${id}`);
  },

  getDefaultTemplates (): Promise<IMediaplanTemplate[]> {
    return Client.get('/api/mediaplans/templates/defaultTemplates/');
  },

  saveOrUpdate (data): Promise<IMediaplanTemplate> {
    return Client.post('/api/mediaplans/templates/', data, true, { isJson: false, isMultipart: true });
  },

  getFile (id: number): Promise<string> {
    return Client.get(`/api/mediaplans/templates/file/${id}`, {}, true, {
      isJson: false,
    });
  },
};

export default mediaplanTemplatesAPI;
